import {
	AfterViewInit,
	Component,
	ElementRef,
	Inject,
	OnInit,
	ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import Player from '@vimeo/player';
import { BroadcastService } from 'src/app/core/services/broadcast.service';

@Component({
	selector: 'app-new-user-tutorial-popup',
	templateUrl: './new-user-tutorial-popup.component.html',
	styleUrls: ['./new-user-tutorial-popup.component.scss'],
})
export class NewUserTutorialPopupComponent implements AfterViewInit {
	@ViewChild('vimeo_player') public vimeoPlayer!: ElementRef;
	public closeButtonVisible: boolean = false;

	/**
	 * [ BEFORE_LOGIN, AFTER_LOGIN ]
	 *
	 */
	// private videoId = ['995294249', '996592022'];
	private videoId = ['1001907793', '1001907764'];
	constructor(
		private matDialogRef: MatDialogRef<NewUserTutorialPopupComponent>,
		@Inject(MAT_DIALOG_DATA) public data: { beforeLogin: boolean },
		private sanitize: DomSanitizer,
		private broadcastService: BroadcastService
	) {}

	ngAfterViewInit(): void {
		this.initVimeo();
	}

	public get vid() {
		return this.data.beforeLogin ? this.videoId[0] : this.videoId[1];
	}

	public get video() {
		return this.sanitize.bypassSecurityTrustResourceUrl(
			`https://player.vimeo.com/video/${this.vid}?badge=0&amp;autoplay=1&amp;autopause=0&amp;player_id=0&amp;app_id=58479`
		);
	}

	private initVimeo() {
		const vimeoPlayerWidth = this.vimeoPlayer.nativeElement.offsetWidth;

		const options = {
			id: this.vid,
			width: vimeoPlayerWidth,
			loop: false,
			height: this.vimeoPlayer.nativeElement.offsetHeight - 7
		};
		const player = new Player(this.vimeoPlayer.nativeElement, options);
		player.play();
		player.on('play', () => {
			console.log('played');
		});
		player.on('ended', () => {
			this.closeButtonVisible = true;
			this.close();
		});
	}

	public close() {
		this.matDialogRef.close();
	}
}
