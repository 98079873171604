import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	Resolve,
	Router,
	RouterStateSnapshot,
} from '@angular/router';
import { forkJoin, Observable, take } from 'rxjs';
import { AddNewCodeInterface } from '../_models/manage-codes.interface';
import { SettingsService } from '../_services/settings.service';

@Injectable({
	providedIn: 'root',
})
export class GetProductAccessSettingsResolver implements Resolve<any> {
	constructor(private settingsService: SettingsService) {}
	resolve(): Observable<any> {
		return forkJoin([
			this.settingsService.getProductAccessSettings().pipe(take(1)),
			this.settingsService.getAccountManagers().pipe(take(1)),
		]);
	}
}
