import { Component, Input, Output, EventEmitter } from '@angular/core';
import { LocalDataService } from 'src/app/core/services/local-data.service';

@Component({
	selector: 'app-report-footer',
	templateUrl: './report-footer.component.html',
	styleUrls: ['./report-footer.component.scss'],
})
export class ReportFooterComponent {
	@Input() tableDetails: {
		contentSize: number;
		limit: any;
		offset: number;
	};
	@Output() pageChange: EventEmitter<number> = new EventEmitter();
	@Output() limitChange: EventEmitter<string | number> = new EventEmitter();
	constructor(public localData: LocalDataService) {}

	public changeLimit(limit: number | string): void {
		this.limitChange.next(limit);
	}
	public changePage(pageNo: number): void {
		this.pageChange.next(pageNo);
	}
}
