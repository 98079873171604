<div class="mt-2" [class.sticky-report]="isStickyHead">
	<div [class.table-responsive]="!isStickyHead" class="common-table">
		<table
			class="slr-table mat-elevation-z8"
			[class]="themeClass"
			mat-table
			matSort
			[matSortActive]="activeSort"
			[matSortDirection]="sortDirection"
			[dataSource]="tableDataSource"
		>
			<!-- Legend Column -->
			<ng-container *ngIf="hasLegend" [matColumnDef]="'legend'">
				<th
					class="legend-header"
					mat-header-cell
					*matHeaderCellDef
				></th>
				<td
					tableLegend
					[element]="element"
					[legendData]="legendData"
					[key]="legendColumnName"
					mat-cell
					*matCellDef="let element"
					class="table-legend"
				></td>
			</ng-container>

			<!-- Checkbox column -->
			<ng-container *ngIf="isSelectable" [matColumnDef]="'select'">
				<th
					class="check-box-header-cell"
					mat-header-cell
					*matHeaderCellDef
				></th>
				<td
					class="check-box-cell"
					mat-cell
					*matCellDef="let element; let i = index"
				>
					<mat-checkbox
						(change)="toggleSelection(element, $event, i)"
						[checked]="selection.isSelected(element)"
					></mat-checkbox>
				</td>
			</ng-container>

			<!-- Generic Columns -->
			<ng-container
				*ngFor="let tableColumn of tableColumns"
				[matColumnDef]="tableColumn.name"
			>
				<ng-container *ngIf="tableColumn.isVisible">
					<th mat-header-cell *matHeaderCellDef>
						<div
							class="header-container"
							(keyup)="sortColumn({ column: tableColumn }, true)"
							(click)="sortColumn({ column: tableColumn }, true)"
							(keyup)="sortColumn({ column: tableColumn }, true)"
						>
							{{ tableColumn.name | translate }}
							<mat-icon class="sort-icon" *ngIf="isSortable"
								>unfold_more</mat-icon
							>
						</div>
					</th>
					<ng-container *matCellDef="let element">
						<ng-container
							*ngIf="
								tableColumn.mergeWithPrevious
									? !tableColumn.mergeWithPrevious(element)
									: true
							"
						>
							<td
								[ngClass]="{
									'ellipsis-text': tableColumn.tooltip,
									'cursor-pointer': tableColumn.clickAction,
									'no-wrap': tableColumn.noWrap,
									'break-content': tableColumn.breakWord
								}"
								[ngStyle]="{
									'max-width':
										tableColumn.tooltip &&
										tableColumn.tooltip.length + 'ch'
								}"
								[matTooltip]="
									tableColumn.tooltip && element
										| dataPropertyGetter
											: tableColumn.dataKey
								"
								(click)="
									tableColumn.clickAction &&
										emitAction(
											element,
											tableColumn.clickActionType
										)
								"
								(keyup)="
									tableColumn.clickAction &&
										emitAction(
											element,
											tableColumn.clickActionType
										)
								"
								mat-cell
								[attr.colspan]="
									getColspan(element, tableColumn)
								"
							>
								<div
									*ngIf="
										tableColumn.colspanCondition
											? !tableColumn.colspanCondition(
													element
											  )
											: true;
										else mergedColText
									"
									class="d-flex align-items-center gap-2"
								>
									<span
										*ngIf="
											tableColumn.clickActionType ===
											'UPDATE'
										"
									>
										<slr-toggle
											[checked]="element.docSharer"
											[label]="
												element.docSharer ? 'YES' : 'NO'
											"
											(toggle)="
												element.docSharer =
													!element.docSharer
											"
										></slr-toggle>
									</span>
									<span
										*ngIf="
											tableColumn.clickActionType !==
											'UPDATE'
										"
									>
										{{
											element
												| dataPropertyGetter
													: tableColumn.dataKey
												| slrDate
												| translate
										}}{{
											tableColumn.isPercentage ? "%" : ""
										}}
									</span>

									<ng-container
										*ngIf="
											tableColumn.icons &&
											tableColumn.icons.length > 0
										"
									>
										<ng-contanier
											table-data
											[condition]="item"
											[data]="element"
											*ngFor="
												let item of tableColumn.icons
											"
										></ng-contanier>
									</ng-container>
								</div>

								<ng-template #mergedColText
									><div>
										{{ tableColumn.mergedText | translate }}
									</div></ng-template
								>
							</td>
						</ng-container>
					</ng-container>
				</ng-container>
			</ng-container>

			<!-- Action Column -->
			<ng-container [matColumnDef]="'action'" [stickyEnd]="true">
				<th mat-header-cell *matHeaderCellDef></th>
				<td
					mat-cell
					*matCellDef="let element"
					[class]="'action-column'"
				>
					<div class="action-buttons d-flex align-items-center gap-1">
						<ng-container *ngFor="let button of actionButtons">
							<button
								table-action-button
								[condition]="button.condition"
								[data]="element"
								(click)="
									!button.url &&
										emitAction(element, button.icon)
								"
								mat-button
								[matTooltip]="button.tooltip | translate"
								[matTooltipPosition]="'above'"
							>
								<ng-container
									*ngIf="button.url; else onlyButton"
								>
									<a
										[routerLink]="
											getLink(button.url, element)
										"
										[queryParams]="button.queryParams ?? {}"
									>
										<mat-icon
											[class]="
												button.class ?? 'color-gray-4'
											"
											>{{ button.icon }}</mat-icon
										>
									</a>
								</ng-container>
								<ng-template #onlyButton>
									<mat-icon
										[class]="button.class ?? 'color-gray-4'"
										>{{ button.icon }}</mat-icon
									>
								</ng-template>
							</button>
						</ng-container>
					</div>
				</td>
			</ng-container>

			<ng-container
				*ngFor="let rowAction of rowActionIcon"
				[matColumnDef]="rowAction"
				[stickyEnd]="true"
			>
				<th mat-header-cell *matHeaderCellDef></th>
				<td
					mat-cell
					*matCellDef="let element"
					[id]="rowAction"
					[class]="'action-column'"
					(click)="emitRowAction(element, rowAction)"
					(keyup)="emitRowAction(element, rowAction)"
				>
					<button mat-button>
						<mat-icon>{{ rowAction }}</mat-icon>
					</button>
				</td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr
				mat-row
				*matRowDef="let row; columns: displayedColumns"
				tableLegend
				[legendData]="legendData"
				[element]="row"
				[key]="legendColumnName"
			></tr>
		</table>
	</div>
</div>
<div class="no-data-row" *ngIf="_tableData.length == 0">
	{{ "NO_DATA_AVAILABLE" | translate }}
</div>

<div *ngIf="_tableData.length > 0" class="row mt-2 py-2">
	<app-ng-pagination [listSize]="_tableData.length"></app-ng-pagination>
</div>
