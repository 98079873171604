import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { DropdownInterface } from 'src/app/shared/components/dropdowns/slr-drp01/dropdown.model';
import {
	PESurveyInterface,
	PESurveySubmitINterface,
} from 'src/app/shared/models/program-evaluation-survey.model';
import { submitPESurvey } from 'src/app/shared/_state/shared.actions';
import { SharedState } from 'src/app/shared/_state/shared.reducer';
import { LocalDataService } from 'src/app/core/services/local-data.service';

@Component({
	selector: 'app-common-survey-popup',
	templateUrl: './common-survey-popup.component.html',
	styleUrls: ['./common-survey-popup.component.scss'],
})
export class CommonSurveyPopupComponent implements OnInit {
	public qArray: Array<PESurveyInterface>;
	public surveyAnswerForm: FormGroup;
	public qnaMap: Map<number, Array<DropdownInterface>>;
	constructor(
		private matDialogref: MatDialogRef<CommonSurveyPopupComponent>,
		private _fb: FormBuilder,
		@Inject(MAT_DIALOG_DATA)
		private data: { data: Array<PESurveyInterface>; courseId: number },
		private sharedStore: Store<SharedState>,
		private localData: LocalDataService
	) {
		this.qArray = data.data;
	}

	ngOnInit(): void {
		this.initQnAMap();
		this.initForm();
	}
	private initQnAMap(): void {
		this.qnaMap = this.qArray.reduce((a, b, i) => {
			return {
				...a,
				[b.questionId]: this.localData
					.sortStrings(b.options, 'valueId')
					.map((x, i) => {
						return {
							id: x.optionId,
							name: x.option,
						};
					}),
			};
		}, new Map<number, Array<DropdownInterface>>());
	}

	private initForm(): void {
		this.surveyAnswerForm = this._fb.group(
			this.qArray.reduce((a, b) => {
				return {
					...a,
					[`${b.questionId}`]: new FormControl(),
				};
			}, {})
		);
	}
	private getOptionId(qid: number, option: string): number {
		return this.qnaMap[qid].find((x) => x.name == option).id;
	}

	private getQnOArray(): Array<{ option: number; questionId: number }> {
		return Object.entries(this.surveyAnswerForm.value).reduce(
			(a, [k, v]) => {
				return [
					...a,
					{
						option: this.getOptionId(+k, v as string),
						questionId: k,
					},
				];
			},
			[]
		);
	}

	public getOptions(id: number): Array<DropdownInterface> {
		return this.qArray
			.find((x) => x.questionId == id)
			.options.map((x, i) => {
				return {
					id: x.optionId,
					name: x.option,
				};
			});
	}

	public onSubmit(): void {
		const payload: PESurveySubmitINterface = {
			courseId: this.data.courseId,
			questionOptions: this.getQnOArray(),
		};
		this.sharedStore.dispatch(submitPESurvey({ payload: payload }));
	}

	public close(): void {
		this.matDialogref.close();
	}
}
