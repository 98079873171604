<div class="d-flex flex-column" style="height: 100%">
	<app-modal-header-placeholder
		[title]="'SEND_NEW_MESSAGE'"
		(onClose)="close()"
	></app-modal-header-placeholder>

	<div class="new-message-board overflow-hidden container-fluid">
		<div class="row flex-grow-1">
			<div class="col-sm-12 col-lg-4 col-md-6 overflow-y-scroll">
				<div *ngIf="!isChildLocation">
					<label for="">{{ "SET_LOCATION" | translate }}</label>
					<slr-dpr02
						[placeholder]="'SET_LOCATION'"
						[data]="locationData"
						[fgName]="newMessageForm"
						[controlName]="'locationIds'"
						[appendTo]="'body'"
					></slr-dpr02>
				</div>
				<div [class.mt-2]="!isChildLocation">
					<label for="">{{ "SELECT_GROUPS" | translate }}</label>
					<slr-dpr02
						[placeholder]="'SELECT_GROUPS'"
						[data]="groupData"
						[fgName]="newMessageForm"
						[controlName]="'groupIds'"
						[appendTo]="'body'"
					></slr-dpr02>
				</div>
				<div class="mt-2">
					<label for="">{{ "SELECT_ROLES" | translate }}</label>
					<slr-dpr02
						[placeholder]="'SELECT_ROLES'"
						[data]="userRoleData"
						[fgName]="newMessageForm"
						[controlName]="'roleIds'"
						[appendTo]="'body'"
					></slr-dpr02>
				</div>
				<div class="mt-2">
					<label for="">{{ "INDIVIDUAL_USERS" | translate }}</label>
					<slr-dpr02
						[placeholder]="'INDIVIDUAL_USERS'"
						[data]="availableIndividualUsers"
						[fgName]="newMessageForm"
						[controlName]="'userIds'"
						(itemAdded)="assignUser($event)"
						(itemRemoved)="removeUser($event)"
						[appendTo]="'body'"
					></slr-dpr02>
				</div>
				<div class="mt-2">
					<label for="">{{ "EXEMPT_USERS" | translate }}</label>
					<slr-dpr02
						[placeholder]="'EXEMPT_USERS'"
						[data]="availableExcemptedUsers"
						[fgName]="newMessageForm"
						[controlName]="'exemptUserIds'"
						(itemAdded)="exemptUser($event)"
						(itemRemoved)="removeExemptedUser($event)"
						[appendTo]="'body'"
					></slr-dpr02>
				</div>
			</div>
			<div class="col-sm-12 col-lg-8 col-md-6">
				<div class="d-flex flex-column" style="height: 100%">
					<div [formGroup]="newMessageForm" class="row flex-grow-1">
						<div class="col-12">
							<textarea
								formControlName="message"
								#messageInput
								type="text"
								class="message-input"
								[placeholder]="
									'NEW_MESSAGE_PLACEHOLDER' | translate
								"
							></textarea>
						</div>
					</div>
					<div class="row mt-3">
						<div class="col-12">
							<slr-rbtn-group
								[title]="''"
								[data]="sendMessageAsData"
								[align]="isMobile ? 'vertical' : 'horizontal'"
								(onChange)="onSendEmail($event)"
							></slr-rbtn-group>
						</div>
					</div>
					<div class="row mt-3 align-items-center">
						<div class="col-sm-12 col-lg-6">
							<slr-date-input
								[label]="'MESSAGE_EXPIRE_DATE'"
								[fgName]="newMessageForm"
								[controlName]="'expireDate'"
								[min]="today"
							>
							</slr-date-input>
						</div>
						<div class="col-sm-12 col-lg-6">
							<ng-container *ngIf="validEmailCount !== undefined">
								[{{ validEmailCount }} valid emails found in
								system.]
							</ng-container>
						</div>
					</div>
					<div class="row mt-3">
						<div class="col-12">
							<slr-btn02
								(onClick)="createNewConversation(null)"
								>{{ "SEND" | translate }}</slr-btn02
							>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
