import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs';
import { ValidatorMessage } from 'src/app/core/models/validation-messages.model';
import { ErrorMessageService } from 'src/app/core/services/error-message.service';

@Component({
	selector: 'slr-input02',
	templateUrl: './slr-input02.component.html',
	styleUrls: ['./slr-input02.component.scss'],
})
export class SlrInput02Component implements OnInit {
	@Input() label: string;
	@Input() placeholder: string;
	@Input() width: string = '100%';
	@Input() disabled: boolean = false;
	@Input() preText: string;
	@Input() public validatorMessages: ValidatorMessage[];

	@Input() public fgName: FormGroup;
	@Input() public controlName: string;
	@Input() public subControlName: string;

	public defaultValidatorMessages: ValidatorMessage[];
	public errorMessage: string;

	constructor(private errorMessageService: ErrorMessageService) {}

	ngOnInit(): void {
		this.fgName.get(this.subControlName).setValue(this.preText);
		this.defaultValidatorMessages = [
			{
				validationType: 'required',
				validationMessage: `${this.placeholder} is required`,
			},
			...(this.validatorMessages ?? []),
		];
		this.setErrorMessage();
	}
	public setErrorMessage(): void {
		this.errorMessage = this.errorMessageService.setErrorMessage(
			this.defaultValidatorMessages,
			this.fgName.get(this.controlName)
		);
	}
}
