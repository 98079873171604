import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
	forkJoin,
	filter,
	map,
	mergeMap,
	Observable,
	of,
	skipWhile,
	take,
} from 'rxjs';
import { LocalDataService } from 'src/app/core/services/local-data.service';
import { UrlNames } from 'src/app/core/services/urlProfiler';
import { BaseWebApiService } from 'src/app/core/services/_base-web-api.service';
import {
	getUserPrivileges,
	SharedState,
} from 'src/app/shared/_state/shared.reducer';
import {
	AddNewCodeInterface,
	CodeListInterface,
	ManageCodeClientInterface,
	SaveCodeListInterface,
} from '../_models/manage-codes.interface';
import { EncryptionService } from 'src/app/core/services/encryption.service';

@Injectable({
	providedIn: 'root',
})
export class SettingsService {
	private _locationId: number;
	private _clientCode: string;
	private _userId: number;
	constructor(
		private apiService: BaseWebApiService,
		private sharedStore: Store<SharedState>,
		private localDataService: LocalDataService,
		private encryptionService: EncryptionService
	) {
		this.sharedStore
			.pipe(
				select(getUserPrivileges),
				filter((value) => !!value)
			)
			.subscribe({
				next: (res) => {
					this._locationId = res.locationId;
					this._userId = res.userId;
					this._clientCode = res.siteCode;
				},
			});
	}

	public getAllCodes(): Observable<AddNewCodeInterface> {
		return forkJoin([
			this.getGrandparentCodes(),
			this.getParentCodes(),
			this.getVendorCodes(),
			this.getSharedCodeLists(),
		]).pipe(
			mergeMap((x) => {
				return of({
					grandParent: x[0],
					parent: x[1],
					vendor: x[2],
					shared: x[3],
				});
			}),
			map((x) => x)
		);
	}

	public getGrandparentCodes(): Observable<Array<ManageCodeClientInterface>> {
		return this.apiService.get(
			`${UrlNames.manageCodes}${UrlNames.grandperentList}`,
			{
				userId: this._userId,
				clientCode: this.localDataService.getSiteCode(),
			}
		);
	}
	public getParentCodes(): Observable<Array<ManageCodeClientInterface>> {
		return this.apiService.get(
			`${UrlNames.manageCodes}${UrlNames.parentList}`,
			{
				userId: this._userId,
				clientCode: this.localDataService.getSiteCode(),
			}
		);
	}
	public getVendorCodes(): Observable<Array<ManageCodeClientInterface>> {
		return this.apiService.get(
			`${UrlNames.manageCodes}${UrlNames.locationList}`,
			{
				userId: this._userId,
				clientCode: this.localDataService.getSiteCode(),
			}
		);
	}
	public getCodeDetails(): Observable<Array<ManageCodeClientInterface>> {
		return this.apiService.get(
			`${UrlNames.manageCodes}${UrlNames.codeDetails}`,
			{
				userId: this._userId,
				clientCode: this.localDataService.getSiteCode(),
			}
		);
	}

	public saveManageCodes(payload: SaveCodeListInterface): Observable<Object> {
		payload = {
			...payload,
			clientCode: this.localDataService.getSiteCode(),
		};
		return this.apiService.post(
			`${UrlNames.manageCodes}${UrlNames.locationList}`,
			payload
		);
	}

	public getCodeList(): Observable<Array<CodeListInterface>> {
		return this.apiService
			.get<Array<CodeListInterface>>(
				`${UrlNames.manageCodes}${UrlNames.codelist}`,
				{
					userId: this._userId,
					clientCode: this.localDataService.getSiteCode(),
				}
			)
			.pipe(
				map((res: Array<CodeListInterface>) => {
					return res.map((x) => {
						return {
							...x,
							displayName: `${x.clientName} (${x.groupCode})`,
							_docSharer: x.docSharer ? 'Yes' : 'No',
						};
					});
				})
			);
	}

	public deleteManageCodes(
		clientLookupId: number,
		code: string
	): Observable<any> {
		return this.apiService.delete(
			`${UrlNames.deleteManageCodes}`,
			undefined,
			undefined,
			undefined,
			{
				userId: this._userId,
				clientLookupId,
				locationId: this._locationId,
				deleteCode: code,
			}
		);
	}

	public updateManageCodes(
		clientLookupId: number,
		docSharer: boolean
	): Observable<any> {
		return this.apiService.put(`${UrlNames.editdocsharer}`, null, {
			userId: this._userId,
			clientLookupId: clientLookupId,
			docSharer: +docSharer,
		});
	}

	public getSettings(userId?: number, locationId?: number): Observable<any> {
		if (userId && locationId) {
			return this.apiService.get(UrlNames.getSettings, {
				userId: userId,
				locationId: locationId,
			});
		} else {
			return this.apiService.get(UrlNames.getSettings, {
				userId: this.localDataService.getUserId(),
				locationId: this.localDataService.getLocationId(),
			});
		}
	}

	public getUISettings(userId: number, locationId: number): Observable<any> {
		return this.apiService.get(UrlNames.getUISettings, {
			userId: userId,
			locationId: locationId,
		});
	}

	public getUserAccessSettings(): Observable<any> {
		return this.apiService.get(UrlNames.getUserAccessSettings, {
			userId: this._userId,
			locationId: this._locationId,
		});
	}

	public getProductAccessSettings(clientId?: number): Observable<any> {
		return this.apiService.get(`${UrlNames.getSecretKey}`, {}).pipe(
			mergeMap((response: any) => {
				let key = response?.secretKey;
				return this.apiService
					.get(UrlNames.getProductAccessSettings, {
						locationId: clientId ? clientId : this._locationId,
					})
					.pipe(
						map((response: any) => {
							if (response.data && response.data[0]) {
								let decryptedData = {
									...response.data[0],
									defaultPassword:
										this.encryptionService.decrypt(
											response.data[0].defaultPassword,
											key
										),
								};
								response.data[0] = decryptedData;
								return response;
							}
							return response;
						})
					);
			})
		);
	}

	public getGeneralUserProperties(clientId?: number) {
		return this.apiService.get(`${UrlNames.getSecretKey}`, {}).pipe(
			mergeMap((response: any) => {
				let key = response?.secretKey;
				return this.apiService
					.get(UrlNames.getGeneralUserProperties, {
						clientId: clientId ? clientId : this._locationId,
					})
					.pipe(
						map((response: any) => {
							if (response.data && response.data[0]) {
								let decryptedData = {
									...response.data[0],
									defaultPassword:
										this.encryptionService.decrypt(
											response.data[0].defaultPassword,
											key
										),
								};
								response.data[0] = decryptedData;
								return response;
							}
							return response;
						})
					);
			})
		);
	}

	public getAccountManagers(): Observable<any> {
		return this.apiService.get(UrlNames.getAccountManagers, null);
	}

	public getUserAccessClasses(): Observable<any> {
		return this.apiService.get(UrlNames.userClassAccess, {
			userId: this._userId,
		});
	}

	public editSettings(settingsPayload: any): Observable<any> {
		return this.apiService.put(UrlNames.getSettings, settingsPayload);
	}

	public editProductAccessSettings(settingsPayload: any): Observable<any> {
		return this.apiService.get(`${UrlNames.getSecretKey}`, {}).pipe(
			mergeMap((response: any) => {
				let key = response?.secretKey;
				let encryptedPayload = {
					...settingsPayload,
					defaultPassword: this.encryptionService.encrypt(
						settingsPayload.defaultPassword,
						key
					),
				};
				return this.apiService.put(
					UrlNames.getProductAccessSettings,
					encryptedPayload,
					{
						locationId: this._locationId,
					}
				);
			})
		);
	}

	public editUserAccessSettings(settingsPayload: any): Observable<any> {
		return this.apiService.put(
			UrlNames.getUserAccessSettings,
			settingsPayload
		);
	}

	public getSharedCodeLists(): Observable<any> {
		return this.apiService.get(
			`${UrlNames.manageCodes}${UrlNames.sharedList}`,
			{
				userId: this._userId,
				clientCode: this.localDataService.getSiteCode(),
			}
		);
	}
}
