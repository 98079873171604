import {
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Optional,
	Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ValidatorMessage } from 'src/app/core/models/validation-messages.model';
import { DropdownInterface } from '../slr-drp01/dropdown.model';
import { Observable, startWith, map } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { MatOptionSelectionChange } from '@angular/material/core';
import { ErrorMessageService } from 'src/app/core/services/error-message.service';
import { TranslateService } from '@ngx-translate/core';
import { of, debounceTime } from 'rxjs';
@Component({
	selector: 'slr-drp03',
	templateUrl: './slr-drp03.component.html',
	styleUrls: ['./slr-drp03.component.scss'],
})
export class SlrDrp03Component implements OnInit, AfterViewInit {
	@Input() fgName: FormGroup;
	@Input() controlName: string;
	@Input() control: FormControl;
	@Input() width: string = '100%';
	@Input() multiple: boolean = false;
	@Input() validatorMessages: ValidatorMessage[];
	@Input() placeholder: string = 'Select';
	@Input() label: string = 'Select';
	@Input() data: DropdownInterface[];
	@Input() showicon: boolean = false;
	@Input() icon: string = 'user';
	@Input() isDisabled: boolean = false;
	public errorMessage: string;
	private defaultValidatorMessage: Array<ValidatorMessage>;
	@Output() onAdded = new EventEmitter<DropdownInterface>();
	@Output() onRemoved = new EventEmitter<DropdownInterface>();

	selectedItems = new SelectionModel<DropdownInterface>(true, []);
	selectedValues = [];
	filteredOptions: Observable<DropdownInterface[]>;
	public searchTextboxControl = new FormControl();

	constructor(
		private errorMessageService: ErrorMessageService,
		private translateService: TranslateService
	) {}

	ngOnInit(): void {
		this.filteredOptions = this.searchTextboxControl.valueChanges.pipe(
			debounceTime(500),
			startWith<string>(''),
			map((name) => this._filter(name))
		);
		// this.selectedItems.changed.asObservable().subscribe({
		// 	next: (res) => {},
		// });
		this.setDefaultValidatorMessage();
		this.setErrorMessage();
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.selectFormControl.value.forEach((x) => {
				this.selectedItems.select(x);
			});
		});
	}
	getTranslatedString(key: string) {
		if (key) {
			return this.translateService.get(key);
		}
		return of(key);
	}
	private setDefaultValidatorMessage() {
		let localizedKey = this.placeholder;
		this.getTranslatedString(this.placeholder).subscribe((res) => {
			localizedKey = res;
		});
		this.defaultValidatorMessage = [
			{
				validationType: 'required',
				validationMessage: `${localizedKey} is required`,
			},
		];
	}

	compareCategoryObjects(object1: number, object2: DropdownInterface) {
		return object1 && object2 && object1 == object2.id;
	}
	updateSelection(
		item: DropdownInterface,
		event: MatOptionSelectionChange
	): void {
		if (!event.isUserInput) return;
		if (event.source.selected) {
			this.itemAdded(item);
		} else {
			this.itemRemoved(item);
		}
	}

	private itemAdded(item: DropdownInterface): void {
		this.selectedItems.select(item);
		this.selectFormControl.setValue(this.selectedItems.selected);
		this.onAdded.emit(item);
	}
	private itemRemoved(item: DropdownInterface): void {
		this.deselectEntry(item);
		this.onRemoved.emit(item);
	}

	deselectEntry(item: DropdownInterface, fromUi: boolean = false): void {
		let newArray = this.selectedItems.selected.filter(
			(x) => x.id !== item.id
		);
		this.selectedItems.clear();
		this.selectedItems.select(...newArray);

		if (fromUi) {
			this.selectFormControl.setValue(this.selectedItems.selected);
		}
	}

	public get selectFormControl() {
		return this.fgName.get(this.controlName);
	}

	private _filter(name: string): DropdownInterface[] {
		const filterValue = name.toLowerCase();
		// Set selected values to retain the selected checkbox state
		this.setSelectedValues();
		this.selectFormControl.patchValue(this.selectedValues);
		let filteredList = this.data.filter(
			(option) => option.name?.toLowerCase().indexOf(filterValue) >= 0
		);
		return filteredList;
	}
	setSelectedValues() {
		if (
			this.selectFormControl.value &&
			this.selectFormControl.value.length > 0
		) {
			this.selectFormControl.value.forEach((e) => {
				if (this.selectedValues.indexOf(e) == -1) {
					this.selectedValues.push(e);
				}
			});
		}
	}

	public clearValue(): void {
		// setTimeout(() => {
		// 	this.selection = [];
		// }, 10);
	}
	private setErrorMessage(): void {
		this.errorMessage = this.errorMessageService.setErrorMessage(
			this.defaultValidatorMessage,
			this.fgName.get(this.controlName)
		);
	}

	public clearSearch(event: any): void {
		event.stopPropagation();
		this.searchTextboxControl.patchValue('');
	}

	public checkKeyDown(event: KeyboardEvent) {
		if(event.key == ' ') {
			event.stopPropagation();
		}
	}
}
