<div class="blockmobile">
	<div class="d-flex justify-content-between ms-4 ps-1">
		<div class="content-header">
			{{ "USER_ACCESS" | translate }}
		</div>
	</div>
	<div class="">
		<mat-tab-group
			class="settings-tab-group my-4"
			mat-align-tabs="start"
			animationDuration="500ms"
		>
			<mat-tab
				*ngIf="
					userAccessSettingsTabPermissions[
						permissionHelper.settingsModule.USER_ACCESS_LMS
					]
				"
				[label]="'LMS' | translate"
			>
				<div class="container-fluid">
					<div
						class="row mat-elevation-z4 my-2 py-2 align-items-center rounded res-filter-direction"
						*ngFor="let setting of settings"
					>
						<!-- <ng-container *ngIf="setting?.categoryId === 1"> -->
						<div class="col-6 res-full-width">
							<h5>
								{{ setting.comPreName | translate }}
							</h5>
						</div>
						<div class="col-5 res-full-width">
							<slr-drp01
								[fgName]="userAccessForm"
								[controlName]="setting.comPreName"
								[bindValue]="'id'"
								[bindLabel]="'name'"
								[data]="setting.options"
								(onChange)="settingChanged($event, setting)"
								[classes]="'gray-bg'"
								[appendTo]="'body'"
							></slr-drp01>
						</div>
						<div class="col-1 res-full-width user-privilege-higher">
							{{ "OR_HIGHER" | translate }}
						</div>
						<!-- </ng-container> -->
					</div>
				</div>
			</mat-tab>
			<mat-tab
				*ngIf="
					userAccessSettingsTabPermissions[
						permissionHelper.settingsModule.USER_ACCESS_ENTERPRISE
					]
				"
				[label]="'ENTERPRISE' | translate"
			>
				<div class="container-fluid">
					No Settings Available
					<!-- <div
				class="row mat-elevation-z4 my-2 py-2 align-items-center rounded"
			>
				<div class="col-6">
					<h5>
						{{ "VIEW_SITES_DROPDOWN" | translate }}
					</h5>
				</div>
				<div class="col-6">
					<slr-drp01
						[fgName]="userAccessForm"
						[controlName]="'VIEW_SITES_DROPDOWN'"
						[data]="userClassData"
					></slr-drp01>
				</div>
			</div> -->
				</div>
			</mat-tab>
			<mat-tab
				*ngIf="
					userAccessSettingsTabPermissions[
						permissionHelper.settingsModule
							.USER_ACCESS_POLICY_PROFESSIONAL
					]
				"
				[label]="'POLICY_PROFESSIONAL' | translate"
			>
				<div class="container-fluid">
					No Settings Available
					<!-- <div
				class="row mat-elevation-z4 my-2 py-2 align-items-center rounded"
			>
				<div class="col-6">
					<h5>
						{{ "VIEW_POLICY_PRO" | translate }}
					</h5>
				</div>
				<div class="col-6">
					<slr-drp01
						[fgName]="userAccessForm"
						[controlName]="'VIEW_POLICY_PRO'"
						[data]="userClassData"
					></slr-drp01>
				</div>
			</div>
			<div
				class="row mat-elevation-z4 my-2 py-2 align-items-center rounded"
			>
				<div class="col-6">
					<h5>
						{{ "MANAGE_POLICY_PRO" | translate }}
					</h5>
				</div>
				<div class="col-6">
					<slr-drp01
						[fgName]="userAccessForm"
						[controlName]="'MANAGE_POLICY_PRO'"
						[data]="userClassData"
					></slr-drp01>
				</div>
			</div>
			<div
				class="row mat-elevation-z4 my-2 py-2 align-items-center rounded"
			>
				<div class="col-6">
					<h5>
						{{ "CAN_COMMENT_IN_POLICY_PRO" | translate }}
					</h5>
				</div>
				<div class="col-6">
					<slr-drp01
						[fgName]="userAccessForm"
						[controlName]="'CAN_COMMENT_IN_POLICY_PRO'"
						[data]="userClassData"
					></slr-drp01>
				</div>
			</div>
			<div
				class="row mat-elevation-z4 my-2 py-2 align-items-center rounded"
			>
				<div class="col-6">
					<h5>
						{{
							"DEFAULT_USER_ACCESS_LEVEL_A_POLICY_IS_SET_TO_WHEN_IT_IS_ADDED"
								| translate
						}}
					</h5>
				</div>
				<div class="col-6">
					<slr-drp01
						[fgName]="userAccessForm"
						[controlName]="
							'DEFAULT_USER_ACCESS_LEVEL_A_POLICY_IS_SET_TO_WHEN_IT_IS_ADDED'
						"
						[data]="userClassData"
					></slr-drp01>
				</div>
			</div> -->
				</div>
			</mat-tab>
			<mat-tab
				*ngIf="
					userAccessSettingsTabPermissions[
						permissionHelper.settingsModule.USER_ACCESS_QRM
					]
				"
				[label]="'QRM' | translate"
			>
				<div class="container-fluid">
					No Settings Available
					<!-- <div
				class="row mat-elevation-z4 my-2 py-2 align-items-center rounded"
			>
				<div class="col-6">
					<h5>
						{{ "VIEW_QRM" | translate }}
					</h5>
				</div>
				<div class="col-6">
					<slr-drp01
						[fgName]="userAccessForm"
						[controlName]="'VIEW_QRM'"
						[data]="userClassData"
					></slr-drp01>
				</div>
			</div>
			<div
				class="row mat-elevation-z4 my-2 py-2 align-items-center rounded"
			>
				<div class="col-6">
					<h5>
						{{ "MANAGE_QRM" | translate }}
					</h5>
				</div>
				<div class="col-6">
					<slr-drp01
						[fgName]="userAccessForm"
						[controlName]="'MANAGE_QRM'"
						[data]="userClassData"
					></slr-drp01>
				</div>
			</div>
			<div
				class="row mat-elevation-z4 my-2 py-2 align-items-center rounded"
			>
				<div class="col-6">
					<h5>
						{{ "CREATE_ACTIONS" | translate }}
					</h5>
				</div>
				<div class="col-6">
					<slr-drp01
						[fgName]="userAccessForm"
						[controlName]="'CREATE_ACTIONS'"
						[data]="userClassData"
					></slr-drp01>
				</div>
			</div>
			<div
				class="row mat-elevation-z4 my-2 py-2 align-items-center rounded"
			>
				<div class="col-6">
					<h5>
						{{ "ATTACH_PHOTOS_IN_QRM" | translate }}
					</h5>
				</div>
				<div class="col-6">
					<slr-drp01
						[fgName]="userAccessForm"
						[controlName]="'ATTACH_PHOTOS_IN_QRM'"
						[data]="userClassData"
					></slr-drp01>
				</div>
			</div>
			<div
				class="row mat-elevation-z4 my-2 py-2 align-items-center rounded"
			>
				<div class="col-6">
					<h5>
						{{ "ATTACH_COMMENTS_IN_QRM" | translate }}
					</h5>
				</div>
				<div class="col-6">
					<slr-drp01
						[fgName]="userAccessForm"
						[controlName]="'ATTACH_COMMENTS_IN_QRM'"
						[data]="userClassData"
					></slr-drp01>
				</div>
			</div>
			<div
				class="row mat-elevation-z4 my-2 py-2 align-items-center rounded"
			>
				<div class="col-6">
					<h5>
						{{ "ATTACH_DOCUMENTS_IN_QRM" | translate }}
					</h5>
				</div>
				<div class="col-6">
					<slr-drp01
						[fgName]="userAccessForm"
						[controlName]="'ATTACH_DOCUMENTS_IN_QRM'"
						[data]="userClassData"
					></slr-drp01>
				</div>
			</div> -->
				</div>
			</mat-tab>
		</mat-tab-group>
	</div>
</div>

<app-save-button
	class="m-3"
	(saveClicked)="saveSettings(false)"
></app-save-button>

<!-- <app-save-button
	class="m-3"
	[text]="'SETTINGS_SAVE_ALL'"
	(saveClicked)="saveSettings(true)"
></app-save-button> -->
