export interface DataInterface<T> {
	isLoaded: boolean | null;
	isLoading?: boolean | null;
	errorCode: string | null;
	data: T | null;
}
export const NullDataInterface: DataInterface<null> = {
	isLoaded: null,
	isLoading: null,
	errorCode: null,
	data: null,
};
