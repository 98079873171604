import { createEntityAdapter } from '@ngrx/entity';
import {
	createFeatureSelector,
	createReducer,
	createSelector,
	on,
} from '@ngrx/store';
import {
	ProfileSaveSkillInterface,
	UserSavedSkillInterface,
} from '../_models/profile-skills.interfaces';
import { UserProfileInterface } from '../_models/user-profile-interface';
import {
	addCertToSkill,
	addSkill,
	loadAllSkillsSuccess,
	loadUserProfileSuccess,
	loadUserSkillsSuccess,
	removeSkill,
	resetUserSkill,
	saveTempSkills,
	updateBasicDetail,
	updateProfilePicture,
	updateRemoveSkillArray,
	updateSkill,
	updateSkillsAndCred,
} from './user-profile.actions';
export interface UserProfileState {
	userProfile: UserProfileInterface;
	editedUserProfile: UserProfileInterface;
	deletedSkills: number[];
}

const userSkillAdapter = createEntityAdapter<UserSavedSkillInterface>({
	selectId: (skill) => skill.skillId,
});

export const initialUserProfileState: UserProfileState = {
	userProfile: {
		basicDetails: null,
		profilePic: null,
		allSkills: null,
		savedSkills: userSkillAdapter.getInitialState(),
	},
	editedUserProfile: {
		basicDetails: null,
		profilePic: null,
		allSkills: null,
		savedSkills: userSkillAdapter.getInitialState(),
	},
	deletedSkills: [],
};

export const getProfileState =
	createFeatureSelector<UserProfileState>('user-profile');

export const userProfileReducer = createReducer(
	initialUserProfileState,
	on(loadUserProfileSuccess, (state, action) => {
		const deepCopy = JSON.parse(
			JSON.stringify(action.profile)
		) as UserProfileInterface;
		return {
			...state,
			userProfile: {
				...state.userProfile,
				basicDetails: action.profile.basicDetails,
				profilePic: action.profile.profilePic,
			},
			editedUserProfile: {
				...state.editedUserProfile,
				basicDetails: deepCopy.basicDetails,
				profilePic: deepCopy.profilePic,
			},
		};
	}),
	on(loadUserSkillsSuccess, (state, action) => {
		return {
			...state,
			userProfile: {
				...state.userProfile,
				allSkills: action.skills,
				savedSkills: userSkillAdapter.setAll(
					action.userSkills,
					state.userProfile.savedSkills
				),
			},
		};
	}),
	on(addSkill, (state, action) => {
		console.log(
			'Add',
			action.skill,
			state.userProfile.savedSkills[action.skill.skillId]
		);
		// const wasRemoved = state.deletedSkills.includes(action.skill.skillId);
		// if (wasRemoved) {
		// 	return {
		// 		...state,
		// 		userProfile: {
		// 			...state.userProfile,
		// 			savedSkills: userSkillAdapter.addOne(
		// 				{ ...action.skill, isTemp: false, isUpdated: true },
		// 				state.userProfile.savedSkills
		// 			),
		// 		},
		// 		deletedSkills: state.deletedSkills.filter(
		// 			(x) => x !== action.skill.skillId
		// 		),
		// 	};
		// }
		return {
			...state,
			userProfile: {
				...state.userProfile,
				savedSkills: userSkillAdapter.addOne(
					action.skill,
					state.userProfile.savedSkills
				),
			},
		};
	}),
	on(removeSkill, (state, action) => {
		return {
			...state,
			userProfile: {
				...state.userProfile,
				savedSkills: userSkillAdapter.removeOne(
					action.id.toString(),
					state.userProfile.savedSkills
				),
			},
		};
	}),
	on(updateSkill, (state, action) => {
		return {
			...state,
			userProfile: {
				...state.userProfile,
				savedSkills: userSkillAdapter.updateOne(
					{
						id: action.id,
						changes: { ...action.skill },
					},
					state.userProfile.savedSkills
				),
			},
		};
	}),
	on(updateProfilePicture, (state, action) => {
		return {
			...state,
			editedUserProfile: {
				...state.userProfile,
				profilePic: action.s3Path,
			},
			userProfile: {
				...state.userProfile,
				profilePic: action.newPath,
			},
		};
	}),
	on(updateBasicDetail, (state, action) => {
		return {
			...state,
			editedUserProfile: {
				...state.userProfile,
				basicDetails: action.payload,
			},
		};
	}),
	on(addCertToSkill, (state, action) => {
		return {
			...state,
			userProfile: {
				...state.userProfile,
				savedSkills: userSkillAdapter.updateOne(
					{
						id: action.skillId,
						changes: {
							certificatePath: action.path,
						},
					},
					state.userProfile.savedSkills
				),
			},
		};
	}),
	on(saveTempSkills, (state, action) => {
		return {
			...state,
			userProfile: {
				...state.userProfile,
				savedSkills: userSkillAdapter.updateMany(
					Object.values(state.userProfile.savedSkills.entities).map(
						(x) =>
							Object.assign(
								{},
								{
									id: x.skillId,
									changes: {
										isTemp: false,
										isUpdated: false,
										previousSkillStatus: undefined,
									},
								}
							)
					),
					state.userProfile.savedSkills
				),
			},
		};
	}),
	on(updateRemoveSkillArray, (state, action) => {
		if (!action.id) {
			return {
				...state,
				deletedSkills: [],
			};
		}
		const _skill = Object.values(
			state.userProfile.savedSkills.entities
		).find((x) => x.skillId === action.id);
		if (_skill && _skill.isTemp) {
			return state;
		}
		return {
			...state,
			deletedSkills: action.id ? [...state.deletedSkills, action.id] : [],
		};
	}),
	on(resetUserSkill, (state, action) => {
		return {
			...state,
			userProfile: {
				...state.userProfile,
				allSkills: null,
				savedSkills: userSkillAdapter.setAll(
					[],
					state.userProfile.savedSkills
				),
			},
		};
	})
	// on(updateSkillsAndCred, (state, action) => {
	// 	return {
	// 		...state,
	// 		editedUserProfile: {
	// 			...state.userProfile,
	// 			skillsAndCredentials: [
	// 				...state.userProfile.skillsAndCredentials,
	// 				action.payload,
	// 			],
	// 		},
	// 	};
	// })
);

export const getUserBasicDeatils = createSelector(
	getProfileState,
	(state) => state.userProfile.basicDetails
);
export const getUserPicture = createSelector(
	getProfileState,
	(state) => state.userProfile.profilePic
);

const _getUserSkill = createSelector(
	getProfileState,
	(state) => state.userProfile.savedSkills
);
export const { selectAll: getUserSkill, selectEntities: getUserSkillMap } =
	userSkillAdapter.getSelectors(_getUserSkill);

export const getSkillDetailsById = (id: number) =>
	createSelector(_getUserSkill, (skillMap) => skillMap.entities[id]);

export const getAllSkill = createSelector(
	getProfileState,
	(state) => state.userProfile.allSkills
);

export const getUserProfile = createSelector(
	getProfileState,
	(state) => state.userProfile
);
export const editedUserProfile = createSelector(
	getProfileState,
	(state) => state.editedUserProfile
);

export const getRemovedSkills = createSelector(
	getProfileState,
	(state) => state.deletedSkills
);
