import {
	AfterViewInit,
	Directive,
	ElementRef,
	Host,
	Input,
} from '@angular/core';

@Directive({
	selector: '[table-action-button]',
})
export class TableActionButtonDirective implements AfterViewInit {
	@Input() data: any;
	@Input() condition: { lhs: any; op: any; rhs: any };
	constructor(@Host() private el: ElementRef) {}
	ngAfterViewInit(): void {
		if (!this.condition || !this.data) return;
		if (!this.getConditionResult()) {
			this.el.nativeElement.remove();
		}
	}

	private getConditionResult(): boolean {
		switch (this.condition.op) {
			case '!==':
			case '!=':
				return this.data[this.condition.lhs] != this.condition.rhs;
			case '>':
				return this.data[this.condition.lhs] > this.condition.rhs;
			case '<':
				return this.data[this.condition.lhs] < this.condition.rhs;
			case '==':
				return this.data[this.condition.lhs] == this.condition.rhs;
			case '===':
				return this.data[this.condition.lhs] === this.condition.rhs;
			default:
				return false;
		}
	}
}
