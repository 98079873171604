import {
	Component,
	Input,
	OnChanges,
	Output,
	TemplateRef,
	ViewChild,
	EventEmitter,
	SimpleChanges,
	OnDestroy,
} from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { BroadcastService } from 'src/app/core/services/broadcast.service';
import { LocalDataService } from 'src/app/core/services/local-data.service';

@Component({
	selector: 'app-confirmation-modal',
	templateUrl: './confirmation-modal.component.html',
	styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent implements OnChanges {
	@ViewChild('content') modalContent!: TemplateRef<any>;
	@Input() openModal = false;
	@Input() modalSize = '';
	@Input() submitBtnText = 'Submit';
	@Input() cancelBtnText = 'Cancel';
	@Output() modalClosed = new EventEmitter();
	constructor(
		private modalService: NgbModal,
		public localData: LocalDataService,
		config: NgbModalConfig,
		private broadcastService: BroadcastService
	) {
		config.backdrop = 'static';
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['openModal']) {
			this.openModal
				? this.openVerticallyCentered(this.modalContent)
				: this.closeModal();
		}
		if (changes['modalSize'] && !changes['openModal']) {
			document
				.getElementsByClassName('modal-dialog')[0]
				.classList.remove('modal-lg');
			document
				.getElementsByClassName('modal-dialog')[0]
				.classList.add(`modal-${this.modalSize}`);
		}
	}
	openVerticallyCentered(content: any) {
		this.broadcastService.modalOpened$.next(true);
		this.modalService.open(content, {
			centered: true,
			size: this.modalSize,
		});
	}
	closeModal() {
		this.broadcastService.modalOpened$.next(false);
		this.modalService.dismissAll();
		this.modalClosed.next(this.openModal ? true : false);
		this.localData.modalHeader = '';
	}
	getHelp() {
		this.broadcastService.showHelpSystem();
	}
}
