export enum MyCoursesModulePrivilegesEnum {
	MODULE_NAME = 95,
	MANDATORY = 26,
	RECOMMENDED = 27,
	BOOKMARKED = 28,
	HISTORY = 29,
	COURSE_STATUS_DROPDOWN = 30,
	TAKE_COURSES = 31,
	LIVE_EVENTS = 32,
}
