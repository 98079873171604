import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
	PrivilageIntrface,
	UserPrivilageInterface,
} from 'src/app/core/models/user-privilege.interface';
import {
	getModuleAccess,
	SharedState,
	getSettings,
	getUserPrivileges,
} from 'src/app/shared/_state/shared.reducer';
import { Settings } from 'src/app/shared/models/settings';
import { LocalDataService } from 'src/app/core/services/local-data.service';
import { filter } from 'rxjs';
import { ManageUsersModulePrivilegesEnum } from 'src/app/core/models/_module_enums/manage-users-module.privileges.enum';
@Injectable({
	providedIn: 'root',
})
export class UserPermissionService {
	private _userPrivileges: PrivilageIntrface[] | null = null;
	private settings: Settings[];
	private _linkAllow: boolean = false;

	constructor(
		private sharedStore: Store<SharedState>,
		private localData: LocalDataService
	) {
		this.sharedStore
			.pipe(select(getModuleAccess))
			.subscribe((privileges) => {
				this._userPrivileges = privileges;
			});
		this.sharedStore.pipe(select(getUserPrivileges)).subscribe({
			next: (res) => {
				this._linkAllow = res?.linkAllow;
			},
		});
		this.sharedStore
			.pipe(
				select(getSettings),
				filter((value) => !!value)
			)
			.subscribe({
				next: (res) => {
					this.settings = res;
				},
			});
	}

	public checkUserPrivilege(privilege: number | string): boolean {
		if (
			privilege ==
			ManageUsersModulePrivilegesEnum.Manage_Users_Profile_Linking
		) {
			return this._linkAllow &&
				this._userPrivileges?.find((x) => x.privilegeId == privilege)
					?.accessFlag == 1
				? true
				: false;
		} else {
			return this._userPrivileges?.find((x) => x.privilegeId == privilege)
				?.accessFlag == 1
				? true
				: false;
		}
	}

	checkPrivilegeForRC(privilege) {
		let userClass = this.localData.getUserClasses()[0];
		let userRCAccess = false;
		switch (userClass) {
			case 'End User':
				userRCAccess =
					Number(
						this.settings.find(
							(setting) =>
								setting?.name === 'regular_show_library'
						)?.value
					) > 0
						? true
						: false;
				break;
			case 'Restricted User':
				userRCAccess =
					Number(
						this.settings.find(
							(setting) =>
								setting?.name === 'restricted_show_library'
						)?.value
					) > 0
						? true
						: false;
				break;
			default:
				userRCAccess = true;
		}
		return this._userPrivileges?.find((x) => x.privilegeId == privilege)
			?.accessFlag == 1 && userRCAccess
			? true
			: false;
	}

	public set userPrivileges(value: UserPrivilageInterface | null) {
		this._userPrivileges = value?.privileges ?? null;
	}
}
