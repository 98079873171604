import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { NotificationService } from 'src/app/core/services/notification.service';
import { DropdownInterface } from 'src/app/shared/components/dropdowns/slr-drp01/dropdown.model';
import { closeDialog } from '../_state/authentication.action';
import { AuthenticationState } from '../_state/authentication.reducer';

@Component({
	selector: 'app-choose-account-popup',
	templateUrl: './choose-account-popup.component.html',
	styleUrls: ['./choose-account-popup.component.scss'],
})
export class ChooseAccountPopupComponent {
	public selection = new SelectionModel<string>(false);
	public drpData: Array<DropdownInterface>;
	constructor(
		@Inject(MAT_DIALOG_DATA)
		public data: { data: string[]; email: string; token: string },
		private authStore: Store<AuthenticationState>,
		private notificationService: NotificationService
	) {
		this.drpData = this.data.data.map((x, index = 1) => {
			return {
				id: index,
				name: x,
			};
		});
	}

	public close(site: string | undefined): void {
		this.authStore.dispatch(
			closeDialog({
				site: site,
				email: this.data.email,
				token: this.data.token,
			})
		);
	}

	public submit(): void {
		if (this.selection.selected.length == 0) {
			this.notificationService.error('Please select a location');
			return;
		}
		this.close(this.selection.selected[0]);
	}
}
