import { Component, OnInit } from '@angular/core';
import { PolicyEditorService } from 'src/app/modules/policy-professional/_services/editor.service';

@Component({
	selector: 'app-editor-open-message',
	templateUrl: './editor-open-message.component.html',
	styleUrls: ['./editor-open-message.component.scss'],
})
export class EditorOpenMessageComponent {
	constructor(private editorService: PolicyEditorService) {}

	public sendOption(value: 0 | 1): void {
		this.editorService.editorOption$.next(value);
	}
}
