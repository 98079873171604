import {
	TableActionButtonInterface,
	TableColumn,
} from '../../tables/slr-table/slr-table.component';

export const broadcastColumns: TableColumn[] = [
	{
		name: 'NAME',
		isVisible: true,
		dataKey: 'name',
	},
	{
		name: 'HOST',
		isVisible: true,
		dataKey: 'hosted_by',
	},
	{
		name: 'PRODUCT',
		dataKey: 'product',
		isVisible: true,
	},
	{
		name: 'VENDOR',
		dataKey: 'vendorCodeName',
		isVisible: true,
	},
	{
		name: 'CLASS',
		dataKey: 'targetRoleNames',
		isVisible: true,
	},
	{
		name: 'EXPIRY_DATE',
		dataKey: 'date',
		isVisible: true,
	},
	{
		name: 'LINK',
		dataKey: 'link',
		isVisible: true,
		breakWord: true,
	},
	{
		name: 'DESCRIPTION',
		dataKey: 'description',
		isVisible: true,
		breakWord: true,
	},
];
export const broadcastActions: TableActionButtonInterface[] = [
	{
		icon: 'edit',
		tooltip: 'EDIT',
	},
	{
		icon: 'delete',
		tooltip: 'DELETE',
	},
];
