import {
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { ValidatorMessage } from 'src/app/core/models/validation-messages.model';
import { ErrorMessageService } from 'src/app/core/services/error-message.service';
import { DropdownInterface } from './dropdown.model';

@Component({
	selector: 'slr-drp01',
	templateUrl: './slr-drp01.component.html',
	styleUrls: ['./slr-drp01.component.scss'],
})
export class SlrDrp01Component implements OnInit {
	@Input() public data: Array<DropdownInterface>;
	@Input() public placeholder: string;
	@Input() public label: string;
	@Input() public fgName: FormGroup;
	@Input() public controlName: string;
	@Input() public disabled: boolean = false;
	@Input() public control: FormControl | AbstractControl;
	@Input() public searchable: boolean = false;
	@Input() public clearable: boolean = true;
	@Input() public width: string = '100%';
	@Input() public bindValue: 'id' | 'alternateId' | 'name' | 'questionId' =
		'id';
	@Input() public bindLabel: string | 'questionName' = 'name';
	@Input() public groupBy: string;
	@Input() public appendTo: string = '';
	@Input() public classes: string = '';
	@Output() onChange: EventEmitter<DropdownInterface>;

	public errorMessage: string;
	private defaultValidatorMessage: Array<ValidatorMessage>;

	constructor(
		private errorMessageService: ErrorMessageService,
		private translateService: TranslateService
	) {
		this.onChange = new EventEmitter<DropdownInterface>();
	}

	ngOnInit(): void {
		this.setDefaultValidatorMessage();
		this.setErrorMessage();
	}

	getTranslatedString(key: string) {
		if (key) {
			return this.translateService.get(key);
		}
		return of(key);
	}

	public change(event: any): void {
		this.onChange.emit(event);
	}

	private setDefaultValidatorMessage() {
		let localizedKey = this.placeholder;
		this.getTranslatedString(this.placeholder).subscribe((res) => {
			localizedKey = res;
		});
		this.defaultValidatorMessage = [
			{
				validationType: 'required',
				validationMessage: `${localizedKey} is required`,
			},
		];
	}

	private setErrorMessage(): void {
		this.errorMessage = this.errorMessageService.setErrorMessage(
			this.defaultValidatorMessage,
			this.fgName.get(this.controlName)
		);
	}
}
