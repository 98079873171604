import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReportsOverviewComponent } from './reports-overview/reports-overview.component';
import { ReportsRoutingModule } from './reports-routing.module';

@NgModule({
	declarations: [ReportsOverviewComponent],
	imports: [
		CommonModule,
		ReportsRoutingModule,
		TranslateModule,
		SharedModule,
		NgSelectModule,
		ReactiveFormsModule,
	],
	exports: [],
	providers: [],
})
export class ReportsModule {}
