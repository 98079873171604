<div class="editor" [formGroup]="formName">
	<mat-form-field appearence="outline">
		<label *ngIf="label" class="mb-2">{{ label | translate }}</label>
		<input matInput [formControlName]="controlName" hidden />
		<div class="d-flex align-items-center justify-content-between mb-1">
			<ngx-editor-menu
				[editor]="editor"
				[toolbar]="toolbar"
				[customMenuRef]="customImageUpload"
			>
			</ngx-editor-menu>
			<ng-content></ng-content>
		</div>
		<ngx-editor
			[editor]="editor"
			[formControlName]="controlName"
			[placeholder]="placeholder"
			[(ngModel)]="htmlContent"
			[style.height]="'150px'"
		>
		</ngx-editor>
	</mat-form-field>
</div>

<ng-template #customImageUpload>
	<div class="NgxEditor__Seperator"></div>
	<div
		class="NgxEditor__MenuItem NgxEditor__MenuItem--Text"
		(mousedown)="uploadImage($event)"
		[ngClass]="{
			'NgxEditor__MenuItem--Active': isActive,
			'NgxEditor--Disabled': isDisabled
		}"
	>
		Image
	</div>
</ng-template>
