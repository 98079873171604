import {
	Component,
	ElementRef,
	Input,
	OnInit,
	Optional,
	Output,
	ViewChild,
	EventEmitter,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Subject } from 'rxjs';
import {
	getCurrentChatUser,
	getUserId,
	getUserPrivileges,
	SharedState,
} from 'src/app/shared/_state/shared.reducer';
import {
	ChatUserInterface,
	ConversationInterface,
	MessageInterface,
	TemporaryMessage,
} from '../../../_models/conversation.interface';
import {
	addMessage,
	addTemporaryMessage,
	saveTempMessageText,
} from '../../../_state/chat.actions';
import {
	ChatState,
	getSelectedConversations,
	newConversation,
} from '../../../_state/chat.reducer';

@Component({
	selector: 'chat-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
	@ViewChild('messageInput', { static: false })
	public messageInput: ElementRef;

	public messageForm: FormGroup;
	public currentUserId: number;
	private currentUser: ChatUserInterface;
	private selectedConversation: ConversationInterface;
	public tempMessage = new BehaviorSubject<MessageInterface>(null);
	@Optional() @Input() public onPopup: boolean = false;
	@Output() public onSend = new EventEmitter<MessageInterface>();
	constructor(
		private _fb: FormBuilder,
		private chatStore: Store<ChatState>,
		private sharedSotre: Store<SharedState>
	) {
		this.sharedSotre.pipe(select(getUserId)).subscribe({
			next: (res) => (this.currentUserId = res),
		});
		this.sharedSotre.pipe(select(getCurrentChatUser)).subscribe({
			next: (res) => {
				this.currentUser = res;
			},
		});
	}

	ngOnInit(): void {
		this.messageForm = this._fb.group({
			message: new FormControl(),
		});
		if (this.onPopup) {
			this.chatStore.pipe(select(newConversation)).subscribe({
				next: (res) => {
					this.selectedConversation = res;
					this.tempMessage.next(res.temporaryMessage);
				},
			});
		} else {
			this.chatStore.pipe(select(getSelectedConversations)).subscribe({
				next: (res) => {

					this.selectedConversation = res;
					if (!res.temporaryMessage) {
						this.tempMessage.next(this.getDefaultTempMessage());
						this.chatStore.dispatch(
							addTemporaryMessage({
								conversationId: res.conversationId,
								message: this.getDefaultTempMessage(),
							})
						);
						return;
					}
					this.tempMessage.next(res.temporaryMessage);
				},
			});
		}


		this.tempMessage.asObservable().subscribe({
			next: (res) =>
				this.messageForm.get('message').setValue(res.message),
		});
		this.messageForm.valueChanges.subscribe({
			next: (res) => {
				if (this.onPopup) {
					this.tempMessage.value.message = res['message'];
					return;
				}
				this.chatStore.dispatch(
					saveTempMessageText({
						conversationId:
							this.selectedConversation.conversationId,
						text: res['message'],
					})
				);
			},
		});
	}

	public sendMessage(event: Event | void): void {
		if (this.onPopup) {
			this.onSend.emit(this.tempMessage.value);
			return;
		}
		if (event instanceof Event) {
			event.preventDefault();
		}

		const messageBody = this.messageInput.nativeElement.value.trim();
		if (messageBody.length == 0) return;

		this.tempMessage.value.message = messageBody;
		this.tempMessage.value.createdAt = new Date().toDateString();

		this.chatStore.dispatch(
			addMessage({
				conversationId: this.selectedConversation.conversationId,
				message: this.tempMessage.value,
			})
		);
		this.clearMessage();
	}

	private clearMessage(): void {
		this.tempMessage.next(this.getDefaultTempMessage());
		this.chatStore.dispatch(
			addTemporaryMessage({
				conversationId: this.selectedConversation.conversationId,
				message: this.getDefaultTempMessage(),
			})
		);
		this.messageInput.nativeElement.value = '';
	}

	private getDefaultTempMessage(): MessageInterface {
		return new TemporaryMessage(this.currentUser);
	}
}
