<div class="container my-4">
	<div class="row justify-content-center">
		<div
			[class]="
				allowLinks ? 'col-12 col-sm-12 col-md-12 col-lg-5' : 'col-12'
			"
		>
			<div class="row align-items-center justify-content-center">
				<div class="col-12">
					<slr-input-file-upload
						[multiple]="multiple"
						(chosenFile)="choosenFiles($event, 'SINGLE')"
						(chosenFiles)="choosenFiles($event, 'MULTIPLE')"
						[acceptTypes]="acceptTypes"
					></slr-input-file-upload>
					<div
						*ngIf="
							showUploadError &&
							(!selectedFile || !selectedFiles?.length)
						"
						class="row"
					>
						<div class="col-12">
							<mat-error>{{
								"SELECT_A_FILE_TO_UPLOAD" | translate
							}}</mat-error>
						</div>
					</div>
				</div>
			</div>
			<div class="row d-flex d-sm-flex d-md-flex d-lg-none">
				<div
					[class]="
						allowLinks
							? 'col-12 col-sm-12 col-md-12 col-lg-5'
							: 'col-12'
					"
				>
					<slr-btn01
						(onClick)="multiple ? uploadFiles() : uploadFile()"
						>{{ "UPLOAD" | translate | uppercase }}</slr-btn01
					>
				</div>
			</div>
		</div>
		<ng-container *ngIf="allowLinks">
			<div class="col-1 d-flex justify-content-center align-items-center">
				[{{ "OR" | translate | uppercase }}]
			</div>
			<div class="col-12 col-sm-12 col-md-12 col-lg-5">
				<slr-input
					[fgName]="urlForm"
					[controlName]="'url'"
					[placeholder]="'PASTE_URL' | translate"
					[label]="'Paste URL'"
				></slr-input>
				<div *ngIf="youtubeLinkError" class="row">
					<div class="col-12">
						<mat-error>{{
							"VALID_YOUTUBE_LINK_MESSAGE" | translate
						}}</mat-error>
					</div>
				</div>
				<div class="row d-flex d-sm-flex d-md-flex d-lg-none">
					<div class="col-1"></div>
					<div class="col-12 col-sm-12 col-md-12 col-lg-5">
						<slr-btn01 (click)="sendLink()" (keyup)="sendLink()">{{
							"ADD_LINK" | translate | uppercase
						}}</slr-btn01>
					</div>
				</div>
			</div>
		</ng-container>
	</div>
	<div class="row d-none d-sm-none d-md-none d-lg-flex">
		<div
			class="col-5 mt-4 d-flex justify-content-center"
			[class.col-11]="!allowLinks"
		>
			<slr-btn01 (onClick)="multiple ? uploadFiles() : uploadFile()">{{
				"UPLOAD" | translate | uppercase
			}}</slr-btn01>
		</div>
		<ng-container *ngIf="allowLinks">
			<div class="col-1"></div>
			<div class="col-5 mt-4 d-flex justify-content-center">
				<slr-btn01 (click)="sendLink()" (keyup)="sendLink()">{{
					"ADD_LINK" | translate | uppercase
				}}</slr-btn01>
			</div>
		</ng-container>
	</div>
</div>
