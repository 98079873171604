import { Injectable } from '@angular/core';
import JSZip from 'jszip';
import { Observable, Observer } from 'rxjs';
import { GenericHelper } from '../helpers/generic-helper.class';

@Injectable({
	providedIn: 'root',
})
export class ReadZipFileService {

	checkIfFileExists(
		zipFile: File,
		targetFileName: string
	): Observable<boolean> {
		return new Observable((observer: Observer<boolean>) => {
			const zip = new JSZip();

			zip.loadAsync(zipFile)
				.then((zipContents) => {
					// Check if the target file exists in the root
					const fileExists = Object.keys(zipContents.files).some(
						(relativePath) => {
							return (
								!zipContents.files[relativePath].dir &&
								relativePath === targetFileName
							);
						}
					);

					observer.next(fileExists);
					observer.complete();
				})
				.catch((error) => {
					console.error('Error reading ZIP file', error);
					observer.next(false);
					observer.complete();
				});
		});
	}

	checkIfValidNonScorm(
		zipFile: File
	): Observable<'SUCCESS' | 'NOT_VALID' | 'FAILED' | 'INVALID_ZIP'> {
		return new Observable(
			(
				observer: Observer<
					'SUCCESS' | 'NOT_VALID' | 'FAILED' | 'INVALID_ZIP'
				>
			) => {
				const zip = new JSZip();

				zip.loadAsync(zipFile)
					.then((zipContents) => {
						const files = Object.keys(zipContents.files);

						console.log(zipContents);

						observer.next(
							files.some((x) =>
								GenericHelper.validNonScormFileNames.includes(x)
							)
								? 'SUCCESS'
								: 'NOT_VALID'
						);
						observer.complete();
					})
					.catch((error) => {
						console.error('Error reading ZIP file', error);
						observer.next('FAILED');
						observer.complete();
					});
			}
		);
	}
}
