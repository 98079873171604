import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoleGuard } from 'src/app/core/guards/role.guard';
import { ReportModulePrivilegesEnum } from 'src/app/core/models/_module_enums/reports-module.privilege.enum';
import { ReportsOverviewComponent } from './reports-overview/reports-overview.component';

const routes: Routes = [
	{
		path: '',
		canActivate: [RoleGuard],
		data: {
			privileKey: ReportModulePrivilegesEnum.MODULE_NAME,
		},
		children: [
			{
				path: 'user',
				loadChildren: () =>
					import('./user-reports/user-reports.module').then(
						(m) => m.UserReportsModule
					),
			},
			{
				path: 'course',
				loadChildren: () =>
					import('./course-reports/course-reports.module').then(
						(m) => m.CourseReportsModule
					),
			},
			{
				path: 'enterprise',
				loadChildren: () =>
					import(
						'./enterprise-reports/enterprise-reports.module'
					).then((m) => m.EnterpriseReportsModule),
			},
			{
				path: 'quality',
				loadChildren: () =>
					import('./qrm-reports/qrm-reports.module').then(
						(m) => m.QrmReportsModule
					),
			},
			{
				path: 'skills',
				loadChildren: () =>
					import('./skill-reports/skill-reports.module').then(
						(m) => m.SkillReportsModule
					),
			},
			{
				path: 'other',
				loadChildren: () =>
					import('./other-reports/other-reports.module').then(
						(m) => m.OtherReportsModule
					),
			},
		],
		component: ReportsOverviewComponent,
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class ReportsRoutingModule {}
