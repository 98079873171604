import {
	AbstractControl,
	FormControl,
	FormGroup,
	ValidationErrors,
	ValidatorFn,
} from '@angular/forms';
import moment from 'moment';

export class GenericHelper {
	private static vimeoSupportedFormats = [
		'video/mp4',
		'mp4',
		'video/x-ms-wmv',
		'video/quicktime',
		'video/mpeg',
	];
	private static scormSupportedFormats = [
		'application/x-zip-compressed',
		'application/zip',
		'audio/mp3',
		'mp3',
	];
	private static timePattern =
		/PT(?:(\d+(?:\.\d+)?)H)?(?:(\d+(?:\.\d+)?)M)?(?:(\d+(?:\.\d+)?)S)?/;
	private static scormSupportedDownloadableFormats = [
		'application/x-zip-compressed',
	];
	private static scormSupportedPreviewFormats = [
		...this.vimeoSupportedFormats,
		'audio/mp3',
		'mp3',
	];
	public static previewableTypes = [
		...this.scormSupportedPreviewFormats,
		'video',
		'audio',
		'application/pdf',
		'youtube',
		'text/plain',
	];
	public static downloadableTypes = [
		...this.scormSupportedDownloadableFormats,
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		'application/vnd.oasis.opendocument.spreadsheet',
		'xlsx',
		'csv',
		'docx',
		'doc',
	];
	public static uploadableTypes = [
		...this.vimeoSupportedFormats,
		...this.scormSupportedFormats,
		'video',
		'audio',
		'application/pdf',
		'youtube',
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		'application/vnd.oasis.opendocument.spreadsheet',
		'xlsx',
		'csv',
		'docx',
		'doc',
	];

	public static uploadableContentTypes = [
		...this.scormSupportedFormats,
		'application/pdf',
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		'application/vnd.oasis.opendocument.spreadsheet',
		'xlsx',
		'csv',
		'docx',
		'doc',
	];

	public static validNonScormFileNames = [
		'index.html',
		'index_lms.html',
		'presentation.html',
		'story.html',
		'scormcontent/index.html',
		'content/index.html',
	];
	public static userNameValidatorPattern =
		'^[a-zA-Z0-9_-]+[.][a-zA-Z0-9._àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ-]+';
	public static passwordValidatorPattern =
		'(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{7,}';
	public static stripHTMLPattern = /(<([^>]+)>)|(\r\n)/gi;

	public static passwordFildValidationmessage =
		'Password Complexity level does not match. Please check requirements in `i`';
	public static passwordRequirementMessage = `<ul>Requirements:
	<li>8 characters minimum length,</li>
	<li>Contains at least 1 number,</li>
	<li>Contains at least 1 uppercase letter,</li>
	<li>Contains at least 1 lowercase letter,</li> 
<li>Contains at least 1 special character from the following set, or a non-leading, non-trailing space character. ^ $ * .  [ ] { } ( ) ? , "  !  @  # % & \\ / > < ' : ; | _ - ~ + \` + </li></ul>`;
	public static confirmPasswordValidator =
		(pwd: FormControl, cPwd: FormControl) =>
		(): ValidationErrors | null => {
			let pass = pwd.value;
			let confirmPass = cPwd.value;

			return pass === confirmPass ? null : { notSame: true };
		};

	public static anniversaryDateValidator =
		(formGroup: FormGroup, control: string) =>
		(): ValidationErrors | null => {
			const value = formGroup.get(control).value;
			const dateRegEx = new RegExp(
				'(1[0-2]|0[1-9])-(3[01]|[12][0-9]|0[1-9])'
			);
			return dateRegEx.test(value) ? null : { invalidDate: true };
		};
	public static fullNameValidator =
		(
			formGroup: FormGroup,
			control: string,
			fullName: { f: string; l: string }
		) =>
		(): ValidationErrors | null => {
			const value = formGroup.get(control).value as string;
			const inputfname = value?.split(' ')[0];
			const inputlname = value?.split(' ')[value.split(' ').length - 1];
			return fullName.f.toLowerCase() === inputfname.toLowerCase() &&
				fullName.l.toLowerCase() === inputlname.toLowerCase()
				? null
				: { noMatch: true };
		};
	public static usernameValidator =
		(control: FormControl) => (): ValidationErrors | null => {
			const value: string = control.value;
			return value && value.includes(' ') ? { noSpace: true } : null;
		};

	public static getRandomNumber = (min: number, max: number) => {
		return Math.floor(Math.random() * (max - min + 1) + min);
	};

	public static getFullLink = (url: string): string => {
		if (!url.startsWith('http://') && !url.startsWith('https://'))
			return `http://${url}`;
		return url;
	};

	public static encode = (value: string) => {
		return window.btoa(value);
	};
	public static decode = (value: string) => {
		return window.atob(value);
	};

	public static getDateFromString = (value: string): Date => {
		if (!value) return null;
		const d = value.split('-')[0];
		const m = value.split('-')[1];
		const y = value.split('-')[2];

		return new Date(`${m}/${d}/${y}`);
	};

	public static isScormSupported = (fileType: string): boolean => {
		return this.scormSupportedFormats.includes(fileType);
	};
	public static isVimeoFile = (fileType: string): boolean => {
		return this.vimeoSupportedFormats.includes(fileType);
	};

	public static isScormLink = (link: string): boolean => {
		try {
			var linkRootUrl = new URL(link).hostname;
		} catch (err) {
			return false;
		}
		let result: boolean = true;
		if (!this.scormSupportedFormats.includes(linkRootUrl.split('.')[1])) {
			const linkFileUrl = link.split('.')[link.split('.').length - 1];
			if (!this.scormSupportedFormats.includes(linkFileUrl)) {
				result = false;
			}
		}
		return result;
	};
	public static isYoutubeLink = (link: string): boolean => {
		return link.includes('youtube.com') || link.includes('youtu.be');
	};
	public static checkYoutubeLink = (link: string): boolean => {
		if (link.includes('youtu.be')) return false;
		if (!link.includes('youtube')) return true;
		if (!link.includes('embed')) return false;
		return true;
	};
	public static isVimeoLink = (link: string): boolean => {
		return (
			link &&
			(link.includes('play_vimeo.php') ||
				link.includes('player.vimeo.com'))
		);
	};
	public static getVimeoLinkType = (
		link: string
	): 'external' | 'internal' => {
		return link.includes('include/play_vimeo.php')
			? 'internal'
			: 'external';
	};
	public static getLangCode = (langId: number): string => {
		switch (langId) {
			case 1:
				return 'en';
			case 2:
				return 'fr';
			default:
				return 'en';
		}
	};

	public static getClientCodeAndUserName(
		completeUsername: string
	): Array<string> {
		let [code, ...uname] = completeUsername.split('.');
		return [code, uname.join('.')];
	}

	public static getDateRange = (value: {
		year: number;
		month: number;
	}): { startDate: string; endDate: string } => {
		let res: { startDate: string; endDate: string } = {
			startDate: null,
			endDate: null,
		};
		if (value.year && !value.month) {
			res.startDate = `${value.year}-01-01`;
			res.endDate = `${value.year}-12-31`;
		}
		if (value.year && value.month) {
			res.startDate = moment(new Date(value.year, value.month, 1)).format(
				'YYYY-MM-DD'
			);
			res.endDate = moment(
				new Date(value.year, value.month + 1, 0)
			).format('YYYY-MM-DD');
		}
		return res;
	};

	public static stripHTML = (input: string): string => {
		if (!input) return input;
		if (!input.length) return input;
		return input.replace(this.stripHTMLPattern, '');
	};

	public static getPDFFromBytes = (byteString: string): string => {
		const byteCharacters = atob(byteString);
		const byteNumbers = new Array(byteCharacters.length);
		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
		const blob = new Blob([byteArray], {
			type: 'application/pdf',
		});
		return URL.createObjectURL(blob);
	};

	public static parseTime = (time: string): number => {
		let h,
			m,
			s = 0;
		const matches = this.timePattern.exec(time);
		h = matches[1] ? parseInt(matches[1]) : 0;
		m = matches[2] ? parseInt(matches[2]) : 0;
		s = matches[3] ? parseInt(matches[3]) : 0;
		return h * 3600 + m * 60 + s;
	};

	public static htmlInputValidator = (): ValidatorFn => {
		return (control: AbstractControl): ValidationErrors | null => {
			const inputValue: string = control.value
				? control.value.toString()
				: '';

			const trimmedValue = inputValue.trim();
			const strippedValue = GenericHelper.stripHTML(trimmedValue);

			return strippedValue.length === 0 ? { required: true } : null;
		};
	};

	public static getSize = (value: number): string => {
		const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
		let unitIndex = 0;
		while (value >= 1024) {
			value /= 1024;
			unitIndex++;
		}
		return `${value.toFixed(2)} ${units[unitIndex]}`;
	};
}
