import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap } from 'rxjs';
import { ChatService } from '../_services/chat.service';
import { getBroadcastMessages, saveBroadcastMessages } from './chat.actions';

@Injectable()
export class ChatEffects {
	constructor(private chatService: ChatService, private actions$: Actions) {}

	loadBroadcastMessages = createEffect(() =>
		this.actions$.pipe(
			ofType(getBroadcastMessages),
			mergeMap(() =>
				this.chatService
					.getMessageByUserId()
					.pipe(
						map((res) => saveBroadcastMessages({ messages: res }))
					)
			)
		)
	);
}
