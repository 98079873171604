import {
	createFeatureSelector,
	createReducer,
	createSelector,
	on,
	State,
} from '@ngrx/store';
import {
	QuestionsInterface,
	TestInterface,
} from 'src/app/core/models/take-test.interface';
import { LPSideBarInterface } from '../lp-side-bar/lp-side-bar.component';
import {
	CourseDeatilsInterface,
	LPCourseInterface,
	MyCourseListInterface,
} from '../_models/course-details.interface';
import { CourseQuizSummaryInterface } from '../_models/course-quiz.interface';
import { CurrentTestDetailsInterface } from '../_services/take-test.service';
import {
	changeCongratulationsPopupState,
	getCourseDetails,
	getCoursesSuccess,
	getMandatoryCourses,
	hideUserOrientationMode,
	initialise,
	MyCourseActionTypesEnum,
	resetNewCode,
	resetTest,
	saveCourse,
	saveCourseMenu,
	saveLP,
	saveLPSidebarMenu,
	saveMandatoryCourses,
	saveRecommendedCourses,
	saveTest,
	saveTestDetails,
	selectAnswer as selectAnswer,
	selectCourse,
	selectLP,
	setLP,
	showUserOrientationMode,
	submitTest,
	updateCourseTimer,
	updateoption,
	updateProgress,
} from './my-course.action';

const newCodeInitialState: newCodeInterface = {
	activeStep: null,
	course: null,
	isLp: null,
	lp: null,
	parentMenu: null,
	subMenu: null,
	test: null,
	testObject: null,
	testDetails: null,
	congratulationsPopupState: false,
	secondsTracked: 0,
	selfAssigned: null,
};
export interface MyCourseState {
	courseList: {
		recommended: Array<MyCourseListInterface>;
		// history: Array<MyCourseListInterface>;
		mandatory: Array<MyCourseListInterface>;
	};
	mandatoryListTotalCourseTime: number;
	selectedOption: number;
	selectedLP: LPCourseInterface;
	mainMenu: LPSideBarInterface[];
	subMenu: LPSideBarInterface[];
	selectedCourse: any;
	isLearningPathway: boolean;
	currentTest: TestInterface;
	currentTestAnswerCollection: {};
	testSubmmited: boolean;
	activeTestType: 'course' | 'lp';
	isLiveEvent: boolean;
	testResult: {
		testScore: number;
		retakeTest: boolean;
		testStatus: 'Failed' | 'Passed';
	};

	newCode?: newCodeInterface;
	showOrientationMessage: boolean;
}

export const intialMyCourseState: MyCourseState = {
	courseList: null,
	mandatoryListTotalCourseTime: 0,
	selectedOption: 1,
	selectedLP: null,
	mainMenu: null,
	subMenu: null,
	selectedCourse: null,
	isLearningPathway: false,
	currentTest: null,
	currentTestAnswerCollection: null,
	isLiveEvent: null,
	testSubmmited: false,
	activeTestType: null,
	testResult: null,

	newCode: newCodeInitialState,
	showOrientationMessage: false,
};

const getMyCourseState = createFeatureSelector<MyCourseState>('my-course');

export const getCurrent = (key: string) =>
	createSelector(getMyCourseState, (state) => {
		if (!key) return null;
		if (key === 'activeMenu') {
			if (state && state.newCode.parentMenu) {
				const selectedMenu = state.newCode.parentMenu.find(
					(menu) => menu.selected
				);
				if (selectedMenu.type === 'course' && state.newCode.subMenu) {
					return state.newCode.subMenu.find((x) => x.selected);
				} else return selectedMenu;
			}
		}
		return state && state.newCode[key];
	});
export const getSelectedCourse = createSelector(
	getMyCourseState,
	(state) => state.selectedCourse
);

export const getCourseList = createSelector(
	getMyCourseState,
	(state) => state.courseList
);

export const getCurrentTest = createSelector(
	getMyCourseState,
	(state) => state.currentTest
);

export const getSelections = createSelector(
	getMyCourseState,
	(state) => state.currentTestAnswerCollection
);

export const testSubmitted = createSelector(
	getMyCourseState,
	(state) => state.testSubmmited
);

export const getLPProgress = createSelector(getMyCourseState, (state) => {
	const id = state.selectedLP.learningPathwayId;
	return state.courseList.mandatory?.find((x) => x.courseId == id)
		.courseProgress;
});

export const getTestResult = createSelector(
	getMyCourseState,
	(state) => state.testResult
);

export const getSelectedLP = createSelector(
	getMyCourseState,
	(state) => state.selectedLP
);

export const isLearningPathway = createSelector(
	getMyCourseState,
	(state) => state.isLearningPathway
);
export const getActiveTestType = createSelector(
	getMyCourseState,
	(state) => state.activeTestType
);

export const selectedOption = createSelector(
	getMyCourseState,
	(state) => state.selectedOption
);

export const isLiveEvent = createSelector(
	getMyCourseState,
	(state) => state.isLiveEvent
);

export const LPSideBarMenu = createSelector(
	getMyCourseState,
	(state) => state.mainMenu
);
export const courseSubMenu = createSelector(
	getMyCourseState,
	(state) => state.subMenu
);

export const getSelectedMenuItem = createSelector(
	getMyCourseState,
	(state) => state.mainMenu && state.mainMenu.find((x) => x.selected)
);
export const getSelectedSubMenuItem = createSelector(
	getMyCourseState,
	(state) => state.subMenu && state.subMenu.find((x) => x.selected)
);
export const getUserOrientationStatus = createSelector(
	getMyCourseState,
	(state) => state && state.showOrientationMessage
);

export const getTotalMandatoryCourseLength = createSelector(
	getMyCourseState,
	(state) => state && state.mandatoryListTotalCourseTime
);

export const myCourseReducer = createReducer(
	intialMyCourseState,

	on(saveCourse, (state, action) => {
		return {
			...state,
			newCode: {
				...state.newCode,
				course: action.course,
				selfAssigned: action.selfAssigned ?? state.newCode.selfAssigned,
			},
		};
	}),
	on(saveLP, (state, action) => {
		return {
			...state,
			newCode: {
				...state.newCode,
				lp: action.lp,
				selfAssigned: action.selfAssigned ?? state.newCode.selfAssigned,
				isLp: true,
			},
		};
	}),
	on(saveTest, (state, action) => {
		return {
			...state,
			currentTestAnswerCollection: getMap(action.test.questionList),
			newCode: {
				...state.newCode,
				test: action.test,
				testObject: action.testObject,
			},
		};
	}),
	on(saveTestDetails, (state, action) => {
		return {
			...state,
			newCode: {
				...state.newCode,
				testObject: action.testObject,
				testDetails: action.details,
			},
		};
	}),

	on(getCoursesSuccess, (state, action) => {
		return {
			...state,
			courseList: {
				mandatory: action.courses[0],
				// history: action.courses[1],
				recommended: action.courses[1],
			},
		};
	}),
	on(saveMandatoryCourses, (state, action) => {
		return {
			...state,
			courseList: {
				...state.courseList,
				mandatory: action.courses,
			},
			mandatoryListTotalCourseTime: action.totalTime,
		};
	}),
	on(saveRecommendedCourses, (state, action) => {
		return {
			...state,
			courseList: {
				...state.courseList,
				recommended: action.courses,
			},
		};
	}),
	// on(getCourseDetails, (state, action) => {
	// 	return {
	// 		...state,
	// 		isLearningPathway: action.learningPathway,
	// 	};
	// }),
	on(setLP, (state, action) => {
		return {
			...state,
			isLearningPathway: action.value,
		};
	}),
	on(selectLP, (state, action) => {
		return {
			...state,
			selectedLP: action.lp,
		};
	}),
	on(selectCourse, (state, action) => {
		return {
			...state,
			selectedCourse: action.course,
			isLiveEvent: action.isLiveEvent ?? false,
		};
	}),
	on(initialise, (state, action) => {
		return {
			...state,
			currentTest: action.test,
			currentTestAnswerCollection: getMap(action.test.questionList),
			activeTestType: action.testType,
		};
	}),
	on(selectAnswer, (state, action) => {
		return {
			...state,
			currentTestAnswerCollection: {
				...state.currentTestAnswerCollection,
				[action.qid]: action.selectedOptions,
			},
		};
	}),
	on(submitTest, (state, action) => {
		return {
			...state,
			testSubmmited: true,
			testResult: action.result,
		};
	}),
	on(resetTest, (state) => {
		return {
			...state,
			currentTest: null,
			currentTestAnswerCollection: null,
			testResult: null,
			testSubmmited: false,
		};
	}),
	on(updateProgress, (state, action) => {
		const allCourses = JSON.parse(
			JSON.stringify(state.courseList.mandatory)
		) as Array<MyCourseListInterface>;
		if (allCourses) {
			const selectedCourse = allCourses.find(
				(x) => x.courseId == action.cid
			);
			selectedCourse.courseProgress = action.progress;
		}
		return {
			...state,
			courseList: {
				...state.courseList,
				mandatory: allCourses,
			},
		};
	}),
	on(updateoption, (state, action) => {
		return {
			...state,
			selectedOption: action.option,
		};
	}),
	on(saveLPSidebarMenu, (state, action) => {
		return {
			...state,
			mainMenu: action.menu,
			newCode: {
				...state.newCode,
				parentMenu: action.menu,
				activeStep: action.menu.find((x) => x.selected).type,

				// activeStep: !state.newCode.isLp
				// 	? action.menu.find((x) => x.selected).type
				// 	: undefined,
			},
		};
	}),
	on(saveCourseMenu, (state, action) => {
		return {
			...state,
			subMenu: action.menu,
			newCode: {
				...state.newCode,
				subMenu: action.menu,
				activeStep: !state.newCode.isLp
					? undefined
					: action.menu.find((x) => x.selected)?.type ?? 'test', //check this
			},
		};
	}),
	on(resetNewCode, (state) => {
		return {
			...state,
			newCode: newCodeInitialState,
		};
	}),
	on(changeCongratulationsPopupState, (state, action) => {
		return {
			...state,
			newCode: {
				...state.newCode,
				congratulationsPopupState: action.opened,
			},
		};
	}),
	on(updateCourseTimer, (state, action) => {
		return {
			...state,
			newCode: {
				...state.newCode,
				secondsTracked: action.from ?? ++state.newCode.secondsTracked,
			},
		};
	}),
	on(showUserOrientationMode, (state, action) => {
		return {
			...state,
			showOrientationMessage: true,
		};
	}),
	on(hideUserOrientationMode, (state, action) => {
		return {
			...state,
			showOrientationMessage: false,
		};
	})
);

const getMap = (quesList: Array<QuestionsInterface>): {} => {
	const arrayOfQurstionId = quesList.map((x) => x.questionId);
	const map: {} = {};
	arrayOfQurstionId.forEach((x) => {
		map[x] = [];
	});
	return map;
};

interface newCodeInterface {
	course: CourseDeatilsInterface;
	lp: LPCourseInterface;
	isLp: boolean;
	test: TestInterface;
	testObject: CurrentTestDetailsInterface;
	parentMenu: LPSideBarInterface[];
	subMenu: LPSideBarInterface[];
	activeStep: 'course' | 'test' | 'event' | 'test-details';
	testDetails: CourseQuizSummaryInterface;
	congratulationsPopupState: boolean;
	secondsTracked: number;
	selfAssigned: boolean;
}
