<div>
	<app-modal-header-placeholder
		[title]="'MANAGE_COMMENTS'"
		(onClose)="closeModal()"
	></app-modal-header-placeholder>
	<div>
		<div
			class="table-responsive mb-2 global-table-wrap"
			*ngIf="data && data.comments"
		>
			<table class="table lms">
				<thead>
					<tr>
						<th>
							{{ "COMMENT" | translate }}
						</th>
						<th>
							{{ "COMMENTED_DATE" | translate }}
						</th>
						<th>
							{{ "COMMENTED_BY" | translate }}
						</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let comment of data.comments">
						<td>{{ comment.comment }}</td>
						<td>{{ comment.updateDate | dateFormat }}</td>
						<td>{{ comment.uploadedBy }}</td>
						<td>
							<mat-icon
								class="cursor-pointer"
								matTooltip="{{ 'DELETE' | translate }}"
								matTooltipPosition="above"
								(click)="deleteComment(comment)"
								>delete</mat-icon
							>
						</td>
					</tr>
					<tr *ngIf="!data.comments.length">
						<td colspan="4">
							{{ "NO_COMMENTS_YET" | translate }}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<slr-textarea
			[placeholder]="'Add Comment'"
			[label]="'Add Comment'"
			(onChange)="updateComment($event)"
		></slr-textarea>
	</div>
	<div mat-dialog-actions class="d-flex justify-content-center">
		<slr-btn02
			(onClick)="onSubmit()"
			[disabled]="commentForm.get('comment').value == ''"
			>Add</slr-btn02
		>
		<button class="btn cancel-btn ms-2" (click)="closeModal()">
			{{ "CANCEL" | translate }}
		</button>
	</div>
</div>
