import { Component, Input, OnDestroy, Optional } from '@angular/core';
import { Subject } from 'rxjs';
import { GenericHelper } from 'src/app/core/helpers/generic-helper.class';
import { MyCourseService } from 'src/app/modules/my-courses/_services/my-course.service';

@Component({
	selector: 'app-download-certificate',
	templateUrl: './download-certificate.component.html',
	styleUrls: ['./download-certificate.component.scss'],
})
export class DownloadCertificateComponent implements OnDestroy {
	@Input() certifiedCourse: {
		userId: number;
		courseName: string;
		completionDate: string;
	};
	@Optional() @Input() public isOrientationCertificate: boolean = false;
	private unsubscribe$ = new Subject();
	constructor(private myCourseService: MyCourseService) {}
	ngOnDestroy(): void {
		this.unsubscribe$.next(true);
	}
	public downloadCertificate(): void {
		this.myCourseService
			.getCertificateNew(
				this.certifiedCourse.userId,
				this.certifiedCourse.courseName,
				this.certifiedCourse.completionDate
			)
			.subscribe({
				next: (res) => {
					window.open(
						GenericHelper.getPDFFromBytes(res['data'][0]),
						'_blank'
					);
				},
			});
	}
	public downloadOrientationCertificate(): void {}
}
