<div id="main-wrapper">
	<div class="custom-scrollbar">
		<div class="track-white">
			<app-help></app-help>
		</div>
	</div>

	<div class="header-wrapper custom-scrollbar">
		<app-header></app-header>
	</div>
	<div
		class="content-wrapper row custom-scrollbar"
		[class.mini-sidebar]="miniSidebar"
		[class.exp-sidebar]="!miniSidebar"
		[class.hid-sidebar]="hiddenSidebar"
	>
		<div id="left-navbar" class="track-white">
			<app-sidebar
				(onExpanded)="toggleContentArea()"
				[newHidden]="hiddenSidebar"
			></app-sidebar>
		</div>

		<div id="content-container">
			<div
				class="main-content"
				[ngClass]="{ 'pe-0 ps-0': isReportsScreen }"
			>
				<app-breadcrumb></app-breadcrumb>
				<router-outlet></router-outlet>
			</div>
			<div class="footer-wrapper">
				<app-footer></app-footer>
			</div>
		</div>
	</div>
</div>
