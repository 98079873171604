<app-modal-header-placeholder
	[title]="'Add Image'"
	(onClose)="close()"
></app-modal-header-placeholder>

<div class="row mt-2">
	<div class="col-12">
		<slr-rbtn-group
			[data]="uploadType"
			(onChange)="updateUploadType($event.id)"
		></slr-rbtn-group>
	</div>
</div>
<div *ngIf="_uploadType == 1" class="row mt-2">
	<div class="col-12">
		<slr-input
			[fgName]="imageForm"
			[controlName]="'url'"
			[label]="'Enter Image Url'"
			[placeholder]="'https://www.example.com'"
		></slr-input>
	</div>
</div>
<div *ngIf="_uploadType == 2" class="row mt-2">
	<div class="col-12">
		<input
			type="file"
			accept=".jpg,.png"
			(change)="uploadFile($event)"
			hidden
			#imageUpload
		/>
		<slr-btn02 *ngIf="!uploading" (onClick)="imageUpload.click()"
			>Choose File</slr-btn02
		>
		<slr-progress-bar
			*ngIf="uploading"
			[value]="uploadProgress"
		></slr-progress-bar>
	</div>
</div>
<div class="row mt-2">
	<div class="col-12">
		<slr-rbtn-group
			[label]="'Chose Alignment'"
			[data]="alignmentData"
			[fgName]="imageForm"
			[controlName]="'alignment'"
		></slr-rbtn-group>
	</div>
</div>
<div class="row mt-2">
	<div class="col-12">
		<slr-btn02 (onClick)="submit()">{{ "SUBMIT" | translate }}</slr-btn02>
	</div>
</div>
