import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-skill-certificate-icon',
	templateUrl: './skill-certificate-icon.component.html',
	styleUrls: ['./skill-certificate-icon.component.scss'],
})
export class SkillCertificateIconComponent {
	@Input() showTick: boolean = false;
}
