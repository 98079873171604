import { Component, Input, OnInit } from '@angular/core';
import { CourseInfoCard } from './course-info-cards.model';

@Component({
	selector: 'app-course-info-cards',
	templateUrl: './course-info-cards.component.html',
	styleUrls: ['./course-info-cards.component.scss'],
})
export class CourseInfoCardsComponent {
	@Input() content: CourseInfoCard = {} as CourseInfoCard;

}
