import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	Resolve,
	RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { forkJoin, map } from 'rxjs';
import { SurveyService } from 'src/app/modules/survey/_services/survey.service';
import { LocationList } from '../models/locations';
import { getLocationDataFromServer, initLang, saveUserLocation } from './shared.actions';
import {
	getRootLocationId,
	getSelectedLocation,
	getUserLocation,
	getUserPrivileges,
	SharedState,
} from './shared.reducer';
@Injectable({
	providedIn: 'root',
})
export class PrivateLayoutResolver implements Resolve<any> {
	constructor(
		private surveyService: SurveyService,
		private sharedStore: Store<SharedState>
	) {}
	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		// this.sharedStore.dispatch(getLocationDataFromServer());
		this.sharedStore.dispatch(initLang());
		return forkJoin([this.surveyService.getLocationsList(true)]).pipe(
			map((result) => {
				const locationLsit = result[0] as LocationList;
				this.sharedStore.dispatch(
					saveUserLocation({ payload: result[0] })
				);
				return result;
			})
		);
	}
}
