import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
	providedIn: 'root',
})
export class EncryptionService {
	encrypt(data: string, key: string): string {
		return CryptoJS.AES.encrypt(data, key).toString();
	}

	decrypt(encryptedData: string, secretKey: string): string {
		const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
		return bytes.toString(CryptoJS.enc.Utf8);
	}

	encode(data: string): string {
		return btoa(data);
	}
}
