export class ErrorMessages {
	public static CONTACT_ADMIN = 'CONTACT_ADMIN';
	public static UNEXPECTED_ERROR = 'UNEXPECTED_ERROR';
	public static PROFILE_NOT_FOUND = 'PROFILE_NOT_FOUND';
	public static TEST_MAX_ATTEMPT_ERROR = 'TEST_MAX_ATTEMPT_ERROR';
	public static INVALID_SESION = 'INVALID_SESSION_MESSAGE';
	public static COMPLET_COURSE_BEFORE_TEST = 'COMPLET_COURSE_BEFORE_TEST';
	public static COMPLETE_PRE_TEST_BEFORE_COURSE =
		'COMPLETE_PRE_TEST_BEFORE_COURSE';
	public static COMPLETE_COURSE_BEFORE_MOVING_TO_NEXT_COURSE =
		'COMPLETE_COURSE_BEFORE_MOVING_TO_NEXT_COURSE';
	public static NEW_PASSWORD_OLD_PASSWORD_ERROR =
		'New password cannot be same as old password';
}
