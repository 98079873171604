import { formatDate } from '@angular/common';
import {
	Directive,
	ElementRef,
	Injectable,
	Input,
	OnInit,
} from '@angular/core';
import { FormGroup, NgControl } from '@angular/forms';
import { NativeDateAdapter } from '@angular/material/core';

@Directive({
	selector: '[dateInputFormat]',
})
export class DateInputFormatDirective implements OnInit {
	@Input() form: { fgName: FormGroup; control: string };
	constructor(
		private elementRef: ElementRef,
		private formControl: NgControl
	) {}
	ngOnInit(): void {
		this.formControl.control.valueChanges.subscribe({
			next: (res) =>
				(this.elementRef.nativeElement.value = formatDate(
					res,
					'dd-MM-yyyy',
					'en-CA'
				)),
		});
	}
}

export const PICK_FORMATS = {
	parse: { dateInput: { month: 'numeric', year: 'numeric', day: 'numeric' } },
	display: {
		dateInput: 'input',
		monthYearLabel: { year: 'numeric', month: 'numeric' },
		dateA11yLabel: { year: 'numeric', month: 'numeric', day: 'numeric' },
		monthYearA11yLabel: { year: 'numeric', month: 'numeric' },
	},
};
export const SLR_DATE_FORMAT = {
	parse: { dateInput: 'YYYY-MM-DD' },
	display: {
		dateInput: 'YYYY-MM-DD',
		monthYearLabel: 'MMMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'YYYY-MM-DD',
	},
};
export const ANNIVERSARY_DATE_FORMAT = {
	parse: { dateInput: 'MM-DD' },
	display: {
		dateInput: 'MM-DD',
		monthYearLabel: 'MMM',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMM YYYY',
	},
};

@Injectable()
export class PickDateAdapter extends NativeDateAdapter {
	override format(date: Date, displayFormat: Object): string {
		this.locale = 'en-CA';
		if (displayFormat === 'input') {
			return formatDate(date, 'dd-MM-yyyy', this.locale);
		} else {
			return date.toDateString();
		}
	}
}
