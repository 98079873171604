<td>
	<mat-checkbox [checked]="showForm" (change)="change($event)"></mat-checkbox>
</td>

<td>
	<div class="d-flex flex-column gap-2">
		<div class="d-flex align-items-center gap-2">
			<div class="skill-name">{{ name }}</div>
			<mat-icon
				*ngIf="description && description.length > 0"
				[matTooltip]="'Description: ' + description"
				class="small-icon cursor-default"
				>info</mat-icon
			>
			<mat-icon
				*ngIf="skill?.sessionTitle && skill?.sessionTitle.length > 0"
				[matTooltip]="
					'SKILL_SESSION_MESSAGE'
						| translate
							: {
									sessionName: skill.sessionTitle,
									obtainedDate:
										(skill.obatainedDate | dateConverter)
							  }
				"
				class="cursor-default"
			>
				live_tv
			</mat-icon>
		</div>
		<div class="d-flex gap-2">
			<mat-icon
				*ngIf="hasCourseAttached"
				[matTooltip]="'COURSE_ATTACHED_TO_SKILL' | translate"
				class="cursor-default"
				>menu_book</mat-icon
			>
			<ng-container *ngIf="showForm">
				<app-skill-comment-icon
					[showTick]="hasComment"
					(click)="addComment()"
				></app-skill-comment-icon>
				<app-skill-certificate-icon
					(click)="addCertificate()"
					[showTick]="hasCertificate"
				></app-skill-certificate-icon>
			</ng-container>
		</div>
		<div
			(click)="onApproveSkill()"
			*ngIf="showForm && !skill.isTemp && skillStatus"
			class="approvals"
			[class.cursor-pointer]="isManagedUsers"
			[class]="skillStatusClass"
		>
			{{ skillStatus | skillStatusTrasnlate }}
		</div>
	</div>
</td>
<td>
	<slr-rbtn-group
		*ngIf="showForm && useComp"
		[fgName]="skillForm"
		[controlName]="'competency'"
		[data]="competenceData"
		[align]="'vertical'"
	></slr-rbtn-group>
</td>
<td>
	<slr-date-input
		*ngIf="showForm"
		[width]="'125px'"
		[fgName]="skillForm"
		[controlName]="'obtainedDate'"
		[label]="'OBTAINED_DATE'"
	>
	</slr-date-input>
</td>
<td *ngIf="renewalType !== 'Never Expires'">
	<slr-date-input
		*ngIf="showForm"
		[width]="'125px'"
		[fgName]="skillForm"
		[controlName]="'renewalDate'"
		[label]="'RENEWAL_DATE'"
	>
	</slr-date-input>
</td>

<td *ngIf="renewalType !== 'Never Expires'">
	<slr-date-input
		*ngIf="showForm"
		[width]="'125px'"
		[fgName]="skillForm"
		[controlName]="'alertDate'"
		[label]="'ALERT_DATE'"
	>
	</slr-date-input>
</td>
<td *ngIf="renewalType === 'Never Expires'" colspan="2" class="text-center">
	<ng-container *ngIf="showForm"> Never Expires </ng-container>
</td>
<td>
	<ng-container *ngIf="showForm">
		{{ skill?.lastUpdated | stringDateFormat }}
	</ng-container>
</td>
