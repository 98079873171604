import { Directive, ElementRef, Input, OnChanges } from '@angular/core';
import { AppConstants } from '../constants/app-constants';

@Directive({
	selector: '[appIsDocument]',
})
export class IsDocumentDirective implements OnChanges {
	@Input() public fileType = '';

	constructor(private el: ElementRef) {}
	ngOnChanges(): void {
		if (AppConstants.docTypes.includes(this.fileType)) {
			this.el.nativeElement.style.display = 'none';
			return;
		}
		this.el.nativeElement.style.display = 'block';
	}
}
