import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, take, withLatestFrom } from 'rxjs';
import {
	BroadcastMessageInterface,
	ConversationInterface,
} from 'src/app/modules/broadcast-and-messaging/_models/conversation.interface';
import { ChatService } from 'src/app/modules/broadcast-and-messaging/_services/chat.service';

import {
	conversationsLoaded,
	getBroadcastMessages as loadMessages,
	updateUnseenMessageCount,
} from 'src/app/modules/broadcast-and-messaging/_state/chat.actions';
import {
	getBroadcastMessages,
	getUnseenMessageCount,
} from 'src/app/modules/broadcast-and-messaging/_state/chat.reducer';
import {
	ChatState,
	getAllConversations,
	isConversationsLoaded,
} from 'src/app/modules/broadcast-and-messaging/_state/chat.reducer';
import { getUserClass, SharedState } from '../../_state/shared.reducer';
import { NewConversationPopupComponent } from '../new-conversation-popup/new-conversation-popup.component';
import { PermissionHelper } from 'src/app/core/helpers/permission-helper.class';
import { BroadcastService } from 'src/app/core/services/broadcast.service';
import { getLocationInfo } from '../../_state/shared.actions';
import {
	AuthenticationState,
	isLoggedIn,
} from 'src/app/modules/authentication/_state/authentication.reducer';

@Component({
	selector: 'app-chat-popup',
	templateUrl: './chat-popup.component.html',
	styleUrls: ['./chat-popup.component.scss'],
})
export class ChatPopupComponent implements OnInit, AfterViewInit {
	public permissionHelper = new PermissionHelper();
	public searchForm: FormGroup;
	public threads$: Observable<Array<BroadcastMessageInterface>>;
	public userClass: string;

	constructor(
		private chatStore: Store<ChatState>,
		private chatService: ChatService,
		private sharedStore: Store<SharedState>,
		private authStore: Store<AuthenticationState>,
		private _fb: FormBuilder,
		private chatPopup: MatDialogRef<ChatPopupComponent>,
		private matDialog: MatDialog,
		private router: Router,
		private broadcastService: BroadcastService
	) {
		this.sharedStore.pipe(select(getUserClass)).subscribe({
			next: (res) => (this.userClass = res),
		});
		this.chatStore
			.pipe(
				select(isConversationsLoaded),
				withLatestFrom(this.authStore.pipe(select(isLoggedIn)))
			)
			.subscribe({
				next: ([res, isLoggedIn]) => {
					if (!isLoggedIn) return;
					if (!res) {
						this.chatStore.dispatch(loadMessages());
					}
				},
			});
		this.threads$ = this.chatStore.pipe(select(getBroadcastMessages));
	}

	ngOnInit(): void {
		this.searchForm = this._fb.group({
			search: new FormControl(),
		});
	}
	ngAfterViewInit(): void {
		this.chatStore.pipe(select(getUnseenMessageCount), take(1)).subscribe({
			next: (res) => {
				if (res > 0) {
					// TODO: Call API to reset unseen message count.
					this.chatService.markMessageAsSeen().subscribe({
						next: (res) => {
							this.chatStore.dispatch(
								updateUnseenMessageCount({ newCount: 0 })
							);
						},
					});
				}
			},
		});
	}

	public newMessage(): void {
		this.chatPopup.close();
		const smallConfig = {
			width: '100%',
			maxWidth: '100vw',
			panelClass: 'mob-modal-padding',
		};
		const largeConfig = { width: '60%' };
		const defaultConfig = { height: '80%', disableClose: true };
		this.sharedStore.dispatch(getLocationInfo({ root: true }));
		setTimeout(() => {
			this.matDialog.open(
				NewConversationPopupComponent,
				this.broadcastService.screenSize.value === 'small'
					? { ...smallConfig, ...defaultConfig }
					: { ...largeConfig, ...defaultConfig }
			);
		}, 100);
	}

	public viewAll(): void {
		this.matDialog.closeAll();
		this.router.navigate(['/chat']);
	}
}
