import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class UrlProviderProd {
	// -- NOTE: wsApiUrl gets prepended to endpoints Not starting with 'http' or '/'


	public getUrl(endPoint: string, apiFor?: string): string {
		switch (apiFor) {
			case 'HelpSystem':
				return `${environment.helpSystemApiUrl}${endPoint}`;
			case 'scorm':
				return `${environment.scorm.apiUrl}/RusticiEngine/api/v2/${endPoint}`;
			case 'cognito':
				return `${environment.cognito.apiUrl}${endPoint}`;
			case 'RegenisisSurvey':
				return `${environment.helpSystemApiUrl}${endPoint}`;
			case 'cognito_auth':
				return `${environment.cognitoAuthUrl}${endPoint}`;
			case 'vimeo_api':
				return `${environment.vimeo.apiUrl}${endPoint}`;
			default:
				return `${environment.cloudApiUrl}${endPoint}`;
		}
	}
	public getScormUrl(endPoint: string): string {
		return `${environment.scorm.apiUrl}/RusticiEngine/api/v2/${endPoint}`;
	}
}

@Injectable()
export class UrlProvider extends UrlProviderProd {
	// -- NOTE: wsApiUrl gets prepended to endpoints Not starting with 'http' or '/'
}

@Injectable()
export class UrlProviderTest extends UrlProviderProd {
	// -- NOTE: wsApiUrl gets prepended to endpoints Not starting with 'http' or '/'
}

@Injectable()
export class UrlProviderDev extends UrlProviderProd {
	// -- NOTE: wsApiUrl gets prepended to endpoints Not starting with 'http' or '/'
}
