import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import { ErrorMessageService } from 'src/app/core/services/error-message.service';

@Component({
	selector: 'slr-drp06',
	templateUrl: './slr-drp06.component.html',
	styleUrls: ['./slr-drp06.component.scss'],
})
export class SlrDrp06Component implements OnChanges {
	@Input() formGroup: FormGroup;
	@Input() controlName: string;
	@Input() data: any[];
	@Input() bindLabel: string;
	@Input() bindValue: string;
	@Input() placeholder: string = '';
	@Input() searchable = false;
	@Input() width = '100%';
	@Input() disabled = false;
	@Input() clearable = true;
	@Input() label: string;
	@Input() showError = true;
	public isRequired = false;

	constructor(
		private errorMessageService: ErrorMessageService,
		private translate: TranslatePipe
	) {}

	ngOnChanges(): void {
		this.isRequired = this.formGroup
			.get(this.controlName)
			.hasValidator(Validators.required);
	}
	private get validatorMessages() {
		return [
			{
				validationType: 'required',
				validationMessage: `${this.translate.transform(
					this.label
				)} is required`,
			},
		];
	}
	public get errorMessage(): string {
		return this.errorMessageService.setErrorMessage(
			this.validatorMessages,
			this.formGroup.get(this.controlName)
		);
	}
}
