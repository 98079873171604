import { Component, HostBinding, TemplateRef } from '@angular/core';
import { NotificationService } from 'src/app/core/services/notification.service';

@Component({
  selector: 'app-notification-container',
  templateUrl: './notification-container.component.html',
  styleUrls: ['./notification-container.component.scss'],
})
export class NotificationContainerComponent {
  @HostBinding('class.ngb-toasts') showToastr: boolean = true;
  constructor(public notificationService: NotificationService) {}
  isTemplate(toast: any) {
    return toast.textOrTpl instanceof TemplateRef;
  }
}
