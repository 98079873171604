import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { GenericHelper } from 'src/app/core/helpers/generic-helper.class';
import { ValidatorMessage } from 'src/app/core/models/validation-messages.model';
import { ErrorMessageService } from 'src/app/core/services/error-message.service';

@Component({
	selector: 'slr-username-tb',
	templateUrl: './slr-username-tb.component.html',
	styleUrls: ['./slr-username-tb.component.scss'],
})
export class SlrUsernameTbComponent implements OnInit {
	@Input() public placeholder: string;
	@Input() public lable: string;
	@Input() public control: FormControl;
	@Input() public validatorMessages: ValidatorMessage[];

	public errorMessage: string;

	constructor(private errorMessageService: ErrorMessageService) {}

	ngOnInit(): void {
		this.control.addValidators(
			GenericHelper.usernameValidator(this.control)
		)
		this.setErrorMessage();
	}

	public setErrorMessage(): void {
		this.errorMessage = this.errorMessageService.setErrorMessage(
			this.validatorMessages,
			this.control
		);
	}
}
