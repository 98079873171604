import { Action } from '@ngrx/store';

export enum AppTypes {
	ResetAppState = '[Application] Clean State',
}
export class ResetAppState implements Action {
	readonly type = AppTypes.ResetAppState;
}


export type AppAction = ResetAppState