import { Component, Input, OnChanges } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { PreviewFileDetails } from 'src/app/shared/models/create-resource';

@Component({
	selector: 'app-file-preview',
	templateUrl: './file-preview.component.html',
	styleUrls: ['./file-preview.component.scss'],
})
export class FilePreviewComponent implements OnChanges {
	@Input() fileDetails: PreviewFileDetails = {} as PreviewFileDetails;
	public videoAudioFile: boolean = false;
	public rusticiFilePath: SafeUrl;

	constructor(private _sanitizer: DomSanitizer) {}
	ngOnChanges(): void {
		if (this.fileDetails.fileUrl == 'rustici') {
			const file = this.fileDetails.filePathInSystem;
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => {
				this.rusticiFilePath =
					this._sanitizer.bypassSecurityTrustResourceUrl(
						reader.result as string
					);
			};
		}
		if (this.fileDetails.fileType) {
			this.videoAudioFile =
				this.fileDetails.fileType.includes('video') ||
				this.fileDetails.fileType.includes('audio');
		}
	}
}
