<div class="blockmobile">
	<div class="d-flex justify-content-between ms-4 ps-1">
		<div class="content-header">
			{{ "SETTINGS" | translate }}
		</div>
	</div>
	<div class="">
		<mat-tab-group
			class="settings-tab-group my-4"
			mat-align-tabs="start"
			animationDuration="500ms"
		>
			<mat-tab
				*ngIf="
					mainSettingsTabPermissions[
						permissionHelper.settingsModule.SETINGS_LMS
					]
				"
				[label]="'LMS' | translate"
				class="toggle-tabs"
			>
				<div class="container-fluid">
					<div
						class="row mat-elevation-z4 my-2 py-2 align-items-center rounded"
						*ngFor="let setting of lmsSettings"
					>
						<div class="col-12 col-sm-12 col-md-4 col-lg-6">
							<h5>
								{{ setting.name | translate }}
							</h5>
							<h6>
								{{ setting.name + "_description" | translate }}
							</h6>
						</div>
						<div class="col-12 col-sm-12 col-md-8 col-lg-6">
							<div *ngIf="setting.useType === 'checkbox'">
								<slr-toggle
									[checked]="setting.value"
									(toggle)="
										slrCheckboxChange($event, setting)
									"
								></slr-toggle>
							</div>
							<div *ngIf="setting.useType == 'radio'">
								<slr-rbtn-group
									[data]="setting.options"
									[fgName]="settingsForm"
									[controlName]="setting.name"
									(onChange)="radioChange($event, setting)"
								></slr-rbtn-group>
							</div>
							<div *ngIf="setting.useType === 'text'">
								<slr-input
									[fgName]="settingsForm"
									[controlName]="setting.name"
								></slr-input>
							</div>
							<div *ngIf="setting.useType === 'select'">
								<slr-drp01
									[fgName]="settingsForm"
									[controlName]="setting.name"
									[data]="setting.options"
								></slr-drp01>
							</div>
							<div *ngIf="setting.type === 'file'">
								<div class="col-12">
									<ng-container
										*ngIf="
											uploadingFileFor == setting.name &&
												fileUploading;
											else nonUploading
										"
									>
										<ng-container>
											<slr-progress-bar
												[value]="fileUploadProgress"
											></slr-progress-bar>
										</ng-container>
									</ng-container>
									<ng-template #nonUploading>
										<ng-container
											*ngIf="
												settingsForm.get(setting.name)
													?.value &&
												settingsForm.get(setting.name)
													?.value != 'null'
											"
										>
											<div
												class="d-flex gap-2 flex-wrap align-items-center"
											>
												{{
													settingsForm
														.get(setting.name)
														?.value.split("/")
														.pop()
												}}
												<mat-icon
													[matTooltip]="
														'DELETE'
															| translate
															| uppercase
													"
													class="small-icon cursor-pointer"
													(click)="
														removeFile(setting.name)
													"
													(keyup)="
														removeFile(setting.name)
													"
													>delete</mat-icon
												>
												<mat-icon
													[matTooltip]="
														'PREVIEW' | translate
													"
													class="small-icon cursor-pointer"
													(click)="
														previewFile(
															setting.name
														)
													"
													(keyup)="
														previewFile(
															setting.name
														)
													"
													>preview</mat-icon
												>
												<slr-btn01
													(click)="
														selectFile(setting.name)
													"
													(keyup)="
														selectFile(setting.name)
													"
													>{{
														"REPLACE_FILE"
															| translate
													}}</slr-btn01
												>
											</div>
										</ng-container>
										<ng-container
											*ngIf="
												!settingsForm.get(setting.name)
													?.value ||
												settingsForm.get(setting.name)
													?.value == 'null'
											"
										>
											<slr-btn01
												(click)="
													selectFile(setting.name)
												"
												(keyup)="
													selectFile(setting.name)
												"
												>{{
													"CHOOSE_FILE" | translate
												}}</slr-btn01
											>
										</ng-container>
									</ng-template>
								</div>
							</div>
							<div *ngIf="setting.type === 'color-picker'">
								<div class="col-6">
									<div
										class="d-flex gap-2 align-items-center"
									>
										<slr-color-input
											[label]="'SELECT_COLOR' | translate"
											[fgName]="settingsForm"
											[controlName]="setting.name"
											[position]="'top'"
										></slr-color-input>
										<mat-icon
											class="small-icon cursor-pointer"
											[matTooltip]="
												'RESET_COLOR' | translate
											"
											(click)="resetColor(setting.name)"
											(keyup)="resetColor(setting.name)"
											>format_color_reset</mat-icon
										>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</mat-tab>
			<mat-tab *ngIf="false" [label]="'ENTERPRISE' | translate">
				<div class="container-fluid">
					<div
						class="row mat-elevation-z4 my-2 py-2 align-items-center rounded"
						*ngFor="let setting of enterpriseSettings"
					>
						<div class="col-12 col-sm-12 col-md-4 col-lg-6">
							<h5>
								{{ setting.name | translate }}
							</h5>
							<h6>
								{{ setting.name + "_description" | translate }}
							</h6>
						</div>
						<div class="col-12 col-sm-12 col-md-8 col-lg-6">
							<div *ngIf="setting.useType === 'checkbox'">
								<slr-toggle
									[checked]="setting.value"
									(toggle)="
										slrCheckboxChange($event, setting)
									"
								></slr-toggle>
							</div>
							<div *ngIf="setting.useType == 'radio'">
								<slr-rbtn-group
									[data]="setting.options"
									[fgName]="settingsForm"
									[controlName]="setting.name"
									(onChange)="radioChange($event, setting)"
								></slr-rbtn-group>
							</div>
							<div *ngIf="setting.useType === 'text'">
								<slr-input
									[fgName]="settingsForm"
									[controlName]="setting.name"
								></slr-input>
							</div>
							<div *ngIf="setting.useType === 'select'">
								<slr-drp01
									[fgName]="settingsForm"
									[controlName]="'{{ setting.name }}'"
									[data]="setting.options"
								></slr-drp01>
							</div>
							<div *ngIf="setting.useType === 'date'">
								Date Type
							</div>
						</div>
					</div>
				</div>
			</mat-tab>
			<mat-tab
				*ngIf="
					mainSettingsTabPermissions[
						permissionHelper.settingsModule.SETTINGS_QRM
					]
				"
				[label]="'QRM' | translate"
			>
				<div class="container-fluid">
					<div
						class="row mat-elevation-z4 my-2 py-2 align-items-center rounded"
						*ngFor="let setting of qrmSettings"
					>
						<div class="col-12 col-sm-12 col-md-4 col-lg-6">
							<h5>
								{{ setting.name | translate }}
							</h5>
							<h6>
								{{ setting.name + "_description" | translate }}
							</h6>
						</div>
						<div class="col-12 col-sm-12 col-md-8 col-lg-6">
							<div *ngIf="setting.useType === 'checkbox'">
								<slr-toggle
									[checked]="setting.value"
									(toggle)="
										slrCheckboxChange($event, setting)
									"
								></slr-toggle>
							</div>
							<div *ngIf="setting.useType == 'radio'">
								<slr-rbtn-group
									[data]="setting.options"
									[fgName]="settingsForm"
									[controlName]="setting.name"
									(onChange)="radioChange($event, setting)"
								></slr-rbtn-group>
							</div>
							<div *ngIf="setting.useType === 'text'">
								<slr-input
									[fgName]="settingsForm"
									[controlName]="setting.name"
								></slr-input>
							</div>
							<div *ngIf="setting.useType === 'select'">
								<slr-drp01
									[fgName]="settingsForm"
									[controlName]="setting.name"
									[data]="setting.options"
								></slr-drp01>
							</div>
							<div *ngIf="setting.useType === 'date'">
								Date Type
							</div>
						</div>
					</div>
				</div>
			</mat-tab>

			<mat-tab
				*ngIf="
					mainSettingsTabPermissions[
						permissionHelper.settingsModule
							.SETTINGS_POLICY_PROFESSIONAL
					]
				"
				[label]="'POLICY_PROFESSIONAL' | translate"
			>
				<div class="container-fluid">
					<div
						class="row mat-elevation-z4 my-2 py-2 align-items-center rounded"
						*ngFor="let setting of policySettings"
					>
						<div class="col-12 col-sm-12 col-md-4 col-lg-6">
							<h5>
								{{ setting.name | translate }}
							</h5>
							<h6>
								{{ setting.name + "_description" | translate }}
							</h6>
						</div>
						<div class="col-12 col-sm-12 col-md-8 col-lg-6">
							<div *ngIf="setting.useType === 'checkbox'">
								<slr-toggle
									[checked]="setting.value"
									(toggle)="
										slrCheckboxChange($event, setting)
									"
								></slr-toggle>
							</div>
							<div *ngIf="setting.useType == 'radio'">
								<slr-rbtn-group
									[data]="setting.options"
									[fgName]="settingsForm"
									[controlName]="setting.name"
									(onChange)="radioChange($event, setting)"
								></slr-rbtn-group>
							</div>
							<div *ngIf="setting.useType === 'text'">
								<slr-input
									[fgName]="settingsForm"
									[controlName]="setting.name"
								></slr-input>
							</div>
							<div *ngIf="setting.useType === 'select'">
								<slr-drp01
									[fgName]="settingsForm"
									[controlName]="setting.name"
									[data]="setting.options"
								></slr-drp01>
							</div>
							<div *ngIf="setting.useType === 'date'">
								Date Type
							</div>
						</div>
					</div>
				</div>
			</mat-tab>
			<mat-tab
				*ngIf="
					mainSettingsTabPermissions[
						permissionHelper.settingsModule.SETTINGS_OTHER
					]
				"
				[label]="'OTHER' | translate"
			>
				<div class="container-fluid">
					<div
						class="row mat-elevation-z4 my-2 py-2 align-items-center rounded"
						*ngFor="let setting of otherSettings"
					>
						<div class="col-12 col-sm-12 col-md-4 col-lg-6">
							<h5>
								{{ setting.name | translate }}
							</h5>
							<h6>
								{{ setting.name + "_description" | translate }}
							</h6>
						</div>
						<div class="col-12 col-sm-12 col-md-8 col-lg-6">
							<div *ngIf="setting.useType === 'checkbox'">
								<slr-toggle
									[checked]="setting.value"
									(toggle)="
										slrCheckboxChange($event, setting)
									"
								></slr-toggle>
							</div>
							<div *ngIf="setting.useType == 'radio'">
								<slr-rbtn-group
									[data]="setting.options"
									[fgName]="settingsForm"
									[controlName]="setting.name"
									(onChange)="radioChange($event, setting)"
								></slr-rbtn-group>
							</div>
							<div *ngIf="setting.useType === 'text'">
								<slr-input
									[fgName]="settingsForm"
									[controlName]="setting.name"
								></slr-input>
							</div>
							<div *ngIf="setting.useType === 'select'">
								<slr-drp01
									[fgName]="settingsForm"
									[controlName]="setting.name"
									[data]="setting.options"
								></slr-drp01>
							</div>
							<div *ngIf="setting.useType === 'date'">
								<slr-input
									[placeholder]="'MM-DD'"
									[fgName]="settingsForm"
									[controlName]="setting.name"
								></slr-input>
							</div>
						</div>
					</div>
				</div>
			</mat-tab>
			<mat-tab
				*ngIf="
					mainSettingsTabPermissions[
						permissionHelper.settingsModule.SETTINGS_SKILLS
					]
				"
				[label]="'SKILLS' | translate"
			>
				<div class="container-fluid">
					<div
						class="row mat-elevation-z4 my-2 py-2 align-items-center rounded"
						*ngFor="let setting of skillsSettings"
					>
						<div class="col-12 col-sm-12 col-md-4 col-lg-6">
							<h5>
								{{ setting.name | translate }}
							</h5>
							<h6>
								{{ setting.name + "_description" | translate }}
							</h6>
						</div>
						<div class="col-12 col-sm-12 col-md-8 col-lg-6">
							<div *ngIf="setting.useType === 'checkbox'">
								<slr-toggle
									[checked]="setting.value"
									(toggle)="
										slrCheckboxChange($event, setting)
									"
								></slr-toggle>
							</div>
							<div *ngIf="setting.useType == 'radio'">
								<slr-rbtn-group
									[data]="setting.options"
									[fgName]="settingsForm"
									[controlName]="setting.name"
									(onChange)="radioChange($event, setting)"
								></slr-rbtn-group>
							</div>
							<div *ngIf="setting.useType === 'text'">
								<slr-input
									[fgName]="settingsForm"
									[controlName]="setting.name"
								></slr-input>
							</div>
							<div *ngIf="setting.useType === 'select'">
								<slr-drp01
									[fgName]="settingsForm"
									[controlName]="setting.name"
									[data]="setting.options"
								></slr-drp01>
							</div>
							<div *ngIf="setting.useType === 'date'">
								Date Type
							</div>
						</div>
					</div>
				</div>
			</mat-tab>
			<mat-tab
				*ngIf="
					mainSettingsTabPermissions[
						permissionHelper.settingsModule.SETTINGS_EMAIL
					]
				"
				[label]="'EMAIL' | translate"
			>
				<div class="container-fluid">
					<div
						class="row mat-elevation-z4 my-2 py-2 align-items-center rounded"
						*ngFor="let setting of emailSettings"
					>
						<div class="col-lg-6 col-sm-12">
							<h5>
								{{ setting.name | translate }}
							</h5>
							<h6>
								{{ setting.name + "_description" | translate }}
							</h6>
						</div>
						<div class="col-lg-6 col-sm-12">
							<div *ngIf="setting.useType === 'checkbox'">
								<slr-toggle
									[checked]="setting.value"
									(toggle)="
										slrCheckboxChange($event, setting)
									"
								></slr-toggle>
							</div>
							<div *ngIf="setting.useType == 'radio'">
								<slr-rbtn-group
									[data]="setting.options"
									[fgName]="settingsForm"
									[controlName]="setting.name"
									(onChange)="radioChange($event, setting)"
								></slr-rbtn-group>
							</div>
							<div *ngIf="setting.useType === 'text'">
								<slr-input
									[fgName]="settingsForm"
									[controlName]="setting.name"
								></slr-input>
							</div>
							<div *ngIf="setting.useType === 'select'">
								<slr-drp01
									[fgName]="settingsForm"
									[controlName]="setting.name"
									[data]="setting.options"
								></slr-drp01>
							</div>
							<div *ngIf="setting.useType === 'date'">
								Date Type
							</div>
						</div>
					</div>
				</div>
			</mat-tab>
		</mat-tab-group>
	</div>
	<div class="ms-4">
		<app-save-button
			class="ms-2"
			(saveClicked)="saveSettings(false)"
		></app-save-button>
	</div>
</div>
<input
	#fileSelectInput
	hidden
	type="file"
	(change)="uploadFile($event)"
	accept="image/jpeg; image/png"
/>
