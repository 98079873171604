import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'durationFormat',
})
export class DurationFormatPipe implements PipeTransform {
	transform(duration: string): string {
		const hours = `${Math.floor(+duration / 3600)}`.padStart(2, '0');
		const mins = `${Math.floor((+duration % 3600) / 60)}`.padStart(2, '0');
		const secs = `${Math.floor((+duration % 3600) % 60)}`.padStart(2, '0');
		return `${hours}:${mins}:${secs}`;
	}
}
