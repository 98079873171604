import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
	createFeatureSelector,
	createReducer,
	createSelector,
	on,
} from '@ngrx/store';
import {
	BroadcastMessageInterface,
	ConversationInterface,
} from '../_models/conversation.interface';
import { DummyConversations } from '../_models/dummy-convo';
import {
	addBroadCastMessage,
	addMessage,
	addNewConversation,
	addReplyToTempMessage,
	addTemporaryMessage,
	conversationsLoaded,
	createNewConversation,
	deleteBroadcastMessage,
	removeReplyToTempMessage,
	saveBroadcastMessages,
	saveTempMessageText,
	toggleRightSideBar,
	updateSelectedConversation,
	updateUnseenMessageCount,
} from './chat.actions';

const conversationSortComparator = (
	a: ConversationInterface,
	b: ConversationInterface
) => {
	return a.messages[a.messages?.length - 1]?.createdAt <
		b.messages[b.messages?.length - 1]?.createdAt
		? 1
		: 0;
};

export interface ChatState extends EntityState<ConversationInterface> {
	selectedConversation: ConversationInterface;
	newConversation: ConversationInterface;
	sidebarOpen: boolean;
	conversationsLoaded: boolean;
	broadcastMessages: Array<BroadcastMessageInterface>;
	unseenMessageCount: number;
}

export const conversationAdapter: EntityAdapter<ConversationInterface> =
	createEntityAdapter<ConversationInterface>({
		selectId: (conversation) => conversation.conversationId,
		sortComparer: conversationSortComparator,
	});

export const initialChatState: ChatState = conversationAdapter.getInitialState({
	selectedConversation: DummyConversations[0],
	newConversation: null,
	sidebarOpen: null,
	conversationsLoaded: false,
	broadcastMessages: null,
	unseenMessageCount: 0,
});

export const chatReducer = createReducer(
	initialChatState,

	/**
	 * For Legacy System like Broadcast Feature
	 */

	on(saveBroadcastMessages, (state, action) => {
		return {
			...state,
			broadcastMessages: action.messages,
			conversationsLoaded: true,
		};
	}),
	on(addBroadCastMessage, (state, action) => {
		return {
			...state,
			broadcastMessages: [action.message, ...state.broadcastMessages],
		};
	}),
	on(deleteBroadcastMessage, (state, action) => {
		return {
			...state,
			broadcastMessages: state.broadcastMessages.filter(
				(x) => x.messageId != action.id
			),
		};
	}),
	on(updateUnseenMessageCount, (state, action) => {
		if (action.newCount) {
			return {
				...state,
				unseenMessageCount: action.newCount,
			};
		}
		if (action.decrement) {
			return {
				...state,
				unseenMessageCount:
					state.unseenMessageCount > 0
						? state.unseenMessageCount - 1
						: 0,
			};
		}
		if (action.newCount) {
			return {
				...state,
				unseenMessageCount: state.unseenMessageCount + 1,
			};
		}
		return state;
	}),

	/**
	 * Will be used for Chat functionality in future.
	 */

	on(conversationsLoaded, (state, action) => {
		state = {
			...state,
			conversationsLoaded: true,
		};
		return conversationAdapter.addMany(action.conversations, state);
	}),
	on(addMessage, (state, action) => {
		return conversationAdapter.updateOne(
			{
				id: action.conversationId,
				changes: {
					messages: [
						action.message,
						...state.entities[action.conversationId].messages,
					],
				},
			},
			state
		);
	}),
	on(addTemporaryMessage, (state, action) => {
		return conversationAdapter.updateOne(
			{
				id: action.conversationId,
				changes: { temporaryMessage: action.message },
			},
			state
		);
	}),
	on(addReplyToTempMessage, (state, action) => {
		return conversationAdapter.updateOne(
			{
				id: action.conversationId,
				changes: {
					temporaryMessage: {
						...state.entities[action.conversationId]
							.temporaryMessage,
						isReply: true,
						replyMessage: action.replyMessage,
					},
				},
			},
			state
		);
	}),
	on(saveTempMessageText, (state, action) => {
		return conversationAdapter.updateOne(
			{
				id: action.conversationId,
				changes: {
					temporaryMessage: {
						...state.entities[action.conversationId]
							.temporaryMessage,
						message: action.text,
					},
				},
			},
			state
		);
	}),
	on(removeReplyToTempMessage, (state, action) =>
		conversationAdapter.updateOne(
			{
				id: action.conversationId,
				changes: {
					temporaryMessage: {
						...state.entities[action.conversationId]
							.temporaryMessage,
						isReply: false,
						replyMessage: null,
					},
				},
			},
			state
		)
	),
	on(updateSelectedConversation, (state, { conversation }) => {
		return {
			...state,
			selectedConversation: conversation,
		};
	}),
	on(toggleRightSideBar, (state) => {
		return {
			...state,
			sidebarOpen: !state.sidebarOpen,
		};
	}),
	on(addNewConversation, (state, action) => {
		return {
			...state,
			newConversation: action.conversation,
		};
	}),
	on(createNewConversation, (state, action) => {
		const convo = state.newConversation;
		convo.users = action.toUsers;
		if (convo.users.length > 2) {
			convo.conversationName =
				convo.users
					.map((x) => x.name)
					.slice(0, 3)
					.join(', ') + `+ ${convo.users.length - 2} more`;
		}
		convo.messages = [convo.temporaryMessage];
		convo.temporaryMessage = null;
		return conversationAdapter.addOne(
			convo as ConversationInterface,
			state
		);
	})
);

export const conversations = createFeatureSelector<ChatState>('slr-chat');
export const getBroadcastMessages = createSelector(
	conversations,
	(state) => state.broadcastMessages
);
export const { selectAll: getAllConversations } =
	conversationAdapter.getSelectors(conversations);

export const getSelectedConversations = createSelector(
	conversations,
	(state) => state.selectedConversation
);

export const getMessageById = createSelector(conversations, (state) => {
	return state.entities[state.selectedConversation.conversationId]?.messages;
});

export const sidebarStatus = createSelector(
	conversations,
	(state) => state.sidebarOpen
);
export const isConversationsLoaded = createSelector(
	conversations,
	(state) => state.conversationsLoaded
);
export const newConversation = createSelector(
	conversations,
	(state) => state.newConversation
);
export const getUnseenMessageCount = createSelector(
	conversations,
	(state) => state.unseenMessageCount
);