import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { HelpSystemService } from 'src/app/core/services/help-system.service';
import { NotificationService } from 'src/app/core/services/notification.service';

@Component({
	selector: 'app-unsubscribe',
	templateUrl: './unsubscribe.component.html',
	styleUrls: ['./unsubscribe.component.scss'],
})
export class UnsubscribeComponent implements OnInit {
	formGroup: FormGroup;
	reason: any;
	userId: any;
	unsubscriber$ = new Subject();
	submitted: boolean;
	successResponse: any;
	constructor(
		private fb: FormBuilder,
		private routerSnapshot: ActivatedRoute,
		private service: HelpSystemService,
		private notificationService: NotificationService
	) {}

	ngOnInit(): void {
		this.submitted = false;
		this.userId = this.routerSnapshot.snapshot.queryParamMap.get('userId');
		this.formGroup = this.fb.group({
			reason: [null, Validators.required],
		});
	}

	ngOnDestroy(): void {
		this.unsubscriber$.next('');
	}

	unsubscribe() {
		if (this.formGroup.valid) {
			let payload = {
				reason: this.formGroup.get('reason').value,
				userId: this.userId,
			};
			this.service
				.unSubscribeFeeds(payload)
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe({
					next: (response) => {
						this.successResponse = response;
						this.notificationService.success(
							`Unsubscription Successfull`
						);
						this.submitted = true;
					},
					error: (err) => {
						this.notificationService.error(`${err.status}`);
					},
				});
		}
	}
}
