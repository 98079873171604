import {
	Component,
	Input,
	Output,
	EventEmitter,
	OnChanges,
} from '@angular/core';
import { LocalDataService } from 'src/app/core/services/local-data.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { CustomTableColumn } from 'src/app/modules/reports/_models/reports-overview.model';

@Component({
	selector: 'app-customize-columns',
	templateUrl: './customize-columns.component.html',
	styleUrls: ['./customize-columns.component.scss'],
})
export class CustomizeColumnsComponent implements OnChanges {
	@Input() columnList: Array<CustomTableColumn> = [];
	public displayingList: Array<CustomTableColumn> = [];
	public openModal: boolean = false;
	@Output() updatedColumns = new EventEmitter();

	constructor(
		private localData: LocalDataService,
		private notificationService: NotificationService
	) {}
	ngOnChanges(): void {
		this.displayingList = this.localData.getUnreferredArray(
			this.columnList
		);
	}
	public emitSelectedValues(update: boolean): void {
		if (update === true) {
			const checkedCols = this.displayingList?.filter(
				(col) => col?.isShown
			);
			if (checkedCols?.length === 0) {
				this.notificationService.error('ATLEASE_ONE_COL')
				return;
			}
		}
		this.openModal = false;
		this.updatedColumns.next({
			columns: this.localData.getUnreferredArray(this.displayingList),
			update,
		});
	}
	public openConfirmationModal(): void {
		this.openModal = true;
		this.localData.modalHeader = 'CUSTOMIZE_REPORT_COLUMNS';
		// reset the value suppose if we checked some col but cancel the event
		this.displayingList = this.localData.getUnreferredArray(
			this.columnList
		);
	}
}
