import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-pdf-button',
	templateUrl: './pdf-button.component.html',
	styleUrls: ['./pdf-button.component.scss'],
})
export class PdfButtonComponent {
	@Input() classes: string = '';
	@Output() download: EventEmitter<boolean> = new EventEmitter();
	public downloadPdfFile(): void {
		this.download.next(true);
	}
}
