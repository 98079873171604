<app-modal-header-placeholder
	[title]="'Choose Location To Login'"
	(onClose)="close(undefined)"
></app-modal-header-placeholder>
<div class="d-flex flex-column align-items-center cont">
	<div class="flex-grow-1 w-100 px-5">
		<slr-drp01
			[data]="drpData"
			[placeholder]="'Choose Account *'"
			(onChange)="selection.select($event.name)"
		></slr-drp01>
	</div>
	<div class="" >
		<slr-btn01 (onClick)="submit()" class="mt-3">SUBMIT</slr-btn01>
	</div>
</div>
