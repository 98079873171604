import {
	Component,
	OnInit,
	Output,
	Input,
	EventEmitter,
	ViewChild,
	ElementRef,
	AfterViewInit,
} from '@angular/core';
import moment from 'moment';
import { AppConstants } from '../../../constants/app-constants';

@Component({
	selector: 'slr-year-month-input',
	templateUrl: './year-month-input.component.html',
	styleUrls: ['./year-month-input.component.scss'],
})
export class YearMonthInputComponent implements AfterViewInit {
	@Input() public set selected(value: { year: number; month: number }) {
		if (value) this.value = value;
	}
	@Output()
	public onChange = new EventEmitter<{
		year: number;
		month: number;
	}>();
	@ViewChild('yearInput') public yearInput: ElementRef;
	@ViewChild('monthInput') public monthInput: ElementRef;

	public value: {
		year: number;
		month: number;
	} = {
		year: moment().year(),
		month: null,
	};
	ngAfterViewInit(): void {
		this.value.year && this.updateYear(undefined, this.value.year);
		this.value.month && this.updateMonth(undefined, this.value.month);
	}

	updateMonth(date: string, month: number) {
		if (date) {
			this.monthInput.nativeElement.value =
				AppConstants.shortMonths[moment(date).month()];
		}
		if (month) {
			this.monthInput.nativeElement.value =
				AppConstants.shortMonths[month];
		}
	}
	updateYear(date: string, year: number) {
		if (year) {
			this.yearInput.nativeElement.value = year;
		}
		if (date) {
			this.yearInput.nativeElement.value = moment(date).year();
		}
		this.monthInput.nativeElement.value = null;
	}

	changeMonth(event: any, el: any, input: any): void {
		el.close();
		this.updateMonth(event, undefined);
		this.selected = {
			...this.value,
			month: moment(event).month(),
		};
		this.onChange.emit(this.value);
	}
	changeYear(event: any, el: any, input: any): void {
		el.close();
		this.updateYear(event, undefined);
		this.selected = {
			...this.value,
			month: null,
			year: moment(event).year(),
		};
		this.onChange.emit(this.value);
	}
}
