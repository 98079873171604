<div class="year-month">
	<mat-form-field [matTooltip]="'VIEW_EVENTS_FOR_A_YEAR' | translate">
		<input
			#yearInput
			matInput
			[matDatepicker]="yearSelector"
			(focusout)="updateYear(yearInput.value, undefined)"
		/>
		<mat-datepicker-toggle
			matSuffix
			[for]="yearSelector"
		></mat-datepicker-toggle>
		<mat-datepicker
			#yearSelector
			(yearSelected)="changeYear($event, yearSelector, yearInput)"
			startView="multi-year"
		></mat-datepicker>
	</mat-form-field>
	<mat-form-field [matTooltip]="'VIEW_EVENTS_FOR_A_MONTH' | translate">
		<input
			#monthInput
			matInput
			[matDatepicker]="monthSelector"
			(focusout)="updateMonth(monthInput.value, undefined)"
		/>
		<mat-datepicker-toggle
			matSuffix
			[for]="monthSelector"
		></mat-datepicker-toggle>
		<mat-datepicker
			(monthSelected)="changeMonth($event, monthSelector, monthInput)"
			#monthSelector
			startView="year"
		></mat-datepicker>
	</mat-form-field>
</div>
