import { Injectable } from '@angular/core';
import { Observable, map, throwError } from 'rxjs';
import { LocalDataService } from 'src/app/core/services/local-data.service';
import { UrlNames } from 'src/app/core/services/urlProfiler';
import { BaseWebApiService } from 'src/app/core/services/_base-web-api.service';
import { ResourceDetails } from 'src/app/shared/models/create-resource';
import {
	CreateDocsPayload,
	EditDocPayload,
} from '../_models/create-docs.model';

@Injectable({
	providedIn: 'root',
})
export class CreateResourceService {
	constructor(
		private apiService: BaseWebApiService,
		private localData: LocalDataService
	) {}
	public createResource(payload: ResourceDetails, isLp: boolean) {
		return this.apiService.post(
			`${UrlNames.courses}${
				isLp ? UrlNames.learningPathway : UrlNames.resource
			}`,
			payload
		);
	}
	public getClusterDetails() {
		return this.apiService.get(`${UrlNames.courses}${UrlNames.cluster}`, {
			locationId: this.localData.getLocationId(),
		});
	}
	public getAvailableLiveEvents() {
		return this.apiService.get(
			`${UrlNames.liveEvents}${UrlNames.allLiveEvents}`,
			{
				userId: this.localData.getUserId(),
				locationId: this.localData.getLocationId(),
				startDate: '',
				endDate: '',
			}
		);
	}
	public editResource(updatedBody, isLp = false): Observable<any> {
		return this.apiService
			.put(
				`${isLp ? UrlNames.lp : UrlNames.course}${UrlNames.edit}`,
				updatedBody
			)
			.pipe(
				map((res: any) => {
					if (res && res.errorcode === 1) {
						return new Error(res.errorMessage);
					}
					return res
				})
			);
	}
	public createDocs(payload: CreateDocsPayload) {
		return this.apiService.post(
			`${UrlNames.resources}${UrlNames.docs}`,
			payload
		);
	}
	public editDoc(payload: EditDocPayload[], convertToCourse: boolean) {
		return this.apiService.put(
			`${UrlNames.resources}${UrlNames.docs}`,
			payload,
			{
				loginUserId: this.localData.getUserId(),
				convertToCourse: convertToCourse,
			}
		);
	}
}
