import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { QuestionsInterface } from 'src/app/core/models/take-test.interface';
import { SlrUrlQueryParams } from 'src/app/core/models/url-params.enum';
import { DropdownInterface } from 'src/app/shared/components/dropdowns/slr-drp01/dropdown.model';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { getSelections, MyCourseState } from '../_state/my-course.reducer';

@Component({
	selector: 'app-test-option',
	templateUrl: './test-option.component.html',
	styleUrls: ['./test-option.component.scss'],
})
export class TestOptionComponent implements OnInit, OnDestroy {
	@Input() public question: QuestionsInterface;
	@Input() public optionType: 'mcq' | 'multiple' | 'paragraph' | 'slider';
	@Input() public option: DropdownInterface;

	public isShowTestAnswer: boolean;

	public optionCorrect: boolean;
	public optionSelected: boolean;
	public selectedAnswers: {};
	public paraghraphAnswer: string = '';

	private unsubscriber = new Subject<void>();

	constructor(
		private _route: ActivatedRoute,
		private testStore: Store<MyCourseState>
	) {
		this.testStore.pipe(select(getSelections)).subscribe({
			next: (res) => {
				this.selectedAnswers = res;
			},
		});
		this._route.queryParams
			.pipe(takeUntil(this.unsubscriber))
			.subscribe((params) => {
				this.isShowTestAnswer =
					params[SlrUrlQueryParams.SHOW_TEST_ANSWERS];
			});
	}

	ngOnInit(): void {
		if (this.question.type !== 'paragraph') {
			this.optionCorrect = this.isOptionCorrect(this.option.id);
			this.optionSelected = this.isOptionSelected(this.option.id);
		} else {
			this.paraghraphAnswer =
				this.selectedAnswers[this.question.questionId];
		}
	}

	// Check if the option is present in the answerKey
	private isOptionCorrect(id: number): boolean {
		const option = this.question.options.find((x) => x.valueId === id);
		return option && option.correctAnswer;
	}

	// Check if the user selected this option
	private isOptionSelected(id: number): boolean {
		if (typeof this.question.selectedAnswers === 'string') return false;
		return this.selectedAnswers[this.question.questionId].includes(id);
	}

	ngOnDestroy(): void {
		this.unsubscriber.next();
		this.unsubscriber.unsubscribe();
	}
}
