<ngb-pagination
	[boundaryLinks]="boundaryLinks"
	[directionLinks]="directionLinks"
	[disabled]="disabled"
	[collectionSize]="collectionSize"
	[(page)]="page"
	[ellipses]="ellipses"
	[maxSize]="maxSize"
	[pageSize]="pageSize"
	[rotate]="rotate"
	[size]="size"
	(pageChange)="pageChanged()"
>
	<ng-template ngbPaginationPrevious>
		<span class="material-icons-outlined" [ngStyle]="{
				'opacity': isPreviousDisabled ? '0.5' : '1'
			  }">{{ previous }}</span>
	</ng-template>
	<ng-template ngbPaginationNext>
		<span class="material-icons-outlined" [ngStyle]="{
				'opacity': isNextDisabled ? '0.5' : '1'
			  }">{{ next }}</span>
	</ng-template>
	</ngb-pagination>
