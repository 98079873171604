import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, Subject, take, takeUntil } from 'rxjs';
import { SlrUrlQueryParams } from 'src/app/core/models/url-params.enum';
import { BroadcastService } from 'src/app/core/services/broadcast.service';
import { LocalDataService } from 'src/app/core/services/local-data.service';
interface IBreadCrumb {
	title: string;
	url: string;
	queryParams: string;
}
@Component({
	selector: 'app-breadcrumb',
	templateUrl: './breadcrumb.component.html',
	styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnDestroy {
	public breadCrumbItems = new Array<IBreadCrumb>();
	public isReportsScreen = false;
	private unsubscribe$ = new Subject();
	constructor(
		private router: Router,
		private localData: LocalDataService,
		private route: ActivatedRoute,
		private broadcastService: BroadcastService
	) {
		this.localData.getRouteChange().subscribe((event) => {
			this.breadCrumbItems = event['breadCrumb'];
			this.checkFormSearchParams();
		});
		this.checkReportsUrl();
	}

	public get mobileSidebarState() {
		return this.broadcastService.mobileSidebarState.value;
	}
	public get currentScreenSize() {
		return this.broadcastService.screenSize.value;
	}
	checkReportsUrl() {
		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				takeUntil(this.unsubscribe$)
			)
			.subscribe(
				(route: NavigationEnd) =>
					(this.isReportsScreen = route.url
						.toString()
						.includes('reports'))
			);
	}
	private checkFormSearchParams(): void {
		this.route.queryParams.pipe(take(1)).subscribe({
			next: (res) => {
				if (res[SlrUrlQueryParams.SEARCH_QUERY]) {
					this.breadCrumbItems = [
						...this.breadCrumbItems,
						{
							queryParams: '',
							title: `Search (${
								res[SlrUrlQueryParams.SEARCH_QUERY]
							})`,
							url: '',
						},
					];
				}
			},
		});
	}
	navigateToRoute(menu: any) {
		menu.url &&
			this.router.navigate([menu.url], {
				queryParams: menu.queryParams,
			});
	}

	public toggleSidebar(): void {
		this.broadcastService.toggleMobileSidebarState();
	}
	ngOnDestroy(): void {
		this.unsubscribe$.next(true);
	}
}
