import { SelectionChange } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { NotificationService } from 'src/app/core/services/notification.service';
import { DropdownInterface } from 'src/app/modules/survey/_models/take-survey.interface';
import { MultiSelectChkbInterface } from 'src/app/shared/components/multi-select-chkb/_models/multi-select-chkb.model';
import {
	AddNewCodeInterface,
	ManageCodeClientInterface,
	ManageCodeGPInterface,
	ManageCodesParentInterface,
	ManageCodesVendorInterface,
	SaveCodeListInterface,
	VOListInterface,
} from '../../../_models/manage-codes.interface';
import { SettingsService } from '../../../_services/settings.service';

@Component({
	selector: 'app-add-new-code',
	templateUrl: './add-new-code.component.html',
	styleUrls: ['./add-new-code.component.scss'],
})
export class AddNewCodeComponent implements OnInit, OnDestroy {
	public data: AddNewCodeInterface;

	// Grand Parent Lists
	public grandParentData = [];
	public filteredGrandParentData = [];

	// Parent Lists
	public parentData = [];
	public filteredParentData = [];

	// Vendor Lists
	public vendorData = [];
	public filteredVendorData = [];

	// Shared Lists
	public sharedData = [];
	public filteredSharedData = [];

	public searchFormGroup: FormGroup;
	grandParentToggle: boolean = null;
	parentToggle: boolean = null;
	vendorToggle: boolean = null;

	private selection: {
		grandParent: Array<number>;
		parent: Array<number>;
		vendor: Array<number>;
	} = { grandParent: [], parent: [], vendor: [] };

	private voSelection: { [key: number]: VOListInterface } = {};
	private unsubscriber$ = new Subject<void>();
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private settingService: SettingsService,
		private notficationService: NotificationService,
		private _fb: FormBuilder
	) {}

	ngOnInit(): void {
		this.initData();
		this.searchFormGroup = this._fb.group({
			grandParentSearch: [''],
			parentSearch: [''],
			vendorSearch: [''],
			sharedSearch: [''],
		});
		this.observeFormChanges();
	}

	observeFormChanges() {
		this.searchFormGroup
			.get('grandParentSearch')
			.valueChanges.subscribe((searchInput) => {
				this.filterList(searchInput, 'GRAND_PARENT');
			});

		this.searchFormGroup
			.get('parentSearch')
			.valueChanges.subscribe((searchInput) => {
				this.filterList(searchInput, 'PARENT');
			});
		this.searchFormGroup
			.get('vendorSearch')
			.valueChanges.subscribe((searchInput) => {
				this.filterList(searchInput, 'VENDOR');
			});
	}

	filterList(input, listToToggle) {
		if (input) {
			switch (listToToggle) {
				case 'GRAND_PARENT':
					this.filteredGrandParentData = this.grandParentData.filter(
						(code: any) =>
							code?.clientName.toLowerCase().includes(input)
					);
					break;
				case 'PARENT':
					this.filteredParentData = this.parentData.filter(
						(code: any) =>
							code?.clientName.toLowerCase().includes(input)
					);
					break;
				case 'VENDOR':
					this.filteredVendorData = this.vendorData.filter(
						(code: any) =>
							code?.clientName.toLowerCase().includes(input)
					);
					break;
				case 'SHARED':
					this.filteredSharedData = this.sharedData.filter(
						(code: any) =>
							code?.clientName.toLowerCase().includes(input)
					);
					break;
				default:
					console.log('Default Case');
					break;
			}
			this.setToggles();
		}
	}

	// private get payload(): SaveCodeListInterface {
	// 	const res: SaveCodeListInterface = {
	// 		clientCode: null,
	// 		manageCodesVOList: Object.values(this.voSelection),
	// 	};

	// 	return res;
	// }

	private addVO(
		type: Array<MultiSelectChkbInterface<ManageCodeClientInterface>>,
		id: number
	): void {
		this.voSelection = {
			...this.voSelection,
			[id]: {
				docSharer: true,
				groupCode: type.find((x) => x.id == id).extras['clientCode'],
				meesageBoardMsg: true,
			},
		};
	}
	private removeVO(id: string) {
		delete this.voSelection[id];
	}

	private initData(): void {
		let i = 1;
		this.route.data.pipe(takeUntil(this.unsubscriber$)).subscribe({
			next: (res) => {
				console.log('Response: ', res);
				if (!res['masterData']) return;

				this.data = res['masterData'];

				this.grandParentData = JSON.parse(
					JSON.stringify(this.data.grandParent)
				);
				this.filteredGrandParentData = JSON.parse(
					JSON.stringify(this.grandParentData)
				);
				this.filteredGrandParentData.forEach(
					(code) => (code['changed'] = false)
				);

				this.parentData = JSON.parse(JSON.stringify(this.data.parent));
				this.filteredParentData = JSON.parse(
					JSON.stringify(this.parentData)
				);
				this.filteredParentData.forEach(
					(code) => (code['changed'] = false)
				);

				this.vendorData = JSON.parse(JSON.stringify(this.data.vendor));
				this.filteredVendorData = JSON.parse(
					JSON.stringify(this.vendorData)
				);
				this.filteredVendorData.forEach(
					(code) => (code['changed'] = false)
				);

				this.sharedData = JSON.parse(JSON.stringify(this.data.shared));
				this.filteredSharedData = JSON.parse(
					JSON.stringify(this.sharedData)
				);
				this.filteredSharedData.forEach(
					(code) => (code['changed'] = false)
				);
			},
		});
		// this.setToggles();
	}

	setToggles(event?: MatCheckboxChange, code?) {
		if (code) {
			code.newCode = !event?.checked;
			code.changed = !code.changed;
		}
		// this.grandParentToggle = this.filteredGrandParentData.every(
		// 	(code) => code.newCode
		// );

		// this.parentToggle = this.filteredParentData.every(
		// 	(code) => code.newCode
		// );
		// this.vendorToggle = this.filteredVendorData.every(
		// 	(code) => code.newCode
		// );
	}

	public submit(): void {
		// Added Codes
		let filteredGrandParent = this.filteredGrandParentData
			.filter((code) => code.changed)
			.filter((code) => !code.newCode)
			.map((code) => {
				return {
					docSharer: code.docSharer,
					groupCode: code.clientCode,
					newCode: code.newCode,
					meesageBoardMsg: true,
				};
			});
		let filteredParent = this.filteredParentData
			.filter((code) => code.changed)
			.filter((code) => !code.newCode)
			.map((code) => {
				return {
					docSharer: code.docSharer,
					groupCode: code.clientCode,
					newCode: code.newCode,
					meesageBoardMsg: true,
				};
			});
		let filteredVendor = this.filteredVendorData
			.filter((code) => code.changed)
			.filter((code) => !code.newCode)
			.map((code) => {
				return {
					docSharer: code.docSharer,
					groupCode: code.clientCode,
					newCode: code.newCode,
					meesageBoardMsg: true,
				};
			});
		let filteredShared = this.filteredSharedData
			.filter((code) => code.changed)
			.filter((code) => !code.newCode)
			.map((code) => {
				return {
					docSharer: code.docSharer,
					groupCode: code.clientCode,
					newCode: code.newCode,
					meesageBoardMsg: true,
				};
			});

		// Removed Codes
		let filteredGrandParentRemoved = this.filteredGrandParentData
			.filter((code) => code.changed)
			.filter((code) => code.newCode)
			.map((code) => code.clientCode);
		let filteredParentRemoved = this.filteredParentData
			.filter((code) => code.changed)
			.filter((code) => code.newCode)
			.map((code) => code.clientCode);
		let filteredVendorRemoved = this.filteredVendorData
			.filter((code) => code.changed)
			.filter((code) => code.newCode)
			.map((code) => code.clientCode);
		let filteredSharedrRemoved = this.filteredSharedData
			.filter((code) => code.changed)
			.filter((code) => code.newCode)
			.map((code) => code.clientCode);

		const payload: SaveCodeListInterface = {
			clientCode: null,
			manageCodesVOList: [
				...filteredGrandParent,
				...filteredParent,
				...filteredVendor,
				...filteredShared,
			],
			deleteGroupCodeList: [
				...filteredGrandParentRemoved,
				...filteredParentRemoved,
				...filteredVendorRemoved,
				...filteredSharedrRemoved,
			],
		};
		// if (this.selection.grandParent.length !== 1) {
		// 	this.notficationService.error('Please select one grandparent');
		// 	return;
		// }
		// if (this.selection.parent.length !== 1) {
		// 	this.notficationService.error('Please select one parent');
		// 	return;
		// }
		// if (this.selection.vendor.length == 0) {
		// 	this.notficationService.error('Please select atleast one vendor');
		// 	return;
		// }

		this.settingService.saveManageCodes(payload).subscribe({
			next: (res) => {
				this.notficationService.success('Codes have been added');
				this.routeBack();
			},
		});
	}

	routeBack() {
		this.router.navigate(['/manage-codes']);
	}

	public update(type: number, event: SelectionChange<any>): void {
		switch (type) {
			case 1:
				this.selection.grandParent = event.source.selected;
				this.updateVo(this.grandParentData, event);
				break;
			case 2:
				this.selection.parent = event.source.selected;
				this.updateVo(this.parentData, event);
				break;
			case 3:
				this.selection.vendor = event.source.selected;
				this.updateVo(this.vendorData, event);
				break;
		}
	}

	private updateVo(
		type: Array<MultiSelectChkbInterface<ManageCodeClientInterface>>,
		event: SelectionChange<any>
	): void {
		if (event.added.length > 0) {
			this.addVO(type, event.added[0]);
		}
		if (event.removed.length > 0) {
			this.removeVO(event.removed[0]);
		}
	}

	ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.unsubscribe();
	}

	// onToggle(event, listToToggle) {
	// 	switch (listToToggle) {
	// 		case 'GRAND_PARENT':
	// 			this.filteredGrandParentData.forEach(
	// 				(code) => (code.newCode = event)
	// 			);
	// 			break;
	// 		case 'PARENT':
	// 			this.filteredParentData.forEach(
	// 				(code) => (code.newCode = event)
	// 			);
	// 			break;
	// 		case 'VENDOR':
	// 			this.filteredVendorData.forEach(
	// 				(code) => (code.newCode = event)
	// 			);
	// 			break;
	// 		default:
	// 			console.log('Default Case');
	// 			break;
	// 	}
	// }
}
