import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
const loaderStates: boolean[] = [];
@Injectable({
	providedIn: 'root',
})
export class BroadcastService {
	public showHelpSystem$ = new Subject<boolean>();
	public toggleSidebar$ = new Subject();
	private mobileSidebarState$ = new BehaviorSubject<'show' | 'hide'>('hide');
	public screenSize = new BehaviorSubject<'small' | 'medium' | 'large'>(
		'large'
	);
	public loaderState$: Subject<boolean[]> = new Subject<boolean[]>();
	public modalOpened$ = new Subject<boolean>();
	public openCourseContent$ = new Subject<void>(); // to open course content in my course
	public productbroadcastdata;

	constructor(private screenObs: BreakpointObserver) {
		this.showHelpSystem$.next(false);
		this.modalOpened$.next(false);
		this.checkScrenSize();
	}

	get mobileSidebarState() {
		return this.mobileSidebarState$;
	}
	checkScrenSize(): void {
		this.screenObs
			.observe([
				Breakpoints.Small,
				Breakpoints.XSmall,
				Breakpoints.Medium,
				Breakpoints.Large,
				Breakpoints.XLarge,
				Breakpoints.Handset,
			])
			.subscribe({
				next: (res) => {
					if (
						res['breakpoints'][Breakpoints.Small] ||
						res['breakpoints'][Breakpoints.XSmall]
					) {
						this.screenSize.next('small');
					}
					if (res['breakpoints'][Breakpoints.Medium]) {
						this.screenSize.next('medium');
					}
					if (
						res['breakpoints'][Breakpoints.Large] ||
						res['breakpoints'][Breakpoints.XLarge]
					) {
						this.screenSize.next('large');
					}
				},
			});
	}
	toggleMobileSidebarState(): void {
		const mobSidebarState = this.mobileSidebarState$.value;
		this.mobileSidebarState$.next(
			mobSidebarState === 'hide' ? 'show' : 'hide'
		);
	}
	hideMobileSidebarState(): void {
		this.mobileSidebarState$.next('hide');
	}
	showMobileSidebarState(): void {
		this.mobileSidebarState$.next('show');
	}
	toggleSidebar(value: boolean) {
		this.toggleSidebar$.next(value);
	}
	public showGlobalLoader(): void {
		loaderStates.push(true);
		this.loaderState$.next(loaderStates);
	}
	public hideGlobalLoader(): void {
		loaderStates.pop();
		this.loaderState$.next(loaderStates);
	}

	get loaderState(): Observable<boolean[]> {
		return this.loaderState$.asObservable();
	}
	showHelpSystem() {
		this.showHelpSystem$.next(true);
	}
	hideHelpSystem() {
		this.showHelpSystem$.next(false);
	}
}
