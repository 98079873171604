<div
	class="help-system"
	[ngClass]="showHelpSystem ? 'show-help-width' : 'hide-help-width'"
>
	<div class="help-header align-items-center justify-content-center py-2">
		<div class="d-flex align-items-center justify-content-between col-12">
			<h2 class="text-end col-7">{{ "HELP" | translate }}</h2>
			<a
				class="material-icons-outlined close-help-icon text-end pe-2 mob-help-close"
				(click)="closeHelpSystem()"
				target="_blank"
			>
				close
			</a>
		</div>
		<div class="search-help m-auto mb-2">
			<div class="input-group">
				<input
					type="text"
					class="form-control"
					id="validationCustomUsername"
					[placeholder]="'HOW_CAN_WE_HELP_YOU' | translate"
					[(ngModel)]="searchHelpText"
					aria-describedby="inputGroupPrepend"
					(change)="searchHelp(searchHelpText)"
				/>
			</div>
		</div>
	</div>
	<div
		class="zendesk-article px-4 pt-3 custom-scrollbar"
		*ngIf="articleToShow"
	>
		<div
			*ngIf="articles?.length > 1"
			class="d-flex align-items-center back-arrow"
			(click)="backToArticlesList()"
			(keyup)="backToArticlesList()"
		>
			<em class="material-icons-outlined me-2"> arrow_backward </em>
			{{ "Back" }}
		</div>

		<div class="d-flex align-items-center justify-content-between">
			<h4 class="mb-2">
				{{ articleToShow?.title }}
			</h4>
		</div>
		<div id="help-article" [innerHTML]="htmlString"></div>
	</div>
	<div
		*ngIf="articles?.length > 1 && !articleToShow"
		class="search-results px-4 pt-3"
	>
		<!-- we should show 'Top Results' heading while search only -->
		<div
			class="top-results mb-2"
			*ngIf="
				isSearchEventTriggered ||
				(searchHelpText && isSearchEventTriggered)
			"
		>
			{{ "Top Results" }}
		</div>
		<div
			*ngFor="let article of articles"
			class="search-results-article pb-2"
			(click)="setArticleToShow(article)"
			(keyup)="setArticleToShow(article)"
		>
			<span>{{ article.title }}</span>
		</div>
	</div>
	<div
		class="zendesk-article px-4"
		*ngIf="!articles?.length && !articleToShow"
	>
		<h6 class="my-4 text-center">
			{{ "ARTICLES_NOT_AVAILABLE" | translate }}
		</h6>
	</div>
</div>
