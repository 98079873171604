import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-slr-menu',
	templateUrl: './slr-menu.component.html',
	styleUrls: ['./slr-menu.component.scss'],
})
export class SlrMenuComponent {
	@Input() public icon: string = 'more_vert';
	@Input() public menuItems: {
		name: string;
		action: string;
		icon?: string;
	}[];
	@Output() public onAction = new EventEmitter<string>();

	public emitAction(action: string): void {
		this.onAction.emit(action);
	}
}
