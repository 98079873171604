import { AfterViewInit, Component, Input, OnChanges } from '@angular/core';
import {
	Chart,
	ChartConfiguration,
	ChartItem,
	registerables,
	ChartData,
} from 'chart.js';
import { BarChartData } from 'src/app/modules/reports/_models/reports-overview.model';
Chart.register(...registerables);

@Component({
	selector: 'app-bar-chart',
	templateUrl: './bar-chart.component.html',
	styleUrls: ['./bar-chart.component.scss'],
})
export class BarChartComponent implements OnChanges, AfterViewInit {
	@Input() barChartData: BarChartData = <BarChartData>{};
	public chart: Chart;
	ngOnChanges(): void {
		const canvas: HTMLElement = document.getElementById(
			`bar-chart-${this.barChartData.id}`
		);
		if (canvas) {
			canvas.remove();
		}
		this.initializeChart();
	}
	ngAfterViewInit(): void {
		this.initializeChart();
	}
	private initializeChart(): void {
		const element = <HTMLCanvasElement>(
			document.getElementById(`bar-chart-${this.barChartData.id}`)
		);
		if (!element) {
			return;
		}
		const context = element.getContext('2d');
		let gradient: any = this.barChartData.backgroundColor;
		if (this.barChartData.isGradient) {
			gradient = [];
			this.barChartData.backgroundColor.forEach((color, i) => {
				gradient.push(
					this.getLinearGradient(
						color,
						this.barChartData.gradientBackgroundColor[i],
						context
					)
				);
			});
		}
		const labels = this.barChartData.labels;
		const data: ChartData = {
			labels: labels,
			datasets: [
				{
					backgroundColor: gradient,
					borderColor: gradient,
					data: this.barChartData.values,
					type: 'bar',
					barPercentage: 0.2,
				},
			],
		};
		const config: ChartConfiguration = this.getChartConfigurations(data);
		this.chart = new Chart(
			document.getElementById(
				`bar-chart-${this.barChartData.id}`
			) as ChartItem,
			config
		);
	}
	updateConfigByMutating(chart) {
		chart.options.plugins.title.text = 'new title';
		chart.update();
	}
	private getChartConfigurations(data): ChartConfiguration {
		return {
			type: 'bar',
			data: data,
			options: {
				responsive: true,
            maintainAspectRatio: false,

				transitions: {
					show: {
						animations: {
							x: {
								from: 0,
							},
							y: {
								from: 0,
							},
						},
					},
					hide: {
						animations: {
							x: {
								to: 0,
							},
							y: {
								to: 0,
							},
						},
					},
				},
				plugins: {
					legend: {
						display: false,
					},
				},
				aspectRatio: 5 / 4,
				scales: {
					xAxis: {
						grid: {
							display: false,
							drawBorder: false,
						},
						ticks: {
							color: '#666666',
						},
					},
					yAxis: {
						grid: {
							borderDash: [2, 2],
							drawBorder: false,
							borderColor: '#999999',
						},
					},
				},
			},
		};
	}
	private getLinearGradient(
		color1: string,
		color2: string,
		context: CanvasRenderingContext2D
	): CanvasGradient {
		const gradient = context.createLinearGradient(0, 0, 0, 600);
		gradient.addColorStop(0, color1);
		gradient.addColorStop(1, color2);
		return gradient;
	}
}
