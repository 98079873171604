export enum LocalStorageKey {
	TOKEN = 'token',
	DECODED_JWT = 'decodedJwt',
	ROLE = 'role',
	PRIVILEGES = 'privileges',
	SELECTED_LOCATION = 'selectedLocation',
	LOCATION_DATA = 'locationData',
	LOCATION_TREE = 'locationTree',
	LANGUAGE = 'lang',
	CURRENT_LOCATION_TYPE = 'currentLocationType',
	SETTINGS = 'settings',
	PRODUCT_ACCESS_SETTINGS = 'productAccessSettings',
	MY_COURSE_EXTRA_PARAMS = 'mcEP',
	USER_SESSION_ID = 'userSessionId',
	ORIENTATION_MODE = 'orientationMode',
	LOGGEDT_OUT = 'loggedOut',
	EXPIRE_TIME = 'expiryTime',
	REMEMBER_SITE_CODE = 'rememberSiteCode',
}
