import { Pipe, PipeTransform } from '@angular/core';
import { CustomTableColumn } from '../_models/reports-overview.model';

@Pipe({
	name: 'reportCategoryColspan',
})
export class ReportCategoryColspanPipe implements PipeTransform {
	transform(columns: CustomTableColumn[]): number {
		return columns.filter((column) => column.isShown).length;
	}
}
