<mat-form-field [formGroup]="fgName" appearance="outline" [style.width]="width">
	<mat-label *ngIf="label">{{ label | translate }}</mat-label>
	<mat-icon matPrefix *ngIf="icon && iconAling === 'prefix'">{{
		icon
	}}</mat-icon>
	<input
		#inputText
		matInput
		[type]="'number'"
		[min]="min"
		[max]="max"
		[autocomplete]="autoComplete"
		[placeholder]="placeholder | translate"
		(change)="setErrorMessage()"
		[formControlName]="controlName"
	/>
	{{ autoFocus ? inputText.focus() : "" }}
	<mat-icon matSuffix *ngIf="icon && iconAling === 'suffix'">{{
		icon
	}}</mat-icon>
	<mat-error *ngIf="fgName?.get(controlName).invalid && showError">{{
		errorMessage
	}}</mat-error>
</mat-form-field>
