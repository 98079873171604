/* eslint-disable no-undef */
/**
 * [id] is a required attribute while using site-location component
 */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DropdownPosition } from '@ng-select/ng-select';
import { DropdownInterface } from '../dropdowns/slr-drp01/dropdown.model';

@Component({
  selector: 'app-site-location',
  templateUrl: './site-location.component.html',
  styleUrls: ['./site-location.component.scss'],
})
export class SiteLocationComponent {
  @Input() id: string = '';
  @Input() placeholder: string = 'Multi-Site Location';
  @Input() dropdownPosition: DropdownPosition = 'auto';
  @Input() classes: string = '';
  @Input() hidden: boolean = false;
  @Input() options: Array<DropdownInterface> = [];
  @Input() public value: number

  @Output() selectChange: EventEmitter<DropdownInterface> = new EventEmitter<DropdownInterface>();

  ngSelectChange(selectedOption: DropdownInterface) {
    this.selectChange.emit(selectedOption);
  }
}
