import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	Router,
	RouterStateSnapshot,
	UrlTree,
} from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { SlrUrlQueryParams } from 'src/app/core/models/url-params.enum';
import { MyCourseState, testSubmitted } from '../_state/my-course.reducer';

@Injectable()
export class TakeTestGuard implements CanActivate {
	private _testSubmitted: boolean;
	constructor(
		private router: Router,
		private testStore: Store<MyCourseState>
	) {
		this.testStore.pipe(select(testSubmitted)).subscribe({
			next: (res) => (this._testSubmitted = res),
		});
	}
	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	):
		| boolean
		| UrlTree
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree> {
		const showingAswers = route.queryParamMap.has(
			SlrUrlQueryParams.SHOW_TEST_ANSWERS
		);
		if (this._testSubmitted && !showingAswers) {
			this.router.navigate(['/courses']);
			return of(false);
		}

		return of(true);
	}
}
