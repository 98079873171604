import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { FileUploadService } from 'src/app/core/services/file-upload.service';
import { NotificationService } from 'src/app/core/services/notification.service';

@Component({
	selector: 'app-user-image',
	templateUrl: './user-image.component.html',
	styleUrls: ['./user-image.component.scss'],
})
export class UserImageComponent implements OnInit, OnDestroy {
	@Input() filePath: string = '';
	public fileUrl: string = '';
	private unsubscribe$ = new Subject();
	constructor(
		private fileUploadService: FileUploadService,
		private notificationService: NotificationService
	) {}

	ngOnInit(): void {
		console.log(this.filePath);

		if (this.filePath) {
			this.fileUploadService
				.getPresignedUrl(this.filePath)
				.pipe(takeUntil(this.unsubscribe$))
				.subscribe({
					next: (res) => {
						this.fileUrl = res.s3PresingedURL;
					},
					error: (err) => {
						this.notificationService.error(err);
					},
				});
		}
	}
	ngOnDestroy(): void {
		this.unsubscribe$.next(true);
	}
}
