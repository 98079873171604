export enum RosourceCenterModulePrivilegesEnum {
	MODULE_NAME = 96,
	CREATING_TEST = 13,
	CREATE_NEW_LEARNING_PATHWAY = 14,
	RESOURCE_MODULE_ALL_RESOURCES = 15,
	RESOURCE_MODULE_SURGE_LIBRARY = 16,
	RESOURCE_MODULE_MY_LIBRRARY = 17,
	RESOURCE_MODULE_SHARED_LIBRARY = 18,
	RESOURCE_MODULE_DOCS_SURGE = 19,
	RESOURCE_MODULE_DOCS_PRIVILEGE = 20,
	ADD_NEW_RESOURCE = 262, //21,
	ADD_NEW_RESOURCE_SHARED = 22,
	ADD_NEW_RESOURCE_SURGE = 23,
	ADD_NEW_RESOURCE_MY_LIBRARY = 24,
	ADD_NEW_RESOURCE_DOCS = 263, //25,
	ASSIGN = 1,
	UNASSIGN = 2,
	UNHIDE = 3,
	REMOVE_RECOMMEND = 4,
	ADD_COURCE_TO_LP = 5,
	RECOMMEND = 6,
	EDIT = 7,
	ARCHIVE = 8,
	ACTIVATE = 8,
	INACTIVATE = 10,
	HIDE = 11,
	DELETE = 12,
	STATUS_DROPDOWN = 87,
	LANGUAGE_DROPDOWN = 88,
	DOWNLOAD_PDF = 206,
	SET_REQUIRED_EDUCATION_DEPARTMENT = 219,
	SET_REQUIRED_EDUCATION_USER = 220,
}
