import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
	BehaviorSubject,
	map,
	mapTo,
	mergeMap,
	Observable,
	skipWhile,
	take,
	takeUntil,
	withLatestFrom,
} from 'rxjs';
import { LocalDataService } from 'src/app/core/services/local-data.service';
import { UrlNames } from 'src/app/core/services/urlProfiler';
import { BaseWebApiService } from 'src/app/core/services/_base-web-api.service';
import { SearchCourseDetails } from 'src/app/shared/models/create-resource';
import { Group, LocationList } from 'src/app/shared/models/locations';

import {
	getSelectedLocation,
	getUserLocation,
	getUserPrivileges,
	SharedState,
} from 'src/app/shared/_state/shared.reducer';
import { EducationGroupAPIInterface } from '../_models/education-groups.interface';
import { NewSkillDetailsFormInterface } from '../_models/new-skill.model';
import {
	SkillAchievementParams,
	UserCertificate,
	UserComment,
} from '../_models/skilled-users.model';
import { SLRSkills, SLRSkillsAPI } from '../_models/skills.interface';

@Injectable({
	providedIn: 'root',
})
export class SkillsService {
	public skill = new BehaviorSubject<NewSkillDetailsFormInterface | null>(
		null
	);

	public selectedSkill = new BehaviorSubject<any | null>(null);

	private _locationId: number;
	private _userId: number;
	public groups: Array<Group>;
	public groupNameMap: Array<any>;
	private _rootLocationId: number;

	constructor(
		private http: HttpClient,
		private baseApi: BaseWebApiService,
		private datePipe: DatePipe,
		private sharedStore: Store<SharedState>,
		private localData: LocalDataService
	) {
		this.sharedStore.pipe(select(getUserPrivileges), take(1)).subscribe({
			next: (res) => {
				this._userId = res.userId;
			},
		});
		this.sharedStore.pipe(select(getSelectedLocation), take(1)).subscribe({
			next: (res) => {
				this._locationId = res.location.id;
			},
		});

		this.getEducationGRoups().subscribe({
			next: (groups) => {
				this.groups = groups?.map((x) => {
					return {
						groupId: x.educationGroupId,
						groupName: x.educationGroupName,
						data: null,
					};
				});
			},
		});
	}

	public getSkills(
		module: 'skills' | 'liveevents' = 'skills'
	): Observable<Array<SLRSkills>> {
		return this.baseApi
			.get<SLRSkillsAPI[]>(`${UrlNames.skillsOnLoad}`, {
				locationId: this.localData.getLocationId(),
				userid: this.localData.getUserId(),
				module: module,
			})
			.pipe(
				map((res: SLRSkillsAPI[]) => {
					return res.map((skills) => {
						let tableSkills: SLRSkills = {
							status: skills.activeFlag ? 'Active' : 'InActive',
							skillId: skills.skillId,
							skillName: skills.skillName,
							skillType: skills.skillType,
							type: skills.skillType,
							description: skills.description,
							multilevel: skills.isMultiLevel ? 'Yes' : 'No',
							renewalType: skills.renewalType,
							locationIdLocation: skills.locationIdLocation,
							updatedLocationIds: skills.existingLocationIds,
							existingLocationIds: skills.existingLocationIds,
							edGroups: this.getGroupName(
								skills.existingUserGroupIds
							),
							isMultiLevel: skills.isMultiLevel,
							activeFlag: skills.activeFlag,
							alertDate: skills.alertDate,
							useCompetency: skills.useCompetency,
							dateOverridesAllowed: skills.dateOverridesAllowed,
							defaultRenewalFrequency:
								skills.defaultRenewalFrequency,
							renewalDate: skills.renewalDate,
							occurrences: skills.occurrences,
							defaultAlertThresholdInDays:
								skills.defaultAlertThresholdInDays,
							createdBy: skills.createdBy,
							updatedBy: skills.updatedBy,
							educationalGroup: skills.existingUserGroupIds,
							existingUserGroupIds: skills.existingUserGroupIds,
							testMandatory: skills.isMultiLevel,
							newRenewalDate: skills.newRenewalDate,
							courseIdCourse: skills.courseIdCourse,
							testScoreRequired: skills.testScoreRequired,
							newRenewalFrequencyInYears:
								skills.newRenewalFrequencyInYears,
							customYears: 0,
							customYears2: 0,
							selected: false,
						};
						return tableSkills;
					});
				})
			);
	}

	public getGroupName(groupIds: Array<number>): string {
		let groupNamesList = [];
		let commaSepratedNames = '';
		const groupsName = groupIds.map((x) => this.getName(x)?.[0]?.groupName);
		if (!groupIds || !groupIds.length) return '';
		groupIds.forEach((id) => {
			if (this.getName(id)[0]?.groupName) {
				groupNamesList.push(this.getName(id)[0]?.groupName);
			}
		});
		if (groupNamesList.length > 3) {
			commaSepratedNames = groupNamesList.slice(0, 2).join(', ');
			commaSepratedNames += `, +${groupNamesList.length - 3} more...`;
		} else {
			commaSepratedNames = groupNamesList.join(', ');
		}

		return commaSepratedNames;
	}

	getName(x: number): any {
		return this.groups?.filter((group) => group.groupId === x);
	}

	public setSkillDetails(value: SLRSkills): void {
		const skill: NewSkillDetailsFormInterface = {
			skillId: value.skillId,
			skillName: value.skillName,
			skillType: value.skillType,
			description: value.description,
			isMultiLevel: value.isMultiLevel,
			activeFlag: value.activeFlag,
			useCompetency: value.useCompetency,
			renewalType: value.renewalType,
			dateOverridesAllowed: value.dateOverridesAllowed,
			defaultRenewalFrequency: value.defaultRenewalFrequency,
			renewalDate: value.renewalDate,
			occurrences: value.occurrences,
			defaultAlertThresholdInDays: value.defaultAlertThresholdInDays,
			createdBy: value.createdBy,
			updatedBy: value.updatedBy,
			locationId: value.locationIdLocation,
			updatedLocationIds: value.updatedLocationIds,
			existingLocationIds: value.existingLocationIds,
			educationalGroup: value.existingUserGroupIds,
			existingUserGroupIds: value.existingUserGroupIds,
			educationGroups: null,
			testMandatory: value.testMandatory,
			newRenewalDate: value.newRenewalDate,
			courseIdCourse: value.courseIdCourse,
			testScoreRequired: value.testScoreRequired,
			newRenewalFrequencyInYears: value.newRenewalFrequencyInYears,
			customYears: value.customYears,
			customYears2: value.customYears2,
		};
		this.skill.next(skill);
	}

	public getSkillDetail(): Observable<NewSkillDetailsFormInterface | null> {
		return this.skill.asObservable();
	}

	public addNewSkill(
		skillDetails: NewSkillDetailsFormInterface
	): Observable<Object> {
		skillDetails = {
			...skillDetails,
			createdBy: this._userId,
			updatedBy: this._userId,
		};
		if (skillDetails.skillId)
			return this.baseApi.put(`${UrlNames.updateSkill}`, skillDetails);
		else return this.baseApi.post(`${UrlNames.addNewSkill}`, skillDetails);
	}

	public assignedSkill(skillDetails: any): Observable<Object> {
		return this.baseApi.post(`${UrlNames.assignedSkill}`, skillDetails);
	}
	public getLocationsListSkill(
		root: boolean = false
	): Observable<LocationList> {
		return this.baseApi.get(`${UrlNames.locations}`, {
			userId: this.localData.getUserId(),
			isLiveEvents: false,
			locationId: root ? this._rootLocationId : this._locationId,
		});
	}

	public getCourseList(): Observable<Array<SearchCourseDetails>> {
		return this.baseApi.get(`${UrlNames.courses}${UrlNames.search}`, {
			language: 1,
			except: '',
			userid: this._userId,
			locationId: this._locationId,
		});
	}

	removeSkill(payload: any): Observable<any> {
		return this.baseApi.delete(`${UrlNames.deleteSkill}`, payload);
	}

	selectedSkills(skills: any): void {
		this.selectedSkill.next(skills);
	}

	editSkills(event) {
		return this.baseApi.get<any>(`${UrlNames.editSkills}/${event}`, {
			locationId: this._locationId,
		});
	}

	public getSelectedSkills(): Observable<any | null> {
		return this.selectedSkill.asObservable();
	}

	private getEducationGroup(x: Array<EducationGroupAPIInterface>): string {
		const groups = x.map((x) => x.educationGroupName);
		if (x.length > 3) {
			return `${groups.slice(0, 3).join(', ')} +${
				groups.length - 3
			} more`;
		}
		return groups.join(', ');
	}

	public getEducationGRoups(type: string = 'LMS'): Observable<any> {
		return this.baseApi.get<any>(`${UrlNames.educationGroups}`, {
			type: type,
			userId: this._userId,
			locationId: this._locationId ?? this._locationId,
		});
	}
	public getSkillUserDetails(skillId: number) {
		return this.baseApi.get(
			`${UrlNames.skills}${UrlNames.loadUsersForSelectedSkillId}/${skillId}`,
			{ locationId: this.localData.getLocationId() }
		);
	}
	public getUnskilledUsers(skillId: number): Observable<any> {
		return this.baseApi.get(
			`${UrlNames.skills}${UrlNames.loadUsersApartfromSelectedSkill}`,
			{
				locationId: this.localData.getLocationId(),
				skillId,
			}
		);
	}
	public getUserSkillComments(
		params: SkillAchievementParams
	): Observable<any> {
		return this.baseApi.get(
			`${UrlNames.skills}${UrlNames.loadUserComments}`,
			{ ...params, locationId: this.localData.getLocationId() }
		);
	}
	public addSkillComment(
		{ skillId, userId },
		body: UserComment
	): Observable<any> {
		return this.baseApi.post(
			`${UrlNames.skills}${UrlNames.addUserComment}/${skillId}`,
			body,
			{ userId, locationId: this.localData.getLocationId() }
		);
	}
	public deleteUserSkillComment(commentId: number): Observable<any> {
		return this.baseApi.delete(
			`${UrlNames.skills}${UrlNames.deleteUserSkillComment}`,
			{},
			false,
			[],
			{ commentId, loggedInUser: this.localData.getUserId() }
		);
	}
	public getUserSkillCertificates(
		params: SkillAchievementParams
	): Observable<any> {
		return this.baseApi.get(
			`${UrlNames.skills}${UrlNames.loadUserCertificates}`,
			{ locationId: this.localData.getLocationId(), ...params }
		);
	}
	public addUserSkillCertificate(
		skillId: number,
		userId: number,
		body: UserCertificate
	): Observable<any> {
		return this.baseApi.post(
			`${UrlNames.skills}${UrlNames.addUserCertificate}/${skillId}`,
			body,
			{ userId, locationId: this.localData.getLocationId() }
		);
	}
	public deleteUserSkillCertificate(certificateId: number): Observable<any> {
		return this.baseApi.delete(
			`${UrlNames.skills}${UrlNames.deleteUserSkillCertificate}`,
			{},
			false,
			[],
			{ certificateId, loggedInUser: this.localData.getUserId() }
		);
	}
	public getCompetencyLevels(): Observable<any> {
		return this.baseApi.get(
			`${UrlNames.users}${UrlNames.myProfile}${UrlNames.skills}${UrlNames.competences}`,
			{}
		);
	}
	public addSkillUser(body): Observable<any> {
		return this.baseApi.post(
			`${UrlNames.skills}${UrlNames.addUserSkill}`,
			body,
			{
				userId: this.localData.getUserId(),
				locationId: this.localData.getLocationId(),
			}
		);
	}
	public updateSkillUsers(body, updateStatus = false): Observable<any> {
		return this.baseApi.put(
			`${UrlNames.skills}${UrlNames.updateSkillUsers}`,
			body,
			{
				updateStatus,
				userId: this.localData.getUserId(),
				locationId: this.localData.getLocationId(),
			}
		);
	}
	public deleteUserSkill(userId: number, skillId: number): Observable<any> {
		return this.baseApi.delete(
			`${UrlNames.deleteUserSkill}`,
			{
				skillIds: [skillId],
			},
			false,
			[],
			{ userId, locationId: this.localData.getLocationId() }
		);
	}
}
