<div class="d-flex align-items-center">
	<div class="col-lg-5 col-sm-12">
		<div class="title">{{ title | translate }}:</div>
	</div>
	<div class="col-lg-6 col-sm-9" *ngIf="filterable">
		<slr-input [fgName]="searchForm" [controlName]="'search'"></slr-input>
	</div>
	<div
		class="col-sm-3 d-flex justify-content-end"
		[class]="{ 'offset-lg-3': !filterable }"
	>
		<slr-toggle
			[checked]="
				this.selection.selected?.length == this.filteredData?.length
			"
			[disabled]="disabled"
			[label]="'SELECT_ALL'"
			(toggle)="onToggle($event)"
		></slr-toggle>
	</div>
</div>

<div class="row">
	<div class="col-12 col-lg-9" [class]="{ 'offset-lg-3': hasPadding }">
		<mat-accordion multi="true"
			><ng-container *ngFor="let group of filteredData">
				<ng-container
					*ngTemplateOutlet="accordionTemplate; context: group"
				>
				</ng-container>
			</ng-container>
		</mat-accordion>
	</div>

	<div
		*ngIf="showSeeLessButton"
		class="col-9"
		[class]="{ 'offset-3': hasPadding }"
	>
		<a
			(click)="seeLess = !seeLess; updateData()"
			href="javascript:void(0)"
			>{{ "SEE_" + (seeLess ? "MORE" : "LESS") | translate }}</a
		>
	</div>
</div>
<ng-template #accordionTemplate let-data="data" let-name="name" let-id="id">
	<mat-expansion-panel
		[disabled]="!data"
		[hideToggle]="!data"
		[expanded]="selection.isSelected(id) && data"
	>
		<mat-expansion-panel-header>
			<div>
				<mat-checkbox
					(change)="onSelectParent(id, data)"
					[checked]="selection.isSelected(id)"
					(click)="$event.stopPropagation()"
					(keyup)="$event.stopPropagation()"
					[disabled]="disabled"
				>
					{{ name }}
				</mat-checkbox>
			</div>
		</mat-expansion-panel-header>
		<ng-container *ngFor="let item of data">
			<ng-container *ngIf="item.data">
				<ng-container
					*ngTemplateOutlet="accordionTemplate; context: item"
				>
				</ng-container>
			</ng-container>
			<ng-container *ngIf="!item.data">
				<div class="sub-menu">
					<mat-checkbox
						[checked]="selection.isSelected(item.id)"
						(change)="onSelect(item.id)"
						[disabled]="disabled"
						(click)="$event.stopPropagation()"
						(keyup)="$event.stopPropagation()"
					></mat-checkbox>
					{{ item.name }}
				</div>
			</ng-container>
		</ng-container>
	</mat-expansion-panel>
</ng-template>
