<div class="d-flex view-switcher">
	<div
		class="border-end d-flex align-items-center pe-2 cursor-pointer"
		[class.active-view]="viewType === 'list'"
		(click)="switchViewType('list')"
	>
		<span class="material-icons-outlined"> table_rows </span>
		<span class="ms-2">
			{{ "LIST" | translate }}
		</span>
	</div>
	<div
		class="d-flex align-items-center ms-2 cursor-pointer"
		[class.active-view]="viewType === 'graph'"
		(click)="switchViewType('graph')"
		*ngIf="includeGraph"
	>
		<span class="material-icons-outlined"> pie_chart </span>
		<span class="ms-2">
			{{ "GRAPHICAL" | translate }}
		</span>
	</div>
	<div
		class="d-flex align-items-center ms-2 cursor-pointer"
		[class.active-view]="viewType === 'grid'"
		[class.border-start]="includeGraph"
		(click)="switchViewType('grid')"
		*ngIf="includeGrid"
	>
		<span class="material-icons-outlined" [class.ms-2]="includeGraph">
			grid_view
		</span>
		<span class="ms-2">
			{{ "GRID" | translate }}
		</span>
	</div>
</div>
