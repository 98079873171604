<div>
	<app-modal-header-placeholder
		[title]="'ADD_CERTIFICATE'"
		(onClose)="closeModal()"
	></app-modal-header-placeholder>
	<div>
		<div
			class="table-responsive mb-2 global-table-wrap"
			*ngIf="data && data.certificates"
		>
			<table class="table lms">
				<thead>
					<tr>
						<th>
							{{ "CERTIFICATE" | translate }}
						</th>
						<th>
							{{ "UPLOADED" | translate }}
							{{ "DATE" | translate }}
						</th>
						<th>
							{{ "UPLOADED" | translate }} {{ "BY" | translate }}
						</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let certificate of data.certificates">
						<td
							class="cp"
							(click)="downloadcertificate(certificate)"
						>
							{{ certificate.fileName }}
						</td>
						<td>{{ certificate.updateDate | dateFormat }}</td>
						<td>{{ certificate.uploadedBy }}</td>
						<td>
							<mat-icon
								class="cursor-pointer"
								matTooltip="{{ 'DELETE' | translate }}"
								matTooltipPosition="above"
								(click)="deleteCertificate(certificate)"
								>delete</mat-icon
							>
						</td>
					</tr>
					<tr *ngIf="!data.certificates.length">
						<td colspan="4">
							{{ "NO_CERTIFICATES_YET" | translate }}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<ng-container *ngIf="data.uploadAllowed">
			<slr-input-file-upload
				#fileUpload
				[acceptTypes]="allowedFileTypes"
				(chosenFile)="selectFile($event)"
			></slr-input-file-upload>
			<slr-progress-bar
				*ngIf="uploadProgress"
				[type]="'primary'"
				[value]="this.uploadProgress || 0"
			></slr-progress-bar>
		</ng-container>
	</div>
	<div *ngIf="data.uploadAllowed" class="mt-2 d-flex justify-content-center">
		<slr-btn02
			[disabled]="addButtonDisabled"
			(onClick)="uploadCertificate()"
			>{{ "ADD" | translate }}</slr-btn02
		>
		<button class="btn cancel-btn ms-2" (click)="closeModal()">
			{{ "CANCEL" | translate }}
		</button>
	</div>
</div>
