import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	Router,
	RouterStateSnapshot,
	UrlTree,
} from '@angular/router';
import { Observable, take, map } from 'rxjs';
import { UserPermissionService } from 'src/app/modules/authentication/_services/user-permissions.service';
import { NotificationService } from '../services/notification.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class RoleGuard implements CanActivate {
	constructor(
		private permissionService: UserPermissionService,
		private router: Router,
		private notificationService: NotificationService,

		private translateService: TranslateService
	) {}
	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	):
		| boolean
		| UrlTree
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree> {
		const id = route.data['privileKey'];
		return this.translateService.get('UNAUTHORIZED_ACCESS').pipe(
			take(1),
			map((translatedValue) => {
				if (!this.permissionService.checkUserPrivilege(id)) {
					this.notificationService.error(translatedValue);
					this.router.navigate(['']);
					return false;
				}
				return true;
			})
		);
	}
}
