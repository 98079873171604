import {
	AfterViewInit,
	Directive,
	ElementRef,
	Host,
	Input,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { TableIconInterface } from 'src/app/shared/components/tables/slr-table/slr-table.component';

@Directive({
	selector: '[table-data]',
})
export class TableIconDirective implements AfterViewInit {
	@Input() data: any;
	@Input() condition: TableIconInterface;
	constructor(
		@Host() private el: ElementRef,
		private translate: TranslatePipe
	) {}
	ngAfterViewInit(): void {
		if (
			this.getConditionResult()
		) {
			this.initIcon();
		}
	}

	private initIcon(): void {
		if (
			this.condition.innerHtml ||
			(this.condition.dataKey && this.data[this.condition.dataKey])
		) {
			const parent = this.el.nativeElement.parentElement;
			const element = document.createElement(this.condition.el);
			element.classList = this.condition.iconClass.join(' ');
			element.innerHTML = this.translate.transform(
				this.condition.innerHtml.length
					? this.condition.innerHtml
					: this.data[this.condition.dataKey]
			);
			if (this.condition.tooltip) {
				element.title = this.tooltipText;
			}
			parent.replaceChild(element, this.el.nativeElement);
		}
	}

	private get tooltipText(): string {
		let res: string = 'test-tooltip';

		const regex = /{{(.*?)}}/g;
		if (this.condition.tooltip.match(regex)) {
			res = this.condition.tooltip.replace(
				regex,
				(_match, capturedText) => {
					const replacement = this.data[capturedText];
					return replacement !== undefined ? replacement : '';
				}
			);
		}
		return res;
	}

	private getConditionResult(): boolean {
		switch (this.condition.condition.op) {
			case '!==':
			case '!=':
				return (
					this.data[this.condition.condition.lhs] !=
					this.condition.condition.rhs
				);
			case '>':
				return (
					this.data[this.condition.condition.lhs] >
					this.condition.condition.rhs
				);
			case '<':
				return (
					this.data[this.condition.condition.lhs] <
					this.condition.condition.rhs
				);
			case '==':
				return (
					this.data[this.condition.condition.lhs] ==
					this.condition.condition.rhs
				);
			case '===':
				return (
					this.data[this.condition.condition.lhs] ===
					this.condition.condition.rhs
				);
			default:
				return false;
		}
	}
}
