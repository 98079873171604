export enum DashboardModulePrivilegesEnum {
	MODULE_NAME = 94,
	ADDITIONAL_LINKS = 118,
	ADDITIONAL_LINKS_ADD = 119,
	ADDITIONAL_LINKS_REMOVE = 120,
	CONFIGURE = 121,
	MANAGE_ORGANIZATION = 123,
	CREATE_BADGES = 124,
	SEE_ALL_BADGES = 125,
	SEE_ALL_REWARDS = 126,
	CREATE_REWARDS = 127,
	EVENT_PARTICIPANTS = 128,
	NEW_MESSAGE = 129,
	TILE_SKILLS_PENDING_APPROVAL = 253,
	DASHBOARD_ADD_NEW_MESSAGE = 218,
}
