import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import moment from 'moment';
import { GenericHelper } from 'src/app/core/helpers/generic-helper.class';

@Pipe({
	name: 'dateFormat',
})
export class DateFormatPipe extends DatePipe implements PipeTransform {
	override transform(value: any): any {
		// return super.transform(value, 'dd-MM-yyyy');
		return super.transform(value, 'yyyy-MM-dd');
	}
}
