import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TableColumn } from '../../tables/slr-table/slr-table.component';
import { broadcastActions, broadcastColumns } from './brodcast-list.columns';
import moment from 'moment';
import { LocalDataService } from 'src/app/core/services/local-data.service';
import { BroadcastService } from '../broadcast.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'app-broadcast-list',
	templateUrl: './broadcast-list.component.html',
	styleUrls: ['./broadcast-list.component.scss'],
})
export class BroadcastListComponent implements OnInit, OnDestroy {
	public broadcastsearchForm: FormGroup;
	public broadcastData: any[] = [];
	public tableColumns: TableColumn[] = broadcastColumns;
	public actionButtons = broadcastActions;
	@Input() isSurgeAdmin = false;
	@Input() clientCode: string;
	@Output() editBroadcast: EventEmitter<any> = new EventEmitter();
	@Output() deleteBroadcast: EventEmitter<any> = new EventEmitter();
	private unsubscribe$: Subject<boolean> = new Subject();

	constructor(
		private fb: FormBuilder,
		private localData: LocalDataService,
		private broadcastService: BroadcastService
	) {}

	ngOnInit(): void {
		this.initializeForm();
		this.manageTableColumnList();
		this.updateListListener();
	}
	ngOnDestroy(): void {
		this.unsubscribe$.next(true);
	}
	private manageTableColumnList() {
		if (!this.isSurgeAdmin) {
			this.tableColumns = this.tableColumns.map((col, i) =>
				i === 2 || i === 3 ? { ...col, isVisible: false } : col
			);
		}
	}
	private initializeForm(): void {
		this.broadcastsearchForm = this.fb.group({
			fromDate: ['', [Validators.required]],
			toDate: ['', [Validators.required]],
		});
		this.intializeFormValues();
	}
	private updateListListener(): void {
		this.broadcastService.refreshBroadcasts$
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe({
				next: (res) => {
					res && this.searchData();
				},
			});
	}
	private intializeFormValues(): void {
		let fromDate: Date = new Date(
			this.localData.getToday().getFullYear(),
			0,
			1
		);
		let toDate: Date = new Date(
			this.localData.getToday().getFullYear(),
			11,
			31
		);
		if (!this.isSurgeAdmin) {
			const currentYear = this.localData.currentEducationYear;
			const dates = this.localData.getEducationYearDates(currentYear);
			fromDate = dates.startDate;
			toDate = dates.endDate;
		}
		this.broadcastsearchForm.setValue({
			fromDate,
			toDate,
		});
		this.searchData();
	}
	public searchData(): void {
		if (!this.broadcastsearchForm.valid) {
			this.broadcastsearchForm.markAllAsTouched();
			return;
		}
		const fromDate = moment(this.broadcastsearchForm.value.fromDate).format(
			'YYYY-MM-DD'
		);
		const toDate = moment(this.broadcastsearchForm.value.toDate).format(
			'YYYY-MM-DD'
		);

		this.broadcastService
			.getBroadcastList({
				clientCode: this.clientCode,
				fromDate,
				toDate,
			})
			.pipe()
			.subscribe({
				next: (res) => {
					this.broadcastData = res.data.map((data) => ({
						...data,
						targetRoleNames: data.targetRoleData
							?.map((roleData) => roleData.userClassName)
							?.toString(),
					}));
				},
			});
	}
	public handleAction(event: { action: 'edit' | 'delete'; row: any }): void {
		event.action === 'edit'
			? this.editBroadcast.emit(event.row)
			: this.deleteBroadcast.emit(event.row);
	}
}
