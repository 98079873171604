import {
	Component,
	Input,
	ViewChild
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { SLRPaginatorDirective } from 'src/app/core/directives/slr-paginator.derective';
import { LocalDataService } from 'src/app/core/services/local-data.service';

@Component({
	selector: 'app-ng-pagination',
	templateUrl: './ng-pagination.component.html',
	styleUrls: ['./ng-pagination.component.scss'],
})
export class NgPaginationComponent {
	@Input() public itemLabelText: string = 'ITEMS_PER_PAGE';
	@Input() public limit: any = 100;
	@Input() public offset: number = 0;
	@Input() public listSize: number = 0;
	@Input() paginationSizes: number[] = [100, 200, 300, 400];

	@ViewChild(MatPaginator, { static: false }) matPaginator: MatPaginator;
	@ViewChild(SLRPaginatorDirective, { static: false })
	pagdir: SLRPaginatorDirective;

	constructor(public localData: LocalDataService) {}

	public changePageLimit() {
		this.matPaginator._changePageSize(
			this.limit === 'All' ? this.listSize : this.limit
		);
    this.matPaginator.disabled = this.limit === 'All';
	}
}
