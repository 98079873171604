<mat-form-field [formGroup]="fgName" appearance="outline" [style.width]="width">
	<mat-label>{{ label | translate }}</mat-label>
	<mat-select
		disableOptionCentering
		[formControlName]="controlName"
		[compareWith]="compareCategoryObjects"
		[placeholder]="placeholder | translate"
		[multiple]="multiple"
		[panelClass]="'slr-select'"
		[disableRipple]="true"
		[disabled]="isDisabled"
	>
		<mat-select-trigger>
			{{
				selectedItems.selected.length > 0
					? selectedItems.selected.length +
					  " " +
					  ("ITEMS_SELECTED" | translate)
					: (placeholder | translate)
			}}
		</mat-select-trigger>
		<mat-optgroup>
			<mat-form-field style="width: 100%">
				<input
					#search
					autocomplete="off"
					placeholder="Search"
					aria-label="Search"
					matInput
					(keydown)="checkKeyDown($event)"
					[formControl]="searchTextboxControl"
				/>
				<button
					[disableRipple]="true"
					*ngIf="search.value"
					matSuffix
					mat-icon-button
					aria-label="Clear"
					(click)="clearSearch($event)"
				>
					<mat-icon>close</mat-icon>
				</button>
			</mat-form-field>
		</mat-optgroup>
		<mat-optgroup *ngIf="(filteredOptions | async)?.length == 0">
			<div>No results found!</div>
		</mat-optgroup>
		<mat-option
			class="slr-select-option"
			(onSelectionChange)="updateSelection(item, $event)"
			*ngFor="let item of filteredOptions | async"
			[value]="item.id"
			[matTooltip]="item.name"
		>
			{{ item.name }}
		</mat-option>
		<mat-option hidden></mat-option>
	</mat-select>
</mat-form-field>

<div #ref><ng-content></ng-content></div>
<ng-container *ngIf="!ref.hasChildNodes()">
	<div *ngIf="selectFormControl.value && selectFormControl.value.length > 0">
		<div class="user-list">
			<div
				*ngFor="let item of selectedItems.selected; let i = index"
				class="users d-flex align-items-center justify-content-between mt-2"
			>
				<span *ngIf="item" class="account-detail">
					<i
						class="material-icons-outlined account-icon"
						*ngIf="showicon"
					>
						{{ icon }}
					</i>
					{{ item.name }}
				</span>
				<span
					*ngIf="item && !(selectFormControl.disabled || isDisabled)"
					class="material-icons-outlined remove-user-icon cursor-pointer"
					(click)="deselectEntry(item, true)"
					(keyup)="deselectEntry(item, true)"
				>
					clear
				</span>
			</div>
		</div>
	</div>
	<div
		class="errors"
		*ngIf="
			fgName?.get(controlName)?.errors &&
			fgName?.get(controlName)?.touched
		"
	>
		{{ errorMessage }}
	</div>
</ng-container>
