<div
	[formGroup]="broadcastsearchForm"
	class="new-message-board container-fluid broadcast-table"
>
	<div class="row">
		<div class="col-sm-4 col-lg-4 col-md-6 mb-3">
			<slr-date-input
				[label]="'From date'"
				[fgName]="broadcastsearchForm"
				[controlName]="'fromDate'"
			>
			</slr-date-input>
		</div>
		<div class="col-sm-4 col-lg-4 col-md-6 mb-3">
			<slr-date-input
				[label]="'To Date'"
				[fgName]="broadcastsearchForm"
				[controlName]="'toDate'"
			>
			</slr-date-input>
		</div>
		<div class="col-sm-4 col-lg-4 col-md-6 mb-3">
			<slr-btn02 (onClick)="searchData()">{{
				"SEARCH" | translate
			}}</slr-btn02>
		</div>
	</div>
</div>
<div class="overflow-modal track-white">
	<div style="width: 98%">
		<slr-table
			[tableColumns]="tableColumns"
			[tableData]="broadcastData"
			[isSortable]="true"
			[actionButtons]="actionButtons"
			(rowAction)="handleAction($event)"
		></slr-table>
	</div>
</div>
