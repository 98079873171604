<label *ngIf="label">{{ label | translate }}</label>
<mat-radio-group
	(change)="onChangeOption($event)"
	aria-label="Select an option"
	class="d-inline-flex gap-2"
	[ngClass]="{
		'flex-column': align === 'vertical',
		'flex-row': align === 'horizontal'
	}"
>
	<ng-container *ngFor="let entry of data">
		<mat-radio-button
			[checked]="entry.data?.selected == true || entry.selected == true"
			[disabled]="entry.data?.disabled == true"
			[value]="entry.id"
			>{{ entry.name | translate }}
			<ng-container *ngIf="entry.data && entry.data.image">
				<img [src]="entry.data.image.src | async" alt="" />
			</ng-container>
		</mat-radio-button>
	</ng-container>
</mat-radio-group>
