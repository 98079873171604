import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'getStringPercent',
})
export class GetStringPercentPipe implements PipeTransform {
	transform(value: number, total: number): string {
		if (total === 0) return '100';
		if (!total) return '0';
		return ((value / total) * 100).toFixed(2);
	}
}
