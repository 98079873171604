<div class = "Brodcast-section">
<h2 id="registerbrod">{{"REGISTER_BROADCAST" | translate}}</h2>
<div *ngFor = "let obj of content" class="mb-23">
<p id="casttitle">{{obj.name}}</p>
<p>{{obj.description}}</p>
<p id="broddate">{{obj.date}}</p>

    <div class="ps-2">
   <span class="material-icons-outlined"> account_circle</span><span id="hostedby">{{"HOSTED_BY" | translate}} :{{obj.hosted_by}}
    </span></div >

<button type="button"  (click)="gotoregister(obj);"class="btn redeem-btn mt-2 mb-1 register"><span class="px-2">{{"REGISTER" | translate}}</span></button>
</div>
</div>
