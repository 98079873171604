import { Injectable, EventEmitter } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
	forkJoin,
	filter,
	map,
	mergeMap,
	Observable,
	of,
	skipWhile,
	take,
} from 'rxjs';
import { LocalDataService } from 'src/app/core/services/local-data.service';
import { UrlNames } from 'src/app/core/services/urlProfiler';
import { BaseWebApiService } from 'src/app/core/services/_base-web-api.service';
import { Subscription } from 'rxjs/internal/Subscription';
import {
	getUserPrivileges,
	SharedState,
} from 'src/app/shared/_state/shared.reducer';
import { ManageCodeClientInterface } from 'src/app//modules/user-settings/_models/manage-codes.interface';
import { BehaviorSubject, Subject } from 'rxjs';
import { SupervisorUser } from '../../models/broadcast.interface';

@Injectable({
	providedIn: 'root',
})
export class BroadcastService {
	public screenSize = new BehaviorSubject<'small' | 'medium' | 'large'>(
		'large'
	);
	invokesearchfunction = new EventEmitter();
	subsVar: Subscription;
	private _locationId: number;
	private _clientCode: string;
	public deleteobj: any;
	private _userId: number;
	public refreshBroadcasts$: Subject<boolean> = new Subject();

	constructor(
		private apiService: BaseWebApiService,
		private sharedStore: Store<SharedState>,
		private localDataService: LocalDataService
	) {
		this.sharedStore
			.pipe(
				select(getUserPrivileges),
				filter((value) => !!value)
			)
			.subscribe({
				next: (res) => {
					this._locationId = res.locationId;
					this._userId = res.userId;
					this._clientCode = res.siteCode;
				},
			});
	}

	public getVendorCodes(): Observable<Array<ManageCodeClientInterface>> {
		return this.apiService.get(
			`${UrlNames.manageCodes}${UrlNames.codelist}`,
			{
				userId: this._userId,
				clientCode: this.localDataService.getSiteCode(),
			}
		);
	}
	public sendbroadcast(badgePayload: any): Observable<any> {
		return this.apiService.post(
			`/${UrlNames.SendBroadcast}`,
			badgePayload,
			null
		);
	}
	searchdataonbroadcast() {
		this.invokesearchfunction.emit();
	}
	public getBroadcastList(params: {
		clientCode: string;
		fromDate: string;
		toDate: string;
	}): Observable<any> {
		return this.apiService.get(`${UrlNames.getBroadcastList}`, params);
	}
	public getSupervisorUsers(): Observable<{ data: SupervisorUser[] }> {
		return this.apiService.get(
			`${UrlNames.users}${UrlNames.higherThanRole}`,
			{
				role: 3,
			}
		);
	}
}
