<div class="padding-wrapper">
	<div class="d-flex mb-4">
		<div class="content-header">
			{{ "MANAGE_CODES" | translate }}
		</div>
		<div class="ms-auto">
			<button class="btn icon-btn d-flex" (click)="addNewCode()">
				<i class="material-icons-outlined me-2"> add_circle </i>
				{{ "ADD_NEW_CODE" | translate }}
			</button>
		</div>
	</div>

	<div class="code-list">
		<div class="row">
			<div class="col-12">
				<mat-expansion-panel
					[expanded]="true"
					class="mb-4"
					*ngIf="
						columns && grandParentData && grandParentData.length > 0
					"
				>
					<mat-expansion-panel-header>
						<h5>{{ "GRAND_PARENT" | translate }}</h5>
					</mat-expansion-panel-header>

					<slr-table
						*ngIf="columns && grandParentData"
						[tableColumns]="columns"
						[tableData]="grandParentData"
						[actionButtons]="[
							{
								icon: 'delete',
								tooltip: 'Delete Code',
								sticky: true
							}
						]"
						(rowAction)="removeCode(1, $event)"
						[isPageable]="true"
						[activeSort]="'displayName'"
					></slr-table>
				</mat-expansion-panel>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<mat-expansion-panel
					[expanded]="true"
					class="mb-4"
					*ngIf="columns && parentData && parentData.length > 0"
				>
					<mat-expansion-panel-header>
						<h5>{{ "PARENT" | translate }}</h5>
					</mat-expansion-panel-header>

					<slr-table
						*ngIf="columns && parentData"
						[tableColumns]="columns"
						[tableData]="parentData"
						[actionButtons]="[
							{
								icon: 'delete',
								tooltip: 'Delete Code',
								sticky: true
							}
						]"
						(rowAction)="removeCode(2, $event)"
						[isPageable]="true"
						[activeSort]="'displayName'"
					></slr-table>
				</mat-expansion-panel>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<mat-expansion-panel
					[expanded]="true"
					class="mb-4"
					*ngIf="columns && childData && childData.length > 0"
				>
					<mat-expansion-panel-header>
						<h5>{{ "CHILD" | translate }}</h5>
					</mat-expansion-panel-header>

					<slr-table
						*ngIf="columns && childData"
						[tableColumns]="columns"
						[tableData]="childData"
						[actionButtons]="[
							{
								icon: 'delete',
								tooltip: 'Delete Code',
								sticky: true
							}
						]"
						(rowAction)="removeCode(3, $event)"
						[isPageable]="true"
						[activeSort]="'displayName'"
					></slr-table>
				</mat-expansion-panel>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<mat-expansion-panel
					[expanded]="true"
					class="mb-4"
					*ngIf="columns && vendorData && vendorData.length > 0"
				>
					<mat-expansion-panel-header>
						<h5>{{ "VENDOR" | translate }}</h5>
					</mat-expansion-panel-header>

					<slr-table
						*ngIf="columns && vendorData"
						[tableColumns]="columns"
						[tableData]="vendorData"
						[actionButtons]="[
							{
								icon: 'delete',
								tooltip: 'Delete Code',
								sticky: true
							}
						]"
						(rowAction)="removeCode(4, $event)"
						[isPageable]="true"
						[activeSort]="'displayName'"
					></slr-table>
				</mat-expansion-panel>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<mat-expansion-panel
					[expanded]="true"
					class="mb-4"
					*ngIf="columns && sharedData && sharedData.length > 0"
				>
					<mat-expansion-panel-header>
						<h5>{{ "SHARED" | translate }}</h5>
					</mat-expansion-panel-header>

					<slr-table
						*ngIf="columns && sharedData"
						[tableColumns]="columns"
						[tableData]="sharedData"
						[actionButtons]="[
							{
								icon: 'delete',
								tooltip: 'Delete Code',
								sticky: true
							}
						]"
						(rowAction)="removeCode(5, $event)"
						[isPageable]="true"
						[activeSort]="'displayName'"
					></slr-table>
				</mat-expansion-panel>
			</div>
		</div>
	</div>
</div>
