  import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse,HttpHeaders } from "@angular/common/http";
import { throwError, Observable } from "rxjs";
import { retry, catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";


@Injectable({
  providedIn: 'root'
})
export class ApiUrlService {
  private REST_API_URL = environment.cloudApiUrl;
  callfunction:any

  constructor(private httpClient: HttpClient) { }
  handleError(error: HttpErrorResponse) {
    let errorMessage = "Unknown error!";
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
  public sendGetRequest(url: any): Observable<any> {
    return this.httpClient
      .get(this.REST_API_URL + url)
      .pipe(catchError(this.handleError));
  }
  public sendPutRequest(url: any, dataBody: any): Observable<any> {
    return this.httpClient
      .put(this.REST_API_URL + url, dataBody)
      .pipe(retry(3), catchError(this.handleError));
  }
  public sendPatchRequest(url:any,dataBody:any):Observable<any>{
    return this.httpClient
    .patch(this.REST_API_URL + url,dataBody)
    .pipe(retry(3), catchError(this.handleError));
  }
  public sendDeleteRequest(url: any): Observable<any> {
    return this.httpClient
      .delete(this.REST_API_URL + url)
      .pipe(catchError(this.handleError));
  }
  public senddeleteRequest(url: any, dataBody: any): Observable<any> {
    let headers = new HttpHeaders();
    return this.httpClient
      .delete(this.REST_API_URL + url, { headers, body: dataBody })
      .pipe(retry(3), catchError(this.handleError));
  }
  public deletefile(url: any): Observable<any> {
    return this.httpClient
      .delete(this.REST_API_URL + url,)
      .pipe(retry(3), catchError(this.handleError));
  }
  public sendPostRequest(url: any, dataBody: any): Observable<any> {
  
    return this.httpClient
      .post(this.REST_API_URL + url, dataBody)
      .pipe(retry(3), catchError(this.handleError));
    
  }
  public uploadfile(event:any): Observable<any> {
    return this.callfunction
  }
  public getcontent(url: any): Observable<any> {
    return this.httpClient
      .get(url)
      .pipe(catchError(this.handleError));
  }
  public senddeleteRequestUrlOnly(url: any): Observable<any> {
    return this.httpClient
      .delete(this.REST_API_URL + url,)
      .pipe(retry(3), catchError(this.handleError));
  }
}

