export async function getBase64Logo() {
	var res = await fetch('assets/images/logo.png');
	var blob = await res.blob();

	return new Promise((resolve, reject) => {
		var reader = new FileReader();
		reader.addEventListener(
			'load',
			function () {
				resolve(reader.result);
			},
			false
		);

		reader.onerror = () => {
			return reject(this);
		};
		reader.readAsDataURL(blob);
	});
}
export async function getBase64AnswerLogo() {
	var res = await fetch('assets/images/correct-answer.png');
	var blob = await res.blob();

	return new Promise((resolve, reject) => {
		var reader = new FileReader();
		reader.addEventListener(
			'load',
			function () {
				resolve(reader.result);
			},
			false
		);

		reader.onerror = () => {
			return reject(this);
		};
		reader.readAsDataURL(blob);
	});
}
