import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
	selector: 'app-save-button',
	templateUrl: './save-button.component.html',
	styleUrls: ['./save-button.component.scss'],
})
export class SaveButtonComponent {
	@Input() text: string = 'SAVE';
	@Input() themeClass: 'lms' | 'policy' | 'qrm' = 'lms';
	@Output() saveClicked = new EventEmitter();
	saveForm() {
		this.saveClicked.emit('clicked');
	}
}
