import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appPrecision]',
})
export class PrecisionDirective {
  @Input() precision = 3;
  public text: string = '';
  private regex: RegExp = new RegExp('');
  private specialKeys: Array<string> = ['Backspace', 'Tab'];
  constructor(private el: ElementRef) {}
  @HostListener('keypress', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    this.regex =
      this.precision === 0
        ? new RegExp(`^(-?[0-9]+$)`)
        : new RegExp(`^(-?[0-9]+.?[0-9]{0,${this.precision}}$)`);
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key);

    if (next.includes('.')) {
      if (this.text == next) {
        event.preventDefault();
      }
      this.text = next;
    }
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}
