export enum resourceCenterActions {
	assign = 'assign',
	unassign = 'unassign',
	viewAssignments = 'view-assignments',
	recommend = 'recommend',
	removeRecommend = 'remove-recommend',
	addToLP = 'addToLP',
	edit = 'edit',
	archive = 'archive',
	unarchive = 'unarchive',
	activate = 'activate',
	inactivate = 'inactivate',
	hide = 'hide',
	unhide = 'unhide',
	delete = 'delete',
	viewDoc = 'viewDoc',
}
export enum fileDetailActions {
	addContent = 'add-content',
	previewFile = 'preview-file',
}
