import { Component, Output, Input, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-cancel-button',
	templateUrl: './cancel-button.component.html',
	styleUrls: ['./cancel-button.component.scss'],
})
export class CancelButtonComponent {
	@Input() public themeClass: 'lms' | 'policy' | 'qrm' = 'lms';
	@Output() actionCancelled = new EventEmitter();

	cancelAction() {
		this.actionCancelled.next('cancelled');
	}
}
