import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BroadcastService } from '../broadcast-popup/broadcast.service';
import { ApiUrlService } from 'src/app/core/services/api-url.service';
import { NotificationService } from 'src/app/core/services/notification.service';
@Component({
	selector: 'app-broadcastdelete',
	templateUrl: './broadcastdelete.component.html',
	styleUrls: ['./broadcastdelete.component.scss'],
})
export class BroadcastdeleteComponent {
	public broadcastDetail = this.broadcastservice.deleteobj;
	constructor(
		private dialogRef: MatDialogRef<BroadcastdeleteComponent>,
		public broadcastservice: BroadcastService,
		public ApiService: ApiUrlService,
		private notificationService: NotificationService
	) {}

	public close(): void {
		this.dialogRef.close();
	}
	deletebroadcast() {
		this.ApiService.sendDeleteRequest(
			`/api/announcement/v1/${this.broadcastservice.deleteobj.announcementId}`
		)
			.pipe()
			.subscribe({
				next: (res) => {
					this.dialogRef.close(true);
					this.notificationService.success('Deleted successfully');
				},
			});
	}
	cancel() {
		this.dialogRef.close();
	}
}
