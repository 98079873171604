<div
  class="step"
  [ngClass]="{
    'step-active': i + 1 === activeStep,
    'step-done': i + 1 < activeStep,
    'clickable-step': step.enabled
  }"
  *ngFor="let step of wizardSteps; index as i"
  (click)="step.enabled && changeActiveStep(i)"
>
  <div>
    <div class="circle">
      <span *ngIf="i + 1 >= activeStep">
        {{ i + 1 }}
      </span>
      <span *ngIf="i + 1 < activeStep" class="material-icons-outlined">
        done
      </span>
    </div>
  </div>
  <div>
    <div class="title">{{ step.title | translate }}</div>
  </div>
</div>
