<form [formGroup]="filterForm" class="filter-dropdown">
	<label [for]="filter.label" [class.required]="filter.isMandatory">
		{{ filter.showLabel | translate }}
	</label>
	<ng-select
		[items]="filter.data"
		[bindLabel]="filter.label | translate"
		[bindValue]="filter.value"
		[multiple]="filter.multiple"
		[searchable]="true"
		[closeOnSelect]="!filter.multiple"
		[formControlName]="filter.formControlName"
		[groupBy]="groupBy"
		[placeholder]="filter.placeholder | translate"
		(change)="selectChange($event)"
	>
		<ng-template
			ng-option-tmp
			let-item="item"
			let-item$="item$"
			let-index="index"
		>
			<input
				*ngIf="filter.multiCheckbox"
				type="checkbox"
				id="item-{{ index }}-res"
				class="slr-checkbox form-check-input"
				[ngModel]="item$.selected"
				[ngModelOptions]="{ standalone: true }"
			/>
			<span class="ms-2">
				{{ filter.label ? (item[filter.label] | translate) : item }}
			</span>
		</ng-template>
		<ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
			<div class="ng-value" *ngFor="let item of items | slice : 0 : 1">
				<span class="ng-value-label">
					{{ $any(item[filter.label]) | translate }}</span
				>
				<span
					class="ng-value-icon right"
					(click)="clear(item)"
					(keyup)="clear(item)"
					aria-hidden="true"
					>×</span
				>
			</div>
			<div class="ng-value" *ngIf="items.length > 1">
				<span class="ng-value-label"
					>{{ items.length - 1 }} more...</span
				>
			</div>
		</ng-template>
		<ng-template ng-label-tmp let-item="item">
			{{ filter.label ? (item[filter.label] | translate) : item }}
		</ng-template>
	</ng-select>
</form>
