import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TabFilterDataInterface } from './tab-filter.model';

@Component({
	selector: 'app-tab-filters',
	templateUrl: './tab-filters.component.html',
	styleUrls: ['./tab-filters.component.scss'],
})
export class TabFiltersComponent {
	@Input() public data: Array<TabFilterDataInterface>;
	@Output() onChange = new EventEmitter<TabFilterDataInterface>();

	public change(item: TabFilterDataInterface): void {
		this.onChange.emit(item);
	}
}
