export enum SurveysModulePrivilegesEnum {
	MODULE_NAME = 100,
	ASSIGNED_SURVEYS = 67,
	OPEN_SURVEYS = 68,
	SURVEY_LIBRARY = 69,
	ADD_NEW_SURVEY = 76,
	VIEWING_SURVEY = 65,
	COMPLETING_SURVEY = 66,
	SURVEY_STATUS_DROP_DOWN = 70,
	SURVEY_STATUS_ACTIVE = 72,
	SURVEY_STATUS_INACTIVE = 74,
	SURVEY_STATUS_EDIT = 71,
	SURVEY_STATUS_ASSIGN = 73,
	SURVEY_STATUS_RETIRE = 75,
	SURVEY_STATUS_UNASSIGN = 111,
	SURVEY_OVERVIEW = 77,
	SURVEY_CREATING_LOGIC = 83,
	SURVEY_PENDING_PUBLISH = 82,
	SURVEY_MANAGE_GROUPS = 8,
	SURVEY_MANAGE_SURVEY = 214,
	SURVEY_CATEGORY = 260,
}
