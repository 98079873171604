<div class="d-flex">
	<button
		class="ms-2 btn submit-btn d-flex align-items-center"
		(click)="downloadPdfFile()"
	>
		<span class="material-icons-outlined me-2"> file_download </span>
		PDF
	</button>
	<button
		class="ms-2 btn submit-btn d-flex align-items-center"
		(click)="downloadCsvFile()"
	>
		<span class="material-icons-outlined me-2"> file_download </span>

		Excel
	</button>
</div>
<div style="display: none" id="report-header">
	<div class="d-flex justify-content-between col-12">
		<img src="assets/images/logo.png" alt="logo" />
		<span>date:12-05-2022</span>
	</div>
</div>
