<div class="padding-wrapper">
	<h3>{{ "ADD_SKILL" | translate }}</h3>

	<div>
		<div class="row">
			<div class="col-12">
				<mat-accordion>
					<mat-expansion-panel>
						<mat-expansion-panel-header>
							{{ selectedUsers?.length }} users Selected
						</mat-expansion-panel-header>
						<div class="selected-users">
							<span
								class="user"
								*ngFor="let user of selectedUsers"
								>{{ user.name }}</span
							>
						</div>
					</mat-expansion-panel>
				</mat-accordion>
			</div>
		</div>
		<div class="row my-5">
			<div class="col-lg-3 col-sm-12">
				<slr-drp01
					[title]="'SLECT_SKILL'"
					[placeholder]="'SLECT_SKILL'"
					[searchable]="true"
					[data]="skillData"
					[fgName]="skillForm"
					[controlName]="'skill'"
					(onChange)="selectSkill($event)"
				></slr-drp01>
			</div>
		</div>
		<div class="row my-5 align-items-center" *ngIf="selectedSkill">
			<div class="col-lg-3 col-sm-12 mb-2">
				<slr-rbtn-group
					[align]="'vertical'"
					[data]="competenceData"
					[label]="'COMPETENCY'"
					[fgName]="skillForm"
					[controlName]="'competence'"
				></slr-rbtn-group>
			</div>
			<div class="col-lg-3 col-sm-12 mb-2">
				<slr-date-input
					[controlName]="'obtainedDate'"
					[label]="'OBTAINED_DATE'"
					[placeholder]="'OBTAINED_DATE'"
					[fgName]="skillForm"
				></slr-date-input>
			</div>
			<div class="col-lg-3 col-sm-12 mb-2">
				<slr-date-input
					[controlName]="'renewalDate'"
					[label]="'RENEWAL_DATE'"
					[placeholder]="'RENEWAL_DATE'"
					[fgName]="skillForm"
				></slr-date-input>
			</div>
			<div class="col-lg-3 col-sm-12 mb-2">
				<slr-date-input
					[controlName]="'alertDate'"
					[label]="'ALERT_DATE'"
					[placeholder]="'ALERT_DATE'"
					[fgName]="skillForm"
				></slr-date-input>
			</div>
		</div>

		<div class="row" *ngIf="selectedSkill">
			<div class="col-12">
				<div class="d-flex gap-2">
					<slr-btn01 (onClick)="onSaveClick()">{{
						"SUBMIT" | translate
					}}</slr-btn01>
					<slr-btn03 (onClick)="onCancelClick()">{{
						"CANCEL" | translate
					}}</slr-btn03>
				</div>
			</div>
		</div>
	</div>
</div>
