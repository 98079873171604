import { Injectable, TemplateRef } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class NotificationService {
	toasts: any[] = [];

	success(textOrTpl: string | TemplateRef<any>) {
		this.toasts.push({
			textOrTpl,
			className: 'bg-success',
			icon: 'check_circle',
			title: 'SUCCESS',
		});
	}
	error(textOrTpl: string | TemplateRef<any>) {
		this.toasts.push({
			textOrTpl,
			className: 'bg-danger',
			icon: 'warning',
			title: 'ERROR',
		});
	}
	remove(toast: any) {
		this.toasts = this.toasts.filter((t) => t !== toast);
	}
}
