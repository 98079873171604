import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
	selector: 'slr-toggle',
	templateUrl: './slr-toggle.component.html',
	styleUrls: ['./slr-toggle.component.scss'],
})
export class SlrToggleComponent {
	@Input() public checked: boolean;
	@Input() public disabled: boolean = false;
	@Input() public label: string;
	@Output() public toggle = new EventEmitter<boolean>();

	public onToggle(event: MatSlideToggleChange): void {
		this.toggle.emit(event.checked);
	}
}
