import {
	Component,
	ElementRef,
	AfterViewInit,
	OnDestroy,
	ViewChild,
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import {
	SharedState,
	getBrandingSettingsByName,
	getProductAccessSettings,
} from '../../_state/shared.reducer';
import { Subject, filter, takeUntil } from 'rxjs';
import { LocalDataService } from 'src/app/core/services/local-data.service';
import { SettingsMap } from 'src/app/modules/user-settings/_models/settins-map.enum';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements AfterViewInit, OnDestroy {
	@ViewChild('footer') public footerElement: ElementRef;
	infoLinks: Array<string> = [
		'Demo',
		'Customer Support',
		'FAQ',
		'Private Policy',
		'Terms & Conditions',
	];
	copyrights: string = `Copyright Surge Learning Inc. ${new Date().getFullYear()}`;
	contactUs: Array<string> = ['support@surgelearning.ca', '1.844.843.4670'];
	private unsubscriber$ = new Subject<void>();
	constructor(
		private sharedStore: Store<SharedState>,
		private localData: LocalDataService
	) {}

	private getFooterProperties(): void {
		this.sharedStore
			.pipe(
				select(
					getBrandingSettingsByName(
						SettingsMap.footer_color,
						SettingsMap.footer_background_color
					)
				),
				takeUntil(this.unsubscriber$)
			)
			.subscribe({
				next: (res) => {
					this.footerElement.nativeElement.style.backgroundColor =
						res[1] ?? this.localData.defaultColors[1];
					this.footerElement.nativeElement.style.color =
						res[0] ?? this.localData.defaultColors[0];
				},
			});
	}

	ngAfterViewInit(): void {
		this.getFooterProperties();
	}

	ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.unsubscribe();
	}
}
