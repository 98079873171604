import { DashboardModulePrivilegesEnum } from 'src/app/core/models/_module_enums/dashboard-module.privileges.enum';
import { LiveEventModulePrivilegesEnum } from 'src/app/core/models/_module_enums/live-events-module.privileges.enum';
import { ManageUsersModulePrivilegesEnum } from 'src/app/core/models/_module_enums/manage-users-module.privileges.enum';
import { MyCoursesModulePrivilegesEnum } from 'src/app/core/models/_module_enums/my-courses-module-privileges.enum';
import { OnboardClientsModulePrivilegesEnum } from 'src/app/core/models/_module_enums/onboard-clients-module.privileges.enum';
import { PolicyProfessionalModulePrivilegesEnum } from 'src/app/core/models/_module_enums/policy-professional-module.privileges.enum';
import { QRMModulePrivilegesEnum } from 'src/app/core/models/_module_enums/qrm-module.privileges.enum';
import { ReportModulePrivilegesEnum } from 'src/app/core/models/_module_enums/reports-module.privilege.enum';
import { ResourceCategoriesModulePrivilegesEnum } from 'src/app/core/models/_module_enums/resource-center-categories-module.privileges.enum';
import { ResourceGroupsModulePrivilegesEnum } from 'src/app/core/models/_module_enums/resource-center-groups-module.privileges.enum';
import { RosourceCenterModulePrivilegesEnum } from 'src/app/core/models/_module_enums/resource-center-module-privileges.enum';
import { SettingsModulePrivilegesEnum } from 'src/app/core/models/_module_enums/settings-module-privilege.enum';
import { SkillsModulePrivilegesEnum } from 'src/app/core/models/_module_enums/skills-module.privileges.enum';
import { SurveyCategoriesModulePrivilegesEnum } from 'src/app/core/models/_module_enums/survey-categories-module.privileges.enum';
import { SurveysModulePrivilegesEnum } from 'src/app/core/models/_module_enums/surveys-module-privileges.enum';
import { SystemToolsModulePrivilegesEnum } from 'src/app/core/models/_module_enums/system-tools-module-privileges.enum';

export const menuItems = [
	{
		name: 'DASHBOARD',
		icon: 'dashboard',
		url: '/dashboard',
		moduleName: DashboardModulePrivilegesEnum.MODULE_NAME,
		subMenu: [
			{
				name: 'CONFIGURATION_MENU',
				icon: 'settings',
				url: '/dashboard/configuration/badges',
				parent: 'DASHBOARD',
				moduleName: DashboardModulePrivilegesEnum.CONFIGURE,
			}
		],
	},
	{
		name: 'MY_COURSES_CAPS',
		icon: 'library_books',
		url: '/courses',
		moduleName: MyCoursesModulePrivilegesEnum.MODULE_NAME,
		subMenu: [],
	},
	{
		name: 'RESOURCE_CENTER',
		icon: 'collections_bookmark',
		url: '/resources',
		moduleName: RosourceCenterModulePrivilegesEnum.MODULE_NAME,
		subMenu: [
			{
				name: 'EDUCATIONAL_CATEGORIES',
				icon: 'backup_table',
				url: '/categories',
				parent: 'RESOURCE_CENTER',
				moduleName: ResourceCategoriesModulePrivilegesEnum.MODULE_NAME,
			},
			{
				name: 'EDUCATIONAL_GROUPS',
				icon: 'switch_account',
				url: '/groups',
				parent: 'RESOURCE_CENTER',
				moduleName: ResourceGroupsModulePrivilegesEnum.MODULE_NAME,
			},
		],
	},
	{
		name: 'MANAGE_USERS_CAPS',
		icon: 'manage_accounts',
		url: '/manage-users',
		subMenu: [],
		moduleName: ManageUsersModulePrivilegesEnum.MODULE_NAME,
	},
	{
		name: 'ONBOARD_CLIENTS_CAPS',
		icon: 'keyboard_command_key',
		url: '/onboard-client',
		subMenu: [],
		moduleName: OnboardClientsModulePrivilegesEnum.MODULE_NAME,
	},
	{
		name: 'POLICY_PROFESSIONAL',
		icon: 'admin_panel_settings',
		class: 'policy',
		url: '/policy',
		subMenu: [],
		moduleName: PolicyProfessionalModulePrivilegesEnum.MODULE_NAME,
	},
	{
		name: 'SURVEYS',
		icon: 'fact_check',
		url: '/surveys',
		moduleName: SurveysModulePrivilegesEnum.MODULE_NAME,
		subMenu: [
			{
				name: 'SURVEY_CATEGORIES',
				icon: 'backup_table',
				url: '/survey-categories',
				parent: 'SURVEYS',
				moduleName: SurveysModulePrivilegesEnum.SURVEY_MANAGE_SURVEY,
			},
		],
	},
	{
		name: 'LIVE_EVENTS',
		icon: 'live_tv',
		url: '/live-event',
		subMenu: [],
		moduleName: LiveEventModulePrivilegesEnum.MODULE_NAME,
	},
	{
		name: 'SKILLS_AND_CREDENTIALS',
		icon: 'engineering',
		url: '/skillsAndCredentials',
		subMenu: [],
		moduleName: SkillsModulePrivilegesEnum.MODULE_NAME,
	},
	{
		name: 'QRM',
		icon: 'quickreply',
		class: 'qrm',
		url: '/qrm',
		subMenu: [
			{
				name: 'ROLES_CAPS',
				icon: 'settings_accessibility',
				class: 'qrm',
				url: '/qrm/role',
				parent: 'QRM',
				moduleName: null,
			},
			{
				name: 'MANAGE_INDICATORS_CAPS',
				icon: 'settings_accessibility',
				class: 'qrm',
				url: '/qrm/manage-indicators',
				parent: 'QRM',
				moduleName: null,
			},
			{
				name: 'MANAGE_QI_CAPS',
				icon: 'settings_accessibility',
				class: 'qrm',
				url: '/qrm/manage-qi',
				parent: 'QRM',
				moduleName: null,
			},
		],
		moduleName: QRMModulePrivilegesEnum.MODULE_NAME,
	},
	{
		name: 'REPORTS_CAPS',
		icon: 'assignment_turned_in',
		url: '/reports/user/overview',
		subMenu: [],
		moduleName: ReportModulePrivilegesEnum.MODULE_NAME,
	},
	{
		name: 'SETTINGS_CAPS',
		icon: 'settings',
		url: '/settings',
		moduleName: SettingsModulePrivilegesEnum.MODULE_NAME,
		subMenu: [],
	},
	{
		name: 'USER_ACCESS_CAPS',
		icon: 'settings_accessibility',
		url: '/user-access',
		// parent: 'SETTINGS',
		subMenu: [],
		moduleName: SettingsModulePrivilegesEnum.USER_ACCESS,
	},
	{
		name: 'PRODUCT_ACCESS_CAPS',
		icon: 'inventory',
		url: '/product-access',
		// parent: 'SETTINGS',
		subMenu: [],
		moduleName: SettingsModulePrivilegesEnum.PRODUCT_ACCESS,
	},
	{
		name: 'MANAGE_CODES_CAPS',
		icon: 'settings_suggest',
		url: '/manage-codes',
		// parent: 'SETTINGS',
		subMenu: [],
		moduleName: SettingsModulePrivilegesEnum.MANAGE_CODES,
	},
	{
		name: 'HELP_SYSTEM_CAPS',
		icon: 'help_center',
		url: '/help-center',
		subMenu: [],
		moduleName: DashboardModulePrivilegesEnum.MODULE_NAME,
	},
	{
		name: 'SYSTEM_TOOLS_CAPS',
		icon: 'settings_system_daydream',
		url: '/system-tools',
		subMenu: [],
		moduleName: SystemToolsModulePrivilegesEnum.MODULE_NAME,
	},
];
