export enum LiveEventModulePrivilegesEnum {
	MODULE_NAME = 101,
	NEW_EDIT_EVENT = 33,
	EVENT_DATE_TIME = 34,
	C_N_L_A_I = 36,
	CREATE_NEW_EDIT_LIVE_EVENT_EXCEMPT_USER = 37,
	CREATE_NEW_EDIT_LIVE_EVENT_SEATING_CAP = 38,
	CREATE_NEW_EDIT_LIVE_EVENT_OPEN_MANDATED_REG = 39,
	CREATE_NEW_EDIT_LIVE_EVENT_GENERAL_DETAILS = 40,
	CREATE_NEW_EDIT_LIVE_EVENT_SELECT_SITES = 41,
	CREATE_NEW_EDIT_LIVE_EVENT_REPORTING_GROUP = 42,
	CREATE_NEW_EDIT_LIVE_EVENT_ADD_NEW_SESSION = 43,
	EVENT_DETAILS_AND_PREREQUIISTS = 44,
	EVENT_DETAILS_AND_PREREQUIISTS_ADD_SERVEY = 45,
	EVENT_DETAILS_AND_PREREQUIISTS_SELECT_COURSE_FOR_PART = 46,
	EVENT_DETAILS_AND_PREREQUIISTS_SELECT_SKILLS_FOR_PART = 47,
	EVENT_DETAILS_AND_PREREQUIISTS_DATE_ENTERED_RENEWEL = 48,
	EVENT_DETAILS_AND_PREREQUIISTS_SUPPORT_FILES = 49,
	ALL_LIVE_EVENTS = 50,
	ADD_LIVE_EVENT_LP = 51,
	INACTIVATE = 52,
	EDIT = 53,
	DELETE = 54,
	LIVE_EVENT_LIST_VIEW_BY_YEAR_PRI_FAC_ONLY_CAL_VIEW_L_EVENTS = 55,
	CALENDER_VIEW_LIVE_EVENT = 56,
	DATE_DROP_DOWN = 57,
	OPEN_UP_PAST_EVENT = 58,
	EVENT_NAME = 59,
	DESCRIPTION = 60,
	AUTHOR = 61,
	DATE_OF_SESSION = 62,
	DATE_ENTERED = 63,
	COMPLETED = 64,
	REGISTER = 39,
	LIVE_EVENT_MANAGE_LIVE_EVENT = 213,
}
