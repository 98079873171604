import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-due-date',
	templateUrl: './due-date.component.html',
	styleUrls: ['./due-date.component.scss'],
})
export class DueDateComponent {
	@Input() dueDate: {
		daysPast?: number;
		dueDate: string;
	};
}
