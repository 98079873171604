import {
	Directive,
	OnInit,
	OnDestroy,
	Input,
	Host,
	ElementRef,
} from '@angular/core';
import { select } from '@ngrx/store';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import {
	getUserSkillMap,
	UserProfileState,
} from 'src/app/modules/user-profile/_state/user-profile.reducer';

@Directive({
	selector: '[skillRowLegend]',
})
export class SkillRowLegendDirective implements OnInit, OnDestroy {
	@Input() skillId: number;
	@Input() renewalType: string;
	private unsubscriber$ = new Subject<void>();
	constructor(
		private profileStore: Store<UserProfileState>,
		@Host() private el: ElementRef
	) {}

	private getClassName(due: number): string {
		switch (due) {
			case 1:
				return 'past-due';
			case 2:
				return 'coming-due';
			default:
				return '';
		}
	}

	ngOnInit(): void {
		this.profileStore
			.pipe(select(getUserSkillMap), takeUntil(this.unsubscriber$))
			.subscribe({
				next: (res) => {
					if (res[this.skillId] && this.renewalType !== 'Never Expires') {
						this.el.nativeElement.classList = this.getClassName(
							res[this.skillId]._due
						);
					} else {
						this.el.nativeElement.class = '';
					}
				},
			});
	}

	ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.unsubscribe();
	}
}
