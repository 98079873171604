<mat-form-field
	class="auth"
	[ngClass]="{ error: control.invalid && control.touched }"
>
	<!-- <mat-label>{{ lable }}</mat-label> -->
	<input
		(focusout)="setErrorMessage()"
		matInput
		[formControl]="control"
		[placeholder]="placeholder"
	/>
	<!-- <mat-icon *ngIf="control.valid" class="color-green-1" matSuffix
		>done</mat-icon
	> -->
	<mat-error class="br-red error" *ngIf="control.invalid">{{
		errorMessage
	}}</mat-error>
</mat-form-field>
