import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-skill-comment-icon',
	templateUrl: './skill-comment-icon.component.html',
	styleUrls: ['./skill-comment-icon.component.scss'],
})
export class SkillCommentIconComponent {
	@Input() showTick: boolean = false;
}
