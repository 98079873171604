<div #footer class="footer">
	<div class="container-fluid">
		<div class="row m-0">
			<div class="col-sm-5 text-start p-0">
				<div class="footer-links">
					<span *ngFor="let info of infoLinks; last as last">
						<span class="footer-items">
							{{ info }}
						</span>
						<span *ngIf="!last" class="bullet"> &bull; </span>
					</span>
				</div>
			</div>
			<div class="col-sm-3 text-start">
				<div class="copyrights">&copy; {{ copyrights }}</div>
			</div>
			<div class="col-sm-4 text-end p-0">
				<div class="contact-links">
					<span *ngFor="let contact of contactUs; last as last">
						<span class="footer-items">
							{{ contact }}
						</span>
						<span *ngIf="!last" class="bullet"> &bull; </span>
					</span>
				</div>
			</div>
		</div>
	</div>
</div>
