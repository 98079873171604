import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-view-switcher',
	templateUrl: './view-switcher.component.html',
	styleUrls: ['./view-switcher.component.scss'],
})
export class ViewSwitcherComponent {
	@Input() viewType: 'list' | 'graph' | 'grid' = 'list';
	@Input() includeGrid: boolean = false;
	@Input() includeGraph: boolean = true;
	@Output() typeChanged = new EventEmitter();

	public switchViewType(type: 'list' | 'graph' | 'grid'): void {
		this.viewType = type;
		this.typeChanged.next(type);
	}
}
