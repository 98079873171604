import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainSettingsComponent } from './components/main-settings/main-settings.component';
import { GetSettingsResolver } from './_resolvers/get-settings.resolver';
import { RoleGuard } from 'src/app/core/guards/role.guard';
import { SettingsModulePrivilegesEnum } from 'src/app/core/models/_module_enums/settings-module-privilege.enum';

const routes: Routes = [
	{
		path: '',
		data: {
			breadCrumb: [
				{
					title: 'HOME',
					url: 'dashboard',
					queryParams: '',
				},
				{
					title: 'SETTINGS',
					url: 'settings',
					queryParams: '',
				},
			],
			privileKey: SettingsModulePrivilegesEnum.MODULE_NAME,
		},
		canActivate: [RoleGuard],
		resolve: { masterDatas: GetSettingsResolver },
		component: MainSettingsComponent,
	},
	// {
	// 	path: '/user-access',
	// 	data: {
	// 		breadCrumb: [
	// 			{
	// 				title: 'HOME',
	// 				url: 'dashboard',
	// 				queryParams: '',
	// 			},
	// 			{
	// 				title: 'SETTINGS',
	// 				url: 'settings',
	// 				queryParams: '',
	// 			},
	// 		],
	// 		privileKey: SettingsModulePrivilegesEnum.USER_ACCESS
	// 	},
	// 	canActivate: [RoleGuard],
	// 	resolve: { masterDatas: GetUserAccessSettingsResolver },
	// 	component: UserAccessSettingsComponent,
	// },
	// {
	// 	path: 'product-access',
	// 	data: {
	// 		breadCrumb: [
	// 			{
	// 				title: 'HOME',
	// 				url: 'dashboard',
	// 				queryParams: '',
	// 			},
	// 			{
	// 				title: 'SETTINGS',
	// 				url: 'settings',
	// 				queryParams: '',
	// 			},
	// 		],
	// 		privileKey: SettingsModulePrivilegesEnum.PRODUCT_ACCESS
	// 	},
	// 	canActivate: [RoleGuard],
	// 	resolve: { masterDatas: GetProductAccessSettingsResolver },
	// 	component: ProductAccessSettingsComponent,
	// },
	// {
	// 	path: 'manage-codes',
	// 	resolve: { codeList: ManageCodesListResolver },
	// 	data: {
	// 		breadCrumb: [
	// 			{
	// 				title: 'HOME',
	// 				url: 'dashboard',
	// 				queryParams: '',
	// 			},
	// 			{
	// 				title: 'SETTINGS',
	// 				url: 'settings',
	// 				queryParams: '',
	// 			},
	// 			{
	// 				title: 'MANAGE_CODES',
	// 				url: 'settings/manage-codes',
	// 				queryParams: '',
	// 			},
	// 		],
	// 		privileKey: SettingsModulePrivilegesEnum.MANAGE_CODES
	// 	},
	// 	canActivate: [RoleGuard],
	// 	component: ManageCodeListComponent,
	// },
	// {
	// 	path: 'manage-codes/add-new',
	// 	data: {
	// 		breadCrumb: [
	// 			{
	// 				title: 'HOME',
	// 				url: 'dashboard',
	// 				queryParams: '',
	// 			},
	// 			{
	// 				title: 'SETTINGS',
	// 				url: 'settings',
	// 				queryParams: '',
	// 			},
	// 			{
	// 				title: 'MANAGE_CODES',
	// 				url: 'settings/manage-codes',
	// 				queryParams: '',
	// 			},
	// 			{
	// 				title: 'ADD_NEW_CODE',
	// 				url: 'settings/manage-codes/add-new',
	// 				queryParams: '',
	// 			},
	// 		],
	// 	},
	// 	resolve: { masterData: AddNewCodeResolver },
	// 	component: AddNewCodeComponent,
	// },
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class UserSettingsRoutingModule {}
