<app-modal-header-placeholder
	class="model-header"
	[title]="' Delete Survey'"
	(onClose)="close()"
></app-modal-header-placeholder>
<div class="global-table-wrap coursestable">
	<div class="row">
		<div class="col-12">
			<p class="text-center">
				{{"SURVEY_DELETE_WARNING_MSG" | translate}}
				<strong>{{ selectedSurvey?.surveyName }}</strong>
				?
			</p>
		</div>
	</div>
	<div class="pl-34">
		<slr-btn02 class="mrs-10" (click)="close()" (keyup)="close()">No</slr-btn02>

		<slr-btn02 class="mr-10" (click)="delete()" (keyup)="delete()">Yes</slr-btn02>
	</div>
</div>
