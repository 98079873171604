import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { LegendData } from 'src/app/shared/components/tables/slr-table/slr-table.component';
@Directive({
	selector: '[tableLegend]',
})
export class TableLegendDirective<T> implements OnInit {
	@Input() public legendData: Array<LegendData>;
	@Input() public element: T;
	@Input() public key: string;
	constructor(private elementRef: ElementRef) {}

	ngOnInit(): void {
		if (!this.legendData) return;
		this.elementRef.nativeElement.style.backgroundColor =
			this.legendData.find((x) => x.id === this.element[this.key])
				?.color ?? '#ffffff';
	}
}
