import { Injectable } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { ValidatorMessage } from '../models/validation-messages.model';

@Injectable({
	providedIn: 'root',
})
export class ErrorMessageService {
	public setErrorMessage(
		validatorMessage: ValidatorMessage[],
		formControl: FormControl | AbstractControl
	): string {
		let errorMessage: string = '';
		if (validatorMessage && formControl?.errors) {
			const error = validatorMessage.find(
				(message) =>
					message.validationType ===
					Object.keys(formControl.errors || {})[0]
			);

			errorMessage = error
				? error.validationMessage
				: 'This field is not valid';
		}
		return errorMessage;
	}
}
