<div
	id="sidebar"
	(click)="$event.stopPropagation()"
	(keyup)="$event.stopPropagation()"
	class="sidebar {{ mobileSidebarState }}"
>
	<a
		*ngIf="!this._newHidden"
		href="javascript:void(0);"
		class="col-12 p-0 d-flex menu-layout border-bottom py-3 collapse-icon align-items-center"
		(click)="changeSidebarState(!sidebarExpanded)"
	>
		<i
			class="material-icons-outlined"
			*ngIf="miniSidebar && !sidebarExpanded"
		>
			menu
		</i>
		<span *ngIf="!miniSidebar && !sidebarExpanded">
			<i class="material-icons-outlined"> arrow_circle_right </i>
			<span>
				{{ "EXPAND_MENU" | translate }}
			</span>
		</span>
		<span *ngIf="sidebarExpanded">
			<i class="material-icons-outlined"> arrow_circle_left </i>
			<span>
				{{ "COLLAPSE_MENU" | translate }}
			</span>
		</span>
	</a>
	<div *ngFor="let menu of availableMenuItems">
		<a
			class="col-12 p-0 d-flex menu-item align-items-center"
			(click)="openSelectedMenu(menu)"
			[routerLink]="[menu.url]"
			[queryParams]="menu.queryParams"
			[class.active-menu]="
				activeMenu?.name === menu.name ||
				(miniSidebar && checkSubMenu(menu, activeMenu))
			"
			[ngClass]="menu.class ?? menu.class"
		>
			<div>
				<i
					class="material-icons-outlined py-3"
					[ngClass]="menu.class ?? menu.class"
				>
					{{ menu.icon }}
				</i>
			</div>
			<div
				class="icon-text border-bottom p-0 flex-fill d-flex align-items-center"
				*ngIf="!miniSidebar || sidebarExpanded"
			>
				{{ (menu.name | translate | uppercase) || "-" }}
			</div></a
		>

		<div *ngIf="!miniSidebar || sidebarExpanded">
			<div *ngFor="let subMenu of menu.subMenu">
				<a
					class="col-12 p-0 d-flex menu-item align-items-center"
					(click)="openSelectedMenu(subMenu)"
					[routerLink]="[subMenu.url]"
					[queryParams]="subMenu.queryParams"
					[class.active-menu]="activeMenu?.name === subMenu.name"
					[ngClass]="menu.class ?? menu.class"
				>
					<div>
						<i
							class="material-icons-outlined py-3 ps-5"
							[ngClass]="menu.class ?? menu.class"
						>
							{{ subMenu.icon }}
						</i>
					</div>
					<div
						class="icon-text border-bottom p-0 flex-fill d-flex align-items-center"
					>
						{{ (subMenu.name | translate) || "-" }}
					</div></a
				>
			</div>
		</div>
	</div>
</div>

