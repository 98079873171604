import { UrlNames } from '../services/urlProfiler';

export const exceptedUrls: Array<string> = [
	'api.vimeo.com',
	'tus.vimeo.com',
	'/upload',
	'/courses/importJobs/',
	'/users/courses/completion/status',
	'/broadcast/v1/valid_email_count',
	'api.ipify.org',
	'users/sessions/track',
	'RusticiEngine/api/v2/registrations/',
	'search.json',
	'alerts/v1/count',
	'broadcast/v1/seen',
	'https://regenisisdevaliaspooldomain.auth.ca-central-1.amazoncognito.com/oauth2/token',
	,
	's3.ca-central-1.amazonaws.com'
];

export const authExceptedUrls: Array<string> = [
	UrlNames.loadUserSavedSecurityQuestions,
	UrlNames.checkUsersSecurityQuestionsAnswers,
	UrlNames.loadAvailableSecurityQuestions,
	UrlNames.changePasswordAfterSecurityQuestion,
	UrlNames.forgotPassword,
	UrlNames.loadUserEmail,
	UrlNames.forgotPasswordConfirmation,
	UrlNames.changeUserPassword,
	UrlNames.newUserLogin,
	UrlNames.logoutNewUserSession,
	UrlNames.refreshSession,
	'api.vimeo.com',
	'tus.vimeo.com',
	'signin',
	'https://regenisisdevaliaspooldomain.auth.ca-central-1.amazoncognito.com/oauth2/token',
];
