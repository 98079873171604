<div
  ngbDropdown
  [autoClose]="autoClose"
  [display]="display"
  [dropdownClass]="dropdownClass"
  [open]="open"
  [placement]="placement"
  (openChange)="isDropdownOpened($event)"
>
  <button class="btn site-location" id="multisite-location" ngbDropdownToggle>
    {{ placeholder | translate }}
    <span class="material-icons-outlined dropdown-icon">{{ icon }}</span>
  </button>
  <div
    ngbDropdownMenu
    #dropdown
    class="dropdown-menu"
    aria-labelledby="dropdown-options"
  >
    <button
      #dropdownOption
      [id]="option + i"
      class="dropdown-menu-items"
      ngbDropdownItem
      *ngFor="let option of options; index as i"
      (click)="itemSelection(option, dropdown, dropdownOption)"
    >
      {{ option | translate }}
    </button>
  </div>
</div>
