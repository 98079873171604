import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'completionPercentage',
})
export class CompletionPercentagePipe implements PipeTransform {
	transform(value: number, total: number): number {
		if (!total) return 0;
		return +((value / total) * 100).toFixed(2);
	}
}
