export enum QRMModulePrivilegesEnum {
	MODULE_NAME = 103,
	MY_QRM = 103,
	ON_DEMAND = 26,
	MANAGE_QRM = 132,
	MY_ACTION = 28,
	QRM_CREATE_AUDIT = 136,
	QRM_MANAGE_CATEGORY = 135,
	Transfer = 134,
	ACTIVE_LIBRARY=103,
	SCHEDULED_QRM=103,
	INACTIVE_QRM = 103,
	UNDER_CONSTRUCTION = 103,
	QRM_LIBRARY=103,
}
