<ng-select
	[items]="filteredData"
	[searchFn]="searchFn"
	[clearSearchOnAdd]="true"
	placeholder="{{ placeholder | translate }}"
	class="col-12 users-input"
	[typeToSearchText]="'Start typing to add user'"
	(change)="selectEntry($event)"
	[(ngModel)]="_selectedItem"
	[bindLabel]="'name'"
	[bindValue]="'id'"
	[appendTo]="appendTo"
>
	<ng-template ng-option-tmp let-item="item" let-index="index">
		{{ item.name }}
	</ng-template>
</ng-select>
<div class="user-list">
	<div
		*ngFor="let item of selectedItems.selected; let i = index"
		class="users d-flex align-items-center justify-content-between mt-2"
	>
		<span *ngIf="item" class="account-detail">
			<i class="material-icons-outlined account-icon" *ngIf="showicon">
				{{ icon }}
			</i>
			{{ item.name }}
		</span>
		<span
			*ngIf="item"
			class="material-icons-outlined remove-user-icon cursor-pointer"
			(click)="deselectEntry(item)"
			(keyup)="deselectEntry(item)"
		>
			clear
		</span>
	</div>
</div>
