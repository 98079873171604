<circle-progress
	[percent]="+percent"
	[radius]="radius"
	[space]="space"
	[outerStrokeWidth]="outerStrokeWidth"
	[innerStrokeWidth]="innerStrokeWidth"
	[outerStrokeColor]="outerStrokeColor"
	[innerStrokeColor]="innerStrokeColor"
	[outerStrokeGradient]="outerStrokeGradient"
	[outerStrokeGradientStopColor]="outerStrokeGradientStopColor"
	[title]="title"
	[units]="units"
	[animation]="animation"
	[animationDuration]="animationDuration"
	[clockwise]="clockwise"
	[titleFontSize]="titleFontSize"
	[showSubtitle]="showSubtitle"
	[titleFontWeight]="titleFontWeight"
	[showTitle]="showTitle"
	[unitsFontWeight]="unitsFontWeight"
	[subtitle]="subTitle"
	[subtitleFontSize]="'24'"
	[showUnits]="showUnit"
	[subtitleColor]="subtitleColor"
></circle-progress>
