import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
	selector: 'slr-textarea',
	templateUrl: './slr-textarea.component.html',
	styleUrls: ['./slr-textarea.component.scss'],
})
export class SlrTextareaComponent {
	@Input() public placeholder: string;
	@Input() public control: FormControl | AbstractControl;
	@Input() public label: string;
	@Input() public disabled: boolean = false;
	@Input() public width: string = '100%';
	@Input() public height: string = '100px';
	@Input() public resize: 'none' | 'horizontal' | 'vertival' | 'both' =
		'none';
	@Input() public value: string;
	@Output() public onChange: EventEmitter<string>;

	constructor() {
		this.onChange = new EventEmitter<string>();
	}

	public change(): void {
		this.onChange.emit(this.value);
	}
}
