import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'getSkillClass',
})
export class GetSkillClassPipe implements PipeTransform {
	transform(renewDate: string): string {
		const today = new Date();
		const bufferedDate = new Date();
		bufferedDate.setMonth(today.getMonth() + 3);
		if (renewDate) {
			if (new Date(renewDate) < new Date()) {
				return 'passed-due-skill';
			} else if (
				new Date(renewDate) >= new Date() &&
				new Date(renewDate) < bufferedDate
			) {
				return 'coming-due-skill';
			}
		}
		return '';
	}
}
