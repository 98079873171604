<ng-select
  [id]="id"
  [placeholder]="placeholder | translate"
  [dropdownPosition]="dropdownPosition"
  [items]="options"
  [ngClass]="classes"
  [clearable]="false"
  [searchable]="false"
  [hidden]="hidden"
  [bindLabel]="'name'"
  [bindValue]="'id'"
  (change)="ngSelectChange($event)"
  [(ngModel)]="value"
></ng-select>
