import { LocalDataService } from 'src/app/core/services/local-data.service';
import { ReportFilters } from './report.filters';
import { CourseListParams } from './_models/course-completion.report.model';
import { CourseDetailParams } from './_models/course-details.report.model';
import { CustomizeColumns } from './_models/customize-columns.models';
import { EduactionStatusParams } from './_models/education-detail-report.model';
import { UserSummaryReportParams } from './_models/education-summary-report.model';
import { UserStatusParams } from './_models/educational-status-report.model';
import { MonthlySummaryReportParams } from './_models/monthly-education-report.model';
import { ProgramEvaluationParams } from './_models/program-evaluation-report.model';
import { RequiredEducationHistoryParams } from './_models/required-course-history.model';
import { RequiredEducationReportParams } from './_models/required-education-report.model';
import { RequiredReportParams } from './_models/required-report.model';
import { SkillsComingDueParams } from './_models/skills-coming-due.model';
import { SkillsPendingApprovalParams } from './_models/skills-pending-approval.model';
import { SkillsSummaryQueryParams } from './_models/skills-summary.model';
import { TestSummaryParams } from './_models/test-summary-report.model';
import { DashboardQueryParams } from './_models/user-dashboard-report.model';
import { UserEducationAssignmentParams } from './_models/user-education-assignment.model';
import { UserEducationHistoryParams } from './_models/user-history-report.model';
import { UtilizationReporParams } from './_models/utilization-report.models';
import moment from 'moment';

export class DefaultReportParams {
	private today: Date = this.localData.getToday();
	constructor(private localData: LocalDataService) {}
	public getCustomizeColumnParams(): CustomizeColumns {
		return {
			loginUserId: this.localData.getUserId(),
			report: '',
			clientCode: this.localData.getSiteCode(),
			locationId: this.localData.getLocationId(),
		};
	}
	public getDashboardParams(): DashboardQueryParams {
		return {
			completedStatus: ReportFilters.completedStatusList[0].id,
			educationalYear: new Date().getFullYear(),
			orientationMode: ReportFilters.userDashboardFilters[3].data[0].id,
			userGroups: ReportFilters.departmentFilter.data[0].educationGroupId,
			locationId: this.localData.getLocationId(),
			userId: this.localData.getUserId(),
			supervisorId: ReportFilters.supervisorFilter.data[0].userId,
			previousYear: 0,
			usersWithNoLoginThisyear: 0,
		};
	}
	public getUserStatusParams(): UserStatusParams {
		return {
			locationId: this.localData.getLocationId(),
			activeOrInactive: ReportFilters.userStatusFilter.data[1].id,
		};
	}
	public getSkillSummaryParams(): SkillsSummaryQueryParams {
		return {
			userId: this.localData.getUserId(),
			locationId: this.localData.getLocationId(),
			type: 'local',
			activeorInactive: ReportFilters.userStatusFilter.data[1].id,
			groups: `${ReportFilters.departmentFilter.data[0].educationGroupId}`,
			skillId: `${ReportFilters.skillsSummaryReportFilters[0].data[0].id}`,
			skillStatus: ReportFilters.skillsSummaryReportFilters[4].data[0].id,
			skillStatusWithUser:
				ReportFilters.skillsSummaryReportFilters[5].data[0].id,
			supervisorID: ReportFilters.supervisorFilter.data[0].userId,
			includeComments: 0,
			includeCommentsDate: 0,
		};
	}
	public getSkillComingDueParams(): SkillsComingDueParams {
		return {
			userId: this.localData.getUserId(),
			locationId: this.localData.getLocationId(),
			type: 'local',
			skillId: ReportFilters.comingDueFilters[2].data[0].skillId,
			groups: `${ReportFilters.departmentFilter.data[0].educationGroupId}`,
			alertDays: ReportFilters.comingDueFilters[1].data[0].value,
		};
	}
	public getSkillPendingApprovalParams(): SkillsPendingApprovalParams {
		return {
			userId: this.localData.getUserId(),
			locationId: this.localData.getLocationId(),
			skillId: ReportFilters.comingDueFilters[2].data[0].skillId,
			groups: `${ReportFilters.departmentFilter.data[0].educationGroupId}`,
		};
	}
	public getUtilizationReportParams(): UtilizationReporParams {
		return {
			locationId: this.localData.getLocationId(),
			userId: this.localData.getUserId(),
			groups: `${ReportFilters.departmentFilter.data[0].educationGroupId}`,
			educationalYear: new Date().getFullYear(),
			educationReport:
				ReportFilters.utilizationFilters?.dropdowns[2]?.data[0].id,
		};
	}
	public getMonthlyReportParams(): MonthlySummaryReportParams {
		return {
			userId: this.localData.getUserId(),
			locationId: this.localData.getLocationId(),
			groups: `${ReportFilters.departmentFilter.data[0].educationGroupId}`,
			educationYear: new Date().getFullYear(),
			month: new Date().getMonth() + 1,
			status: ReportFilters.courseStatusFilter.data[1].id,
		};
	}
	public getUserEducationStatusParams(): EduactionStatusParams {
		return {
			userGroups: `${ReportFilters.departmentFilter.data[0].educationGroupId}`,
			completedStatus: ReportFilters.userEducationFilters[1]?.data[0].id,
			reportingCategory:
				ReportFilters.userEducationFilters[2]?.data[0].id,
			educationReport: ReportFilters.userEducationFilters[3]?.data[0].id,
			userId: 0,
			locationId: this.localData.getLocationId(),
			educationalYear: this.today.getFullYear(),
			includePrevYearIncomplete: 0,
		};
	}
	public getEducationHistoryParams(): UserEducationHistoryParams {
		return {
			userId: 0,
			locationId: this.localData.getLocationId(),
			fromDate: `${this.today.getFullYear()}-01-01`,
			toDate: `${this.today.getFullYear()}-12-31`,
			// activeOrInactive: '',
			active: `${ReportFilters.userEducationHistoryDetailFilters.dropdowns[0].data[0].id}`,
			sortBy: 'name',
			orderBy: 'ASC',
			type: `${ReportFilters.userEducationHistoryDetailFilters.dropdowns[1].data[0].id}`,
		};
	}
	public getCourseListParams(): CourseListParams {
		return {
			userId: this.localData.getUserId(),
			language: 1,
			locationId: this.localData.getLocationId(),
			activeOrInactive: `${ReportFilters.coursesFilter[0].data[0].id}`,
		};
	}
	public getCourseDetailReportParams(): CourseDetailParams {
		return {
			active: ReportFilters.coursesDetailsFilter.dropdowns[5].data[0].id,
			beforeDate: '',
			courseId: 0,
			educationalGroups: `${ReportFilters.departmentFilter.data[0].educationGroupId}`,
			educationalYear: this.today.getFullYear(),
			fromDate: `${this.today.getFullYear()}-01-01`,
			required:
				ReportFilters.coursesDetailsFilter.dropdowns[3].data[0].id,
			status: ReportFilters.coursesDetailsFilter.dropdowns[2].data[0].id,
			supervisor: ReportFilters.supervisorFilter.data[0].userId,
			toDate: this.localData.getReportFormattedDate(this.today),
			locationId: this.localData.getLocationId(),
			learningPathId: 0,
			liveEventId: 0,
			loggedInuser: this.localData.getUserId(),
		};
	}

	public getUserSummaryParams(): UserSummaryReportParams {
		const previousMonth = this.localData.getToday();
		const currentMonth = this.localData.getToday();
		previousMonth.setMonth(previousMonth.getMonth() - 1);
		previousMonth.setDate(1);
		currentMonth.setDate(1);
		return {
			checkCompletions: 0,
			completedFrom: this.localData.getReportFormattedDate(previousMonth),
			completedTo: this.localData.getReportFormattedDate(currentMonth),
			completion: 'all',
			educationalGroup: '0',
			educationalYear: this.today.getFullYear(),
			includeLiveEvents: 1,
			includeOptional: 0,
			requiredFrom: this.localData.getReportFormattedDate(
				new Date(this.today.getFullYear(), 0, 1)
			),
			requiredTo: this.localData.getReportFormattedDate(
				new Date(this.today.getFullYear(), 11, 31)
			),
			status: 'active',
			userId: this.localData.getUserId(),
			locationId: this.localData.getLocationId(),
			includeExempt: 0,
		};
	}
	public getRequiredCompletionParams(): RequiredEducationReportParams {
		return {
			locationId: this.localData.getLocationId(),
			userId: this.localData.getUserId(),
			educationalGroups: '0',
			educationalYear: this.today.getFullYear(),
			reportingCategory: 0,
			supervisor: 0,
			timeFrame: 0,
			showDatesInGrid: 0,
			includeInactive: 0,
			includeTrigger: 0,
			orientation: 0,
			coursesList: [],
			lpList: [],
			liveEvents: [],
		};
	}
	public getRequiredReportParams(): RequiredReportParams {
		return {
			locationId: this.localData.getLocationId(),
			educationalGroups: '0',
			educationalYear: this.today.getFullYear(),
			reportingCategory: 0,
			supervisor: 0,
			timeFrame: 0,
			includeInactive: 0,
			removedDepartments: 0,
			lang: 0,
			module: 0,
			sortBy: 'title',
		};
	}
	public getUserAssignmentParams(): UserEducationAssignmentParams {
		return {
			activeUsers: 'active',
			educationalYear: this.today.getFullYear(),
			userId: this.localData.getUserId(),
			locationId: this.localData.getLocationId(),
			timeFrame: 0,
			lang: 0,
			module: 0,
		};
	}
	public getRequiredEducationHistoryParams(): RequiredEducationHistoryParams {
		return {
			locationId: this.localData.getLocationId(),
			educationYear: this.today.getFullYear(),
			groups: '0',
			includeAllStaff: 0,
			showRemovedDepartment: 0,
		};
	}
	public getTestSummaryReportParams(): TestSummaryParams {
		return {
			locationId: this.localData.getLocationId(),
			required: 'required',
			courseId: 0,
			fromDate: moment(new Date(this.today.getFullYear(), 0, 1)).format(
				'YYYY-MM-DD'
			),
			educationYear: 0,
			toDate: moment(this.today).format('YYYY-MM-DD'),
			learningPathId: 0,
			includeAnswers: 0,
			courseTestId: 0,
		};
	}
	get programEvaluationParams(): ProgramEvaluationParams {
		return {
			locationId: this.localData.getLocationId(),
			courseId: 0,
			fromDate: moment(new Date(this.today.getFullYear(), 0, 1)).format(
				'YYYY-MM-DD'
			),
			toDate: moment(new Date(this.today)).format('YYYY-MM-DD'),
		};
	}
	public loginHistoryReportParams() {
		return {
			educationalYear: this.today.getFullYear(),
			userId: 0,
			fromDate: '',
			toDate: '',
		};
	}
}
