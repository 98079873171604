import { AppConstants } from 'src/app/shared/constants/app-constants';
import { FilterDatepicker } from './_models/reports-overview.model';
import {
	CompletionStatus,
	FilterCheckBox,
	FilterContent,
} from './_models/user-dashboard-report.model';

export class ReportFilters {
	static departmentFilter: FilterContent = {
		data: [
			{
				educationGroupId: 0,
				educationGroupName: 'SHOW_ALL',
			},
		],
		label: 'educationGroupName',
		value: 'educationGroupId',
		showLabel: 'DEPARTMENTS',
		formControlName: 'departments',
		multiple: true,
		multiCheckbox: true,
	};
	static userStatusFilter: FilterContent = {
		label: 'name',
		data: [
			{
				id: 'both',
				name: 'SHOW_ALL',
			},
			{
				id: 'active',
				name: 'ACTIVE_USERS',
			},
			{
				id: 'inactive',
				name: 'INACTIVE_USERS',
			},
		],
		value: 'id',
		showLabel: 'USER_STATUS',
		multiple: false,
		formControlName: 'userStatus',
	};
	static supervisorFilter: FilterContent = {
		data: [
			{
				userId: 0,
				fullName: 'SHOW_ALL',
			},
		],
		label: 'fullName',
		value: 'userId',
		showLabel: 'SUPERVISOR',
		formControlName: 'supervisor',
		multiple: false,
	};
	static educationYearFilter: FilterContent = {
		data: [
			+new Date().getFullYear() - 3,
			+new Date().getFullYear() - 2,
			+new Date().getFullYear() - 1,
			+new Date().getFullYear(),
			+new Date().getFullYear() + 1,
			+new Date().getFullYear() + 2,
			+new Date().getFullYear() + 3,
		],
		label: '',
		value: '',
		showLabel: 'EDUCATION_YEAR',
		formControlName: 'educationYear',
		multiple: false,
	};
	static courseStatusFilter: FilterContent = {
		label: 'name',
		value: 'id',
		data: [
			{
				id: 'both',
				name: 'SHOW_ALL',
			},
			{
				id: 'active',
				name: 'ACTIVE_COURSES',
			},
			{
				id: 'inactive',
				name: 'INACTIVE_COURSES',
			},
		],
		multiple: false,
		showLabel: 'COURSE_STATUS',
		formControlName: 'courseStatus',
	};
	static surveyFilter: FilterContent = {
		showLabel: 'SURVEY_NAME',
		formControlName: 'surveyId',
		data: [],
		multiple: false,
		value: 'surveyId',
		label: 'surveyName',
		placeholder: 'SELECT_SURVEY',
	};
	static surveyCategoryFilter: FilterContent = {
		showLabel: 'CATEGORY_NAME',
		formControlName: 'surveyCategoryId',
		data: [],
		multiple: false,
		value: 'surveyCategoryId',
		label: 'categoryName',
		placeholder: 'SELECT_CATEGORY',
	};
	static timeFrameFilter: FilterContent = {
		label: 'name',
		data: [
			{
				id: 0,
				name: 'WHOLE_YEAR',
			},
			{
				id: 1,
				name: 'JANUARY',
			},
			{
				id: 2,
				name: 'FEBRUARY',
			},
			{
				id: 3,
				name: 'MARCH',
			},
			{
				id: 4,
				name: 'APRIL',
			},
			{
				id: 5,
				name: 'MAY',
			},
			{
				id: 6,
				name: 'JUNE',
			},

			{
				id: 7,
				name: 'JULY',
			},
			{
				id: 8,
				name: 'AUGUST',
			},
			{
				id: 9,
				name: 'SEPTEMBER',
			},
			{
				id: 10,
				name: 'OCTOBER',
			},
			{
				id: 11,
				name: 'NOVEMBER',
			},
			{
				id: 12,
				name: 'DECEMBER',
			},
		],
		showLabel: 'TIME_FRAME',
		value: 'id',
		formControlName: 'timeFrame',
		multiple: false,
	};
	static sortBy: FilterContent = {
		label: 'name',
		data: [
			{
				id: 'title',
				name: 'TITLE',
			},
			{
				id: 'dueDate',
				name: 'DUE_DATE',
			},
		],
		showLabel: 'SORT_BY',
		value: 'id',
		formControlName: 'sortBy',
		multiple: false,
	};
	static reportingGroupFilter: FilterContent = {
		data: [
			{
				id: 0,
				name: 'SHOW_ALL',
			},
		],
		label: 'name',
		value: 'id',
		showLabel: 'REPORTING_GROUPS',
		formControlName: 'requiredCategory',
		multiple: false,
	};
	private static requiredFilter: FilterContent = {
		label: 'name',
		data: [
			{
				id: 'required',
				name: 'SHOW_REQUIRED_USERS',
			},
			{
				id: 'all',
				name: 'SHOW_ALL',
			},
		],
		showLabel: 'Required',
		value: 'id',
		formControlName: 'required',
		multiple: false,
	};
	private static educationReportFilter: FilterContent = {
		data: [
			{
				id: 0,
				name: 'SHOW_ALL',
			},
			{
				id: 1,
				name: 'ANNUAL_EDUCATION',
			},
			{
				id: 2,
				name: 'ORIENTATION_EDUCATION',
			},
		],
		label: 'name',
		value: 'id',
		// showLabel: 'EDUCATION_REPORT',
		showLabel: 'EDUCATION_TYPE',
		formControlName: 'educationReport',
		multiple: false,
	};
	private static completionStatusFilter: FilterContent = {
		label: 'name',
		data: [
			{
				id: 'active',
				name: 'ACTIVE_USERS_ONLY',
			},
			{
				id: 'beforeDate',
				name: 'ACTIVE_USERS_BEFORE_DATE',
			},
			{
				id: 'inactive',
				name: 'INACTIVE_USERS_ONLY',
			},
			{
				id: 'all',
				name: 'SHOW_ALL',
			},
		],
		showLabel: 'Active',
		value: 'id',
		formControlName: 'active',
		multiple: false,
	};
	public static completedStatusList: Array<CompletionStatus> = [
		{
			name: 'SHOW_ALL',
			id: -1,
		},
		{
			name: 'ALL_COURSES_COMPLETED',
			id: 1,
		},
		{
			name: 'COURSES_ARE_OVERDUE',
			id: 2,
		},
		{
			name: 'COURSES_OUTSTANDING',
			id: 3,
		},
		{
			name: 'NO_COURSES_COMPLETED',
			id: 4,
		},
	];
	public static userDashboardFilters: Array<FilterContent> = [
		{
			data: this.completedStatusList,
			label: 'name',
			showLabel: 'COMPLETION_STATUS',
			value: 'id',
			formControlName: 'completedStatus',
			multiple: false,
		},
		{ ...this.departmentFilter, formControlName: 'userGroups' },
		{ ...this.educationYearFilter, formControlName: 'educationalYear' },
		{
			data: [
				{
					id: -1,
					name: 'SHOW_ALL',
				},
				{
					id: 1,
					name: 'ORIENTATION_YES',
				},
				{
					id: 0,
					name: 'ORIENTATION_NO',
				},
			],
			label: 'name',
			value: 'id',
			showLabel: 'ORIENTATION_MODE',
			formControlName: 'orientationMode',
			multiple: false,
		},
		{ ...this.supervisorFilter, formControlName: 'supervisorId' },
	];
	public static loginHistoryReportFilters: Array<FilterDatepicker> = [
		{
			label: 'FROM_DATE',
			formControlName: 'fromDate',
			// options: {
			// 	minDate: {
			// 		year: new Date().getFullYear(),
			// 		month: 1,
			// 		day: 1,
			// 	},
			// 	maxDate: {
			// 		year: new Date().getFullYear(),
			// 		month: 12,
			// 		day: 31,
			// 	},
			// },
		},
		{
			label: 'TO_DATE',
			formControlName: 'toDate',
			// options: {
			// 	minDate: {
			// 		year: new Date().getFullYear(),
			// 		month: 1,
			// 		day: 1,
			// 	},
			// 	maxDate: {
			// 		year: new Date().getFullYear(),
			// 		month: 12,
			// 		day: 31,
			// 	},
			// },
		},
	];

	public static userSummaryFilters: Array<FilterContent> = [
		{ ...this.departmentFilter, formControlName: 'userGroups' },
	];

	public static userEducationFilters: Array<FilterContent> = [
		this.educationYearFilter,
		{
			data: [
				{
					id: 0,
					name: 'SHOW_ALL',
				},
				{
					id: 1,
					name: 'COMPLETED',
				},
				{
					id: 2,
					name: 'NOT_COMPLETED',
				},
			],
			label: 'name',
			value: 'id',
			showLabel: 'COMPLETION_STATUS',
			formControlName: 'completionStatus',
			multiple: false,
		},
		this.reportingGroupFilter,
		this.educationReportFilter,
		this.departmentFilter,
	];
	public static userEducationStatusFilters: Array<FilterContent> = [
		this.userStatusFilter,
	];
	public static userEducationSummaryFilters: {
		dropdowns: Array<FilterContent>;
		datePickers: Array<FilterDatepicker>;
		checkboxes: FilterCheckBox[];
	} = {
		dropdowns: [
			this.userStatusFilter,
			this.departmentFilter,
			this.educationYearFilter,
			{
				label: 'name',
				data: [
					{
						id: 'all',
						name: 'SHOW_ALL',
					},
					{
						id: 'complete',
						name: 'COMPLETED',
					},
					{
						id: 'notcomplete',
						name: 'NOT_COMPLETED',
					},
				],
				showLabel: 'COMPLETION',
				value: 'id',
				formControlName: 'completion',
				multiple: false,
			},
		],
		datePickers: [
			{
				label: 'COMPLETED_FROM',
				formControlName: 'completedFrom',
			},
			{
				label: 'COMPLETED_TO',
				formControlName: 'completedTo',
			},
			{
				label: 'REQUIRED_FROM',
				formControlName: 'requiredFrom',
				options: {
					minDate: {
						year: new Date().getFullYear(),
						month: 1,
						day: 1,
					},
					maxDate: {
						year: new Date().getFullYear(),
						month: 12,
						day: 31,
					},
				},
			},
			{
				label: 'REQUIRED_TO',
				formControlName: 'requiredTo',
			},
		],
		checkboxes: [
			{
				label: 'INCLUDE_TRIGGER',
				formControlName: 'includeTrigger',
			},
			{
				label: 'INCLUDE_OPTIONAL',
				formControlName: 'includeOptional',
			},
			{
				label: 'INCLUDE_LIVE_EVENTS',
				formControlName: 'includeLiveEvents',
			},
			{
				label: 'INCLUDE_EXEMPTED',
				formControlName: 'includeExempted',
			},
		],
	};
	public static monthlyEducationFilters: Array<FilterContent> = [
		{
			label: 'name',
			formControlName: 'month',
			data: AppConstants.months,
			showLabel: 'MONTH',
			value: 'id',
			multiple: false,
		},
		this.educationYearFilter,
		this.departmentFilter,
		this.courseStatusFilter,
	];
	static coursesFilter: FilterContent[] = [
		{
			formControlName: 'courseStatus',
			label: 'value',
			value: 'id',
			showLabel: 'COURSE_STATUS',
			multiple: false,
			data: [
				{
					id: 'active',
					value: 'ACTIVE_COURSES',
				},
				{
					id: 'inactive',
					value: 'INACTIVE_COURSES',
				},
				{
					id: 'hidden',
					value: 'RETIRED_COURSES',
				},
			],
		},
	];
	public static userEducationHistoryDetailFilters: {
		dropdowns: Array<FilterContent>;
		datePickers: Array<FilterDatepicker>;
	} = {
		dropdowns: [
			this.courseStatusFilter,
			{
				label: 'name',
				value: 'id',
				showLabel: 'TYPE',
				multiple: false,
				formControlName: 'resourceType',
				data: [
					{
						id: 'all',
						name: 'SHOW_ALL',
					},
					{
						id: 'liveevents',
						name: 'LIVE_EVENTS',
					},
					{
						id: 'required',
						name: 'REQUIRED',
					},
					{
						id: 'optional',
						name: 'OPTIONAL',
					},
					{
						id: 'recommended',
						name: 'RECOMMENDED',
					},
				],
			},
		],
		datePickers: [
			{
				label: 'FROM',
				formControlName: 'completedFrom',
			},
			{
				label: 'TO',
				formControlName: 'completedTo',
			},
		],
	};
	public static coursesDetailsFilter: {
		dropdowns: Array<FilterContent>;
		datePickers: Array<FilterDatepicker>;
	} = {
		dropdowns: [
			this.supervisorFilter,
			this.departmentFilter,
			{
				label: 'name',
				data: [
					{
						id: 'complete',
						name: 'COMPLETED',
					},
					{
						id: 'notcomplete',
						name: 'NOT_COMPLETED',
					},
					{
						id: 'all',
						name: 'SHOW_ALL',
					},
				],
				showLabel: 'Status',
				value: 'id',
				formControlName: 'status',
				multiple: false,
			},
			this.requiredFilter,
			this.educationYearFilter,
			this.completionStatusFilter,
		],
		datePickers: [
			{
				label: 'COMPLETED_FROM',
				formControlName: 'completedFrom',
			},
			{
				label: 'COMPLETED_TO',
				formControlName: 'completedTo',
			},
		],
		// Removed excel formatting as suggested by Elmer
	};
	public static requiredEducationFilter: {
		dropdowns: Array<FilterContent>;
		checkboxes: FilterCheckBox[];
	} = {
		dropdowns: [
			this.departmentFilter,
			this.reportingGroupFilter,
			this.educationYearFilter,
			this.timeFrameFilter,
			this.educationReportFilter,
		],
		checkboxes: [
			{
				label: 'INCLUDE_INACTIVE_ARCHIVED_COURSES',
				formControlName: 'includeCourses',
			},
			{
				label: 'INCLUDE_TRIGGER',
				formControlName: 'includeTrigger',
			},
			{
				label: 'SHOW_ALL_COURSES_LPS',
				formControlName: 'showCourses',
			},
		],
	};
	public static skillsSummaryReportFilters: FilterContent[] = [
		{
			multiple: true,
			showLabel: 'SKILLS',
			label: 'skillName',
			value: 'id',
			data: [
				{
					id: 0,
					skillName: 'SHOW_ALL',
				},
			],
			formControlName: 'skills',
		},
		this.departmentFilter,
		this.supervisorFilter,
		this.userStatusFilter,
		{
			label: 'label',
			formControlName: 'userSkills',
			showLabel: 'SKILL_STATUS',
			value: 'id',
			data: [
				{
					id: 'all',
					label: 'SHOW_ALL',
				},
				{
					id: 'approved',
					label: 'APPROVED',
				},
				{
					id: 'pendingApproval',
					label: 'PENDING_APPROVAL',
				},
				{
					id: 'pendingRenewal',
					label: 'PENDING_RENEWAL',
				},
				{
					id: 'expired',
					label: 'EXPIRED',
				},
			],
			multiple: false,
		},
		{
			label: 'label',
			formControlName: 'userSkillstatus',
			showLabel: 'USER_SKILLS',
			value: 'id',
			data: [
				{
					id: 'all',
					label: 'SHOW_ALL',
				},
				{
					id: 'withSkill',
					label: 'USERS_WITH_SKILL',
				},
				{
					id: 'withoutSkill',
					label: 'USERS_WITHOUT_SKILL',
				},
			],
			multiple: false,
		},
	];
	static skillsFilter: FilterContent = {
		showLabel: 'SKILLS',
		multiple: false,
		label: 'skillName',
		value: 'skillId',
		formControlName: 'skill',
		data: [
			{
				skillId: 0,
				skillName: 'SHOW_ALL',
			},
		],
	};
	public static comingDueFilters: FilterContent[] = [
		this.departmentFilter,
		{
			showLabel: 'RENEW_DAYS_AWAY',
			multiple: false,
			label: 'label',
			value: 'value',
			formControlName: 'renewDays',
			data: [
				{
					label: 'TODAY_BEFORE',
					value: 0,
					displayValue: 0,
				},
				{
					label: 'MONTHS',
					value: 3,
					displayValue: 3,
				},
				{
					label: 'MONTHS',
					value: 6,
					displayValue: 6,
				},
				{
					label: 'YEAR',
					value: 12,
					displayValue: 1,
				},
			],
		},
		this.skillsFilter,
	];
	public static pendingApprovalFilters: FilterContent[] = [
		this.departmentFilter,
		this.skillsFilter,
	];
	public static surveySummaryFilters: {
		dropdowns: FilterContent[];
		checkboxes: FilterCheckBox[];
	} = {
		dropdowns: [
			{ ...this.surveyCategoryFilter, isMandatory: true },
			{ ...this.surveyFilter, isMandatory: true },
			{
				showLabel: 'HOME',
				formControlName: 'location',
				data: [
					{
						id: 0,
						name: 'SHOW_ALL',
					},
				],
				multiple: false,
				value: 'id',
				label: 'name',
			},
		],
		checkboxes: [
			{
				label: 'INCLUDE_COMMENTS',
				formControlName: 'includeComments',
			},
		],
	};

	public static requiredReportFilter: {
		dropdowns: Array<FilterContent>;
		checkboxes: FilterCheckBox[];
	} = {
		dropdowns: [
			this.departmentFilter,
			this.reportingGroupFilter,
			this.timeFrameFilter,
			this.educationYearFilter,
			this.sortBy,
		],
		checkboxes: [
			{
				label: 'SHOW_REMOVED_DEPARTMENTS',
				formControlName: 'showRemovedDepartments',
			},
			{
				label: 'INCLUDE_INACTIVE_ARCHIVED_COURSES',
				formControlName: 'includeInactive',
			},
		],
	};
	public static individualSurveyFilters: {
		dropdowns: Array<FilterContent>;
		checkboxes: FilterCheckBox[];
	} = {
		dropdowns: [
			{ ...this.surveyCategoryFilter, isMandatory: true },
			{ ...this.surveyFilter, isMandatory: true },
			{
				showLabel: 'STATUS',
				formControlName: 'status',
				label: 'status',
				multiple: false,
				data: [
					{
						id: 0,
						status: 'SHOW_ALL',
					},
					{
						id: 1,
						status: 'SUBMITTED',
					},
					{
						id: 2,
						status: 'NOT_SUBMITTED',
					},
				],
				value: 'id',
				isMandatory: true,
			},
			{
				showLabel: 'HOME',
				formControlName: 'location',
				data: [],
				multiple: false,
				value: 'id',
				label: 'name',
			},
		],
		checkboxes: [],
	};
	// public static utilizationFilters: FilterContent[] = [
	// 	this.departmentFilter,
	// 	this.educationYearFilter,

	// ];
	public static utilizationFilters: {
		dropdowns: Array<FilterContent>;
		checkboxes: FilterCheckBox[];
	} = {
		dropdowns: [
			this.departmentFilter,
			this.educationYearFilter,
			this.educationReportFilter,
		],
		checkboxes: [
			{
				label: 'INCLUDE_TRIGGER',
				formControlName: 'includeTrigger',
			},
		],
	};
	public static programEvaluationFilters: {
		dropdowns: FilterContent[];
		datePicker: FilterDatepicker[];
	} = {
		dropdowns: [
			{
				formControlName: 'courseId',
				label: 'name',
				data: [
					{
						id: 0,
						name: 'INCLUDE_ALL',
					},
				],
				showLabel: 'COURSE',
				multiple: false,
				value: 'id',
			},
		],
		datePicker: [
			{
				label: 'FROM_DATE',
				formControlName: 'fromDate',
			},
			{
				label: 'TO_DATE',
				formControlName: 'toDate',
			},
		],
	};
	public static courseHistoryReportFilters: {
		dropdowns: FilterContent[];
		checkboxes: FilterCheckBox[];
	} = {
		dropdowns: [this.departmentFilter, this.educationYearFilter],
		checkboxes: [
			{
				formControlName: 'includeAllStaffs',
				label: 'INCLUDE_ALL_STAFF',
			},
			{
				formControlName: 'showRemovedDeps',
				label: 'SHOW_REMOVED_DEPARTMENTS',
			},
		],
	};
	public static enterpriseRequiredFilters: FilterContent[] = [
		this.departmentFilter,
		this.educationYearFilter,
	];
	public static enterpriseRequiredCompletionFilters: FilterContent[] = [
		this.reportingGroupFilter,
		this.timeFrameFilter,
		this.educationYearFilter,
	];
	public static enterpriseCourseCompletionFilters: FilterContent[] = [
		{
			formControlName: 'courseStatus',
			label: 'status',
			value: 'id',
			showLabel: 'COURSE_STATUS',
			multiple: false,
			data: [
				{
					id: 'active',
					status: 'ACTIVE_COURSES',
				},
				{
					id: 'inactive',
					status: 'INACTIVE_COURSES',
				},
				{
					id: 'retired',
					status: 'RETIRED_COURSES',
				},
			],
		},
	];
	public static enterpriseCourseCompletionDetailFilters: {
		dropdowns: FilterContent[];
		datePickers: FilterDatepicker[];
	} = {
		dropdowns: [
			this.requiredFilter,
			this.educationYearFilter,
			this.departmentFilter,
			this.completionStatusFilter,
		],
		datePickers: [
			{
				label: 'COMPLETED_FROM',
				formControlName: 'completedFrom',
			},
			{
				label: 'COMPLETED_TO',
				formControlName: 'completedTo',
			},
		],
	};
	public static testSummaryFilters: {
		datePickers: FilterDatepicker[];
		checkboxes: FilterCheckBox[];
	} = {
		datePickers: [
			{
				formControlName: 'fromDate',
				label: 'FROM',
			},
			{
				formControlName: 'toDate',
				label: 'TO',
			},
		],
		checkboxes: [
			{ label: 'INCLUDE_TEXT_ANSWERS', formControlName: 'includeText' },
		],
	};
	public static userAssignmentFilters: FilterContent[] = [
		this.timeFrameFilter,
		this.userStatusFilter,
		this.educationYearFilter,
	];
	public static enterpriseMultipCourseCompletionFilters: {
		dropdowns: FilterContent[];
		datePickers: FilterDatepicker[];
	} = {
		dropdowns: [
			{
				showLabel: 'LOCATION',
				formControlName: 'location',
				data: [
					{
						id: 0,
						name: 'SHOW_ALL',
					},
				],
				multiple: true,
				value: 'id',
				label: 'name',
			},
			{
				showLabel: 'CATEGORIES',
				formControlName: 'categoryIds',
				data: [],
				multiple: true,
				value: 'id',
				label: 'name',
				placeholder: 'SELECT_CATEGORIES',
			},
			{
				showLabel: 'COURSES',
				formControlName: 'courseIds',
				data: [],
				multiple: true,
				value: 'id',
				label: 'name',
				placeholder: 'SELECT_COURSES',
			},
			{
				showLabel: 'EDUCATION_GROUPS',
				formControlName: 'groupIds',
				data: [],
				multiple: true,
				value: 'id',
				label: 'name',
				placeholder: 'SELECT_GROUPS',
			},
			{
				showLabel: 'USERS',
				formControlName: 'userIds',
				data: [],
				multiple: true,
				value: 'id',
				label: 'name',
				placeholder: 'SELECT_USERS',
			},
		],
		datePickers: [
			{
				formControlName: 'fromDate',
				label: 'FROM',
			},
			{
				formControlName: 'toDate',
				label: 'TO',
			},
		],
	};
}
