<div class="filter-container">
	<div class="row align-items-center">
		<div>
			<ul ngbNav #nav="ngbNav" class="nav-pills toggle-tabs">
				<li *ngFor="let item of data" ngbNavItem (click)="change(item)">
					<a ngbNavLink>{{ item.name | translate }}</a>
				</li>
			</ul>
		</div>
	</div>
</div>