import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
  selector: 'slr-input-gray',
  templateUrl: './slr-input-gray.component.html',
  styleUrls: ['./slr-input-gray.component.scss'],
})
export class SlrInputGrayComponent {
  @Input() public placeholder: string = '';
  @Input() public width: string = '100%';
  @Input() public value: string = '';
  @Input() public label: string | undefined;
  @Input() public required: boolean = false;
}
