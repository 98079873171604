import { Component, Optional, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'slr-rbtn01',
	templateUrl: './slr-rbtn01.component.html',
	styleUrls: ['./slr-rbtn01.component.scss'],
})
export class SlrRbtn01Component {
	@Input() public value: string;
	@Input() public disabled: boolean;
	@Input() public checked: boolean;
	@Input() public label: string;
	@Optional() @Input() public image: string = undefined

	@Output() public onChange: EventEmitter<boolean>;
	public selected: boolean = false;
	constructor() {
		this.onChange = new EventEmitter<boolean>();
	}

	public change(): void {
		this.onChange.emit(this.selected);
	}
}
