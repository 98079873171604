import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { SurveyService } from 'src/app/modules/survey/_services/survey.service';
import { ApiUrlService } from 'src/app/core/services/api-url.service';
import { NotificationService } from 'src/app/core/services/notification.service';
@Component({
	selector: 'app-deletesurveypopup',
	templateUrl: './deletesurveypopup.component.html',
	styleUrls: ['./deletesurveypopup.component.scss'],
})
export class DeletesurveypopupComponent implements OnInit {
	public selectedSurvey = null;
	constructor(
		private dialogRef: MatDialogRef<DeletesurveypopupComponent>,
		private surveyService: SurveyService,
		public ApiService: ApiUrlService,
		private notificationService: NotificationService
	) {}

	ngOnInit(): void {
		this.selectedSurvey = this.surveyService.deleteobj;
	}
	close() {
		this.dialogRef.close();
	}
	delete() {
		this.ApiService.sendDeleteRequest(
			`/api/survey/v1/${this.surveyService.deleteobj.surveyId}`
		)
			.pipe()
			.subscribe({
				next: (res) => {
					///this.broadcastservice.searchdataonbroadcast();
					this.dialogRef.close();
					this.notificationService.success('Deleted successfully');
				},
			});
	}
}
