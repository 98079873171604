<mat-form-field [formGroup]="fgName" appearance="outline" [style.width]="width">
	<mat-label *ngIf="label">{{ label | translate }}</mat-label>
	<mat-icon matPrefix *ngIf="icon && iconAling === 'prefix'">{{
		icon
	}}</mat-icon>
	<input
		matInput
		type="text"
		[(ngModel)]="selectedTime"
		(ngModelChange)="validateTime()"
		[placeholder]="placeholder | translate"
		[formControlName]="controlName"
	/>
	<mat-icon
		matSuffix
		[matMenuTriggerFor]="timeMenu"
		*ngIf="icon && iconAling === 'suffix'"
		>{{ icon }}</mat-icon
	>
	<mat-menu class="slr-time-input" #timeMenu="matMenu">
		<div class="time-popup">
			<div
				*ngFor="let entry of timeEntries"
				[id]="entry === nearSelectedTime ? 'selectedTime' : ''"
				[class.active]="entry === nearSelectedTime"
				(click)="updateTime(entry)"
				class="time-entry"
			>
				{{ entry }}
			</div>
		</div>
	</mat-menu>
	<mat-error *ngIf="fgName.get(controlName).invalid || showError">{{
		errorMessage
	}}</mat-error>
</mat-form-field>
