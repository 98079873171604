import { Injectable } from '@angular/core';
import { LocalDataService } from './local-data.service';
import { UrlNames } from './urlProfiler';
import { BaseWebApiService } from './_base-web-api.service';
import { LocalStorageKey } from '../models/local-storage-key.enum';

@Injectable({
	providedIn: 'root',
})
export class HelpSystemService {
	language =
		localStorage.getItem(LocalStorageKey.LANGUAGE) == 'en'
			? 'en-us'
			: localStorage.getItem(LocalStorageKey.LANGUAGE);
	constructor(
		private apiService: BaseWebApiService,
		private localDataService: LocalDataService
	) {}

	public helpSystem(label: string) {
		return this.apiService.get(
			`${UrlNames.helpSystemArticles}${UrlNames.searchArticle}`,
			{ query: label, locale: this.language },
			'HelpSystem'
		);
	}

	public getArticleBasedOnLabels(label) {
		return this.apiService.get(
			`/${this.language}${UrlNames.articlesJson}`,
			{ label_names: label },
			'HelpSystem'
		);
	}

	public getArticleAttachments(articleId: number) {
		return this.apiService.get(
			`${UrlNames.helpSystemArticles}/${articleId}/attachments`,
			null,
			'HelpSystem'
		);
	}

	public getNotificationCounts() {
		return this.apiService.get(`${UrlNames.notificationCounts}`, {
			locationId: this.localDataService.getLocationId(),
			userId: this.localDataService.getUserId(),
		});
	}

	public getNotifications() {
		return this.apiService.get(`${UrlNames.notifications}`, {
			locationId: this.localDataService.getLocationId(),
			userId: this.localDataService.getUserId(),
		});
	}

	public unSubscribeFeeds(payload: any) {
		return this.apiService.post(`${UrlNames.unsubscribe}`, payload);
	}
}
