<div class="con">
	<div class="row flex-grow-1 g-0 conversations">
		<div class="col-12">
			<ng-container
				*ngIf="(threads$ | async)?.length > 0; else noMessageView"
			>
				<ng-container *ngFor="let thread of threads$ | async">
					<app-thread-card
						[conversation]="thread"
						[isOnPopup]="true"
					></app-thread-card>
				</ng-container>
			</ng-container>
			<ng-template #noMessageView>
				<div class="no-message">{{ "NO_MESSAGES" | translate }}</div>
			</ng-template>
		</div>
	</div>
	<div
		isAuthorised
		[matTooltip]="'ADD_NEW_MESSAGE' | translate"
		[action]="permissionHelper.dashboardModule.DASHBOARD_ADD_NEW_MESSAGE"
		(click)="newMessage()"
		class="new-message"
	>
		<mat-icon>edit</mat-icon>
	</div>
</div>
