<div class="slr-card card mt-2 questions mobile">
	<div class="card-header">
		<h6 class="color-gray-7 d-flex">
			<span class="color-blue-2 me-2">Q{{ index }}:</span>
			<!-- {{ question.question }} -->
			<div [innerHtml]="question.question"></div>
		</h6>
		<ng-container *ngIf="question.questionImageUrl">
			<img [src]="questionImageS3String | async" alt="" />
		</ng-container>
	</div>
	<div
		[class]="'card-body ' + question.type"
		*ngIf="!showTestAnswers && !showSurveyAnswers"
	>
		<ng-container *ngIf="question.type === 'mcq' && question.options">
			<!-- <slr-rbtn-group
				[align]="'vertical'"
				[data]="options"
				(onChange)="updateMCQAnswer($event)"
			></slr-rbtn-group> -->
			<mat-radio-group
				(change)="updateMCQAnswer($event)"
				aria-label="Select an option"
				class="d-inline-flex gap-2 flex-column"
			>
				<ng-container *ngFor="let entry of options">
					<mat-radio-button
						[checked]="
							entry.data?.selected == true ||
							entry.selected == true
						"
						[disabled]="entry.data?.disabled == true"
						[value]="entry.id"
						><div [innerHtml]="entry.name"></div>
					</mat-radio-button>
				</ng-container>
			</mat-radio-group>
			<!-- <ng-container *ngFor="let option of options">
				<mat-radio-button (onChange)="updateMCQAnswer(option)">
					<div [innerHtml]="option.name"></div>
				</mat-radio-button>
			</ng-container> -->
		</ng-container>
		<ng-container *ngIf="question.type === 'multiple'">
			<mat-checkbox
				[disabled]="keepDisabled"
				(change)="updateMultipleAnswer(option, $event.checked)"
				*ngFor="let option of options"
			>
				<div [innerHtml]="option.name"></div>
				<ng-container *ngIf="option.data && option.data.image">
					<img [src]="option.data.image.src | async" alt="" />
				</ng-container>
			</mat-checkbox>
		</ng-container>
		<ng-container *ngIf="question.type === 'paragraph'">
			<slr-textarea
				[disabled]="keepDisabled"
				[label]="'Answer'"
				[placeholder]="'Answer'"
				(onChange)="updateParagraphAnswer($event)"
			></slr-textarea>
		</ng-container>
		<ng-container *ngIf="question.type === 'slider'">
			<div
				*ngFor="let option of question.options"
				class="d-flex align-items-center gap-5"
			>
				<p class="mb-0">{{ option.option }}</p>
				<mat-slider
					[disabled]="keepDisabled"
					class="min"
					step="1"
					min="1"
					max="5"
					thumbLabel="true"
					tickInterval="1"
				></mat-slider>
			</div>
		</ng-container>
	</div>

	<div class="card-body" *ngIf="showSurveyAnswers">
		<ng-container *ngFor="let option of options">
			<survey-option
				[option]="option"
				[question]="question"
				[optionType]="question.type"
			></survey-option>
		</ng-container>
	</div>
</div>

<div class="answer-container answerclass" *ngIf="showTestAnswers">
	<!-- <div class="header">
		<h6>Correct Response and Rationale</h6>
		<span
			class="cursor-pointer"
			*ngIf="!addCommentOpen"
			(click)="toggleAddComment()"
			>Add Comment</span
		>
	</div> -->
	<div class="body testbody">
		<ng-container *ngIf="!question.options">
			<app-test-option
				[question]="question"
				[optionType]="question.type"
			></app-test-option>
		</ng-container>
		<app-test-option
			*ngFor="let option of options"
			[question]="question"
			[optionType]="question.type"
			[option]="{
				id: option.id,
				name: option.name,
				data: option.data
			}"
		></app-test-option>
		<div *ngIf="addCommentOpen" class="comment-container commentbody">
			<slr-textarea
				[control]="addCommentControl"
				[placeholder]="'Add Your Comment'"
				[label]="'Add Your Comment'"
				(onChange)="updateComment($event)"
			></slr-textarea>
			<div class="d-flex justify-content-start gap-3 mt-4">
				<slr-btn01 (onClick)="saveQuestionComment()">Submit</slr-btn01>
				<slr-btn04 (onClick)="toggleAddComment()">Cancel</slr-btn04>
			</div>
		</div>
	</div>
</div>
