import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import {
	forkJoin,
	map,
	mergeMap,
	of,
	iif,
	withLatestFrom,
	switchMap,
} from 'rxjs';
import { AppLanguages } from 'src/app/core/models/app-lang.enum';
import { NotificationService } from 'src/app/core/services/notification.service';
import {
	UserProfileBasicDetailAPIInterface,
	UserProfileInterface,
} from '../_models/user-profile-interface';
import { UserProfileService } from '../_services/user-profile.service';
import {
	deleteSkill,
	doSkillAction,
	loadAllSkillsWithFilter,
	// loadAllSkillsSuccess,
	loadUserProfile,
	loadUserProfileSuccess,
	loadUserSkills,
	loadUserSkillsSuccess,
	removeSkill,
	saveTempSkills,
	updateRemoveSkillArray,
} from './user-profile.actions';
import { getUserSkill, UserProfileState } from './user-profile.reducer';

@Injectable()
export class UserProfileEffets {
	constructor(
		private action$: Actions,
		private profileService: UserProfileService,
		private profileStore: Store<UserProfileState>,
		private notificationService: NotificationService,
		private router: Router
	) {}

	loadUserProfile$ = createEffect(() => {
		return this.action$.pipe(
			ofType(loadUserProfile),
			mergeMap(() => {
				return this.profileService.getProfileDetails().pipe(
					map((details: UserProfileBasicDetailAPIInterface) => {
						const profile: UserProfileInterface = {
							basicDetails: {
								displayName: details.displayName,
								educaiontGroups: details.educationGroups,
								email: details.email,
								language:
									details.language ?? AppLanguages.ENGLISH,
								surgeAlerts: details.surgeAlertsCheck,
								surgeCorespondence:
									details.surgeCorrespondenseCheck,
								title: details.title,
								username: `${details.clientCode}.${details.userName}`,
							},
							profilePic: details.picturePath,
						};
						return profile;
					}),
					map((profile) =>
						loadUserProfileSuccess({ profile: profile })
					)
				);
			})
		);
	});

	loadSkills = createEffect(() => {
		return this.action$.pipe(
			ofType(loadUserSkills),
			mergeMap((x) => {
				const all = this.profileService.getAllAvailableSkills(
					undefined,
					x.userId
				);

				const per = this.profileService.getUserSkills(
					x.userId ?? undefined
				);
				return forkJoin([all, per]).pipe(
					map((x) => {
						return loadUserSkillsSuccess({
							skills: x[0],
							userSkills: x[1],
						});
					})
				);
			})
		);
	});

	loadSkillWithFilter$ = createEffect(() => {
		return this.action$.pipe(
			ofType(loadAllSkillsWithFilter),
			map((action) => action),
			withLatestFrom(this.profileStore.pipe(select(getUserSkill))),
			mergeMap(([action, userSkills]) =>
				this.profileService
					.getAllAvailableSkills(action.filter, action.userId)
					.pipe(
						map((x) =>
							loadUserSkillsSuccess({
								skills: x,
								userSkills: userSkills,
							})
						)
					)
			)
		);
	});

	deleteSkill$ = createEffect(
		() => {
			return this.action$.pipe(
				ofType(deleteSkill),
				map((action) => {
					return {
						ids: action.ids,
						userId: action.userId,
					};
				}),
				withLatestFrom(this.profileStore.pipe(select(getUserSkill))),
				mergeMap(([payload, skills]) => {
					// const _skill = skills.find((x) => x.skillId === payload.id);
					// if (_skill && _skill.isTemp) {
					// 	return of(removeSkill({ id: payload.id }));
					// }
					return this.profileService
						.deleteSkill(payload.ids, payload.userId)
						.pipe(
							map((res) => {
								this.notificationService.success(
									payload.ids.length +
										' skill(s) removed successfully.'
								);
								payload.ids.map((id) =>
									removeSkill({ id: id })
								);
								return updateRemoveSkillArray({});
							})
						);
				})
			);
		}
		// {
		// 	dispatch: false,
		// }
	);

	updateRemoveSkillArray$ = createEffect(() =>
		this.action$.pipe(
			ofType(updateRemoveSkillArray),
			map((action) => action.id),
			withLatestFrom(this.profileStore.pipe(select(getUserSkill))),
			mergeMap(([id, skills]) => {
				// const _skill = skills.find((x) => x.skillId === id);
				// console.log("removing temp skill", _skill )
				// return iif(
				// 	() => _skill !== undefined && _skill.isTemp,
				// 	of(removeSkill({ id: id })),
				// 	of()
				// );
				if (id) {
					return of(removeSkill({ id: id }));
				}
				return of();
			})
		)
	);

	doSkillActions$ = createEffect(() =>
		this.action$.pipe(
			ofType(doSkillAction),
			map((action) => {
				let actions: any[] = [];
				action.add &&
					actions.push(
						this.profileService.saveProfileSkills(
							action.add,
							action.userId
						)
					);
				action.update &&
					actions.push(
						this.profileService.updateSkill(action.update)
					);
				action.remove &&
					actions.push(
						this.profileService.deleteSkill(
							action.remove.ids,
							action.remove.userId
						)
					);
				return {
					actions,
					skillType: action.skillTypeFilter,
					add: action.add,
					update: action.update,
					remove: action.remove,
					isManageUsers: action.isManageUsers,
					comingFromSkill: action.comingFromSkill,
				};
			}),
			mergeMap((data) =>
				forkJoin(data.actions).pipe(
					switchMap((res) => {
						this.notificationService.success(
							'Skills successfully updated'
						);
						let dispatchActions = [];
						if (data.isManageUsers) {
							if (data.comingFromSkill) {
								this.router.navigate([
									'/reports/skills/summary',
								]);
							} else {
								this.router.navigate(['/manage-users']);
							}
							// return of(true);
						} else {
							dispatchActions.push(loadUserSkills({}));
						}
						if (data.remove) {
							data.remove.ids.map((id) =>
								dispatchActions.push(removeSkill({ id: id }))
							);
							dispatchActions.push(updateRemoveSkillArray({}));
						}
						return dispatchActions;
					})
				)
			)
		)
	);
}
