<mat-form-field [formGroup]="fgName" class="slr-input-02" appearance="outline" [style.width]="width">
	<mat-label *ngIf="label">{{ label | translate }}</mat-label>
	<input
		(click)="$event.stopPropagation()"
		matInput
		[placeholder]="placeholder | translate"
		[formControlName]="controlName"
	/>
	<span class="pe-1" matPrefix>{{ preText }}.</span>
	<mat-error *ngIf="fgName.get(controlName).invalid">{{
		errorMessage
	}}</mat-error>
</mat-form-field>
