<form [formGroup]="fileForm">
	<div class="d-flex justify-content-between align-items-center">
		<div class="wrapping-text">
			<div class="d-flex gap-3">
				<p
					class="mb-0"
					*ngIf="
						fileForm.value.type === 'internal' ||
						fileForm.value.type === 'vimeo' ||
						fileForm.value.type == 'scorm' || 
						fileForm.value.type == 'invalid_non_scorm' || 
						fileForm.value.type == 'non-scorm'
					"
				>
					{{ fileForm.value.name }}
				</p>
				<p class="mb-0" *ngIf="fileForm.value.type === 'external'">
					{{ fileForm.value.link }}
				</p>
			</div>
			<slr-progress-bar
				*ngIf="!fileForm.value.uploaded"
				[type]="'primary'"
				[value]="fileForm.value.uploadProgress || 0"
				[width]="'180px'"
			></slr-progress-bar>
		</div>
		<ng-container *ngIf="fileForm?.value?.type !== 'scorm'">
			<ng-container
				*ngIf="isEdit; then showPreview; else previewLoader"
			></ng-container>
			<ng-template #previewLoader>
				<ng-container *ngIf="fileForm.value.file">
					<div
						class="spinner-border"
						role="status"
						*ngIf="!fileForm.value.uploaded; else showPreview"
					>
						<span class="visually-hidden">Loading...</span>
					</div>
				</ng-container>
			</ng-template>
			<ng-template #showPreview>
				<span
					class="material-icons-outlined color-blue-2 cursor-pointer"
					(click)="onPreviewEntry()"
					[matTooltip]="'PREVIEW_FILE' | translate"
				>
					preview
				</span>
			</ng-template>
		</ng-container>
		<a
			#downloadFile
			style="display: none"
			[href]="fileDetails?.fileUrl"
		></a>
		<div class="d-flex gap-3 align-items-center">
			<div *ngIf="showDuration">
				<slr-input
					[fgName]="returnFormGroup()"
					[controlName]="'duration'"
					[label]="'Duration'"
					[placeholder]="'Duration (If any) 00:00:00'"
					[showError]="true"
				></slr-input>
			</div>
			<span
				class="material-icons-outlined color-red-0 cursor-pointer"
				*ngIf="!hideRemove"
				(click)="onDeleteEntry()"
			>
				close
			</span>
		</div>
	</div>
</form>
<app-confirmation-modal
	[openModal]="openModal"
	(modalClosed)="onCloseModal($event)"
	[modalSize]="'xl'"
>
	<app-file-preview [fileDetails]="fileDetails"></app-file-preview>
</app-confirmation-modal>
