<div class="unsubscribe d-flex align-items-center justify-content-center">
	<div
		class="unsubscribe-modal flex-column align-items-center justify-content-center"
	>
		<ng-container *ngIf="!submitted">
			<div
				class="modal-header d-flex align-items-center justify-content-center"
			>
				{{ "UNSUBSCRIBE_FEEDS" | translate }}
			</div>
			<div
				class="modal-content col-12 d-flex align-items-center justify-content-center"
			>
				<slr-input
					class="col-8"
					[label]="'UNSUBSCRIBE_REASON_LABEL'"
					[placeholder]="'UNSUBSCRIBE_REASON'"
					[controlName]="'reason'"
					[fgName]="formGroup"
				></slr-input>
			</div>
			<div
				class="modal-footer d-flex align-items-center justify-content-center mt-1"
			>
				<slr-btn02 (onClick)="unsubscribe()">{{
					"UNSUBSCRIBE" | translate
				}}</slr-btn02>
			</div>
		</ng-container>
		<div *ngIf="submitted">
			<div
				class="modal-header d-flex align-items-center justify-content-center"
			>
				{{ "UNSUBSCRIBED" | translate }}
			</div>
			<div
				class="modal-content col-12 d-flex align-items-center justify-content-center"
			>
				<img
					class="tick-check"
					src="../../../../assets/images/tick-check.png"
					alt="tick"
				/>
			</div>
			<div
				class="modal-footer d-flex align-items-center justify-content-center mt-1"
			>
				<div class="success-message text-center">
					{{ successResponse["message"] | translate }}
				</div>
			</div>
		</div>
	</div>
</div>
