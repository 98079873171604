import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { LocalDataService } from 'src/app/core/services/local-data.service';

@Component({
	selector: 'slr-input-file-upload',
	templateUrl: './slr-input-file-upload.component.html',
	styleUrls: ['./slr-input-file-upload.component.scss'],
})
export class SlrInputFileUploadComponent
	implements OnInit, OnChanges, OnDestroy
{
	@ViewChild('fileInput') fileInput: ElementRef;
	@Input() acceptTypes: string = '*/*';
	@Input() multiple: string = '';
	@Input() fileAttr = this.translate.instant('CHOOSE_FILE');

	@Output() chosenFile: EventEmitter<File>;
	@Output() chosenFiles: EventEmitter<File[]>;

	private unsubscriber$ = new Subject<void>();

	constructor(
		private localDatService: LocalDataService,
		private translate: TranslateService
	) {
		this.chosenFile = new EventEmitter<File>();
		this.chosenFiles = new EventEmitter<File[]>();
	}

	public uploadFileEvt(file: any): void {
		if (file.target.files && file.target.files[0]) {
			this.fileAttr = '';
			Array.from(file.target.files).forEach((file: any) => {
				this.fileAttr += file.name + ' - ';
			});
			this.chosenFile.emit(file.target.files[0]);
			//   this.fileInput.nativeElement.setValue('')
		} else {
			this.fileAttr = this.translate.instant('CHOOSE_FILE');
		}
	}

	public uploadMultipleFileEvt(file: any): void {
		if (file.target.files && file.target.files[0]) {
			this.fileAttr = '';
			Array.from(file.target.files).forEach((file: any) => {
				this.fileAttr += file.name + ' - ';
			});
			this.chosenFiles.emit(file.target.files);
			//   this.fileInput.nativeElement.setValue('')
		} else {
			this.fileAttr = this.translate.instant('CHOOSE_FILE');
		}
	}

	ngOnInit(): void {
		this.localDatService.castModalStatus.subscribe((res) => {
			if (!res) {
				this.fileAttr = this.translate.instant('CHOOSE_FILE');
				this.fileInput.nativeElement.value = '';
			}
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (!changes['fileAttr']?.currentValue) {
			if (this.fileInput) {
				this.fileInput.nativeElement.value = null;
			}
		}
	}

	ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.unsubscribe();
	}
}
