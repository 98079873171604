<div
	class="course-info-card"
	[class.small]="content.size == 's'"
	[ngClass]="content.class"
>
	<div class="content">
		<div class="count">{{ content.count }}</div>
		<div *ngIf="content.type" class="type">
			{{ content.type | translate }}
		</div>
	</div>
	<div class="footer">
		{{ content.footerText | translate }}
	</div>
</div>
