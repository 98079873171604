import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
	name: 'skillStatusTrasnlate',
})
export class SkillStatusTrasnlatePipe implements PipeTransform {
	constructor(private translateService: TranslateService) {}
	transform(value: any, ...args: any[]) {
        if(value == 'Approved') {
            return this.translateService.instant("APPROVED");
        }
        if(value == 'Pending Approval') {
            return this.translateService.instant("PENDING_APPROVAL")
        }
        if(value == 'Pending Course Completion') {
            return this.translateService.instant("PENDING_COURSE_COMPLETION")
        }
    }
}
