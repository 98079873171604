import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ValidatorMessage } from 'src/app/core/models/validation-messages.model';
import { ErrorMessageService } from 'src/app/core/services/error-message.service';
import { GenericHelper } from 'src/app/core/helpers/generic-helper.class';
import {
	MatDialog,
	MatDialogConfig,
	MatDialogRef,
} from '@angular/material/dialog';
@Component({
	selector: 'slr-password-tb',
	templateUrl: './slr-password-tb.component.html',
	styleUrls: ['./slr-password-tb.component.scss'],
})
export class SlrPasswordTbComponent implements OnInit {
	@ViewChild('myButton', { static: false, read: ElementRef })
	public myButtonRef: ElementRef;

	@Input() public control: FormControl;
	@Input() public placeholder: string;
	@Input() public label: string = 'Enter your password';
	@Input() public validatorMessages: ValidatorMessage[];

	public passwordTooltipText = GenericHelper.passwordRequirementMessage;
	public hide: boolean = true;
	public errorMessage: string;

	private matDialogRef: MatDialogRef<any>;
	constructor(
		private errorMessageService: ErrorMessageService,
		private matDialog: MatDialog
	) {}

	ngOnInit(): void {
		this.setErrorMessage();
	}

	public setErrorMessage(): void {
		this.errorMessage = this.errorMessageService.setErrorMessage(
			this.validatorMessages,
			this.control
		);
	}

	public showMessage(templateRef): void {
		const matDialogConfig = new MatDialogConfig();
		const rect: DOMRect =
			this.myButtonRef.nativeElement.getBoundingClientRect();
		matDialogConfig.position = {
			right: `10px`,
			top: `${rect.bottom + 2}px`,
		};

		this.matDialogRef = this.matDialog.open(templateRef, {
			width: '300px',
			hasBackdrop: false,
			panelClass: ['no-padding-popup'],
		});
		this.matDialogRef.updatePosition(matDialogConfig.position);
	}

	public closeMessage(): void {
		this.matDialogRef.close();
	}
}
