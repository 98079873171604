export enum SettingsMap {
	force_change_password_new_users = 19,
	show_mycourses_time_totals = 20,
	use_evaluation = 21,
	my_courses_hide_future_override = 5,
	my_courses_view_next_year = 42,
	my_courses_hide_future = 3,
	my_courses_hide_future_threshold = 4,
	use_mandatory_email = 30,
	use_mandatory_categories = 6,
	regular_show_library = 9,
	restricted_show_library = 10,
	regular_show_manual = 12,
	allow_questions_on_verification = 50,
	users_printtest = 59,
	use_parent_lock = 43,
	default_pass_score = 51,
	default_test_attempts = 56,
	remove_records = 60,
	my_courses_check_previous_year = 11,
	use_timed_completion = 17,
	use_timed_completion_bypass = 18,
	allow_link_in = 33,
	allow_link_out = 34,
	client_education_year = 5943,
	hide_required_before_date_entered = 7,
	check_previous_on_users_added_this_year = 14,
	footer_background_color = 'footer_background_color',
	footer_color = 'footer_color',
	header_background_color = 'header_background_color',
	header_color = 'header_color',
	custom_certificate = 'custom_certificate',
	custom_logo = 'custom_logo',
	inside_certificate_logo = 'inside_certificate_logo',
	default_language = 'default_language',
}

export enum BrandSettingsMapForAPI {
	footer_background_color = <any>'footerColor',
	footer_color = <any>'footerFontColor',
	header_background_color = <any>'headerColor',
	header_color = <any>'headerFontColor',
	custom_certificate = <any>'certificateLogo',
	custom_logo = <any>'customLogo',
	inside_certificate_logo = <any>'insideCertificateLogo',
	default_language = <any>'default_language',
}
