import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take, takeUntil } from 'rxjs';
import { ValidatorMessage } from 'src/app/core/models/validation-messages.model';
import { ErrorMessageService } from 'src/app/core/services/error-message.service';

@Component({
	selector: 'slr-input',
	templateUrl: './slr-input.component.html',
	styleUrls: ['./slr-input.component.scss'],
})
export class SlrInputComponent implements OnInit {
	@Input() label: string;
	@Input() placeholder: string;
	@Input() control: FormControl;
	@Input() fgName: FormGroup;
	@Input() controlName: string;
	@Input() width: string = '100%';
	@Input() icon: string;
	@Input() iconAling: 'suffix' | 'prefix' = 'suffix';
	@Input() showError: boolean = true;
	@Input() public validatorMessages: ValidatorMessage[];
	@Input() public autoComplete: boolean = true;
	@Input() public autoFocus?: boolean = false;

	public defaultValidatorMessages: ValidatorMessage[];

	public errorMessage: string;

	constructor(
		private errorMessageService: ErrorMessageService,
		private trasnlateService: TranslateService
	) {}

	ngOnInit(): void {
		let fildName: string;
		if (this.placeholder) {
			this.trasnlateService.get(this.placeholder).subscribe({
				next: (res) => (fildName = res),
			});
		}
		this.defaultValidatorMessages = [
			{
				validationType: 'required',
				validationMessage: `${this.trasnlateService.instant(
					this.placeholder ?? 'THIS_FIELD'
				)} ${this.trasnlateService.instant('IS_REQUIRED_GENERAL')}`,
			},
			...(this.validatorMessages ?? []),
		];
		this.setErrorMessage();
	}
	public setErrorMessage(): void {
		this.errorMessage = this.errorMessageService.setErrorMessage(
			this.defaultValidatorMessages,
			this.control ?? this.fgName?.get(this.controlName)
		);
	}
}
