import {
	Component,
	HostListener,
	OnDestroy,
	OnInit,
	AfterViewInit,
	Output,
	EventEmitter,
	Input,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
	Subject,
	takeUntil,
	combineLatest,
} from 'rxjs';
import { BroadcastService } from 'src/app/core/services/broadcast.service';
import { LocalDataService } from 'src/app/core/services/local-data.service';
import { UserPermissionService } from 'src/app/modules/authentication/_services/user-permissions.service';
import { menuItems } from './menu-items';

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy, AfterViewInit {
	public miniSidebar = true;
	public sidebarExpanded = false;
	public availableMenuItems: any;
	public activeMenu: any;
	private collapsing = false;
	private isExpanded: boolean = false;
	public mobileSidebarState: 'show' | 'hide' = 'hide';
	private flattenMenus: any = [];
	private unsubsriber$ = new Subject<void>();

	@Output() public onExpanded = new EventEmitter<void>();
	public _newHidden: boolean;
	@Input() public set newHidden(_: boolean) {
		this.sidebarExpanded = _ ? _ : this.isExpanded;
		this._newHidden = _;
	}

	@HostListener('mouseenter', ['$event'])
	onResize(event: any) {
		this.miniSidebar = false;
	}
	@HostListener('mouseleave', ['$event'])
	mouseleave(event: any) {
		this.miniSidebar = true;
	}

	constructor(
		private broadcastService: BroadcastService,
		private localData: LocalDataService,
		private router: Router,
		private permissionService: UserPermissionService
	) {}
	ngAfterViewInit(): void {
		combineLatest([
			// this.broadcastService.screenSize,
			this.broadcastService.mobileSidebarState,
		]).subscribe({
			next: ([sidebarState]) => {
				setTimeout(() => {
					this.mobileSidebarState = sidebarState;
				}, 10);
			},
		});
	}

	private checkNavigationEnd(): void {
		this.router.events.pipe(takeUntil(this.unsubsriber$)).subscribe({
			next: (res) => {
				if (res instanceof NavigationEnd) {
					this.miniSidebar = true;
					this._newHidden &&
						this.broadcastService.hideMobileSidebarState();
				}
			},
		});
	}

	ngOnInit(): void {
		this.availableMenuItems = JSON.parse(JSON.stringify(menuItems));
		this.getFlattenMenus();
		this.highlightMenu(window.location.href);
		this.getActiveRoute();
		this.checkNavigationEnd();
	}

	getFlattenMenus() {
		this.availableMenuItems = this.availableMenuItems.filter((menu) => {
			menu.subMenu = menu.subMenu.filter((subMenu) => {
				if (subMenu.moduleName == null) return true;
				return this.permissionService.checkUserPrivilege(
					subMenu.moduleName
				);
			});
			if (menu.moduleName == null) return true;
			return this.permissionService.checkUserPrivilege(menu.moduleName);
		});
		this.availableMenuItems.forEach((menu: any) => {
			this.flattenMenus = [...this.flattenMenus, ...menu.subMenu, menu];
		});
	}

	getActiveRoute() {
		this.localData
			.getRouteChange()
			.subscribe(() => this.highlightMenu(window.location.href));
	}

	highlightMenu(currentUrl: string) {
		this.changeActiveMenu(
			this.flattenMenus.find((menu: any) => {
				return currentUrl.includes(menu.url);
			}) || this.activeMenu
		);
	}

	changeSidebarState(isExpanded: boolean) {
		this.onExpanded.emit();
		this.sidebarExpanded = isExpanded;
		this.isExpanded = isExpanded;
		setTimeout(() => {
			this.collapsing = !this.collapsing;
		}, 500);
	}
	openSelectedMenu(menu: any) {
		this.changeActiveMenu(menu);
	}
	changeActiveMenu(menu: any) {
		this.activeMenu = menu;
	}
	checkSubMenu(menu: any, activeMenu: any) {
		return menu.subMenu.some(
			(subMenu: any) => activeMenu?.name === subMenu.name
		);
	}

	ngOnDestroy(): void {
		this.unsubsriber$.next();
		this.unsubsriber$.unsubscribe();
	}
}
