/* eslint-disable no-unused-vars */
import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { finalize, Observable, take, mergeMap, iif, combineLatest } from 'rxjs';
import {
	AuthenticationState,
	getIDToken,
	getToken,
} from 'src/app/modules/authentication/_state/authentication.reducer';
import { BroadcastService } from '../services/broadcast.service';
import { authExceptedUrls, exceptedUrls } from './loader-except-urls';
import {
	SharedState,
	getSelectedLocation,
} from 'src/app/shared/_state/shared.reducer';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
	constructor(
		private broadcastService: BroadcastService,
		private authStore: Store<AuthenticationState>,
		private sharedStore: Store<SharedState>
	) {}

	public intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		exceptedUrls.find((url) => request.url.includes(url)) ||
			this.broadcastService.showGlobalLoader();

		if (request.url.includes('zendesk.com')) {
			return next
				.handle(request)
				.pipe(finalize(() => this.broadcastService.hideGlobalLoader()));
		}
		if (
			request.url.includes('api.vimeo.com') ||
			request.url.includes('tus.vimeo.com')
		) {
			return next
				.handle(request)
				.pipe(finalize(() => this.broadcastService.hideGlobalLoader()));
		}
		if (request.url.includes('login.microsoftonline.com')) {
			return next
				.handle(request)
				.pipe(finalize(() => this.broadcastService.hideGlobalLoader()));
		}
		if (request.url.includes('RusticiEngine')) {
			return next
				.handle(request)
				.pipe(finalize(() => this.broadcastService.hideGlobalLoader()));
		}
		if (request.url.includes('s3.ca-central-1.amazonaws.com')) {
			return next
				.handle(request)
				.pipe(finalize(() => this.broadcastService.hideGlobalLoader()));
		}
		return combineLatest([
			this.authStore.pipe(select(getToken)),
			this.authStore.pipe(select(getIDToken)),
			this.sharedStore.pipe(select(getSelectedLocation)),
		]).pipe(
			take(1),
			mergeMap(([token, idToken, location]) =>
				iif(
					() =>
						authExceptedUrls.find((url) =>
							request.url.includes(url)
						) !== undefined,
					next
						.handle(
							request.clone({
								setHeaders: {
									'Access-Control-Allow-Origin': '*',
								},
							})
						)
						.pipe(
							finalize(() =>
								this.broadcastService.hideGlobalLoader()
							)
						),
					next
						.handle(
							request.clone({
								setHeaders: {
									'Access-Control-Allow-Origin': '*',
									Authorization: `Bearer ${token}`,
									id_token: `${idToken}`,
									'Location-Id':
										location && location.location
											? location.location.id.toString()
											: '',
								},
							})
						)
						.pipe(
							finalize(() =>
								this.broadcastService.hideGlobalLoader()
							)
						)
				)
			)
		);
	}
}
