<app-modal-header-placeholder
	[title]="'COMMON_SURVEY_HEADING'"
	(onClose)="close()"
></app-modal-header-placeholder>

<div class="body">
	<div class="flex-grow-1 d-flex flex-column gap-5">
		<div
			*ngFor="let q of qArray; let i = index"
			class="d-flex flex-column gap-4"
		>
			<div class="ques">Q {{ i + 1 }}. {{ q.question }}</div>
			<div class="options">
				<slr-rbtn-group
					[fgName]="surveyAnswerForm"
					[controlName]="q.questionId.toString()"
					[data]="qnaMap[i + 1]"
				></slr-rbtn-group>
			</div>
		</div>
	</div>
	<div class="d-flex justify-content-center">
		<slr-btn02 (onClick)="onSubmit()">{{ "SUBMIT" | translate }}</slr-btn02>
	</div>
</div>
