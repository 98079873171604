import { createAction, props } from '@ngrx/store';
import {
	BroadcastMessageInterface,
	ChatUserInterface,
	ConversationInterface,
	MessageInterface,
} from '../_models/conversation.interface';

export enum ChatActionTypes {
	/**
	 * For Legacy System like Broadcast Feature
	 */
	LOAD_BROADCAST_MESSAGES = '[CHAT] Broadcast messages Loading',
	BROADCASE_MESSAGES_LOADED = '[CHAT] Broadcast messages Loaded',
	BROADCAST_MESSAGES_FAILED = '[CHAT] Broadcast messages Failed',
	ADD_BROADCAST_MESSAGE = '[CHAT] add broadcast message',
	DELETE_BROADCAST_MESSAGE = '[CHAT] delete broadcast message',
	UPDATE_UNSEEN_MESSAGE_COUNT = '[CHAT] update unseen message count',

	/**
	 * Will be used for Chat functionality in future.
	 */
	CONVERSATIONS_LOADED = '[CHAT] Conversations Loaded',
	GET_CONVERSATIONS = '[CHAT] Get Conversations',
	ADD_MESSAGE = '[CHAT] Add Message',
	ADD_TEMPORARY_MESSAGE = '[CHAT] Add Temporary Message To Chat',
	ADD_REPLY = '[TEMP CHAT] Add Reply',
	REMOVE_REPLY = '[TEMP CHAT] Add Reply ',
	SAVE_TMP_MSG_TEXT = '[TEMP CHAT] Save temporary message text',
	UPDATE_SELECTED_CONVERSATION = '[CHAT] Update Selected Conversation',
	ADD_NEW_CONVERSAITON = '[CHAT] Add new conversation',
	CREATE_NEW_CONVERSAITON = '[CHAT] Create new conversation',
	TOOGLE_RIGHT_SIDEBAR = '[CHAT] Toggle Right Sidebar',
}

/**
 * For Legacy System like Broadcast Feature
 */

export const getBroadcastMessages = createAction(
	ChatActionTypes.LOAD_BROADCAST_MESSAGES
);
export const saveBroadcastMessages = createAction(
	ChatActionTypes.BROADCASE_MESSAGES_LOADED,
	props<{ messages: Array<BroadcastMessageInterface> }>()
);

export const broadcastMessagesFailed = createAction(
	ChatActionTypes.BROADCAST_MESSAGES_FAILED,
	props<{ error: string }>()
);

export const addBroadCastMessage = createAction(
	ChatActionTypes.ADD_BROADCAST_MESSAGE,
	props<{ message: BroadcastMessageInterface }>()
);
export const deleteBroadcastMessage = createAction(
	ChatActionTypes.DELETE_BROADCAST_MESSAGE,
	props<{ id: number }>()
);
/**
 * Will be used for Chat functionality in future.
 */

export const getConversations = createAction(ChatActionTypes.GET_CONVERSATIONS);
export const conversationsLoaded = createAction(
	ChatActionTypes.CONVERSATIONS_LOADED,
	props<{ conversations: ConversationInterface[] }>()
);
export const addTemporaryMessage = createAction(
	ChatActionTypes.ADD_TEMPORARY_MESSAGE,
	props<{ conversationId: number; message: MessageInterface }>()
);

export const saveTempMessageText = createAction(
	ChatActionTypes.SAVE_TMP_MSG_TEXT,
	props<{ conversationId: number; text: string }>()
);

export const addReplyToTempMessage = createAction(
	ChatActionTypes.ADD_REPLY,
	props<{ conversationId: number; replyMessage: MessageInterface }>()
);
export const removeReplyToTempMessage = createAction(
	ChatActionTypes.REMOVE_REPLY,
	props<{ conversationId: number }>()
);
export const addMessage = createAction(
	ChatActionTypes.ADD_MESSAGE,
	props<{ conversationId: number; message: MessageInterface }>()
);
export const updateSelectedConversation = createAction(
	ChatActionTypes.UPDATE_SELECTED_CONVERSATION,
	props<{ conversation: ConversationInterface }>()
);
export const toggleRightSideBar = createAction(
	ChatActionTypes.TOOGLE_RIGHT_SIDEBAR
);
export const addNewConversation = createAction(
	ChatActionTypes.ADD_NEW_CONVERSAITON,
	props<{ conversation: ConversationInterface }>()
);
export const createNewConversation = createAction(
	ChatActionTypes.CREATE_NEW_CONVERSAITON,
	props<{ toUsers: Array<ChatUserInterface> }>()
);
export const updateUnseenMessageCount = createAction(
	ChatActionTypes.UPDATE_UNSEEN_MESSAGE_COUNT,
	props<{ newCount?: number; decrement?: boolean; increment?: boolean }>()
);
