import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent {
  @Input() boundaryLinks: boolean = false;
  @Input() directionLinks: boolean = true;
  @Input() disabled: boolean = false;
  @Input() page: number = 1;
  @Input() collectionSize: number = 10;
  @Input() ellipses: boolean = true;
  @Input() maxSize: number = 5;
  @Input() rotate: boolean = true;
  @Input() size: 'sm' | 'lg' = 'sm';
  @Input() previous: string = 'arrow_back_ios';
  @Input() next: string = 'arrow_forward_ios';
  @Input() pageSize: number = 10;

  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

  get isPreviousDisabled(): boolean {
    return this.page === 1;
  }
  get isNextDisabled(): boolean {
    return this.page >= Math.ceil(this.collectionSize / this.pageSize);
  }

  pageChanged() {
    if (!this.disabled) {
      this.pageChange.emit(this.page);
    } else {
      return;
    }
  }
}
