import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tabbed-location-dropdown',
  templateUrl: './tabbed-location-dropdown.component.html',
  styleUrls: ['./tabbed-location-dropdown.component.scss']
})
export class TabbedLocationDropdownComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
