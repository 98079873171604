import { Injectable } from '@angular/core';
import {
	HttpInterceptor,
	HttpRequest,
	HttpHandler,
	HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { NotificationService } from '../services/notification.service';

@Injectable()
export class SanitizeInterceptor implements HttpInterceptor {
	constructor(private notificationService: NotificationService) {}

	intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		if (req.body && typeof req.body === 'object') {
			if (this.sanitizeRequestBody(req.body) === false) {
				this.notificationService.error('MALICIOUS_DATA_FOUND');

				return null;
			}
		}
		return next.handle(req);
	}

	private sanitizeRequestBody(body: any): boolean {
		if (typeof body === 'string') {
			return this.sanitizeHtml(body) === true;
		} else if (
			typeof body === 'number' ||
			typeof body === 'undefined' ||
			typeof body === 'boolean'
		) {
			return true;
		} else if (Array.isArray(body)) {
			return body
				.map((item) => this.sanitizeRequestBody(item))
				.every((x) => x === true);
		} else if (typeof body === 'object') {
			const sanitizedObject = [];
			for (const key in body) {
				if (body.hasOwnProperty(key)) {
					sanitizedObject.push(this.sanitizeRequestBody(body[key]));
				}
			}
			return sanitizedObject.every((element) => element !== false);
		} else {
			return true;
		}
	}

	private sanitizeHtml(htmlContent: string): boolean | string {
		const scriptTagRegex = /<script[\s\S]*?>|<\/script>/i;
		const javascriptRegex = /\bjavascript\b/i;
		if (
			scriptTagRegex.test(htmlContent) ||
			javascriptRegex.test(htmlContent)
		) {
			return 'Malicious data detected in request body';
		} else {
			return true;
		}
	}
}
