<div class="d-flex align-items-center">
	<div class="breadcrumb-div flex-grow-1" [class.pad-div]="isReportsScreen">
		<span
			*ngFor="let menu of breadCrumbItems; first as first; last as last"
		>
			<span
				class="breadcrumb-item"
				[ngClass]="{
					'gray-items': !last,
					'last-item': last,
					'ps-0': first,
					'link-effect': !last
				}"
				(click)="navigateToRoute(menu)"
				(keyup)="navigateToRoute(menu)"
			>
				{{ menu.title | translate }}
			</span>
			<i class="material-icons-outlined gray-items" *ngIf="!last">
				arrow_forward
			</i>
		</span>
	</div>
	<div *ngIf="currentScreenSize === 'small'" class="nav-toggle">
		<mat-icon (click)="$event.stopPropagation(); toggleSidebar()" (keyup)="$event.stopPropagation(); toggleSidebar()">{{
			mobileSidebarState === "hide" ? "menu" : "close"
			}}</mat-icon>
	</div>
</div>
