import { Component, OnInit } from '@angular/core';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	ValidationErrors,
	Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs';
import { GenericHelper } from 'src/app/core/helpers/generic-helper.class';
import { ValidatorMessage } from 'src/app/core/models/validation-messages.model';
import { NotificationService } from 'src/app/core/services/notification.service';
import { AuthService } from '../_services/auth.service';
import {
	AuthenticationState,
	getToken,
} from '../_state/authentication.reducer';
import { Store, select } from '@ngrx/store';
import { ErrorMessages } from 'src/app/core/helpers/error-messages.class';

@Component({
	selector: 'app-change-password-popup',
	templateUrl: './change-password-popup.component.html',
	styleUrls: ['./change-password-popup.component.scss'],
})
export class ChangePasswordPopupComponent implements OnInit {
	public accessTokenControl: FormControl;
	public oldPasswordControl: FormControl;
	public newPasswordControl: FormControl;
	public confirmPasswordControl: FormControl;
	public loginForm: FormGroup;
	public validatorMessages = new Map<string, Array<ValidatorMessage>>([
		[
			'oldPasswordControl',
			[
				{
					validationType: 'required',
					validationMessage: 'Password is required',
				},
				{
					validationType: 'minlength',
					validationMessage:
						GenericHelper.passwordFildValidationmessage,
				},
				{
					validationType: 'maxlength',
					validationMessage: 'Max characters allowed are 20',
				},
				{
					validationType: 'pattern',
					validationMessage:
						GenericHelper.passwordFildValidationmessage,
				},
				{
					validationType: 'same-old-password',
					validationMessage:
						ErrorMessages.NEW_PASSWORD_OLD_PASSWORD_ERROR,
				},
			],
		],
		[
			'confirmPasswordControl',
			[
				{
					validationType: 'required',
					validationMessage: 'Password is required',
				},
				{
					validationType: 'minlength',
					validationMessage:
						GenericHelper.passwordFildValidationmessage,
				},
				{
					validationType: 'maxlength',
					validationMessage: 'Max characters allowed are 20',
				},
				{
					validationType: 'pattern',
					validationMessage:
						GenericHelper.passwordFildValidationmessage,
				},
				{
					validationType: 'notSame',
					validationMessage: 'Passwords do not match',
				},
			],
		],
	]);

	constructor(
		private authStore: Store<AuthenticationState>,
		private authService: AuthService,
		private _fb: FormBuilder,
		private notificationService: NotificationService,
		private matDialogRef: MatDialogRef<ChangePasswordPopupComponent>
	) {}

	ngOnInit(): void {
		this.initialiseForm();
	}

	public onSubmit(): void {
		if (
			this.newPasswordControl.value !== this.confirmPasswordControl.value
		) {
			this.notificationService.error(
				'New Password and Confirm Password do not match.'
			);
			this.confirmPasswordControl.markAsTouched();
			this.confirmPasswordControl.setErrors({
				notSame: true,
			});
			return;
		} else {
			if (this.confirmPasswordControl.invalid)
				this.confirmPasswordControl.setErrors(null);
		}
		if (
			this.accessTokenControl.invalid ||
			this.oldPasswordControl.invalid ||
			this.newPasswordControl.invalid ||
			this.confirmPasswordControl.invalid
		)
			return;
		this.authService
			.changePassword({
				np: this.newPasswordControl.value,
				op: this.oldPasswordControl.value,
				token: this.accessTokenControl.value,
			})
			.pipe(take(1))
			.subscribe({
				next: (res) => {
					if (res['error']) {
						let statuscode = res['error']['status'];
						if (statuscode === '400') {
							this.notificationService.error(
								res['error']['message']
							);
							return;
						} else {
							res['error']['message'].includes(
								'Attempt limit exceeded, please try after some time.'
							) &&
								this.notificationService.error(
									'Attempt limit exceeded, please try after some time'
								);
							res['error']['message'].includes(
								'Incorrect username or password'
							) &&
								this.notificationService.error(
									'Incorrect username or password'
								);
							return;
						}
					}
					this.notificationService.success(
						'Password changed successfully.'
					);
					this.matDialogRef.close();
				},
				error: (err) => {
					this.notificationService.error(err);
				},
			});
	}

	private initialiseForm(): void {
		this.accessTokenControl = new FormControl('', [
			Validators.required,
			Validators.minLength(2),
		]);
		this.oldPasswordControl = new FormControl('', [
			Validators.required,
			Validators.minLength(8),
			Validators.maxLength(20),
			Validators.pattern(GenericHelper.passwordValidatorPattern),
		]);
		this.newPasswordControl = new FormControl('', [
			Validators.required,
			Validators.minLength(8),
			Validators.maxLength(20),
			Validators.pattern(GenericHelper.passwordValidatorPattern),
		]);
		this.confirmPasswordControl = new FormControl('', [
			Validators.required,
			Validators.minLength(8),
			Validators.maxLength(20),
			Validators.pattern(GenericHelper.passwordValidatorPattern),
		]);
		this.newPasswordControl.addValidators((): ValidationErrors | null => {
			if (this.oldPasswordControl.value == this.newPasswordControl.value)
				return {
					'same-old-password': true,
				};

			return null;
		});
		this.confirmPasswordControl.addValidators(
			GenericHelper.confirmPasswordValidator(
				this.newPasswordControl,
				this.confirmPasswordControl
			)
		);
		this.loginForm = this._fb.group({
			accessTokenControl: this.accessTokenControl,
			oldPasswordControl: this.oldPasswordControl,
			newPasswordControl: this.newPasswordControl,
			confirmPasswordControl: this.confirmPasswordControl,
		});

		this.setAccessToken();
	}

	private setAccessToken(): void {
		this.authStore
			.pipe(select(getToken))
			.pipe(take(1))
			.subscribe({
				next: (res) => {
					this.accessTokenControl.setValue(res);
				},
			});
	}

	public close(): void {
		this.matDialogRef.close();
	}
}
