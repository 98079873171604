<div [formGroup]="fgName" [style.width]="width">
	<mat-form-field appearance="outline">
		<mat-label>{{ label | translate }}</mat-label>
		<input
			matInput
			[formControlName]="controlName"
			[matDatepicker]="picker"
			[placeholder]="'YYYY-MM-DD'"
			(change)="setErrorMessage()"
			(dateChange)="matDateChange($event)"
			[min]="min"
		/>
		<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
		<mat-datepicker #picker [startView]="startView"></mat-datepicker>
		<mat-error *ngIf="fgName.get(controlName).invalid">{{
			errorMessage
		}}</mat-error>
	</mat-form-field>
</div>
