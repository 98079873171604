<mat-icon class="cursor-pointer" [matMenuTriggerFor]="slrmenu">{{
	icon
}}</mat-icon>

<mat-menu class="slr-menu" #slrmenu>
	<ng-container *ngFor="let item of menuItems">
		<button mat-menu-item (click)="emitAction(item.action)">
			<mat-icon class="material-icons-outlined" >{{ item.icon }}</mat-icon
			>{{ item.name }}
		</button>
	</ng-container>
</mat-menu>
