<div class="form-check slr-radio mt-1">
	<input
		type="radio"
		class="form-check-input"
		[disabled]="disabled"
		[checked]="checked"
	/>
	<label>{{ label }}</label>
	<ng-container *ngIf="image">
		<img [src]="image" />
	</ng-container>
	<!-- TODO -->
	<!-- Try Adding Material radio button -->
</div>
