import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, take, map } from 'rxjs';
import { CodeListInterface } from '../_models/manage-codes.interface';
import { SiteCodeLevelMapping } from '../_models/site-code-leve-mapping.enum';
import { SettingsService } from '../_services/settings.service';

@Injectable({
	providedIn: 'root',
})
export class ManageCodesListResolver implements Resolve<any> {
	constructor(private settingsService: SettingsService) {}
	resolve(): Observable<
		| [
				Array<CodeListInterface>,
				Array<CodeListInterface>,
				Array<CodeListInterface>,
				Array<CodeListInterface>,
				Array<CodeListInterface>
		  ]
		| null
	> {
		return this.settingsService.getCodeList().pipe(
			take(1),
			map((result: Array<CodeListInterface>) => {
				const gpData = result.filter(
					(x) => x.clientLevel === SiteCodeLevelMapping.GRAND_PARENT
				);
				const pData = result.filter(
					(x) => x.clientLevel === SiteCodeLevelMapping.PARENT
				);
				const vData = result.filter(
					(x) => x.clientLevel === SiteCodeLevelMapping.VENDOR
				);
				const cData = result.filter(
					(x) => x.clientLevel === SiteCodeLevelMapping.CHILD
				);
				const sData = result.filter(
					(x) => x.clientLevel === SiteCodeLevelMapping.SHARED
				);
				return [gpData, pData, vData, cData, sData];
			})
		);
	}
}
