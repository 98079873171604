import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { LocalDataService } from 'src/app/core/services/local-data.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { DropdownInterface } from 'src/app/shared/components/dropdowns/slr-drp01/dropdown.model';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { saveProductAccessSettings } from 'src/app/shared/_state/shared.actions';
import { SharedState } from 'src/app/shared/_state/shared.reducer';
import { SettingsService } from '../../_services/settings.service';
import { FileUploadService } from 'src/app/core/services/file-upload.service';
import { HttpEventType } from '@angular/common/http';
import { TranslatePipe } from '@ngx-translate/core';
import { AppLanguageMapper } from 'src/app/core/models/app-lang.enum';

@Component({
	selector: 'app-product-access-settings',
	templateUrl: './product-access-settings.component.html',
	styleUrls: [
		'./product-access-settings.component.scss',
		'../settings/settings.component.scss',
	],
})
export class ProductAccessSettingsComponent implements OnInit {
	private appLanguageMapperObject = new AppLanguageMapper(this.translatePipe);
	@ViewChild('fileSelectInput') public fileSelect: ElementRef;
	public productAccessForm: FormGroup;
	public pdForm: FormGroup;

	public timeZoneData: Array<DropdownInterface> = [
		{
			id: 1,
			visibleName: 'Pacific Time (America/Los_Angeles)',
			name: 'America/Los_Angeles',
		},
		{
			id: 2,
			visibleName: 'Mountain Time (America/Denver)',
			name: 'America/Denver',
		},
		{
			id: 3,
			visibleName: 'Central Time (America/Chicago)',
			name: 'America/Chicago',
		},
		{
			id: 4,
			visibleName: 'Eastern Time (America/New_York)',
			name: 'America/New_York',
		},
		{
			id: 5,
			visibleName: 'Atlantic Time (America/Halifax)',
			name: 'America/Halifax',
		},
	];
	public primarySectorData: Array<DropdownInterface> = [
		{
			id: 1,
			visibleName: 'Not Set',
			name: '',
		},
		{
			id: 2,
			visibleName: 'Acute Care',
			name: 'Acute Care',
		},
		{
			id: 3,
			visibleName: 'Community Care',
			name: 'Community Care',
		},
		{
			id: 4,
			visibleName: 'Community Living',
			name: 'Community Living',
		},
		{
			id: 5,
			visibleName: 'Long Term Care',
			name: 'Long Term Care',
		},
		{
			id: 6,
			visibleName: 'Retirement Living',
			name: 'Retirement Living',
		},
		{
			id: 7,
			visibleName: 'Other',
			name: 'Other',
		},
	];
	public alertData: Array<DropdownInterface> = [
		{
			id: 0,
			visibleName: 'Off',
			name: '0',
		},
		{
			id: 1,
			visibleName: 'Sunday',
			name: '1',
		},
		{
			id: 2,
			visibleName: 'Monday',
			name: '2',
		},
		{
			id: 3,
			visibleName: 'Tuesday',
			name: '3',
		},
		{
			id: 4,
			visibleName: 'Wednesday',
			name: '4',
		},
		{
			id: 5,
			visibleName: 'Thursday',
			name: '5',
		},
		{
			id: 6,
			visibleName: 'Friday',
			name: '6',
		},
		{
			id: 7,
			visibleName: 'Saturday',
			name: '7',
		},
	];
	public provinceData: Array<DropdownInterface> = [
		{
			id: 1,
			visibleName: 'Not Set',
			name: 'Not Set',
		},
		{
			id: 2,
			visibleName: 'Alberta',
			name: 'Alberta',
		},
		{
			id: 3,
			visibleName: 'British Columbia',
			name: 'British Columbia',
		},
		{
			id: 4,
			visibleName: 'Manitoba',
			name: 'Manitoba',
		},
		{
			id: 5,
			visibleName: 'New Brunswick',
			name: 'New Brunswick',
		},
		{
			id: 6,
			visibleName: 'Newfoundland and Labrador',
			name: 'Newfoundland and Labrador',
		},
		{
			id: 7,
			visibleName: 'Nova Scotia',
			name: 'Nova Scotia',
		},
		{
			id: 8,
			visibleName: 'Northwest Territories',
			name: 'Northwest Territories',
		},
		{
			id: 9,
			visibleName: 'Nunavut',
			name: 'Nunavut',
		},
		{
			id: 10,
			visibleName: 'Ontario',
			name: 'Ontario',
		},
		{
			id: 11,
			visibleName: 'Prince Edward Island',
			name: 'Prince Edward Island',
		},
		{
			id: 12,
			visibleName: 'Quebec',
			name: 'Quebec',
		},
		{
			id: 13,
			visibleName: 'Saskatchewan',
			name: 'Saskatchewan',
		},
	];

	public languages = this.appLanguageMapperObject
		.getLanguageObjectAsDropdownInterface()
		.map((x) => {
			return {
				id: x.id,
				name: this.appLanguageMapperObject.getLanguageCode(x.id),
				visibleName: x.name,
			};
		});

	public accountManagerData: Array<DropdownInterface> = [];

	public productSettingType = [
		{
			settingName: 'usernameStyle',
			visibleName: 'USERNAME_STYLE',
			value: null,
			type: 'select',
			options: AppConstants.usernameStyleData,
			available: false,
		},
		{
			settingName: 'defaultPassword',
			visibleName: 'DEFAULT_PASSWORD',
			value: null,
			type: 'text',
			available: false,
		},
		{
			settingName: 'defaultLanguage',
			visibleName: 'DEFAULT_LANGUAGE',
			value: null,
			type: 'select',
			options: this.languages,
			available: false,
		},
		{
			settingName: 'timezone',
			visibleName: 'TIMEZONE',
			value: null,
			type: 'select',
			options: this.timeZoneData,
			available: false,
		},
		{
			settingName: 'primarySector',
			visibleName: 'PRIMARY_SECTOR',
			value: null,
			type: 'select',
			options: this.primarySectorData,
			available: false,
		},
		{
			settingName: 'province',
			visibleName: 'PROVINCE',
			value: null,
			type: 'select',
			options: this.provinceData,
			available: false,
		},
		{
			settingName: 'lmsAllow',
			visibleName: 'LMS_ALLOW',
			value: null,
			type: 'checkbox',
			available: false,
		},
		{
			settingName: 'policyAllow',
			visibleName: 'POLICY_ALLOW',
			value: null,
			type: 'checkbox',
			available: false,
		},
		{
			settingName: 'surveyAllow',
			visibleName: 'SURVEY_ALLOW',
			value: null,
			type: 'checkbox',
			available: false,
		},
		{
			settingName: 'qrmAllow',
			visibleName: 'QRM_ALLOW',
			value: null,
			type: 'checkbox',
			available: false,
		},
		{
			settingName: 'performanceAllow',
			visibleName: 'PERFORMANCE_ALLOW',
			value: null,
			type: 'checkbox',
			available: false,
		},
		{
			settingName: 'scoreBoardAllow',
			visibleName: 'SCOREBOARD_ALLOW',
			value: null,
			type: 'checkbox',
			available: false,
		},
		{
			settingName: 'linkAllow',
			visibleName: 'LINK_ALLOW',
			value: null,
			type: 'checkbox',
			available: false,
		},
		{
			settingName: 'cloudConvertAllow',
			visibleName: 'CLOUD_CONVERT_ALLOW',
			value: null,
			type: 'checkbox',
			available: false,
		},
		{
			settingName: 'policyBackupAllow',
			visibleName: 'POLICY_BACKUP_ALLOW',
			value: null,
			type: 'checkbox',
			available: false,
		},
		{
			settingName: 'importUsersAllow',
			visibleName: 'IMPORT_USERS_ALLOW',
			value: null,
			type: 'checkbox',
			available: false,
		},
		{
			settingName: 'liphaClient',
			visibleName: 'LIPHA_CLIENT_NEW',
			value: null,
			type: 'checkbox',
			available: false,
		},
		{
			settingName: 'alertCronDay',
			visibleName: 'ALERT_CRON_DAY',
			value: null,
			type: 'select',
			options: this.alertData,
			available: false,
		},
		// {
		// 	settingName: 'customCSS',
		// 	visibleName: 'CUSTOM_CSS',
		// 	value: null,
		// 	type: 'text',
		// 	available: false,
		// },
		// {
		// 	settingName: 'footerColor',
		// 	visibleName: 'FOOTER_BACKGROUND_COLOR',
		// 	value: null,
		// 	type: 'color-picker',
		// 	options: [],
		// 	available: false,
		// },
		// {
		// 	settingName: 'footerFontColor',
		// 	visibleName: 'FOOTER_COLOR',
		// 	value: null,
		// 	type: 'color-picker',
		// 	options: [],
		// 	available: false,
		// },
		// {
		// 	settingName: 'headerColor',
		// 	visibleName: 'HEADER_BACKGROUND_COLOR',
		// 	value: null,
		// 	type: 'color-picker',
		// 	options: [],
		// 	available: false,
		// },
		// {
		// 	settingName: 'headerFontColor',
		// 	visibleName: 'HEADER_COLOR',
		// 	value: null,
		// 	type: 'color-picker',
		// 	options: [],
		// 	available: false,
		// },
		// {
		// 	settingName: 'customLogoPath',
		// 	visibleName: 'CUSTOM_LOGO',
		// 	value: null,
		// 	type: 'file',
		// 	available: false,
		// },
		{
			settingName: 'useParentCode',
			visibleName: 'USE_PARENT_CODE',
			value: null,
			type: 'text',
			available: false,
		},
		{
			settingName: 'showChild',
			visibleName: 'SHOW_CHILD',
			value: null,
			type: 'checkbox',
			available: false,
		},
		{
			settingName: 'supportedBy',
			visibleName: 'SUPPORTED_BY',
			value: null,
			type: 'text',
			available: false,
		},
		{
			settingName: 'hidePassword',
			visibleName: 'HIDE_PASSWORD',
			value: null,
			type: 'checkbox',
			available: false,
		},
		{
			settingName: 'disableUsername',
			visibleName: 'DISABLE_USERNAME',
			value: null,
			type: 'checkbox',
			available: false,
		},
		{
			settingName: 'disableEmail',
			visibleName: 'DISABLE_EMAIL',
			value: null,
			type: 'checkbox',
			available: false,
		},
		{
			settingName: 'strongPassword',
			visibleName: 'STRONG_PASSWORD',
			value: null,
			type: 'checkbox',
			available: false,
		},
		// {
		// 	settingName: 'certificateTemplatePath',
		// 	visibleName: 'CUSTOM_CERTIFICATE',
		// 	value: null,
		// 	type: 'file',
		// 	available: false,
		// },
		{
			settingName: 'activeDirectory',
			visibleName: 'ACTIVE_DIRECTORY',
			value: null,
			type: 'checkbox',
			available: false,
		},
		{
			settingName: 'ssoLogin',
			visibleName: 'SSO_LOGIN',
			value: null,
			type: 'checkbox',
			available: false,
		},
		{
			settingName: 'enterpriseCode',
			visibleName: 'ENTERPRISE_CODE',
			value: null,
			type: 'text',
			available: false,
		},
		{
			settingName: 'demoStatus',
			visibleName: 'DEMO_STATUS',
			value: null,
			type: 'text',
			available: false,
		},
		{
			settingName: 'tempEntry',
			visibleName: 'TEMP_ENTRY',
			value: null,
			type: 'checkbox',
			available: false,
		},
		{
			settingName: 'sendAutoCorrespondence',
			visibleName: 'SEND_AUTO_CORRESPONDENCE',
			value: null,
			type: 'checkbox',
			available: false,
		},
		{
			settingName: 'autoCorrespondenceSend1',
			visibleName: 'AUTO_CORRESPONDENCE_SEND_1',
			value: null,
			type: 'date',
			available: false,
		},
		{
			settingName: 'autoCorrespondenceSend2',
			visibleName: 'AUTO_CORRESPONDENCE_SEND_2',
			value: null,
			type: 'date',
			available: false,
		},
		{
			settingName: 'accountManager',
			visibleName: 'ACCOUNT_MANAGER',
			value: null,
			type: 'select',
			options: [],
			available: false,
		},
	];

	public defaultColors: any;
	public settings: any;
	public settingList: any;
	private unsubscriber$ = new Subject();
	public accountManagers = [];
	public fileUploading: boolean = false;
	public fileUploadProgress: number = 0;
	public uploadingFileFor: string;

	constructor(
		private _fb: FormBuilder,
		private activatedRoute: ActivatedRoute,
		private localDataService: LocalDataService,
		private settingsService: SettingsService,
		private notificationService: NotificationService,
		private sharedStore: Store<SharedState>,
		private uploadService: FileUploadService,
		private translatePipe: TranslatePipe
	) {
		this.defaultColors = this.localDataService.defaultColors;
	}

	ngOnInit(): void {
		this.getSettingsData();
	}

	ngOnDestroy(): void {
		this.unsubscriber$.next('');
	}

	getSettingsData() {
		this.activatedRoute.data
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe((data) => {
				this.settings = data['masterDatas'][0].data[0];
				this.accountManagers = data['masterDatas'][1].data;
				this.transformSettingsData();
			});
	}

	private initProductAccessForm(): void {
		let formGroup = {};
		this.productSettingType.forEach((setting) => {
			formGroup[setting.settingName] = new FormControl(setting.value);
		});
		this.pdForm = new FormGroup(formGroup);
		this.observeFormChanges();
	}

	transformSettingsData() {
		this.setAccountManager();
		let settingsEntries = Object.entries(this.settings);
		settingsEntries.forEach((entry) => {
			this.productSettingType.forEach((setting) => {
				if (entry[0] === setting.settingName) {
					if (setting.type == 'date') {
						setting.value = this.getFormattedDate('06-26');
						setting.available = true;
					} else {
						setting.value = entry[1];
						setting.available = true;
					}

					if (entry[0] === 'alertCronDay') {
						setting.value = `${entry[1]}`;
					}
				}
			});
		});
		this.initProductAccessForm();
	}

	private getFormattedDate(date: Date | string | any): Date {
		return new Date(date);
	}

	setAccountManager() {
		this.accountManagers.forEach((manager) => {
			this.accountManagerData.push({
				id: manager.accountManagerId,
				name: manager.accountManagerName,
				visibleName: manager.accountManagerName,
			});
		});
		this.productSettingType.find(
			(setting) => setting.settingName === 'accountManager'
		).options = this.accountManagerData;
	}

	observeFormChanges() {
		this.pdForm.valueChanges
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe((value) => {
				console.log('pdForm: ', this.pdForm);
			});
	}

	slrCheckboxChange(event, setting) {
		setting.changed = !setting.changed;
		setting.value = event;
		this.pdForm.get(setting.settingName).setValue(event ? 1 : 0);
	}

	saveSettings(editHierarchy: boolean) {
		let payload = this.pdForm.value;
		console.log('Payload: ', payload);

		this.settingsService
			.editProductAccessSettings(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe({
				next: (value) => {
					this.notificationService.success(
						'Updated settings successfully!'
					);
					this.sharedStore.dispatch(
						saveProductAccessSettings({
							payload: payload,
						})
					);
				},
				error: (err) => {
					this.notificationService.error(
						'Unable to update settings!'
					);
				},
			});
	}

	selectFile(event: string): void {
		this.uploadingFileFor = event;
		this.fileSelect.nativeElement.click();
	}

	uploadFile(event: any): void {
		this.fileUploading = true;
		this.uploadService
			.uploadFile(
				event.srcElement.files[0],
				event.srcElement.files[0].type.split('/').pop()
			)
			.subscribe({
				next: (res) => {
					if (res.type == HttpEventType.UploadProgress) {
						this.fileUploadProgress = Math.round(
							100 * (res.loaded / res.total)
						);
					}
					if (res.type === HttpEventType.Response) {
						this.pdForm
							.get(this.uploadingFileFor)
							.setValue(res.body?.data[0]?.filePath);
						setTimeout(() => {
							this.uploadingFileFor = undefined;
							this.fileUploading = false;
							this.fileUploadProgress = 0;
						}, 500);
					}
				},
			});
	}

	removeFile(settingName: string): void {
		this.pdForm.get(settingName).setValue(null);
	}

	previewFile(settingName: string): void {
		this.uploadService
			.getPresignedUrl(this.pdForm.get(settingName).value)
			.subscribe({
				next: (res) => {
					window.open(res.s3PresingedURL, '_blank');
				},
			});
	}
}
