import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserSettingsRoutingModule } from './user-settings-routing.module';
import { ManageCodeListComponent } from './components/manage-codes/manage-code-list/manage-code-list.component';
import { AddNewCodeComponent } from './components/manage-codes/add-new-code/add-new-code.component';
import { SettingsComponent } from './components/settings/settings.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MainSettingsComponent } from './components/main-settings/main-settings.component';
import { UserAccessSettingsComponent } from './components/user-access-settings/user-access-settings.component';
import { ProductAccessSettingsComponent } from './components/product-access-settings/product-access-settings.component';

@NgModule({
	declarations: [
		ManageCodeListComponent,
		AddNewCodeComponent,
		SettingsComponent,
		MainSettingsComponent,
		UserAccessSettingsComponent,
		ProductAccessSettingsComponent,
	],
	imports: [
		CommonModule,
		UserSettingsRoutingModule,
		SharedModule,
		ReactiveFormsModule,
	],
})
export class UserSettingsModule {}
