import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'slr-btn01',
  templateUrl: './slr-btn01.component.html',
  styleUrls: ['./slr-btn01.component.scss'],
})
export class SlrBtn01Component {
  @Input() public disabled: boolean = false  
  @Input() public icon: string 
  @Input() public themeClass: 'lms' | 'policy' | 'qrm' = 'lms'

  @Output() public onClick: EventEmitter<void>;

  constructor() {
    this.onClick = new EventEmitter<void>();
  }
  public clicked(): void {
    this.onClick.emit();
  }
}
