import { SelectionModel } from '@angular/cdk/collections';
import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { DropdownInterface } from '../slr-drp01/dropdown.model';

@Component({
	selector: 'slr-dpr02',
	templateUrl: './slr-dpr02.component.html',
	styleUrls: ['./slr-dpr02.component.scss'],
})
export class SlrDpr02Component implements OnInit, OnChanges, OnDestroy {
	@Input() public data: Array<DropdownInterface>;
	@Input() public searchFn: any;
	@Input() public fgName: FormGroup;
	@Input() public controlName: string;
	@Input() public icon: string = 'account_circle';
	@Input() public placeholder: string = 'Select';
	@Input() public label: string = 'Select';
	@Input() public showicon: boolean = true;
	@Input() public appendTo: string = '';

	@Output() public selectionChanged = new EventEmitter<
		Array<DropdownInterface>
	>();
	@Output() public itemAdded = new EventEmitter<DropdownInterface>();
	@Output() public itemRemoved = new EventEmitter<DropdownInterface>();
	// @Output() public selectionChanged = new EventEmitter<{
	// 	action: string;
	// 	data: Array<DropdownInterface>;
	// }>();

	public filteredData: Array<DropdownInterface>;
	public selectedItems = new SelectionModel<DropdownInterface>(true, []);
	public _selectedItem: DropdownInterface;

	private unsubscriber$ = new Subject<void>();
	private _data$ = new BehaviorSubject<Array<DropdownInterface>>(null);
	private action: 'added' | 'removed';

	constructor() {
		this.filterData();
	}

	ngOnInit(): void {
		this._data$.next(this.data);
		this.initSelection();
		this.selectedItems.changed
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe({
				next: (res) => {
					if (res.added.length > 0) this.itemAdded.emit(res.added[0]);
					if (res.removed.length > 0)
						this.itemRemoved.emit(res.removed[0]);
					this.selectionChanged.emit(res.source.selected);
				},
			});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['data'] && changes['data'].currentValue) {
			if (changes['data'].currentValue.length == 0) {
				this.fgName.get(this.controlName).setValue([]);
			}
			this._data$.next(this.data);
			this.initSelection();
		}
	}

	private initSelection(): void {
		if (!this.fgName) return;
		const selection: Array<number> = this.fgName.get(
			this.controlName
		).value;
		if (selection && this.data) {
			selection.forEach((x) => {
				if (this.data.find((y) => y.id === x)) {
					this.selectedItems.select(
						this.data.find((y) => y.id === x)
					);
				}
			});
		}
	}

	public selectEntry(event: DropdownInterface): void {
		if (!event || !event.id || !event.name) return;
		this.selectedItems.select(event);
		this._selectedItem = null;
		this.setFormValue();
	}

	public deselectEntry(event: DropdownInterface): void {
		if (!event || !event.id || !event.name) return;
		this.selectedItems.deselect(event);
		this._selectedItem = null;
		this.setFormValue();
	}

	private filterData(): void {
		const data$ = this._data$.asObservable();
		data$.pipe(takeUntil(this.unsubscriber$)).subscribe({
			next: (res) => (this.filteredData = res),
		});
	}

	private setFormValue(): void {
		const items = this.selectedItems.selected;
		if (this.fgName) {
			this.fgName
				.get(this.controlName)
				.setValue(items.length === 0 ? null : items.map((x) => x.id));
		}
	}

	public reset(): void {
		this.fgName.get(this.controlName).setValue(null);
		this._data$.next(null);
		this.selectedItems.clear();
		this.data = null;
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.unsubscribe();
	}
}
