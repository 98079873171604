<!-- <div class="form-group">
  <input
    type="text"
    [placeholder]="placeholder"
    [value]="value"
    
    [required]="required"
    class="slr-gray form-control"
  />
  <label
    *ngIf="label"
    class="label"
    [class.mandatory]="required"
    aria-hidden="true"
  >
    {{ label | translate }}
  </label>
</div> -->

<mat-form-field [style.width]="width" class="slr-gray-input" appearance="fill">
  <mat-label *ngIf="label"> {{ label | translate }}</mat-label>
  <input matInput [placeholder]="placeholder" />
</mat-form-field>
