<div class="mt-3 d-flex col-12">
	<div class="float-start dropdown-div">
		<ng-select
			[items]="localData.pageLimits"
			[clearable]="false"
			[searchable]="false"
			[(ngModel)]="tableDetails.limit"
			(change)="changeLimit($event)"
		></ng-select>
	</div>
	<div class="ms-auto">
		<app-pagination
			[collectionSize]="tableDetails.contentSize"
			[pageSize]="
				tableDetails.limit === localData.allContents
					? tableDetails.contentSize
					: tableDetails.limit
			"
			[page]="
				tableDetails.limit === localData.allContents
					? 1
					: tableDetails.offset / tableDetails.limit + 1
			"
			(pageChange)="changePage($event)"
		></app-pagination>
	</div>
</div>
