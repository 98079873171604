import {
	createFeatureSelector,
	createReducer,
	createSelector,
	on,
} from '@ngrx/store';
import { UserDetailsInterface } from 'src/app/core/models/user-details.interface';
import {
	DataInterface,
	NullDataInterface,
} from 'src/app/state/state-entities/data-interface.model';
import {
	loginFailure,
	loginSuccess,
	logout,
	saveRedirect,
	saveSessionExpiryTime,
	saveTempUsername,
	saveToken,
	saveUserSessionId,
	updateLastActivityTime,
} from './authentication.action';

export interface AuthenticationState {
	isAuthenticated: boolean;
	jwtToken: DataInterface<string> | DataInterface<null>;
	userDeatils: DataInterface<UserDetailsInterface> | DataInterface<null>;
	idToken: string;
	refreshToken: string;
	redirectUrl: string | null;
	tempUsername: {
		username: string;
		password: string;
	};
	userSessionId: number;
	sessionExpiryTime: number;
	lastActivityTime: number;
}

export const initialAuthState: AuthenticationState = {
	isAuthenticated: false,
	jwtToken: NullDataInterface,
	userDeatils: NullDataInterface,
	idToken: null,
	refreshToken: null,
	redirectUrl: null,
	tempUsername: null,
	userSessionId: null,
	sessionExpiryTime: null,
	lastActivityTime: Date.now(),
};

const getAuthenticationState =
	createFeatureSelector<AuthenticationState>('authentication');

export const getUserName = createSelector(
	getAuthenticationState,
	(state) => state.userDeatils.data.username
);
export const getUserSessionId = createSelector(
	getAuthenticationState,
	(state) => state && state.userSessionId
);
export const getTemporaryUsername = createSelector(
	getAuthenticationState,
	(state) => state.tempUsername
);
export const getToken = createSelector(
	getAuthenticationState,
	(state) => state && state.jwtToken && state.jwtToken.data
);
export const getIDToken = createSelector(
	getAuthenticationState,
	(state) => state && state.idToken
);

export const getSessionExpiryTime = createSelector(
	getAuthenticationState,
	(state) => state && state.sessionExpiryTime
);

export const getLastActivityTime = createSelector(
	getAuthenticationState,
	(state) => state && state.lastActivityTime
);

export const getRefreshToken = createSelector(
	getAuthenticationState,
	(state) => state && state.refreshToken
);

export const isLoggedIn = createSelector(getAuthenticationState, (state) => {
	return state &&
		state.jwtToken &&
		state.refreshToken &&
		state.sessionExpiryTime &&
		Date.now() < state.sessionExpiryTime
		? true
		: false;
});

export const authenticationReducer = createReducer(
	initialAuthState,
	on(loginSuccess, (state, action) => {
		return {
			...state,
			isAuthenticated: true,
			userDeatils: {
				...state.userDeatils,
				isLoaded: true,
				isLoading: false,
				data: action.user,
			},
		};
	}),
	on(saveToken, (state, action) => {
		return {
			...state,
			jwtToken: {
				...state.jwtToken,
				data: action.token && action.token.accessToken,
				isLoaded: true,
				isLoading: false,
			},
			idToken: action.token && action.token.idToken,
			refreshToken: action.token && action.token.refreshToken,
		};
	}),
	on(saveSessionExpiryTime, (state, action) => {
		return {
			...state,
			sessionExpiryTime: action.time,
		};
	}),
	on(saveUserSessionId, (state, action) => {
		return {
			...state,
			userSessionId: action.sessionId,
		};
	}),
	on(loginFailure, (state, action) => {
		return {
			...state,
			isAuthenticated: false,
			userDeatils: {
				...state.userDeatils,
				isLoaded: false,
				isLoading: false,
				errorCode: action.error,
			},
		};
	}),
	on(saveTempUsername, (state, action) => {
		return {
			...state,
			tempUsername: {
				username: action.username,
				password: action.password,
			},
		};
	}),
	on(saveRedirect, (state, action) => {
		return {
			...state,
			redirectUrl: action.redirectUrl,
		};
	}),
	on(logout, (state, action) => initialAuthState),
	on(updateLastActivityTime, (state, action) => {
		return {
			...state,
			lastActivityTime: action.time,
		};
	})
);
