<div class="reports-header reports">
	<h1>
		{{ "REPORTS_CAPS" | translate }}
	</h1>
	<div class="d-flex align-items-end">
		<ul
			ngbNav
			#nav="ngbNav"
			[(activeId)]="selectedIndex"
			class="nav-tabs mt-3"
		>
			<li
				[ngbNavItem]="i"
				isAuthorised
				[action]="tab.privilegeKey"
				*ngFor="let tab of reportTabs; index as i"
				(click)="openReport(tab.url)"
			>
				<a ngbNavLink [href]="tab.url">{{
					tab.name | translate | uppercase
				}}</a>
			</li>
		</ul>
	</div>
</div>
<div class="reports">
	<router-outlet></router-outlet>
</div>
