import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	Resolve,
	Router,
	RouterStateSnapshot,
} from '@angular/router';
import { Observable, take } from 'rxjs';
import { AddNewCodeInterface } from '../_models/manage-codes.interface';
import { SettingsService } from '../_services/settings.service';

@Injectable({
	providedIn: 'root',
})
export class AddNewCodeResolver implements Resolve<any> {
	constructor(private settingsService: SettingsService) {}
	resolve(): Observable<AddNewCodeInterface | null> {
		return this.settingsService.getAllCodes().pipe(take(1));
	}
}
