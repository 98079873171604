<nav #headerNav class="fixed-top header custom-scrollbar">
	<div class="content-wrapper">
		<div class="header-branding">
			<a class="navbar-brand" id="navLink" href="#">
				<img class="logo" [src]="logoImgSrc" alt="Logo" />
			</a>
			<span
				*ngIf="parentData?.length > 1; else childLocationNameDisplay"
				class="mobile-head"
			>
				<app-site-location
					#parent
					[id]="'sup-site'"
					[classes]="'mr-20'"
					[options]="parentData"
					(selectChange)="handleSelectChange($event)"
				></app-site-location>
				<app-site-location
					#child
					[id]="'sub-site'"
					*ngIf="showChild"
					[options]="childData"
					[placeholder]="'Sub Location'"
					(selectChange)="handleSelectChangeSubLocation($event)"
				></app-site-location>
				<app-site-location
					#linkedUser
					[id]="'linked-user'"
					*ngIf="showLinkedUser"
					[options]="linkeUserData"
					[placeholder]="'Linked Accounts'"
					(selectChange)="handleLinkedUserChange($event)"
				></app-site-location>
			</span>
			<ng-template #childLocationNameDisplay>
				<span class="child-location-header">{{
					headerNameForChildLocations
				}}</span>
			</ng-template>
		</div>

		<div class="header-options">
			<span
				class="material-icons-outlined header-options-icons mobile-noti"
				[matTooltip]="'HELP' | translate"
				(click)="getHelp()"
				(keyup)="getHelp()"
			>
				help_outline
			</span>
			<span class="notification mobile-noti" (click)="openMessagePopup()" (keyup)="openMessagePopup()">
				<span
					class="material-icons-outlined header-options-icons mobile-noti"
					[matTooltip]="'MESSAGES' | translate"
				>
					mail
				</span>
				<span class="badge" *ngIf="messageCount">{{
					messageCount
				}}</span>
			</span>
			<span
				class="notification mobile-noti"
				(click)="openNotificationPopup()"
				(keyup)="openNotificationPopup()"
				[matTooltip]="'NOTIFICAIONS' | translate"
			>
				<span class="material-icons-outlined header-options-icons"
					>notifications</span
				>
				<span class="badge" *ngIf="notificationCount">{{
					notificationCount
				}}</span>
			</span>
			<div class="header-options-profile" ngbDropdown>
				<span
					class="header-options-icons profile-shadow"
					*ngIf="true"
					ngbDropdownToggle
					[matTooltip]="'MY_PROFILE' | translate"
				>
					<img [src]="picPath" alt="profile" class="profile-image" />
				</span>
				<div
					ngbDropdownMenu
					aria-labelledby="dropdown-options"
					class="profile-menu"
				>
					<span
						isAuthorised
						[action]="permissionHelper.myProfileModule.MODULE_NAME"
						(click)="navigate('profile')"
						(keyup)="navigate('profile')"
						ngbDropdownItem
						class="profile-menu-items"
						>{{ "MY_PROFILE" | translate }}</span
					>
					<span
						(click)="changePassword()"
						(keyup)="changePassword()"
						ngbDropdownItem
						class="profile-menu-items"
						>{{ "CHANGE_PASSWORD_TEXT" | translate }}</span
					>
					<span
						isAuthorised
						[action]="permissionHelper.Broadcastmodule.MODULE_NAME"
						(click)="addBrodcast()"
						(keyup)="addBrodcast()"
						ngbDropdownItem
						class="profile-menu-items"
						>{{ "ADD_BROADCAST" | translate }}</span
					>
					<span
						ngbDropdownItem
						class="profile-menu-items menu-items-hide"
						(click)="openMessagePopup()"
						(keyup)="openMessagePopup()"
					>
						{{ "MESSAGES" | translate }}
						<span *ngIf="messageCount"> - {{ messageCount }} </span>
					</span>
					<span
						ngbDropdownItem
						class="profile-menu-items menu-items-hide"
						(click)="openNotificationPopup()"
						(keyup)="openNotificationPopup()"
					>
						{{ "NOTIFICAIONS" | translate }}
						<span *ngIf="notificationCount">
							- {{ notificationCount }}
						</span>
					</span>
					<span
						ngbDropdownItem
						class="profile-menu-items menu-items-hide"
						(click)="getHelp()"
						(keyup)="getHelp()"
					>
						{{ "HELP" | translate }}
					</span>
					<span
						(click)="logout()"
						(keyup)="logout()"
						ngbDropdownItem
						class="profile-menu-items"
						>{{ "LOGOUT" | translate }}</span
					>
				</div>
				<span
					class="material-icons-outlined header-options-icons"
					*ngIf="false"
				>
					person
				</span>
			</div>
		</div>
	</div>
</nav>
<app-confirmation-modal
	[openModal]="openConfirmationModal"
	[modalSize]="confirmationModalSize"
	(modalClosed)="closeConfirmationModal($event)"
>
	<div
		class="zendesk-article px-4 custom-scrollbar"
		*ngIf="articles?.length && articleToShow"
	>
		<div class="d-flex align-items-center justify-content-between">
			<h4 class="mb-2">{{ articleToShow?.title }}</h4>
			<div
				[ngbPopover]="popContent"
				[container]="'body'"
				triggers="mouseenter:mouseleave"
			>
				<a
					class="material-icons-outlined new-window-icon"
					[href]="articleToShow?.html_url"
					target="_blank"
				>
					launch
				</a>
			</div>
			<ng-template #popContent>
				<p
					class="text-start m-0 tooltip-header"
					[innerHTML]="'View Original Article'"
				></p>
			</ng-template>
		</div>
		<div [innerHTML]="articleToShow?.body"></div>
	</div>
	<div
		class="zendesk-article px-4"
		*ngIf="!articles?.length || !articleToShow"
	>
		<h6 class="mb-4 text-center">
			{{ "Articles Not Available!" | translate }}
		</h6>
	</div>
</app-confirmation-modal>
