import { Component, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { QuestionsInterface } from 'src/app/core/models/take-test.interface';
import { SlrUrlQueryParams } from 'src/app/core/models/url-params.enum';
import { DropdownInterface } from 'src/app/shared/components/dropdowns/slr-drp01/dropdown.model';


@Component({
	selector: 'survey-option',
	templateUrl: './survey-option.component.html',
	styleUrls: ['./survey-option.component.scss'],
})
export class SurveytOptionComponent implements OnDestroy {
	@Input() public question: QuestionsInterface;
	@Input() public optionType: 'mcq' | 'multiple' | 'paragraph' | 'slider';
	@Input() public option: DropdownInterface;

	public isShowTestAnswer: boolean;
	public isShowSurvey: boolean;

	public optionCorrect: boolean;
	public optionSelected: boolean;
	public selectedAnswers: {};
	public paraghraphAnswer: string = '';

	private unsubscriber = new Subject<void>();

	constructor(
		private _route: ActivatedRoute,
	) {
		this._route.queryParams
			.pipe(takeUntil(this.unsubscriber))
			.subscribe((params) => {
				this.isShowTestAnswer =
					params[SlrUrlQueryParams.SHOW_TEST_ANSWERS];
			});
		this._route.url.pipe(takeUntil(this.unsubscriber)).subscribe((url) => {
			this.isShowSurvey = url.toString().includes('preview-survey');
		});
	}

	ngOnDestroy(): void {
		this.unsubscriber.next();
		this.unsubscriber.unsubscribe();
	}
}
