import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import moment from 'moment';
import {
	BehaviorSubject,
	catchError,
	filter,
	iif,
	map,
	mergeMap,
	Observable,
	of,
	throwError,
} from 'rxjs';
import { UrlNames } from 'src/app/core/services/urlProfiler';
import { BaseWebApiService } from 'src/app/core/services/_base-web-api.service';
import {
	getSelectedLocation,
	getUserPrivileges,
	SharedState,
} from 'src/app/shared/_state/shared.reducer';
import { BulkAssignSkillToUsersPayloadInterface } from '../../user-profile/_models/profile-skills.interfaces';
import { ActivateUserApiInterface } from '../_models/activate-user.interface';
import { CustomiseTableAPIInterface } from '../_models/customise-table.interface';
import { InctivateUserApiInterface } from '../_models/inactive-user.interface';
import {
	EmailToUserResetPassowordAPIInterface,
	EmailUserAPIInterface,
	NewUserDetailsFormInterface,
	NewUserGroupInterface,
	UserLocationAPIInterface,
} from '../_models/new-user-.model';
import {
	EducationGroupAPIInterface,
	SupervisorInterface,
} from '../_models/supervisors.interface';
import { UserClassInterface } from '../_models/user-class.model';
import { UserEducationHistoryInterface } from '../_models/user-education-history.interface';
import { UserInactivationReasonInterface } from '../_models/user-skill.interface';
import { UserStatusHistoryInterface } from '../_models/user-status-history.interface';

import { SLRUser, SLRUserAPI } from '../_models/users.interface';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LocalDataService } from 'src/app/core/services/local-data.service';
import { EncryptionService } from 'src/app/core/services/encryption.service';

@Injectable({
	providedIn: 'root',
})
export class ManageUserService {
	public user = new BehaviorSubject<NewUserDetailsFormInterface | null>(null);
	private _locationId: number;
	private _clientCode: string;
	private _rootLocationId: number;
	private _userId: number;
	private _userClassId: number;
	public getUserParams = {
		status: 1,
		groups: null,
	};
	constructor(
		private baseApi: BaseWebApiService,
		private sharedStore: Store<SharedState>,
		private http: HttpClient,
		private localData: LocalDataService,
		private encryptionService: EncryptionService
	) {
		this.sharedStore
			.pipe(
				select(getUserPrivileges),
				filter((value) => !!value)
			)
			.subscribe({
				next: (res) => {
					this._userId = res.userId;
					this._rootLocationId = res.locationId;
					this._userClassId = res.userClassId;
				},
			});
		this.sharedStore
			.pipe(
				select(getSelectedLocation),
				filter((value) => !!value || !!value.location)
			)
			.subscribe({
				next: (res) => {
					this._clientCode = res?.location?.sitecode;
					this._locationId = res?.location?.id;
				},
			});
	}

	public getUsers2(
		getDefaultList: boolean = false
	): Observable<Array<SLRUser>> {
		return this.baseApi
			.get<SLRUserAPI[]>(
				`${UrlNames.users}`,
				{
					locationId: this._locationId ?? this._rootLocationId,
					status: this.getUserParams.status,
					group: getDefaultList ? null : this.getUserParams.groups,
					userId: this._userId,
				}
			)
			.pipe(
				map((res: SLRUserAPI[]) => {
					return res.map((user) => {
						let tableUser: SLRUser = {
							id: user.userId,
							name: user.name,
							edGroups: this.getEducationGroup(
								user.educationGroups
							),
							accessClass: user.accessClass,
							dateActive: user.activeDate,
							dateEntered: user.enteredDate,
							email: user.email,
							location: user.locationName,
							status: user.activeFlag ? 'active' : 'inactive',
							orientationMode: user.orientationMode,
							_orientationMode: user.orientationMode ? 1 : null,
							supervisor: this.getSupervisorJoined(
								user.primaryManager,
								user.secondaryManager
							),
							username: user.username,
							profileLinkId: user.profileLinkId,
							custom1: user.custom1,
							custom2: user.custom2,
							custom3: user.custom3,
							reason:
								user.activeFlag == false
									? user.reason ?? ''
									: '',
							inactiveDate:
								user.activeFlag == false
									? user.inactiveDate ?? '0000-00-00'
									: '0000-00-00',
						};
						return tableUser;
					});
				}),
				map((users: SLRUser[]) => {
					const sortKey = 'name';
					return users.sort((a, b) => {
						let aValue = a[sortKey];
						let bValue = b[sortKey];
						if (aValue == '' || aValue == null) aValue = 'zzzz';
						if (bValue == '' || bValue == null) bValue = 'zzzz';

						return aValue
							.toString()
							.toLowerCase()
							.localeCompare(bValue.toString().toLowerCase());
					});
				})
			);
	}

	public activateUser(payload: ActivateUserApiInterface): Observable<Object> {
		return this.baseApi.put(
			`${UrlNames.activateUsers}/${this._userId}`,
			payload
		);
	}
	public inactivateUser(
		payload: InctivateUserApiInterface
	): Observable<Object> {
		return this.baseApi.put(
			`${UrlNames.inactivateUsers}/${this._userId}`,
			payload
		);
	}

	public getUserReasons(): Observable<
		Array<UserInactivationReasonInterface>
	> {
		return this.baseApi.get(`${UrlNames.userInactivationReasons}`, null);
	}

	private getSupervisorJoined(primary: string, secondary: string): string {
		if (!primary && !secondary) return '';
		if (!primary && secondary) return secondary;
		if (primary && !secondary) return primary;
		return `${primary}, ${secondary}`;
	}

	private getEducationGroup(x: Array<EducationGroupAPIInterface>): string {
		const groups = x.map((x) => x.educationGroupName);
		return groups.join(', ');
	}

	public getUserDetail(): Observable<NewUserDetailsFormInterface | null> {
		return this.user.asObservable();
	}

	public setUserDetails(value: NewUserDetailsFormInterface): void {
		this.user.next(value);
	}

	public getUserClasses(): Observable<UserClassInterface[]> {
		return this.baseApi.get<UserClassInterface[]>(
			`${UrlNames.userClasses}`,
			{ loginUserClassId: this._userClassId }
		);
	}

	public getSupervisors(): Observable<SupervisorInterface[]> {
		return this.baseApi.get<SupervisorInterface[]>(
			`${UrlNames.userSupervisors}`,
			{ locationId: this._locationId ?? this._rootLocationId }
		);
	}

	public getUserLocationData(
		root: boolean = false
	): Observable<UserLocationAPIInterface> {
		return this.baseApi.get<UserLocationAPIInterface>(
			`${UrlNames.userLocations}`,
			{ locationId: root ? this._rootLocationId : this._locationId }
		);
	}

	public getEducationGRoups(
		type: string = 'LMS'
	): Observable<Array<EducationGroupAPIInterface>> {
		return this.baseApi.get<Array<EducationGroupAPIInterface>>(
			`${UrlNames.educationGroups}`,
			{
				locationId: this._locationId ?? this._rootLocationId,
				type: type,
				userId: this._userId,
			}
		);
	}

	/**
	 * A different flow to add new user where we first
	 * register the user on cognito and then we add it to our database.
	 * Problem with previous flow - Not able to decide if the username already exists.
	 *
	 * 5/5/2023 - flow change  (details in SLR-1896)
	 * @param userDetails
	 * @returns
	 */

	public createNewUser(
		userDetails: NewUserDetailsFormInterface
	): Observable<any> {
		return this.baseApi.get(`${UrlNames.getSecretKey}`, {}).pipe(
			mergeMap((response: any) => {
				let key = response?.secretKey;
				let newUserDetails = {
					...userDetails,
					email: this.encryptionService.encrypt(
						userDetails.email,
						key
					),
					userName: this.encryptionService.encrypt(
						userDetails.userName,
						key
					),
					isSupervisor: userDetails.isSupervisor ? true : false,
					isOrientation: userDetails.isOrientation ? true : false,
					notifyPasswordChange: userDetails.notifyPasswordChange
						? true
						: false,
					activeFlag: userDetails.activeFlag ? true : false,
					surgeCorespondenceCheck: userDetails.surgeAlertsCheck
						? true
						: false,
					surgeAlertsCheck: userDetails.surgeAlertsCheck
						? true
						: false,
					reviewSkillsAlertsCheck: userDetails.reviewSkillsAlertsCheck
						? true
						: false,
					locationId : userDetails.location,
				};
				delete newUserDetails.location;
				const username = `${userDetails.clientCode}.${userDetails.userName}`;
				const password = userDetails.password;
				const email = userDetails.email;
				const passwordChange = userDetails.notifyPasswordChange;
				return this.baseApi
					.post<any>(
						`${UrlNames.userRegister}/${this._userId}`,
						newUserDetails,
						undefined,
						undefined,
						undefined,
						undefined,
						0
					)
					.pipe(
						mergeMap((res: any) => {
							return this.baseApi
								.post<{
									message: string;
									statusCode: string;
								}>(
									`${UrlNames.userRegisterOnCognito}`,
									{
										userName:
											this.encryptionService.encrypt(
												username,
												key
											),
										password:
											this.encryptionService.encrypt(
												password,
												key
											),
										email: this.encryptionService.encrypt(
											email,
											key
										),
										activeFlag: res.activeFlag,
										userId: res.userId,
										locationId: userDetails.location,
									},
									{
										passwordChange: passwordChange ?? false,
									},
									undefined,
									undefined,
									undefined,
									0
								)
								.pipe(
									map((res2) => {
										return {
											...res2,
											...res,
										};
									})
								);
						})
					);
			})
		);
	}

	public removeUserInCognito(
		username: string,
		clientCode: string
	): Observable<any> {
		return this.baseApi.delete(
			UrlNames.removeUserInCognito,
			undefined,
			undefined,
			undefined,
			{
				clientCode,
				username,
			}
		);
	}

	public updateUserDetails(
		userDetails: NewUserDetailsFormInterface
	): Observable<Object> {
		return this.baseApi.get(`${UrlNames.getSecretKey}`, {}).pipe(
			mergeMap((response: any) => {
				let key = response?.secretKey;
				let payload = {
					...userDetails,
					email: this.encryptionService.encrypt(
						userDetails.email,
						key
					),
					userName: this.encryptionService.encrypt(
						userDetails.userName,
						key
					),
					educationGroups:
						userDetails.educationGroups &&
						userDetails.educationGroups.map((x) => x.groupId),
					removeEducationGroups:
						userDetails.removeEducationGroups &&
						userDetails.removeEducationGroups.map((x) => x.groupId),
					addEducationGroups:
						userDetails.addEducationGroups &&
						userDetails.addEducationGroups.map((x) => {
							return {
								...x,
								triggerId: !x.triggerId ? 0 : x.triggerId,
							};
						}),
					locationId : userDetails.location
				};
				delete payload.location
				return this.baseApi.put(
					`${UrlNames.usersUpdate}/${this._userId}`,
					payload
				);
			})
		);
	}

	public addEdGroup(payload: {
		sourceEducationGroupId: number;
		targetEducationGroupId: number;
	}): Observable<Object> {
		const newPayload = {
			...payload,
			locationId: this._locationId,
			loginUserId: this._userId,
		};
		return this.baseApi.post(`${UrlNames.addEdGroup}`, newPayload);
	}
	public changeEdGroup(payload: {
		sourceEducationGroupId: number;
		targetEducationGroupId: number;
	}): Observable<Object> {
		const newPayload = {
			...payload,
			locationId: this._locationId,
			loginUserId: this._userId,
		};
		return this.baseApi.put(`${UrlNames.changeEdGroup}`, newPayload);
	}
	public removeEdGroup(id: number): Observable<Object> {
		return this.baseApi.delete(
			`${UrlNames.removeEdGroup}/${id}`,
			undefined,
			undefined,
			undefined,
			{
				locationId: this._locationId,
				loginUserId: this._userId,
			}
		);
	}
	public removeAssociateEdGroup(
		targetId: number,
		sourceId: number
	): Observable<Object> {
		return this.baseApi.delete(
			`${UrlNames.removeAssociateEdGroup}/${targetId}/${sourceId}`,
			undefined,
			undefined,
			undefined,
			{
				locationId: this._locationId,
				loginUserId: this._userId,
			}
		);
	}
	public getUserDetails(
		id: number,
		activeFlag: number
	): Observable<NewUserDetailsFormInterface> {
		return this.baseApi
			.get<NewUserDetailsFormInterface>(`${UrlNames.userDetails}/${id}`, {
				loginUserId: this._userId,
				activeFlag: activeFlag ?? 1,
			})
			.pipe(
				map((x) => {
					return {
						...x,
						password: null,
						notifyPasswordChange: null,
					};
				})
			);
	}
	public showUserEducationHistory(
		userId: number
	): Observable<Array<UserEducationHistoryInterface>> {
		return this.baseApi
			.get(`${UrlNames.showUserEducationHistory}`, {
				loginUserId: userId,
			})
			.pipe(
				map((data) => {
					const _data: UserEducationHistoryInterface[] = data['data'];
					return _data.map((x) => {
						return {
							...x,
							activationDate:
								x.activationDate == null
									? ''
									: moment(x.activationDate).format(
											'YYYY-MM-DD  h:mm A'
									  ),
							endDate:
								x.endDate == null
									? ''
									: moment(x.endDate).format(
											'YYYY-MM-DD  h:mm A'
									  ),
						};
					});
				}),
				catchError((err) => {
					if (err && err.error && err.error.status == 404) {
						return of([]);
					}
					return of(null);
				})
			);
	}
	public showUserStatusHistory(
		userId: number
	): Observable<Array<UserStatusHistoryInterface>> {
		return this.baseApi
			.get(`${UrlNames.showUserStatusHistory}`, {
				userId: userId,
			})
			.pipe(
				map((data) => {
					return (
						(data &&
							data['data'] &&
							data['data'].map((x) => {
								return {
									...x,
									reason: x.reason ?? 'ACTIVE',
								};
							})) ??
						[]
					);
				}),
				catchError((err) => {
					if (
						err.error &&
						err.error.status == 404 &&
						err.error.message == 'No data found'
					) {
						return of([]);
					}
					return of(err.error.message);
				})
			);
	}

	public updatePrimarySupervisor(
		oldId: number,
		newId: number
	): Observable<Object> {
		const body = {
			sourceManagerId: oldId,
			targetManagerId: newId,
		};
		return this.baseApi.patch(
			`${UrlNames.manager}/${UrlNames.primary}/${this._userId}`,
			body
		);
	}
	public updatePrimarySupervisorByGroup(
		edId: number,
		supId: number
	): Observable<Object> {
		const body = {
			educationGroupId: edId,
			managerId: supId,
		};
		return this.baseApi.patch(
			`${UrlNames.manager}/${UrlNames.primary}/${UrlNames.bygroup}/${this._userId}`,
			body
		);
	}
	public updateSecondarySupervisor(
		oldId: number,
		newId: number
	): Observable<Object> {
		const body = {
			sourceManagerId: oldId,
			targetManagerId: newId,
		};
		return this.baseApi.patch(
			`${UrlNames.manager}/${UrlNames.secondary}/${this._userId}`,
			body
		);
	}
	public updateSecondarySupervisorByGroup(
		edId: number,
		supId: number
	): Observable<Object> {
		const body = {
			educationGroupId: edId,
			managerId: supId,
		};
		return this.baseApi.patch(
			`${UrlNames.manager}/${UrlNames.secondary}/${UrlNames.bygroup}/${this._userId}`,
			body
		);
	}

	public loadUsersOrientationGroupsData(userIds: string): Observable<any> {
		return this.baseApi
			.get(
				`${UrlNames.loadUsersOrientationGroupsData}?userIds=${userIds}`,
				undefined
			)
			.pipe(
				map((res) => {
					if (res && res['data']) {
						const totalUsers = userIds
							.split(',')
							.map((x) => parseInt(x));
						const totalUsersCount = totalUsers.length;
						if (totalUsersCount > res['data'].length) {
							const nonExistingUserIds = totalUsers.filter(
								(x) => !res['data'].find((y) => y.userId == x)
							);
							return [
								...res['data'],
								...nonExistingUserIds.map((x) => {
									return {
										userId: x,
										orientationGrops: false,
									};
								}),
							];
						}
						return res['data'];
					}
					return [];
				})
			);
	}

	public bulkAssignSkillToUsers(
		payload: BulkAssignSkillToUsersPayloadInterface
	): Observable<any> {
		payload = {
			...payload,
			locationId: this._locationId,
		};
		return this.baseApi.post(
			`${UrlNames.bulkAssign}`,
			payload,
			{
				loginUserId: this._userId,
			},
			undefined,
			undefined,
			undefined,
			0
		);
	}

	public importUsers(file: File): Observable<any> {
		const formData = new FormData();
		formData.append('file', file);
		return this.baseApi.post(
			`${UrlNames.importUsers}/${this._userId}`,
			formData
		);
	}

	public getUserTableRecords(
		report: string = 'manage_users'
	): Observable<any> {
		return this.baseApi.get(
			`${UrlNames.customiseUserTable}/${this._userId}`,
			{
				report: report,
				clientCode: this._clientCode,
				locationId: this._locationId,
			}
		);
	}

	public saveUserTableRecords(
		payload: CustomiseTableAPIInterface
	): Observable<any> {
		const params = {
			report: 'manage_users',
			clientCode: this._clientCode,
			locationId: this._locationId,
		};

		return this.baseApi.post(
			`${UrlNames.customiseUserTable}/${this._userId}`,
			payload,
			params
		);
	}

	public getLocations(): Observable<any> {
		return this.baseApi.get(`${UrlNames.getLocations}`, {
			locationId: this._locationId,
		});
	}
	public getUsersInLocation(id: number): Observable<any> {
		return this.baseApi.get(`${UrlNames.getUserByLocation}`, {
			locationId: id,
		});
	}

	public getLinkedUsers(id: number): Observable<any> {
		return this.baseApi.get(`${UrlNames.linkedProfiles}/${id}`, null);
	}

	public getLinkedLocations(id: number): Observable<any> {
		return this.baseApi.get(`${UrlNames.lnkedLocations}/${id}`, null);
	}

	public linkProfile(payload: {
		currentUserId: number;
		linkedUserId: number;
	}): Observable<any> {
		return this.baseApi.post(
			`${UrlNames.linkProfiles}/${this._userId}`,
			payload
		);
	}

	public deletePorfileLinking(payload: {
		currentUserId: number;
		linkedUserId: number;
		action: 'delete' | 'unlink';
	}): Observable<any> {
		return this.baseApi.delete(
			`${UrlNames.linkedProfiles}`,
			null,
			undefined,
			undefined,
			payload
		);
	}

	public changePasswordPrivilege(payload: {
		notifyPasswordChange: boolean;
		password: string;
		username: string;
	}): Observable<any> {
		return this.baseApi.get(`${UrlNames.getSecretKey}`, {}).pipe(
			mergeMap((response: any) => {
				let key = response?.secretKey;
				let encryptedPayload = {
					notifyPasswordChange: payload.notifyPasswordChange,
					password: this.encryptionService.encrypt(
						payload.password,
						key
					),
					username: this.encryptionService.encrypt(
						payload.username,
						key
					),
				};
				return this.baseApi.put(
					`${UrlNames.changePasswordByPrivilegeUser}`,
					encryptedPayload
				);
			})
		);
	}

	public changeUsername(payload: {
		cognitoUsername: string;
		username: string;
	}): Observable<any> {
		return this.baseApi.post(`${UrlNames.changeUsername}`, payload).pipe(
			mergeMap((res: any) =>
				iif(
					() => res.error,
					throwError(() => {
						if (
							res.error &&
							res.error.status == 500 &&
							res.error.message.includes('User does not exist')
						) {
							return 'Some error occurred. Please contact admin.';
						}

						return res.error.message;
					}),
					of(res)
				)
			)
		);
	}

	public emailUsername(payload: EmailUserAPIInterface): Observable<any> {
		payload = {
			...payload,
			clientCode: this._clientCode,
			userId: this._userId,
			locationId: this._locationId,
		};
		return this.baseApi.post(`${UrlNames.emailUsername}`, payload);
	}

	public emailtoRestPassword(
		payload: EmailToUserResetPassowordAPIInterface
	): Observable<any> {
		payload = {
			...payload,
			loggedInUserId: this._userId,
			locationId: this._locationId,
		};
		return this.baseApi.post(`${UrlNames.emailtoRestPassword}`, payload);
	}

	public transferUserDataFromOneLocationToAnother(
		userId: number
	): Observable<any> {
		return this.http
			.post<HttpResponse<Blob>>(
				`${environment.cloudApiUrl}${UrlNames.transferUserDataFromOneLocationToAnother}`,
				{
					clientCode: this._clientCode,
					fromLocationIdLocation: this._locationId,
					userId,
				},
				{
					params: {
						userId: this._userId,
					},
					observe: 'response',
					responseType: 'text' as 'json',
				}
			)
			.pipe(
				map((res) => {
					console.log(
						'in response',
						res.headers.get('content-disposition')
					);
					return {
						fileName: this.getFileNameFromContentDisposition(
							res.headers.get('content-disposition')
						),
						res,
					};
				})
			);
	}

	public importUserData(userId: number, file: File): Observable<any> {
		const formData = new FormData();
		formData.append('exportedFile', file);
		return this.baseApi.post(
			`${UrlNames.importUserData}`,
			formData,
			{
				userId: userId,
			},
			undefined,
			undefined,
			undefined,
			undefined
		);
	}

	private getFileNameFromContentDisposition(
		contentDisposition: string | null
	): string {
		if (!contentDisposition) {
			return 'downloaded_file.bin';
		}

		const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
			contentDisposition
		);
		if (matches != null && matches[1]) {
			return matches[1].replace(/['"]/g, '');
		}

		return 'downloaded_file.bin';
	}
}
