import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'dataPropertyGetter',
})
export class DataPropertyGetterPipe implements PipeTransform {
	transform(value: any, keyName: string, ...args: any[]) {
		return value && value[keyName];
	}
}
