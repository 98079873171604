import {
	AfterViewInit,
	Component,
	Input,
	OnChanges,
	OnDestroy,
} from '@angular/core';
import { Chart, ChartConfiguration, ChartItem } from 'chart.js';

@Component({
	selector: 'app-doughnut-chart',
	templateUrl: './doughnut-chart.component.html',
	styleUrls: ['./doughnut-chart.component.scss'],
})
export class DoughnutChartComponent
	implements OnChanges, AfterViewInit, OnDestroy
{
	public chart: Chart;
	@Input() chartData: {
		id: number | string;
		labels: string[];
		values: number[];
	};

	ngOnChanges(): void {
		this.initializeChart();
	}
	ngAfterViewInit(): void {
		this.initializeChart();
	}
	ngOnDestroy(): void {
		this.chart.destroy();
	}
	private initializeChart(): void {
		//Remove Existing Chart
		const existingCanvas: HTMLElement = document.getElementById(
			`doughnut-chart-${this.chartData.id}`
		);
		if (existingCanvas) {
			existingCanvas.remove();
		}

		// Create New Chart Element
		const chartContainer = document.getElementById(
			`doughnut-chart-container-${this.chartData.id}`
		);
		const newChartElement = document.createElement('canvas');
		newChartElement.setAttribute(
			'id',
			`doughnut-chart-${this.chartData.id}`
		);
		newChartElement.setAttribute('width', '200');
		newChartElement.setAttribute('height', '200');
		chartContainer?.appendChild(newChartElement);

		// Update Chart
		const element = <HTMLCanvasElement>(
			document.getElementById(`doughnut-chart-${this.chartData.id}`)
		);
		if (!element) {
			return;
		}
		const config: ChartConfiguration = {
			type: 'doughnut',
			data: {
				labels: this.chartData.labels,
				datasets: [
					{
						data: this.chartData.values,
						backgroundColor: ['#2F68B7', '#E8F0F9'],
						hoverOffset: 4,
					},
				],
			},
			options: {
				plugins: {
					legend: {
						display: false,
					},
				},
			},
		};
		this.chart = new Chart(<ChartItem>element, config);
	}
}
