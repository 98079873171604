import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
	BehaviorSubject,
	filter,
	map,
	Observable,
	of,
	skipWhile,
	take,
} from 'rxjs';
import { GenericHelper } from 'src/app/core/helpers/generic-helper.class';
import { LocalDataService } from 'src/app/core/services/local-data.service';
import { UrlNames } from 'src/app/core/services/urlProfiler';
import { BaseWebApiService } from 'src/app/core/services/_base-web-api.service';
import {
	getSelectedLocation,
	getUserPrivileges,
	SharedState,
} from 'src/app/shared/_state/shared.reducer';
import {
	ProfileSaveSkillInterface,
	ProfileSkillsInterface,
	UpdateUserSavedSkillInterface,
	UserSavedSkillInterface,
} from '../_models/profile-skills.interfaces';
import {
	SaveUserProfileAPIInterface,
	UserProfileBasicDetailAPIInterface,
	UserProfileBasicDetailsInterface,
	UserProfileInterface,
} from '../_models/user-profile-interface';

@Injectable({
	providedIn: 'root',
})
export class UserProfileService {
	public editing$ = new BehaviorSubject<boolean>(false);
	public langChanged$ = new BehaviorSubject<string>(null);
	public formValid$ = new BehaviorSubject<boolean>(true);
	public changedProfiles3Url: string = null;
	private _locationId: number;
	private _userId: number;
	private _clientCode: string;
	constructor(
		private sharedStore: Store<SharedState>,
		private apiService: BaseWebApiService,
		private localDataService: LocalDataService
	) {
		this.sharedStore
			.pipe(
				select(getUserPrivileges),
				filter((value) => !!value)
			)
			.subscribe({
				next: (res) => {
					this._userId = res.userId;
				},
			});
		this.sharedStore
			.pipe(
				select(getSelectedLocation),
				filter((value) => !!value && !!value.location)
			)
			.subscribe({
				next: (res) => {
					this._clientCode = res.location.sitecode;
					this._locationId = res.location.id;
				},
			});
	}

	public getProfileDetails(): Observable<UserProfileBasicDetailAPIInterface> {
		return this.apiService.get(
			`${UrlNames.getProfile}/${this._userId}`,
			null
		);
	}

	/**
	 * Update User Email
	 * @param payload - User's new email and username
	 */
	public updateEmail(payload: {
		email: string;
		userName: string;
	}): Observable<Object> {
		// payload = {
		// 	...payload,
		// 	userName: this._clientCode + '.' + payload.userName,
		// };
		return this.apiService.post(`${UrlNames.changeEmail}`, payload);
	}

	/**
	 * To update user basic details with Image
	 * @param payload  - Prfile Details
	 * @returns
	 */
	public saveUserProfile(payload: UserProfileInterface): Observable<Object> {
		const data: SaveUserProfileAPIInterface = {
			addSpokenLanguageList: [],
			displayName: payload.basicDetails.displayName,
			removeSpokenLanguageList: [],
			surgeAlertsCheck: payload.basicDetails.surgeAlerts,
			surgeCorrespondenceCheck: payload.basicDetails.surgeCorespondence,
			title: payload.basicDetails.title,
			userId: this._userId,
			picturePath: payload.profilePic,
			language: payload.basicDetails.language.toString(),
			email: payload.basicDetails.email,
		};
		return this.apiService.patch(`${UrlNames.getProfile}`, data);
	}

	public getAllAvailableSkills(
		filter?: number,
		userId?: number
	): Observable<Array<ProfileSkillsInterface>> {
		return this.apiService
			.get<Array<ProfileSkillsInterface>>(
				`${UrlNames.getProfileSkills}${UrlNames.getAllSkills}`,
				{
					locationId: this._locationId,
					userId: userId ?? this._userId,
					filter: filter ?? 1,
				}
			)
			.pipe(
				map((skills) =>
					this.localDataService.sortStrings(skills, 'skillName')
				),
				map((x) => {
					return x.filter(
						(v, i, s) =>
							i === s.findIndex((t) => t.skillId === v.skillId)
					);
				})
			);
	}
	public getUserSkills(
		userId?: number
	): Observable<Array<UserSavedSkillInterface>> {
		return this.apiService
			.get(`${UrlNames.getProfileSkills}/${userId ?? this._userId}`, {
				locationId: this._locationId,
			})
			.pipe(
				map((skills: Array<UserSavedSkillInterface>) =>
					skills.map((skill) => {
						const dueDate =
							skill.renewalDate &&
							new Date(
								skill.renewalDate.split('-').reverse().join('-')
							);
						const today = new Date();
						const futuretimestamp = new Date(
							new Date().getTime() + 30 * 24 * 60 * 60 * 1000
						);
						return {
							...skill,
							_due:
								dueDate && dueDate > today
									? dueDate > futuretimestamp
										? null
										: 2
									: 1,
						};
					})
				),
				map((skills) =>
					this.localDataService.sortStrings(skills, 'skillName')
				)
			);
	}

	public saveProfileSkills(
		payload: Array<UserSavedSkillInterface>,
		userId?: number
	): Observable<Object> {
		const newPayload: Array<ProfileSaveSkillInterface> = payload.map(
			(skill) => {
				return {
					alertDate: skill.alertDate,
					certificatePath: skill.certificatePath,
					isCompleted: null,
					courseId: skill.courseId,
					obtainedDate: skill.obatainedDate,
					primaryManagerId: 0,
					renewalDate: skill.renewalDate,
					skillCompetenceLevelId: skill.skillCompetenceLevelId,
					skillId: skill.skillId,
					skillType: skill.skillType,
					locationId: this._locationId,
					userId: userId ?? this._userId,
				};
			}
		);
		return this.apiService.post(
			`${UrlNames.getProfileSkills}/${this._userId}`,
			newPayload
		);
	}

	public deleteSkill(ids: number[], userId?: number): Observable<Object> {
		return this.apiService.delete(
			`${UrlNames.getProfileSkills}`,
			{
				skillIds: ids,
			},
			undefined,
			undefined,
			{
				locationId: this._locationId,
				userId: userId ?? this._userId,
			}
		);
	}

	public addCommentToSkill(
		comment: string,
		skillId: number
	): Observable<Object> {
		const payload = {
			comments: comment,
			skillId: skillId,
			userId: this._userId,
		};
		return this.apiService.put(`${UrlNames.addCommentToSkill}`, payload);
	}

	public updateSkill(
		payload: Array<UpdateUserSavedSkillInterface>
	): Observable<any> {
		const updatedPayload: Array<UpdateUserSavedSkillInterface> =
			payload.map((x) => {
				return {
					...x,
					userId: x.userId ?? this._userId,
					locationId: this._locationId,
				};
			});
		return this.apiService.put(
			`${UrlNames.userSkills}/${this._userId}`,
			updatedPayload
		);
	}

	public getCourseCompletionStatus(
		courseId: number,
		userId?: number
	): Observable<any> {
		return this.apiService.get(`${UrlNames.userCourseCompletionStatus}`, {
			userId: userId ?? this._userId,
			courseId: courseId,
		});
	}
}
