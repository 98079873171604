<div class="d-flex flex-column broadcast-popup">
	<app-modal-header-placeholder
		class="model-header"
		[title]="'BROADCAST_MESSAGES'"
		(onClose)="close()"
	></app-modal-header-placeholder>
	<div class="mt-3 filter-container Coursescontainer border-bottom">
		<div class="row align-items-center">
			<div class="width-fit">
				<ul ngbNav #nav="ngbNav" class="nav-pills toggle-tabs">
					<li ngbNavItem *ngFor="let item of tabSet">
						<a
							ngbNavLink
							[ngClass]="{ active: Send_Broadcast === true }"
							*ngIf="item.id == 1"
							(click)="TabClick(item.id)"
							>{{ item.name | translate }}</a
						>
						<a
							ngbNavLink
							[ngClass]="{ active: listBroadcast === true }"
							*ngIf="item.id == 2"
							(click)="TabClick(item.id)"
							>{{ item.name | translate }}</a
						>
					</li>
				</ul>
			</div>
		</div>
	</div>
	<div class="coursestable custom-scrollbar">
		<form
			class="table-responsive containerdiv"
			[formGroup]="broadcastform"
			*ngIf="Send_Broadcast"
		>
			<div
				[formGroup]="broadcastform"
				class="new-message-board container-fluid"
			>
				<div class="row flex-grow-1">
					<div class="col-sm-3 col-lg-4 col-md-6 mb-3 mt-2">
						<slr-input
							[autoComplete]="false"
							[placeholder]="'NAME'"
							[label]="'NAME'"
							[fgName]="broadcastform"
							[controlName]="'name'"
						></slr-input>
					</div>
					<div class="col-sm-3 col-lg-4 col-md-6 mb-3 mt-2">
						<slr-drp06
							[data]="userData"
							[placeholder]="'HOST'"
							[searchable]="true"
							[disabled]="false"
							[controlName]="'host'"
							[bindValue]="'name'"
							[bindLabel]="'name'"
							[formGroup]="broadcastform"
							[label]="'HOST'"
						></slr-drp06>
					</div>
					<div
						class="col-sm-3 col-lg-4 col-md-6 mb-3 mt-2"
						*ngIf="isSurgeUser"
					>
						<slr-drp06
							[data]="vendorArr"
							[bindLabel]="'name'"
							[placeholder]="'BY_VENDOR' | translate"
							[searchable]="true"
							[disabled]="false"
							[controlName]="'byvendor'"
							[bindValue]="'id'"
							[label]="'VENDOR' | translate"
							[formGroup]="broadcastform"
						></slr-drp06>
					</div>
					<div
						class="col-sm-3 col-lg-4 col-md-6 mb-3 mt-2"
						*ngIf="isSurgeUser"
					>
						<slr-drp03
							[placeholder]="'PRODUCT'"
							[label]="'PRODUCT'"
							[data]="productdatabroadcast"
							[fgName]="broadcastform"
							[controlName]="'product'"
							[multiple]="true"
						></slr-drp03>
					</div>
					<ng-container
						*ngIf="userRolesDatas && userRolesDatas.length"
					>
						<div class="col-sm-3 col-lg-4 col-md-6 mb-3 mt-2">
							<slr-drp03
								[data]="userRolesDatas"
								[placeholder]="'USER_CLASS' | translate"
								[controlName]="'userclass'"
								[label]="'USER_CLASS' | translate"
								[fgName]="broadcastform"
								[multiple]="true"
							></slr-drp03>
						</div>
					</ng-container>
					<div class="col-sm-6 col-lg-4 mb-3 mt-1">
						<slr-date-input
							[label]="'EXPIRY_DATE'"
							[fgName]="broadcastform"
							[controlName]="'date'"
							[min]="today"
						>
						</slr-date-input>
					</div>
					<div class="col-sm-6 col-lg-4 col-md-6 mb-3 mt-2">
						<slr-input
							[autoComplete]="false"
							[placeholder]="'LINK'"
							[label]="'LINK'"
							[fgName]="broadcastform"
							[showError]="false"
							[controlName]="'link'"
						></slr-input>
						<mat-error
							class="broad-cost-link"
							*ngIf="broadcastform.get('link').errors && broadcastform.get('link').errors['invalidLinkFormat']">
							{{"LINK_TEXT" | translate}}
							</mat-error>
					</div>
				</div>
				<div class="col-sm-4 col-lg-8 col-md-6 mb-3">
					<mat-form-field appearance="outline">
						<mat-label>{{ "DESCRIPTION" | translate }}</mat-label>
						<textarea
							style="height: 80px"
							matInput
							[placeholder]="'ADD_DESCRIPTION_HERE' | translate"
							formControlName="message"
						></textarea>
						<mat-error
							*ngIf="
								broadcastform.get('message').invalid &&
								broadcastform.get('message').touched
							"
							>{{
								("DESCRIPTION" | translate) +
									" " +
									("IS" | translate) +
									" " +
									("REQUIRED" | translate)
							}}</mat-error
						>
					</mat-form-field>
				</div>
				<div class="col-lg-12 col-md-12">
					<div class="" style="float: right">
						<slr-btn02
							*ngIf="postdata"
							(onClick)="createNewConversation(null)"
							>{{ "SEND" | translate }}</slr-btn02
						>

						<slr-btn02
							class="mr-10"
							*ngIf="updatedata"
							(onClick)="gotolist()"
							>{{ "CANCEL" | translate }}</slr-btn02
						>
						<slr-btn02
							*ngIf="updatedata"
							(onClick)="updateForm()"
							>{{ "UPDATE" | translate }}</slr-btn02
						>
					</div>
				</div>
			</div>
		</form>
		<div class="containerdiv" *ngIf="listBroadcast">
			<app-broadcast-list
				[isSurgeAdmin]="isSurgeUser"
				[clientCode]="clientcode"
				(editBroadcast)="editbroadcast($event)"
				(deleteBroadcast)="deletepopup($event)"
			></app-broadcast-list>
		</div>
	</div>
</div>
<ng-template #content let-modal>
	<div class="modal-header justify-content-center">
		<span class="modal-title">jkhjkhkjh</span>

		<button
			type="button"
			aria-label="Close"
			(click)="modal.close('Save click')"
			class="btn close-btn"
		>
			<span class="material-icons-outlined"> close </span>
		</button>
	</div>
	<!-- CALENDAR header popup -->

	<!-- CALENDAR body start popup -->
	<div class="calendar-modal">
		<div class="modal-body"></div>
	</div>
	<!-- CALENDAR body end popup -->
</ng-template>
