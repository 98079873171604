import {
	Component,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ExportToCsv } from 'export-to-csv';
import { jsPDF } from 'jspdf';
import autoTable, { RowInput } from 'jspdf-autotable';
import { Subject, takeUntil } from 'rxjs';
import { getBase64Logo } from 'src/app/core/services/getBase64logo';
import { CustomTableColumn } from 'src/app/modules/reports/_models/reports-overview.model';
import { ExportReportsService } from 'src/app/modules/reports/_services/export-reports.service';
import { DateFormatPipe } from '../../directives/date-format.pipe';
import { exportCsvOptions } from './export-data.options';
@Component({
	selector: 'app-export-data',
	templateUrl: './export-data.component.html',
	styleUrls: ['./export-data.component.scss'],
})
export class ExportDataComponent implements OnChanges, OnDestroy, OnInit {
	@Input() headerColumns: Array<CustomTableColumn> = [];
	@Input() tableData: Array<any> = [];
	@Input() public customTable: boolean = false;
	@Input() fileName: string = 'User dashboard report';
	@Output() downloadFile: Subject<'pdf' | 'csv'> = new Subject();
	@Input() equalSizeColumns: boolean = false;
	private tableContents: Array<any> = [];
	private options = exportCsvOptions;
	private unsubscribe$ = new Subject();
	private columnNames: Array<string> = [];
	private logo: string = '';
	constructor(
		private translate: TranslateService,
		private dateFormataPipe: DateFormatPipe,
		private exportReportService: ExportReportsService
	) {}
	ngOnInit(): void {
		getBase64Logo().then((rslt: string) => (this.logo = rslt));
	}
	ngOnChanges(): void {
		this.getTableHeaders();
		this.tableContents = this.getTableContents();
	}
	private getVisibleColumns(): Array<CustomTableColumn> {
		return this.headerColumns.filter((column) => column.isShown);
	}
	private getTableHeaders(): void {
		this.columnNames = this.getVisibleColumns().map(
			(column) => column.columnName
		);
		this.columnNames.forEach((key) =>
			this.translate
				.get(key)
				.pipe(takeUntil(this.unsubscribe$))
				.subscribe({
					next: (res) => {
						this.columnNames[this.columnNames.indexOf(key)] = res;
					},
				})
		);
	}
	private getTableContents(): any {
		return this.tableData.map((row) => {
			const rowData = [];
			this.getVisibleColumns().forEach((column) => {
				row[column.bindKey]
					? rowData.push(
							column.dateValue
								? column.bindKey == 'latestLogin'
									? row[column.bindKey]
									: this.getFormattedDate(row[column.bindKey])
								: row[column.bindKey].toString()
					  )
					: rowData.push(column.numberColumn ? 0 : '-');
			});
			return rowData;
		});
	}
	private getFormattedDate(date: string): string {
		return this.dateFormataPipe.transform(date);
	}
	ngOnDestroy(): void {
		this.unsubscribe$.next(true);
	}
	public downloadCsvFile(): void {
		if (this.customTable) {
			this.downloadFile.next('csv');
			return;
		}
		let csvExporter = new ExportToCsv({
			...this.options,
			headers: this.columnNames,
			filename: this.fileName,
		});
		csvExporter.generateCsv(this.tableContents);
		csvExporter = null;
	}
	public downloadPdfFile(): void {
		if (this.customTable) {
			this.downloadFile.next('pdf');
			return;
		}
		const doc = new jsPDF();
		let pdfBody: RowInput[] = [];
		const cellWidth =
			(doc.internal.pageSize.width - 20) / this.columnNames.length;
		const minCellWidth = cellWidth > 20 ? 20 : cellWidth;
		this.tableContents.forEach((row) => {
			pdfBody.push(
				row.map((value) => {
					return {
						content: value,
						styles: {
							...(this.equalSizeColumns
								? { cellWidth }
								: { minCellWidth }),
						},
					};
				})
			);
		});
		autoTable(doc, {
			head: [this.columnNames],
			body: pdfBody,
			styles: {
				...(this.equalSizeColumns && { fontSize: 8 }),
			},
			...this.exportReportService.getDefaultAutotableStyles(),
			showHead: 'everyPage',
		});
		this.exportReportService.addPdfHeaders(doc);
		doc.setProperties({ title: this.fileName });
		this.exportReportService.openDocInNewTab(doc);
	}
}
