import { Component, OnInit, OnDestroy } from '@angular/core';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { FileUploadService } from 'src/app/core/services/file-upload.service';
import { DropdownInterface } from 'src/app/shared/components/dropdowns/slr-drp01/dropdown.model';
import { Subject, takeUntil } from 'rxjs';
import { HttpEventType } from '@angular/common/http';

@Component({
	selector: 'app-image-properties-popup',
	templateUrl: './image-properties-popup.component.html',
	styleUrls: ['./image-properties-popup.component.scss'],
})
export class ImagePropertiesPopupComponent implements OnInit, OnDestroy {
	public uploadType: Array<DropdownInterface> = [
		{
			id: 1,
			name: 'URL',
			data: {
				selected: true,
			},
		},
		{
			id: 2,
			name: 'Upload',
			data: {
				selected: false,
			},
		},
	];
	public alignmentData: Array<DropdownInterface> = [
		{
			id: 1,
			name: 'Left',
			data: {
				selected: true,
			},
		},
		{
			id: 2,
			name: 'Right',
			data: {
				selected: false,
			},
		},
		{
			id: 3,
			name: 'Not Set',
			data: {
				selected: false,
			},
		},
	];
	public imageForm: FormGroup;
	public uploading: boolean = false;
	public uploadProgress = 0;

	_uploadType: number = 1;

	private unsubscriber$ = new Subject<void>();

	constructor(
		private _fb: FormBuilder,
		private matDialogRef: MatDialogRef<ImagePropertiesPopupComponent>,
		private uploadService: FileUploadService
	) {}

	ngOnInit(): void {
		this.initForm();
	}

	private initForm(): void {
		this.imageForm = this._fb.group({
			url: new FormControl('', [Validators.required]),
			alignment: new FormControl(this.alignmentData[0].name, [
				Validators.required,
			]),
		});
	}

	public updateUploadType(type: number) {
		this._uploadType = type;
		this.uploadType = this.uploadType.map((x) => {
			return {
				...x,
				data: {
					selected: x.id == type,
				},
			};
		});
	}

	public uploadFile(event: any): void {
		this.uploading = true;
		this.uploadService
			.uploadFile(
				event.target.files[0] as File,
				undefined,
				undefined,
				true
			)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe({
				next: (res) => {
					if (res.type == HttpEventType.UploadProgress) {
						this.uploadProgress = Math.round(
							100 * (res.loaded / res.total)
						);
					}
					if (res.type === HttpEventType.Response) {
						this.uploading = false;
						this.updateUploadType(1);
						this.imageForm.patchValue({
							url: res.body?.data[0]?.filePath,
						});
					}
				},
			});
	}

	public submit(): void {
		this.close(true);
	}
	public close(submit: boolean = false): void {
		this.matDialogRef.close(submit ? this.imageForm.value : null);
	}

	ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.unsubscribe();
	}
}
