import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-modal-header-placeholder',
	templateUrl: './modal-header-placeholder.component.html',
	styleUrls: ['./modal-header-placeholder.component.scss'],
})
export class ModalHeaderPlaceholderComponent {
	@Input() public title: string;
	@Input() public showClose: boolean = true;
	@Output() onClose = new EventEmitter<void>();

	public _onClose(): void {
		this.onClose.emit();
	}
}
