<app-modal-header-placeholder
	[title]="'SESSION_TIMEOUT' | translate"
	[showClose]="false"
></app-modal-header-placeholder>
<div class="row">
	<div class="col-12 text-center">
		<p>
			{{ "SESSION_TIMEOUT_MESSAGE" | translate }}
		</p>
	</div>
</div>

<div class="row">
	<div class="col-12 text-center">
		<slr-btn01 (click)="close()" (keyup)="close()">{{ "OK" | translate }}</slr-btn01>
	</div>
</div>
