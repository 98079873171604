<ngb-toast
	*ngFor="let toast of notificationService.toasts"
	class="mb-2"
	[class]="toast.className"
	[autohide]="true"
	[delay]="toast.delay || 8000"
	(hidden)="notificationService.remove(toast)"
>
	<ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
		<ng-template [ngTemplateOutlet]="toast.textOrTpl"> </ng-template>
	</ng-template>

	<ng-template #text>
		<div class="d-flex col-12 align-items-center">
			<i class="col-2 material-icons-outlined">
				{{ toast.icon || "" }}
			</i>
			<span class="col-9">
				<span class="title">
					{{ (toast.title | translate) || "" }}
				</span>
				<br />
				{{ toast.textOrTpl | translate }}
			</span>
			<button
				class="btn float-end p-0"
				(click)="notificationService.remove(toast)"
			>
				<span class="material-icons-outlined">close</span>
			</button>
		</div>
	</ng-template>
</ngb-toast>
