import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, filter, Observable, catchError, of, map } from 'rxjs';
import { GenericHelper } from 'src/app/core/helpers/generic-helper.class';
import {
	SubmitTestInterface,
	TestInterface,
} from 'src/app/core/models/take-test.interface';
import { UrlNames } from 'src/app/core/services/urlProfiler';
import { BaseWebApiService } from 'src/app/core/services/_base-web-api.service';
import {
	getUserPrivileges,
	SharedState,
} from 'src/app/shared/_state/shared.reducer';

@Injectable({
	providedIn: 'root',
})
export class TakeTestService {
	private _userId: number;
	private _locationId: number;
	public currentTest$ = new BehaviorSubject<CurrentTestDetailsInterface>(
		null
	);
	constructor(
		private apiService: BaseWebApiService,
		private sharedStore: Store<SharedState>
	) {
		this.sharedStore
			.pipe(
				select(getUserPrivileges),
				filter((value) => !!value)
			)
			.subscribe({
				next: (res) => {
					this._userId = res.userId;
					this._locationId = res.locationId;
				},
			});
	}

	public getTestDetails(id: number): Observable<TestInterface> {
		return this.apiService.get(
			`${UrlNames.myCourses}/${UrlNames.takeTest}`,
			{ testId: id }
		);
		// .pipe(
		// 	map((x: TestInterface) => {
		// 		return {
		// 			...x,
		// 			questionList: x.questionList.map((question) => {
		// 				return {
		// 					...question,
		// 					question: GenericHelper.stripHTML(
		// 						question.question
		// 					),
		// 					options:
		// 						question.options &&
		// 						question.options.map((option) => {
		// 							return {
		// 								...option,
		// 								option: GenericHelper.stripHTML(
		// 									option.option
		// 								),
		// 							};
		// 						}),
		// 				};
		// 			}),
		// 		};
		// 	})
		// );
	}

	public submitTestToApi(payload: SubmitTestInterface): Observable<{
		testScore: number;
		retakeTest: boolean;
		testStatus: 'Failed' | 'Passed';
		userTestHistoryId: number;
	}> {
		return this.apiService.post(
			`${UrlNames.myCourses}${UrlNames.submit}${UrlNames.test}`,
			// { ...payload, courseTestId: this.currentTest$.value.testId }
			payload
		);
	}

	public getCourseTestAttemptCount(
		cid: number,
		tid: number,
		lp: boolean,
		userAssignmentId: number
	): Observable<any> {
		return this.apiService
			.get(`${UrlNames.getCourseTestAttemptCount}`, {
				userId: this._userId,
				courseId: cid,
				courseTestId: tid,
				isLearningPath: lp,
				userAssignmentId,
			})
			.pipe(
				map((res) => res['data'][0]),
				catchError((err) => {
					if (err && err.error && err.error.status == 404)
						return of(0);
					return err.error.message;
				})
			);
	}

	public saveQuestionComment(payload: {
		comment: string;
		questionId: number;
		testId: number;
		userId: number;
	}): Observable<any> {
		payload = {
			...payload,
			userId: this._userId,
		};
		return this.apiService.post(`${UrlNames.saveQuestionComment}`, payload);
	}

	public liveEventData(id: number): Observable<any> {
		return this.apiService.get('/liveevents/event', {
			eventId: id,
			locationId: this._locationId,
			userId: this._userId,
		});
	}

	public registerLiveEvent(payload: any): Observable<any> {
		payload = {
			...payload,
			userId: this._userId,
			createdBy: this._userId,
		};
		return this.apiService.post('/liveevents/reguser', payload);
	}

	public setCurrentTestDetails(next: CurrentTestDetailsInterface): void {
		this.currentTest$.next(next);
	}
	public getCurrentTestDetails(): CurrentTestDetailsInterface {
		return this.currentTest$.value;
	}
}

export interface CurrentTestDetailsInterface {
	testId: number;
	courseId: number;
	testType: 'pretest' | 'posttest' | 'microtest';
	courseType: 'course' | 'lp';
	maxAttempts: number;
	passingScore: number;
	testCompleted: boolean;
}
