import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { DropdownInterface } from '../../dropdowns/slr-drp01/dropdown.model';

@Component({
	selector: 'slr-rbtn-group',
	templateUrl: './slr-rbtn-group.component.html',
	styleUrls: ['./slr-rbtn-group.component.scss'],
})
export class SlrRbtnGroupComponent {
	@Input() public data: Array<DropdownInterface>;
	@Input() public label: string;
	@Input() public fgName: FormGroup;
	@Input() public initialValue = null;
	@Input() public controlName: string;
	@Input() public disabled: boolean;
	@Input() public align: 'horizontal' | 'vertical' = 'horizontal';
	@Output() public onChange: EventEmitter<DropdownInterface>;

	constructor() {
		this.onChange = new EventEmitter<DropdownInterface>();
	}

	public onChangeOption(change: MatRadioChange): void {
		const obj = this.data.find((x) => x.id === change.value);
		if (this.fgName) {
			this.fgName.get(this.controlName)?.setValue(obj?.name);
		}
		this.onChange.emit(obj);
	}
}
