import {
	Component,
	Input,
	OnInit,
	OnChanges,
	SimpleChanges,
	ViewChild,
	AfterViewInit,
	OnDestroy,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { ValidatorMessage } from 'src/app/core/models/validation-messages.model';
import { ErrorMessageService } from 'src/app/core/services/error-message.service';
import { Subject } from 'rxjs';

@Component({
	selector: 'slr-time-input',
	templateUrl: './slr-time-input.component.html',
	styleUrls: ['./slr-time-input.component.scss'],
})
export class SlrTimeInputComponent
	implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
	@ViewChild(MatMenuTrigger) public timeMenu_: MatMenuTrigger;
	@Input() label: string;
	@Input() placeholder: string = 'HH:MM AM/PM';
	@Input() control: FormControl;
	@Input() fgName: FormGroup;
	@Input() controlName: string;
	@Input() width: string = '100%';
	@Input() icon: string = 'schedule';
	@Input() iconAling: 'suffix' | 'prefix' = 'suffix';
	@Input() public validatorMessages: ValidatorMessage[];
	@Input() public timeInterval: number = 15; //in minutes to show in the menu
	public timeEntries: string[] = [];
	public showError: boolean = false;

	public selectedTime: string = this.initTime;
	public nearSelectedTime: string = '';

	public defaultValidatorMessages: ValidatorMessage[];

	private get initTime(): string {
		let formTime =
			this.fgName &&
			this.fgName.get(this.controlName) &&
			this.fgName.get(this.controlName).value;

		if (formTime) {
			const timeArray = (<string>formTime).split('');
			const min = parseInt(timeArray[3] + timeArray[4]);
			if (min > 30) {
				timeArray[3] = '0';
				timeArray[4] = '0';
				timeArray[1] = (parseInt(timeArray[1]) + 1).toString();
			} else {
				timeArray[3] = '3';
				timeArray[4] = '0';
			}
			this.nearSelectedTime = timeArray.join('');
			return formTime;
		}
		const _t = moment(new Date().getTime()).format('hh:mm A');
		const timeArray = _t.split('');
		const min = parseInt(timeArray[3] + timeArray[4]);
		if (min > 30) {
			timeArray[3] = '0';
			timeArray[4] = '0';
			timeArray[1] = (parseInt(timeArray[1]) + 1).toString();
		} else {
			timeArray[3] = '3';
			timeArray[4] = '0';
		}
		this.nearSelectedTime = timeArray.join('');
		return _t;
	}
	private unsubsriber$ = new Subject<void>();

	public errorMessage: string;

	constructor(
		private errorMessageService: ErrorMessageService,
		private trasnlateService: TranslateService
	) {
		this.initTimeEntries();
	}
	private initTimeEntries(): void {
		var result = [];
		['AM', 'PM'].forEach((ampm) => {
			[12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].forEach((h) => {
				for (
					var minutes = 0;
					minutes < 60;
					minutes = minutes + this.timeInterval
				) {
					var _h = '';
					var _m = '';
					if (h < 10) {
						_h = '0' + h;
					} else {
						_h = h.toString();
					}
					if (minutes < 10) {
						_m = '0' + minutes;
					} else {
						_m = minutes.toString();
					}
					result.push(_h + ':' + _m + ' ' + ampm);
				}
			});
		});

		this.timeEntries = result;
	}

	ngOnInit(): void {
		let fildName: string;
		if (this.placeholder) {
			this.trasnlateService.get(this.placeholder).subscribe({
				next: (res) => (fildName = res),
			});
		}
		this.defaultValidatorMessages = [
			{
				validationType: 'wrongFormat',
				validationMessage: this.trasnlateService.instant("TIME_FORMAT_ERROR"),
			},
			{
				validationType: 'required',
				validationMessage: `${this.trasnlateService.instant(
					this.placeholder ?? 'THIS_FIELD'
				)} ${this.trasnlateService.instant('IS_REQUIRED_GENERAL')}`,
			},
			...(this.validatorMessages ?? []),
		];
		this.setErrorMessage();
	}
	ngOnChanges(changes: SimpleChanges): void {
		this.selectedTime = this.initTime;
	}
	public validateTime(): void {
		let regEx = /^([0-1][0-9]|2[0-3]):[0-5][0-9] (AM|PM)$/;
		if (
			!regEx.test(this.selectedTime) &&
			this.fgName &&
			this.fgName.get(this.controlName)
		) {
			this.fgName
				.get(this.controlName)
				.setErrors({ required: undefined });
			this.fgName.get(this.controlName).setErrors({ wrongFormat: true });
			this.fgName.get(this.controlName).markAsPristine();
		}
	}
	public setErrorMessage(): void {
		this.errorMessage = this.errorMessageService.setErrorMessage(
			this.defaultValidatorMessages,
			this.control ?? this.fgName.get(this.controlName)
		);
	}

	public updateTime(event: string): void {
		this.nearSelectedTime = event;
		this.selectedTime = event;
	}

	ngAfterViewInit(): void {
		this.timeMenu_.menuOpened.subscribe({
			next: (res) => {
				const s = document.querySelector('.time-popup');
				const selectedItem = s.querySelector('#selectedTime');
				selectedItem.scrollIntoView();
			},
		});
	}
	ngOnDestroy(): void {
		this.unsubsriber$.next();
		this.unsubsriber$.unsubscribe();
	}
}
