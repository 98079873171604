export enum ReportModulePrivilegesEnum {
	MODULE_NAME = 110,
	LMS_REPORTS_USER_REPORT = 233,
	LMS_REPORTS_COURSE_REPORT = 234,
	LMS_REPORTS_ENTERPRISE_REPORT = 235,
	LMS_REPORTS_ENTERPRISE_REQUIRED_REPORT = 236,
	LMS_REPORTS_ENTERPRISE_REQUIRED_EDUCATION_REPORT = 237,
	LMS_REPORTS_ENTERPRISE_COURSE_COMPLETION_REPORT = 238,
	LMS_REPORTS_ENTERPRISE_MULTIPLE_COURSE_COMPLETION_REPORT = 239,
	LMS_REPORTS_OTHERS = 240,
	LMS_REPORTS_SURVEY_SUMMARY_REPORT = 241,
	LMS_REPORTS_INDIVIDUAL_SURVEY_REPORT = 242,
	LMS_REPORTS_PROGRAM_EVALUATTION = 243,
	LMS_REPORTS_SKILLS = 244,
	LMS_REPORTS_SKILLS_SUMMARY_REPORT = 245,
	LMS_REPORTS_SKILL_COMING_DUE_REPORT = 246,
	LMS_REPORTS_sKILLS_PENDING_APPROVAL_REPORT = 247,
	QRM_REPORTS = 248,
	QRM_REPORT_AUDIT_SUMMARY_REPORT = 249,
	QRM_REPORT_DASHBOARD_REPORTS = 250,
	QRM_REPORT_USER_STATUS_REPORT = 251,
	QRM_REPORT_ACTION_SUMMARY_REPORT = 252
}
