<mat-form-field [style.width]="width" appearance="outline">
	<mat-label class="text-area">{{ label | translate }}</mat-label>
	<textarea
		matInput
		[placeholder]="placeholder | translate"
		[style.height]="height"
		[style.resize]="resize"
		(focusout)="change()"
		(keydown)="change()"
		[(ngModel)]="value"
		[readonly]="disabled"
	></textarea>
</mat-form-field>
