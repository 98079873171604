import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';

@Component({
	selector: 'app-slr-input-with-drp',
	templateUrl: './slr-input-with-drp.component.html',
	styleUrls: ['./slr-input-with-drp.component.scss'],
})
export class SlrInputWithDrpComponent implements OnInit {
	@Input() label: string;
	@Input() placeholder: string;
	@Input() fgName: FormGroup;
	@Input() controlName: string;
	@Input() drpControlName: string;
	@Input() width: string = '100%';
	@Input() icon: string;
	@Input() selectDisabled: boolean = false;
	@Input() disabled: boolean = false;
	public selectedOption: number;

	@Input() drpData: Array<{ id: number; name: string; selected: boolean }>;

	ngOnInit(): void {
		this.selectedOption = this.drpData.find((x) => x.selected).id;
	}

	public change(evetn: MatSelectChange): void {
		this.fgName
			.get(this.drpControlName)
			.setValue(this.drpData.find((x) => x.id == evetn.value).name);
	}
}
