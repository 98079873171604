import { HttpEventType } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map, Subject, take, takeUntil } from 'rxjs';
import { FileUploadService } from 'src/app/core/services/file-upload.service';
import { LocalDataService } from 'src/app/core/services/local-data.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UserCertificate } from 'src/app/modules/skills-and-credentials/_models/skilled-users.model';
import { SkillsService } from 'src/app/modules/skills-and-credentials/_services/skills.service';
import { ApiUrlService } from 'src/app/core/services/api-url.service';
import { SlrInputFileUploadComponent } from '../../../inputs/slr-input-file-upload/slr-input-file-upload.component';
@Component({
	selector: 'app-add-skill-certificate-popup',
	templateUrl: './add-skill-certificate-popup.component.html',
	styleUrls: ['./add-skill-certificate-popup.component.scss'],
})
export class AddSkillCertificatePopupComponent implements OnInit, OnDestroy {
	public selectedFile: File;
	@ViewChild('fileUpload')
	public slrInputFileUpload: SlrInputFileUploadComponent;
	public uploadProgress: number = 0;
	public allowedFileTypes = 'application/pdf, image/*';
	public addButtonDisabled: boolean = true;
	private uploadedFileDetails: UserCertificate = <UserCertificate>{};
	private unsubscribe$ = new Subject();
	docurl: any;

	private numberOfCertificates: number = 0;

	constructor(
		@Inject(MAT_DIALOG_DATA)
		public data: {
			userId: number;
			skillId: number;
			certificates: any[];
			uploadAllowed: boolean;
		},
		private fileUploadService: FileUploadService,
		private dialogRef: MatDialogRef<AddSkillCertificatePopupComponent>,
		private localData: LocalDataService,
		private skillsService: SkillsService,
		private notificationService: NotificationService,
		public ApiService: ApiUrlService
	) {}

	ngOnInit(): void {
		this.numberOfCertificates = this.data['certificates'].length;
		console.log(this.data);
	}
	ngOnDestroy(): void {
		this.unsubscribe$.next(true);
	}

	public selectFile(event: File): void {
		this.selectedFile = event;
		this.setFileDetails();
		this.fileUploadService
			.uploadFile(this.selectedFile, this.selectedFile.type, 'skills')
			.subscribe({
				next: (res) => {
					if (res.type == HttpEventType.UploadProgress) {
						this.uploadProgress = Math.round(
							100 * (res.loaded / res.total)
						);
					}
					if (res.type === HttpEventType.Response) {
						this.uploadProgress = null;
						const [_fileName, ..._filePath] =
							res.body?.data[0]?.filePath.split('/').reverse();
						const filePath = _filePath.reverse().join('/');
						this.uploadedFileDetails.certificatePath = filePath;
						this.uploadedFileDetails.fileName = _fileName;
						this.addButtonDisabled = false;
					}
				},
			});
	}
	private setFileDetails(): void {
		this.uploadedFileDetails = {
			fileName: this.selectedFile.name,
			fileExtension: this.selectedFile.type.split('/').pop(),
			certificatePath: '',
			updateDate: '',
			updatedBy: this.localData.getUserId(),
			uploadedBy: '',
			certificateId: null,
		};
	}
	public uploadCertificate(): void {
		// if (this.isFromBulkAdd()) {
		this.skillsService
			.addUserSkillCertificate(
				this.data.skillId,
				this.data.userId,
				this.uploadedFileDetails
			)
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe({
				next: () => {
					this.selectedFile = null;
					this.addButtonDisabled = true;
					this.updateCertificateList();
				},
				error: (err) => {
					this.notificationService.error(err);
				},
			});
		// }
	}
	public deleteCertificate({ certificateId }): void {
		this.skillsService
			.deleteUserSkillCertificate(certificateId)
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe({
				next: () => {
					this.updateCertificateList();
				},
				error: (err) => {
					this.notificationService.error(err);
				},
			});
	}
	private isFromBulkAdd(): boolean {
		return this.data && this.data.userId && this.data.skillId
			? true
			: false;
	}
	public closeModal(): void {
		this.dialogRef.close({
			numberOfCertificates: this.numberOfCertificates,
		});
	}
	private updateCertificateList(): void {
		this.skillsService
			.getUserSkillCertificates({
				userId: this.data.userId,
				skillId: this.data.skillId,
			})
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe({
				next: (res) => {
					this.data['certificates'] = res[0]?.userCertificates || [];
					this.numberOfCertificates =
						this.data['certificates'].length;
				},
				error: (err) => {
					this.notificationService.error(err);
				},
			});
	}
	downloadcertificate(event: any) {
		this.fileUploadService
			.getPresignedUrl(`${event.certificatePath}/${event.fileName}`)
			.pipe(
				take(1),
				map((x) => x.s3PresingedURL)
			)
			.subscribe({
				next: (res) => {
					window.open(res);
				},
			});
	}
}
