<app-modal-header-placeholder
	class="model-header"
	[title]="' Delete Broadcast'"
	(onClose)="close()"
></app-modal-header-placeholder>
<div class="col-12">
	<div class="banner warning-banner d-flex align-items-center">
		<div class="d-flex">
			<mat-icon class="material-icons-round warning-icon ms-2"
				>warning</mat-icon
			>
			<span class="ms-2">
				{{ "BROADCAST_DELETE_WARNING" | translate }}
			</span>
		</div>
	</div>

	<div class="mt-3">
		<div class="bg-gray-0 p-2 round-border">
			<div>
				<span class="fw-bold"> {{ "NAME" | translate }}: </span>
				{{ broadcastDetail.name || "" }}
			</div>
			<div>
				<span class="fw-bold"> {{ "HOST" | translate }}: </span>
				{{ broadcastDetail.hosted_by || "" }}
			</div>
			<div>
				<span class="fw-bold"> {{ "DESCRIPTION" | translate }}: </span>
				{{ broadcastDetail.description || "" }}
			</div>
		</div>
	</div>
</div>
<div class="d-flex justify-content-center mt-3">
	<slr-btn02 class="" (onClick)="deletebroadcast()">{{
		"YES" | translate
	}}</slr-btn02>
	<slr-btn02 class="ms-2" (onClick)="cancel()">{{
		"NO" | translate
	}}</slr-btn02>
</div>
