import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
	selector: 'app-circular-progress',
	templateUrl: './circular-progress.component.html',
	styleUrls: ['./circular-progress.component.scss'],
})
export class CircularProgressComponent implements OnInit, OnChanges {
	@Input() autoHandleColor: boolean = true;
	@Input() percent: number | string = '100';
	@Input() radius: number = 25;
	@Input() space: number = -3;
	@Input() units: string = '%';
	@Input() outerStrokeWidth: number = 3;
	@Input() innerStrokeWidth: number = 3;
	@Input() outerStrokeColor: string = '#0D80BC';
	@Input() innerStrokeColor: string = '#e8f0f9';
	@Input() title: string = '100';
	@Input() animation: boolean = true;
	@Input() animationDuration: number = 700;
	@Input() clockwise: boolean = true;
	@Input() titleFontSize: string = '16';
	@Input() subtitleFontSize: string = '24';
	@Input() showTitle: boolean = true;
	@Input() showUnit: boolean = true;
	@Input() showSubtitle: boolean = false;
	@Input() titleFontWeight: string = '600';
	@Input() unitsFontWeight: string = '600';
	@Input() outerStrokeGradient: boolean = false;
	@Input() outerStrokeGradientStopColor: string = '#0D80BC';
	@Input() subTitle: string = '';
	@Input() subtitleColor: string = '#272727';
	ngOnInit() {
		this.title = this.percent?.toString();
		if (!this.autoHandleColor) {
			return;
		}
		if (this.percent == 100) {
			this.outerStrokeColor = '#1cd17f';
			this.innerStrokeColor = '#1cd17f1c';
		} else {
			this.outerStrokeColor = '#0D80BC';
			this.innerStrokeColor = '#e8f0f9';
		}
	}
	ngOnChanges(): void {
		this.title = this.percent?.toString();
	}
}
