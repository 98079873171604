import moment from 'moment';
import { ConversationInterface } from './conversation.interface';
const getCreatedTime = () => {
	const days = Math.floor(Math.random() * (10 - 1 + 1) + 1);
	const horus = Math.floor(Math.random() * (10 - 1 + 1) + 1);
	return moment(new Date())
		.subtract(days, 'd')
		.subtract(horus, 'h')
		.toString();
};
export const DummyConversations: Array<ConversationInterface> = [
	{
		conversationId: 1,
		users: [
			{
				name: 'Michael M',
				email: '',
				profilePicUrl: '',
				userId: 58,
			},
			{
				name: 'Crystal',
				email: '',
				profilePicUrl: '',
				userId: 27,
			},
		],
		messages: [
			{
				createdAt: getCreatedTime(),
				delivered: true,
				from: {
					name: 'Crystal',
					email: '',
					profilePicUrl: '',
					userId: 27,
				},
				to: [
					{
						name: 'Michael M',
						email: '',
						profilePicUrl: '',
						userId: 58,
					},
				],
				isReply: false,
				message: 'This is from Tulia',
				messageId: 1,
				read: false,
			},
			{
				createdAt: getCreatedTime(),
				delivered: true,
				from: {
					name: 'Michael M',
					email: '',
					profilePicUrl: '',
					userId: 58,
				},
				to: [
					{
						name: 'Crystal',
						email: '',
						profilePicUrl: '',
						userId: 27,
					},
				],
				isReply: false,
				message: 'This is from Michael',
				messageId: 2,
				read: false,
			},
		],
	},
	{
		conversationId: 2,
		users: [
			{
				name: 'Mohammad A',
				email: '',
				profilePicUrl: '',
				userId: 58,
			},
			{
				name: 'Crystal',
				email: '',
				profilePicUrl: '',
				userId: 27,
			},
		],
		messages: [
			{
				createdAt: getCreatedTime(),
				delivered: true,
				from: {
					name: 'Crystal',
					email: '',
					profilePicUrl: '',
					userId: 27,
				},
				to: [
					{
						name: 'Mohammad A',
						email: '',
						profilePicUrl: '',
						userId: 58,
					},
				],
				isReply: false,
				message: 'This is from Tulia',
				messageId: 1,
				read: false,
			},
			{
				createdAt: getCreatedTime(),
				delivered: true,
				from: {
					name: 'Mohammad A',
					email: '',
					profilePicUrl: '',
					userId: 58,
				},
				to: [
					{
						name: 'Crystal',
						email: '',
						profilePicUrl: '',
						userId: 27,
					},
				],
				isReply: false,
				message: 'This is from Mohammad A',
				messageId: 2,
				read: false,
			},
		],
	},
	{
		conversationId: 3,
		users: [
			{
				name: 'Michael M',
				email: '',
				profilePicUrl: '',
				userId: 58,
			},
			{
				name: 'Mohammad A',
				email: '',
				profilePicUrl: '',
				userId: 58,
			},
			{
				name: 'Tulia F',
				email: '',
				profilePicUrl: '',
				userId: 58,
			},
			{
				name: 'Crystal',
				email: '',
				profilePicUrl: '',
				userId: 27,
			},
			{
				name: 'Michael M',
				email: '',
				profilePicUrl: '',
				userId: 58,
			},
			{
				name: 'Mohammad A',
				email: '',
				profilePicUrl: '',
				userId: 58,
			},
			{
				name: 'Tulia F',
				email: '',
				profilePicUrl: '',
				userId: 58,
			},
			{
				name: 'Crystal',
				email: '',
				profilePicUrl: '',
				userId: 27,
			},
			{
				name: 'Michael M',
				email: '',
				profilePicUrl: '',
				userId: 58,
			},
			{
				name: 'Mohammad A',
				email: '',
				profilePicUrl: '',
				userId: 58,
			},
			{
				name: 'Tulia F',
				email: '',
				profilePicUrl: '',
				userId: 58,
			},
			{
				name: 'Crystal',
				email: '',
				profilePicUrl: '',
				userId: 27,
			},
		],
		messages: [
			{
				createdAt: getCreatedTime(),
				delivered: true,
				from: {
					name: 'Crystal',
					email: '',
					profilePicUrl: '',
					userId: 27,
				},
				to: [
					{
						name: 'Michael M',
						email: '',
						profilePicUrl: '',
						userId: 58,
					},
				],
				isReply: false,
				message: 'This is from Tulia',
				messageId: 1,
				read: false,
			},
			{
				createdAt: getCreatedTime(),
				delivered: true,
				from: {
					name: 'Tulia F',
					email: '',
					profilePicUrl: '',
					userId: 58,
				},
				to: [
					{
						name: 'Crystal',
						email: '',
						profilePicUrl: '',
						userId: 27,
					},
				],
				isReply: false,
				message: 'Welcome to the group',
				messageId: 2,
				read: false,
			},
			{
				createdAt: new Date().toString(),
				delivered: true,
				from: {
					name: 'Michael M',
					email: '',
					profilePicUrl: '',
					userId: 58,
				},
				to: [
					{
						name: 'Crystal',
						email: '',
						profilePicUrl: '',
						userId: 27,
					},
				],
				isReply: false,
				message: 'This is from Michael',
				messageId: 2,
				read: false,
			},
		],
	},
];
