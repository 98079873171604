/* eslint-disable no-unused-vars */
import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class ScormHeaderInterceptor implements HttpInterceptor {
	public intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		if (request.url.includes('scorm') && request.url.includes('upload')) {
			const file = request.body.get('file');
			request = request.clone({
				setHeaders: {
					uploadedContentType: this.returnFileType(file.type),
					engineTenantName: environment.scorm.engineTenantName,
				},
			});
		}
		if (request.url.includes('engine.scorm.com')) {
			request = request.clone({
				setHeaders: {
					engineTenantName: environment.scorm.engineTenantName,
					Authorization: `Basic ${btoa(
						environment.scorm.uname + ':' + environment.scorm.key
					)}`,
				},
			});
		}
		return next.handle(request);
	}
	private returnFileType(type: string): string {
		if (type.includes('zip')) {
			return 'application/zip';
		}
		return type;
	}
}
