import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SelectedResource } from 'src/app/shared/models/create-resource';
import { GenericHelper } from 'src/app/core/helpers/generic-helper.class';
import { ContentType } from '../uploads.component';

@Component({
	selector: 'app-add-content',
	templateUrl: './add-content.component.html',
	styleUrls: ['./add-content.component.scss'],
})
export class AddContentComponent implements OnInit, OnChanges {
	public selectedFile: File;
	public selectedFiles: File[];
	public urlForm: FormGroup;
	public showUploadError: boolean = false;
	@Input() contentType: string;
	@Input() acceptTypes: string = '*/*';
	@Input() multiple: string = '';
	@Input() allowLinks: boolean = true;
	@Output() contentDetails: EventEmitter<SelectedResource> =
		new EventEmitter();

	public youtubeLinkError = false;
	constructor(private formBuilder: FormBuilder) {}

	ngOnInit(): void {
		this.urlForm = this.formBuilder.group({
			url: [''],
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		console.log(changes);
	}
	public uploadFile(): void {
		if (!this.selectedFile) {
			this.showUploadError = true;
			return;
		}
		this.selectedFile &&
			this.contentDetails.emit({
				type: this.getContentType(),
				content: this.selectedFile,
			});
		this.selectedFile = null;
	}

	private getContentType(): 'scorm' | 'vimeo' | 'file' {
		if (this.contentType !== ContentType[0]) return 'file';
		if (GenericHelper.isScormSupported(this.selectedFile.type))
			return 'scorm';
		if (GenericHelper.isVimeoFile(this.selectedFile.type)) return 'vimeo';
		return 'file';
	}

	public uploadFiles(): void {
		if (!this.selectedFiles?.length) {
			this.showUploadError = true;
			return;
		}
		this.selectedFiles &&
			this.selectedFiles.length &&
			this.contentDetails.emit({
				type:
					GenericHelper.isScormSupported(
						this.selectedFiles[0].type
					) && this.contentType == ContentType[0]
						? 'scorm'
						: 'file',
				contents: this.selectedFiles,
			});
		this.selectedFiles = [] as File[];
	}

	public sendLink(): void {
		this.youtubeLinkError = !GenericHelper.checkYoutubeLink(
			this.urlForm.value.url
		);
		if (this.youtubeLinkError) {
			return;
		}
		this.urlForm.value.url &&
			this.contentDetails.emit({
				type:
					GenericHelper.isScormLink(this.urlForm.value.url) &&
					this.contentType == ContentType[0]
						? 'scorm'
						: 'link',
				content: this.urlForm.value.url,
				// content: this.checkYoutubeLink(this.urlForm.value.url),
			});
		this.urlForm.patchValue({ url: '' });
	}
	choosenFiles(files, from: 'SINGLE' | 'MULTIPLE') {
		if (from === 'SINGLE') {
			if (!files) {
				this.showUploadError = true;
			} else {
				this.showUploadError = false;
			}
			this.selectedFile = files;
			return;
		} else {
			if (!files?.length) {
				this.showUploadError = true;
			} else {
				this.showUploadError = false;
			}
			this.selectedFiles = files;
		}
	}
	// private checkYoutubeLink(link: string): string {
	// 	if (GenericHelper.isYoutubeLink(link))
	// 		return GenericHelper.getEmbedableYoutubeLnk(link);
	// 	return link;
	// }
}
