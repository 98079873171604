<div class="row">
	<div class="col-12">
		<p>You still have Poliicy Document opened in another window.</p>
	</div>
	<div class="col-12">
		<div class="d-flex gap-2 justify-content-center">
			<slr-btn01 (click)="sendOption(0)">Save and Close</slr-btn01>
			<slr-btn01 (click)="sendOption(1)">Keep Editing</slr-btn01>
		</div>
	</div>
</div>
