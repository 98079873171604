import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { filter, Subject, takeUntil } from 'rxjs';
import { BroadcastService } from 'src/app/core/services/broadcast.service';
import { LocalDataService } from 'src/app/core/services/local-data.service';
import {
	SharedState,
	getUserPrivileges,
	getUserLocation,
} from 'src/app/shared/_state/shared.reducer';
@Component({
	selector: 'app-private-layout',
	templateUrl: './private-layout.component.html',
	styleUrls: ['./private-layout.component.scss'],
})
export class PrivateLayoutComponent implements OnInit, OnDestroy {
	public isReportsScreen = false;
	private unsubscribe$ = new Subject();
	public miniSidebar = true;
	public hiddenSidebar = false;
	constructor(
		private broadcastService: BroadcastService,
		private sharedStore: Store<SharedState>,
		router: Router
	) {
		router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				takeUntil(this.unsubscribe$)
			)
			.subscribe(
				(route: NavigationEnd) =>
					(this.isReportsScreen = route.url
						.toString()
						.includes('reports'))
			);

		this.broadcastService.screenSize.subscribe({
			next: (res) => {
				this.hiddenSidebar = res == 'small';
			},
		});
	}

	ngOnInit(): void {
		this.getMasterData();
	}
	ngOnDestroy(): void {
		this.unsubscribe$.next(true);
	}
	public toggleContentArea(): void {
		this.miniSidebar = !this.miniSidebar;
	}

	// Only for Development Debuggin purposes. Remove one everything is Finalisied.
	private getMasterData(): void {
		this.sharedStore
			.pipe(select(getUserLocation))
			.subscribe((res) => console.log('from store', res));
		this.sharedStore
			.pipe(select(getUserPrivileges))
			.subscribe((res) => console.log('from store', res));
	}
}
