import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-logged-out-message-popup',
	templateUrl: './logged-out-message-popup.component.html',
	styleUrls: ['./logged-out-message-popup.component.scss'],
})
export class LoggedOutMessagePopupComponent {
	constructor(
		private matDialogRef: MatDialogRef<LoggedOutMessagePopupComponent>
	) {}
	close() {
		this.matDialogRef.close();
	}
}
