export interface ProfileSkillsInterface {
	activeFlag: boolean;
	courseId: number;
	description: string;
	datesOverridesAllowed: boolean;
	defaultAlertsThresholdInDays: number;
	defaultRenewalFrequency: number;
	educationGroups: Array<number>;
	locationId: number;
	newRenewalFrequencyInYears: number;
	occurences: number;
	renewalType: string;
	skillId: number;
	skillName: string;
	skillType: string;
	useCompetency: boolean;
	hasCertificates: boolean;
	hasComments: boolean;
}

export interface ProfileSaveSkillInterface {
	alertDate: string;
	certificatePath: string;
	isCompleted: boolean;
	courseId: number;
	locationId?: number;
	obtainedDate: string;
	primaryManagerId: number;
	renewalDate: string;
	skillCompetenceLevelId: number;
	skillId: number;
	skillType: string;
	userId?: number;
}

export interface UserSavedSkillInterface {
	alertDate: string;
	certificatePath: string;
	comments: string;
	skillCompetenceLevelId: number;
	courseId: number;
	lastUpdated: string;
	obatainedDate: string;
	renewalDate: string;
	status: string;
	skillId: number;
	skillName: string;
	hasCertificates: boolean;
	hasComments: boolean;
	userId?: number;
	// to save temporary
	skillType?: string;
	_due?: number; // For legend,
	isTemp: boolean;
	isUpdated: boolean;
	previousSkillStatus?: string;
	sessionTitle: string;
}

export interface UpdateUserSavedSkillInterface {
	alertDate: string;
	approvalStatus: string;
	locationId: number;
	obtainedDate: string;
	renewalDate: string;
	skillCompetenceLevelId: number;
	skillId: number;
	userId: number;
	courseId: number;
}

export interface BulkAssignSkillToUsersPayloadInterface {
	alertDate: string;
	certificatePath: string;
	courseId: number;
	locationId: number;
	obtainedDate: string;
	renewalDate: string;
	skillCompetenceLevelId: number;
	skillId: number;
	skillType: string;
	userIds: Array<number>;
}

export enum SkillStatusEnum {
	pendingCourse = <any>'Pending Course Completion',
	pendingApproval = <any>'Pending Approval',
	approved = <any>'Approved',
}
