import { Component, HostListener } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { filter, map, withLatestFrom } from 'rxjs';
import { LocalStorageKey } from './core/models/local-storage-key.enum';
import { UserPrivilageInterface } from './core/models/user-privilege.interface';
import { BroadcastService } from './core/services/broadcast.service';

import {
	listenStorageChange,
	logout,
	saveSessionExpiryTime,
	saveToken,
	saveUserSessionId,
	updateLastActivityTime,
} from './modules/authentication/_state/authentication.action';
import { AuthenticationState } from './modules/authentication/_state/authentication.reducer';
import { SettingsMap } from './modules/user-settings/_models/settins-map.enum';
import { Settings } from './shared/models/settings';
import {
	getLocationDataFromServer,
	getProfilePicS3URL,
	openPE,
	saveLocationSettings,
	saveProductAccessSettings,
	saveUserPrivilege,
} from './shared/_state/shared.actions';
import {
	getAppLanguage,
	getPEValue,
	getSettingValueById,
	SharedState,
} from './shared/_state/shared.reducer';
import { TokenService } from './core/services/token.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {
	title = 'SurgeLearning-WebApp';
	private userPrivilege: UserPrivilageInterface;
	constructor(
		private translate: TranslateService,
		private broadcastService: BroadcastService,
		private sharedStore: Store<SharedState>,
		private authStore: Store<AuthenticationState>,
		private tokenService: TokenService
	) {
		this.grabClickOnBody();
		this.initLang();
		this.initToken();
		this.initPrivileges();
		this.initUserSettings();
		this.initProductAccessSettings();
		this.checkForPE();
		this.checkLocalStorageChange();
		}

	private grabClickOnBody(): void {
		document.addEventListener('click', () => {
			if (this.broadcastService.mobileSidebarState.value === 'show')
				this.broadcastService.hideMobileSidebarState();
		});
	}
	private initToken(): void {
		const token = this.tokenService.token;
		const sessionExpiryTime = this.tokenService.sessionExipryTime;
		const sessionId = localStorage.getItem(LocalStorageKey.USER_SESSION_ID);

		if ((token && !token.accessToken) || sessionId === 'undefined') {
			this.authStore.dispatch(logout({ redirect: true }));
			return;
		}

		this.authStore.dispatch(saveToken({ token }));
		this.authStore.dispatch(
			saveSessionExpiryTime({ time: sessionExpiryTime })
		);
		this.authStore.dispatch(saveUserSessionId({ sessionId: +sessionId }));
	}
	private initPrivileges(): void {
		this.userPrivilege = JSON.parse(
			localStorage.getItem(LocalStorageKey.PRIVILEGES)
		) as UserPrivilageInterface;
		if (this.userPrivilege) {
			this.sharedStore.dispatch(
				saveUserPrivilege({ payload: this.userPrivilege })
			);
			this.sharedStore.dispatch(getLocationDataFromServer());
			this.sharedStore.dispatch(
				getProfilePicS3URL({
					picturePath: this.userPrivilege.picturePath,
				})
			);
		}
	}
	private initUserSettings(): void {
		const settings = JSON.parse(
			localStorage.getItem(LocalStorageKey.SETTINGS)
		) as Settings[];
		if (settings) {
			this.sharedStore.dispatch(
				saveLocationSettings({ payload: settings })
			);
		}
	}

	private initProductAccessSettings(): void {
		const settings = JSON.parse(
			localStorage.getItem(LocalStorageKey.PRODUCT_ACCESS_SETTINGS)
		);
		if (settings) {
			this.sharedStore.dispatch(
				saveProductAccessSettings({ payload: settings })
			);
		}
	}

	private initLang(): void {
		this.sharedStore.pipe(select(getAppLanguage)).subscribe({
			next: (res) => {
				this.translate.use(res);
			},
		});
	}
	private checkForPE(): void {
		this.sharedStore
			.pipe(
				select(getPEValue),
				withLatestFrom(
					this.sharedStore.pipe(
						select(getSettingValueById(SettingsMap.use_evaluation)),
						filter((value) => !!value),
						map((_eval) => _eval[0] as boolean)
					)
				),
				filter((value) => value !== null)
			)
			.subscribe({
				next: ([res, use_evaluation]) => {
					if (res && res.cid && res.value === 1 && use_evaluation) {
						this.sharedStore.dispatch(
							openPE({ courseId: res.cid })
						);
					}
				},
			});
	}

	private checkLocalStorageChange(): void {
		this.authStore.dispatch(listenStorageChange());
	}

	@HostListener('window:mousemove', ['$event'])
	@HostListener('window:click', ['$event'])
	@HostListener('window:keydown', ['$event'])
	checkUserActivity(): void {
		this.authStore.dispatch(updateLastActivityTime({ time: Date.now() }));
	}
}
