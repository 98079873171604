<mat-form-field [formGroup]="fgName" class="slr-input-drp" appearance="outline" [style.width]="width">
	<mat-label *ngIf="label">{{ label }}</mat-label>
	<div class="row">
		<div class="col-9 order-last">
			<input
				(click)="$event.stopPropagation()"
				matInput
				[placeholder]="placeholder"
				[formControlName]="controlName"
			/>
		</div>
		<div class="col-3 order-first">
			<mat-select [disabled]="selectDisabled" (selectionChange)="change($event)" [(value)]="selectedOption">
				<mat-option
					*ngFor="let option of drpData"
					[value]="option.id"
					>{{ option.name }}</mat-option
				>
			</mat-select>
		</div>
	</div>
</mat-form-field>
