import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'slr-btn02',
	templateUrl: './slr-btn02.component.html',
	styleUrls: ['./slr-btn02.component.scss'],
})
export class SlrBtn02Component  {
	@Input() public disabled: boolean = false;
	@Input() public icon: string;
	@Input() public themeClass: 'lms' | 'policy' | 'qrm' = 'lms'
	@Output() public onClick: EventEmitter<void>;

	constructor() {
		this.onClick = new EventEmitter<void>();
	}

	public clicked(): void {
		this.onClick.emit();
	}
}
