<div class="p-3">
	<div class="file-name mb-2">
		{{ fileDetails.fileName }}
	</div>
	<iframe
		[src]="fileDetails.fileUrl"
		*ngIf="
			fileDetails.fileType === 'application/pdf' ||
			fileDetails.fileType === 'pdf' ||
			fileDetails.fileType === 'text/plain'
		"
		title="pdfIframe"
	></iframe>
	<iframe
		height="300"
		[src]="fileDetails.fileUrl"
		*ngIf="videoAudioFile"
		allow="accelerometer;clipboard-write; encrypted-media; gyroscope; picture-in-picture"
		allowfullscreen
		title="videoIframe"
	></iframe>
	<iframe
		height="300"
		[src]="rusticiFilePath"
		*ngIf="fileDetails.fileUrl == 'rustici'"
		allow="accelerometer;clipboard-write; encrypted-media; gyroscope; picture-in-picture"
		allowfullscreen
		title="rusticiIframe"
	></iframe>
</div>
