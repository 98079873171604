<div class="table-header">
	<div class="container-fluid">
		<div class="row">
			<div class="col-sm-7 col-4 table-header-groups">
				<span
					class="d-flex align-items-center cursor-pointer"
					(click)="sortList('category')"
				>
					<span>{{ tableHeader | translate }}</span>
					<span class="material-icons-outlined sort-icons">{{
						catSortDir
							? catSortDir === "ASC"
								? "expand_less"
								: "expand_more"
							: "unfold_more"
					}}</span>
				</span>
			</div>
		</div>
	</div>
</div>
<div *ngIf="!data?.length" class="no-data-row">
	{{ "NO_DATA_AVAILABLE" | translate }}
</div>
<mat-accordion class="slr-dir-accordion">
	<ng-container *ngFor="let item of data">
		<ng-container
			*ngTemplateOutlet="
				matTemplate;
				context: {
					details: { id: item.id, name: item.name },
					files: item.files,
					folders: item.folders,
					level: 0
				}
			"
		></ng-container>
	</ng-container>
</mat-accordion>

<ng-template
	#matTemplate
	let-details="details"
	let-files="files"
	let-folders="folders"
	let-level="level"
>
	<mat-expansion-panel [hideToggle]="true" class="dir-panel">
		<mat-expansion-panel-header>
			<div
				class="d-flex gap-2 align-items-center"
				[class]="'head-level-' + level"
			>
				<mat-icon> folder </mat-icon>
				<span class="dir-title">
					{{ details.name }}
					{{ "(" + (files.length + folders.length) + ")" }}
				</span>
			</div>
		</mat-expansion-panel-header>
		<div *ngFor="let file of files; index as i" class="row courses-link">
			<div
				class="col-sm-7 course-details"
				[class]="'file-level-' + level"
			>
				<mat-checkbox
					(change)="onSelect(file.id)"
					class="me-2"
				></mat-checkbox>
				<span class="d-flex align-items-center" (click)="_onRowClick(file.id)">{{ file.name }} </span>
			</div>
		</div>
		<mat-accordion class="sub-dir">
			<ng-container *ngFor="let folder of folders; let i = index">
				<ng-container
					*ngTemplateOutlet="
						matTemplate;
						context: {
							details: { id: folder.id, name: folder.name },
							files: folder.files,
							folders: folder.folders,
							level: level + 1
						}
					"
				></ng-container>
			</ng-container>
		</mat-accordion>
	</mat-expansion-panel>
</ng-template>
