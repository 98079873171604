<app-modal-header-placeholder
	[title]="'CHANGE_PASSWORD_TEXT' | translate | uppercase"
	(onClose)="close()"
></app-modal-header-placeholder>

<div class="body px-5">
	<form class="form" [formGroup]="loginForm" (keydown.enter)="onSubmit()">
		<div class="row">
			<div class="col-12">
				<slr-password-tb02
					[controlName]="'oldPasswordControl'"
					[fgName]="loginForm"
					[placeholder]="'Password'"
					[label]="'ENTER_CURRENT_PASSWORD' | translate"
					[validatorMessages]="
						validatorMessages.get('oldPasswordControl')!
					"
				></slr-password-tb02>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<slr-password-tb02
					[controlName]="'newPasswordControl'"
					[fgName]="loginForm"
					[placeholder]="'Password'"
					[label]="'ENTER_NEW_PASSWORD' | translate"
					[validatorMessages]="
						validatorMessages.get('oldPasswordControl')!
					"
				></slr-password-tb02>
			</div>
		</div>
		<div class="row">
			<div class="col-12"></div>
			<slr-password-tb02
				[controlName]="'confirmPasswordControl'"
				[fgName]="loginForm"
				[placeholder]="'Password'"
				[label]="'CONFIRM_NEW_PASSWORD' | translate"
				[validatorMessages]="
					validatorMessages.get('confirmPasswordControl')!
				"
			></slr-password-tb02>
		</div>

		<div class="d-flex flex-column gap-2 align-items-center">
			<button class="login-btn btn" (click)="onSubmit()">
				{{ "CHANGE_PASSWORD_TEXT" | translate | uppercase }}
			</button>
			<a
				href="javascript:void(0)"
				class="color-black"
				(click)="close()"
				>{{ "CANCEL" | translate }}</a
			>
		</div>
	</form>
</div>
