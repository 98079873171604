import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ValidatorMessage } from 'src/app/core/models/validation-messages.model';
import { ErrorMessageService } from 'src/app/core/services/error-message.service';

@Component({
	selector: 'auth-code-input',
	templateUrl: './auth-code-input.component.html',
	styleUrls: ['./auth-code-input.component.scss'],
})
export class AuthCodeInputComponent {
	@Input() public fgName: FormGroup;
	@Input() public controlName: string;
	@Input() public placeholder: string;
	@Input() public label: string;
	@Input() public validatorMessages: ValidatorMessage[];

	errorMessage: string;

	constructor(private errorMessageService: ErrorMessageService) {}

	setErrorMessage(): void {
		this.errorMessage = this.errorMessageService.setErrorMessage(
			this.validatorMessages,
			this.fgName.get(this.controlName)
		);
	}
}
