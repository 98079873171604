import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { UserPermissionService } from 'src/app/modules/authentication/_services/user-permissions.service';
@Directive({
	selector: '[isAuthorised]',
})
export class IsAuthorisedDerective implements OnInit {
	@Input() public action: string | number;
	@Input() public actions: number[];
	@Input() public logic: 'and' | 'or' = 'and';
	constructor(
		private permissionService: UserPermissionService,
		private elementRef: ElementRef,
	) {}

	ngOnInit(): void {
		if (!this.actions?.length && !this.show) {
			this.elementRef.nativeElement.remove();
		}
		if (this.actions?.length && !this.shows) {
			this.elementRef.nativeElement.remove();
		}
	}

	protected get show(): boolean {
		return this.permissionService.checkUserPrivilege(this.action);
	}

	protected get shows(): boolean {
		return	this.logic.toLowerCase() == 'and'
				? this.actions.every((action) =>
						this.permissionService.checkUserPrivilege(action)
				  )
				: this.actions.some((action) =>
						this.permissionService.checkUserPrivilege(action)
				  );
	}
}
