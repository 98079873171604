import { Directive, ElementRef, Input, OnChanges } from '@angular/core';
import moment from 'moment';
import { AppConstants } from '../constants/app-constants';

@Directive({
	selector: '[checkExpiry]',
})
export class CheckMessageExpiry implements OnChanges {
	@Input() public expireDate = '';

	constructor(private el: ElementRef) {}
	ngOnChanges(): void {
	
		if(!this.expireDate) {
			this.el.nativeElement.remove()
			return
		}

		const eDate = moment(this.expireDate)
		const cdate = moment(new Date())
		if(eDate < cdate) {
			this.el.nativeElement.classList = 'message-expiry expired'
			this.el.nativeElement.innerText = 'expired'
			return
		}
		const diff = eDate.diff(cdate, 'days')
		
		if(+diff < 10) {
			this.el.nativeElement.classList = 'message-expiry expiring'
			this.el.nativeElement.innerText = `expiring in ${diff} days`
			return
		}
		this.el.nativeElement.style.display = 'none'
	}
}