import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements OnInit {
  @Input() autoClose: boolean = true;
  @Input() display: string = 'dynamic';
  @Input() dropdownClass: string = '';
  @Input() open: boolean = false;
  @Input() placement: string = 'bottom-left';
  @Input() placeholder: string = 'Multi-Site Location';
  @Input() dropdownClosedIcon: string = 'expand_more';
  @Input() dropdownOpenedIcon: string = 'expand_less';
  @Input() options: Array<string> = [];

  @Output() openChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() selectedItem: EventEmitter<string> = new EventEmitter<string>();

  icon: string = '';
  ngOnInit(): void {
    this.icon = this.dropdownClosedIcon;
  }

  isDropdownOpened(isOpened: boolean) {
    this.openChange.emit(isOpened);
    isOpened
      ? (this.icon = this.dropdownOpenedIcon)
      : (this.icon = this.dropdownClosedIcon);
  }

  itemSelection(
    item: string,
    dropdown: HTMLDivElement,
    dropdownOption: HTMLButtonElement
  ) {
    this.placeholder = item;
    dropdown
      .querySelectorAll('button')
      .forEach((option) => option.classList.remove('active'));
    dropdownOption.classList.add('active');
    this.selectedItem.emit(item);
  }
}
