<mat-form-field [formGroup]="fgName" appearance="outline" [style.width]="width">
	<mat-label *ngIf="label">{{ label | translate }}</mat-label>
	<input
		#inputText
		matInput
		[autocomplete]="autoComplete"
		[placeholder]="placeholder | translate"
		(change)="setErrorMessage()"
		[formControlName]="controlName"
	/>
	{{ autoFocus ? inputText.focus() : "" }}
	<mat-icon
		class="small-icon cursor-pointer"
		[(colorPicker)]="selectedColor"
		[cpPosition]="position"
		[cpOKButton]="true"
		[cpOKButtonText]="'SUBMIT' | translate"
		[cpOKButtonClass]="'btn submit-btn'"
		[cpSaveClickOutside]="false"
		[cpCloseClickOutside]="false"
		[cpCancelButton]="true"
		[cpCancelButtonText]="'CANCEL' | translate"
		[cpCancelButtonClass]="'btn cancel-btn'"
		(colorPickerChange)="
			selectedColor = $event; fgName?.get(controlName)?.setValue($event)
		"
		matSuffix
		>palette</mat-icon
	>
	<mat-error *ngIf="fgName?.get(controlName).invalid && showError">{{
		errorMessage
	}}</mat-error>
</mat-form-field>
