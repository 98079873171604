import {
	HttpClient,
	HttpClientModule,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { AppGlobalRippleOptions } from './core/services/global-ripple-options.service';
import { AuthGuard } from './core/guards/auth.guard';
import { AuthRedirectGuard } from './core/guards/auth-redirect.guard';
import { DatePipe } from '@angular/common';

import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FullCalendarModule } from '@fullcalendar/angular';
import { StoreModule } from '@ngrx/store';
import {
	initialAppState,
	REDUCER_TOKEN,
	reducers,
	clearStateMetaReducer,
} from './state/app.state';
import { EffectsModule } from '@ngrx/effects';
import { RoleGuard } from './core/guards/role.guard';
import { TakeTestGuard } from './modules/my-courses/_guards/take-test.guard';
export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http);
}

// Zendesk Configuration
export class ZendeskConfig extends NgxZendeskWebwidgetConfig {
	accountUrl = 'surgelearning1.zendesk.com';
	callback(zE) {
		zE.setLocale('en');
	}
}
import {
	NgxZendeskWebwidgetModule,
	NgxZendeskWebwidgetConfig,
} from 'ngx-zendesk-webwidget';
import { MsalModule, MsalService, MSAL_INSTANCE } from '@azure/msal-angular';
import {
	IPublicClientApplication,
	PublicClientApplication,
} from '@azure/msal-browser';
import { environment } from 'src/environments/environment';

export const msalInstanceFactory = (): IPublicClientApplication => {
	return new PublicClientApplication({
		auth: {
			clientId: environment.ms.clientId,
			redirectUri: environment.ms.redirectUri,
		},
	});
};
@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
			defaultLanguage: 'en',
		}),
		StoreModule.forRoot(REDUCER_TOKEN, {
			metaReducers: [clearStateMetaReducer],
			initialState: initialAppState,
			runtimeChecks: {
				strictStateImmutability: false,
				strictActionImmutability: false,
			},
		}),
		EffectsModule.forRoot(),
		HttpClientModule,
		SharedModule,
		NgbModule,
		BrowserAnimationsModule,
		FullCalendarModule,
		NgxZendeskWebwidgetModule.forRoot(ZendeskConfig),
		MsalModule,
	],
	providers: [
		{
			provide: REDUCER_TOKEN,
			useValue: reducers,
		},
		{
			provide: MAT_RIPPLE_GLOBAL_OPTIONS,
			useExisting: AppGlobalRippleOptions,
		},
		{
			provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
			useValue: { appearance: 'outline' },
		},
		AuthGuard,
		RoleGuard,
		TakeTestGuard,
		AuthRedirectGuard,
		DatePipe,
		{
			provide: MSAL_INSTANCE,
			useFactory: msalInstanceFactory,
		},
		MsalService,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
