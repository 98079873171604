import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-catch-scorm-progress',
	templateUrl: './catch-scorm-progress.component.html',
	styleUrls: ['./catch-scorm-progress.component.scss'],
})
export class CatchScormProgressComponent implements OnInit {
	constructor(private route: ActivatedRoute) {}

	ngOnInit(): void {
		console.log('Hello');
		window.top.postMessage({ data: 'scorm-window-closed' });
	}
}
