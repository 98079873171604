<div class="outline-label form-group" [formGroup]="formGroup">
	<ng-select
		[class.custom-ng-select]="formGroup?.get(controlName)?.value"
		[ngClass]="{
			'is-invalid':
				formGroup?.get(controlName)?.errors &&
				formGroup?.get(controlName)?.touched &&
				showError
		}"
		[items]="data"
		[placeholder]="placeholder | translate"
		[bindLabel]="bindLabel"
		[bindValue]="bindValue"
		[formControlName]="controlName"
		[searchable]="searchable"
		[style.width]="width"
		[disabled]="disabled"
		[clearable]="clearable"
	></ng-select>
	<label
		*ngIf="label"
		[for]="label"
		class="label"
		[ngClass]="{
			'is-invalid':
				formGroup?.get(controlName)?.errors &&
				formGroup?.get(controlName)?.touched
		}"
	>
		{{ label | translate }}
		<ng-container *ngIf="isRequired">*</ng-container>
	</label>
</div>
<small
	class="text-error"
	*ngIf="
		formGroup?.get(controlName)?.errors &&
		formGroup?.get(controlName)?.touched &&
		showError
	"
>
	{{ errorMessage }}
</small>
