<span class="icon-container d-flex" [class.sub-tick-icon]="showTick">
	<!-- <span
		class="material-icons cursor-pointer"
		matTooltip="{{ 'MANAGE_CERTIFICATES' | translate }}"
		>workspace_premium</span
	> -->
	<div
		matTooltip="{{ 'MANAGE_CERTIFICATES' | translate }}"
		class="certificate-icon cursor-pointer"
	>
		<img src="../../../../../assets/images/certificate-icon.png" />
	</div>
</span>
