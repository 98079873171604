<span>
	<ng-container
		*ngIf="dueDate.daysPast && dueDate.daysPast > -1; else dateValue"
	>
		{{ dueDate.daysPast + " " + ("DAYS_PAST" | translate) }}
	</ng-container>
	<ng-template #dateValue>
		<span>
			{{ dueDate.dueDate ?? dueDate | dateFormat }}
		</span>
	</ng-template>
</span>
