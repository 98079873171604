import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-notification-popup',
	templateUrl: './notification-popup.component.html',
	styleUrls: ['./notification-popup.component.scss'],
})
export class NotificationPopupComponent implements OnInit {
	unsubscriber$ = new Subject();
	notifications = [];

	constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

	ngOnInit(): void {
		this.notifications = this.data;
	}
}
