<ng-template #content let-modal>
	<div class="modal-header align-items-center justify-content-center">
		<span class="modal-title">{{ localData.modalHeader | translate }}</span>
		<span class="material-icons-outlined help-btn ms-2" (click)="getHelp()">
			help_outline
		</span>
		<button
			type="button"
			class="btn close-btn"
			aria-label="Close"
			(click)="closeModal()"
		>
			<span class="material-icons-outlined"> close </span>
		</button>
	</div>
	<div class="modal-body">
		<ng-content></ng-content>
	</div>
</ng-template>
