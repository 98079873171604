import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-report-summary-card',
	templateUrl: './report-summary-card.component.html',
	styleUrls: ['./report-summary-card.component.scss'],
})
export class ReportSummaryCardComponent {
	@Input() name: string = '';
	@Input() value: string | number = 0;
}
