import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { logout } from 'src/app/modules/authentication/_state/authentication.action';
import { AuthenticationState } from 'src/app/modules/authentication/_state/authentication.reducer';
import { ErrorMessages } from '../helpers/error-messages.class';
import { NotificationService } from '../services/notification.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
	constructor(
		private notificationService: NotificationService,
		private authStore: Store<AuthenticationState>
	) {}

	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			catchError((requestError) => {
				if (requestError instanceof HttpErrorResponse) {
					if (requestError.status === 401) {
						// User session is invalid. Logout user.
						this.notificationService.error(
							ErrorMessages.INVALID_SESION
						);
						setTimeout(() => {
							this.authStore.dispatch(logout({ redirect: true }));
						}, 100);
					}
					if (
						requestError.status === 409 ||
						requestError.status === 400
					) {
						let errorMsg = '';
						if (requestError?.error?.error && requestError?.error?.error?.message) {
							errorMsg = requestError?.error?.error?.message;
						} else {
							errorMsg = requestError?.error?.message;
						}
						if (errorMsg) {
							this.notificationService.error(errorMsg);
						}
					}
					if (requestError.status === 500) {
						let errorMsg = '';
						if (requestError?.error?.data && requestError?.error?.data[0]) {
							errorMsg = requestError?.error?.data[0].message;
						} else {
							errorMsg = requestError?.error?.message;
						}
						if (errorMsg) {
							this.notificationService.error(
								`Error - ${errorMsg}`
							);
						}
					}
				} else {
					this.notificationService.error('api error');
				}
				return throwError(() => requestError.error);
			})
		);
	}
}
