import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { take, takeUntil } from 'rxjs';
import { NotificationService } from 'src/app/core/services/notification.service';
import {
	ChangeCoursesStatusPayload,
	Courses,
} from 'src/app/shared/models/categories';
import { ResourceCenterService } from '../_services/resource-center.service';

@Component({
	selector: 'app-inactivate-resource',
	templateUrl: './inactivate-resource.component.html',
	styleUrls: ['./inactivate-resource.component.scss'],
})
export class InactivateResourceComponent implements OnInit {
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private resourceCenterService: ResourceCenterService,
		private notificationService: NotificationService,
		private dialogRef: MatDialogRef<InactivateResourceComponent>
	) {}

	ngOnInit(): void {
		console.log('data: ', this.data);
	}

	inactivateResources() {
		switch (this.data.module) {
			case 'ALL_RESOURCES':
				this.inactivateCourses();
				break;
			case 'DOCUMENT_MANAGER':
				this.inactivateDocs();
				break;
			default:
				console.error('Invalid Action!');
		}
	}

	inactivateCourses() {
		// if (this.isLps) {
		// 	this.inactivateLps();
		// 	return;
		// }
		let payload: ChangeCoursesStatusPayload = {
			courses: this.data?.course?.id ? [this.data?.course?.id] : [],
			learningPathwayIdList: this.data?.course?.learningPathwayId
				? [this.data?.course?.learningPathwayId]
				: [],
		};

		this.resourceCenterService
			.inactivateCourses(payload)
			.pipe(take(1))
			.subscribe({
				next: (res) => {
					this.dialogRef.close({ success: true, data: res });
				},
				error: (err) => {
					this.notificationService.error('api error');
				},
			});
	}

	inactivateDocs() {
		const payload = [this.data?.course?.id];
		this.resourceCenterService
			.inactivateDocs(payload)
			.pipe(take(1))
			.subscribe({
				next: (res) => {
					this.dialogRef.close({ success: true, data: res });
				},
				error: (err) => {
					this.notificationService.error('api error');
				},
			});
	}

	closeModal() {
		this.dialogRef.close({ success: false, data: null });
	}
}
