<button
	[disabled]="disabled"
	[class]="themeClass"
	class="btn btn02"
	(click)="clicked()"
>
	<div class="d-flex gap-3 align-items-center">
		<mat-icon class="material-icons-outlined" *ngIf="icon">{{
			icon
		}}</mat-icon>
		<ng-content></ng-content>
	</div>
</button>
