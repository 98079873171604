<div class="row">
	<div class="d-flex justify-content-start align-items-center limit-dropdown col-12 col-sm-6 col-md-6 col-lg-4"
	>
		<span class="items-label"> {{ itemLabelText | translate }}: </span>
		<ng-select
			[items]="localData.pageLimits"
			[(ngModel)]="limit"
			[clearable]="false"
			[searchable]="false"
			class="ms-2"
			(change)="changePageLimit()"
		></ng-select>
		<span class="items-label ms-3">
			{{ offset + 1 }} -
			{{
				listSize < offset + limit || limit === localData.allContents
					? listSize
					: offset + limit
			}}
		</span>
		<span class="ms-1 me-1 small-label">{{ "OF" | translate }}</span>
		<span class="items-label"> {{ listSize }} </span>
	</div>
	<div class="d-flex justify-content-end col-12 col-sm-6 col-md-6 col-lg-8 align-items-center">
		<mat-paginator
			slrPaginator
			[pageSize]="limit"
			[pageSizeOptions]="paginationSizes"
		>
		</mat-paginator>
	</div>
</div>
