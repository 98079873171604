import { Action, createAction, props } from '@ngrx/store';
import { UserSkillInterface } from '../../manage-users/_models/user-skill.interface';
import {
	ProfileSaveSkillInterface,
	ProfileSkillsInterface,
	UserSavedSkillInterface,
} from '../_models/profile-skills.interfaces';
import {
	UserProfileBasicDetailsInterface,
	UserProfileInterface,
} from '../_models/user-profile-interface';

export enum UserProfileActionType {
	LOAD_USER_PROFILE = '[User Profile] Load User Profile',
	LOAD_USER_PROFILE_SUCCESS = '[User Profile] Load User Profile Success',
	LOAD_USER_PROFILE_FAILURE = '[User Profile] Load User Profile Failure',

	LOAD_PROFILE_SKILLS = '[User Profile] Load Skills',
	LOAD_PROFILE_SKILLS_SUCCESS = '[User Profile] Load Skills Success',
	LOAD_PROFILE_SKILLS_FAILURE = '[User Profile] Load Skills Failure',
	LOAD_ALL_SKILLS = '[User Profile] Load All Skills',
	LOAD_PROFILE_SKILLS_WITH_FILTER = '[User Profile] Load All Skills with filter',
	LOAD_ALL_SKILLS_SUCCESS = '[User Profile] Load All Skills Success',
	LOAD_ALL_SKILLS_FAILURE = '[User Profile] Load All Skills Failure',

	ADD_NEW_SKILL = '[User Profile] Add Skill',
	ADD_TO_REMOVE_LIST = '[User Profile] Add Skillto remove list',
	REMOVE_USER_SKILL = '[User Profile] Remove Skill',
	UPDATE_SKILL = '[User Profile] Update Skill',
	DELETE_SKILL = '[User Profile] Delete Skill from Server',

	UPDATE_PROFILE_PIC = '[User Profile] update profile picture',
	UPDATE_BASIC_DETAIL = '[User Profile] update basic detail',
	UPDATE_SKILL_AND_CRED = '[User Profile] update skills and credentials',

	ADD_CERT_TO_SKILL = '[User Profile] Add Certificate to skill',
	SAVE_SKILL = '[User Profile] Change isTemp to false',

	RESET_USER_SKILLS = '[User Profile] reset user skills',

	/**
	 *  New action to perform all skill actions together
	 * 1. add new skills
	 * 2. remove existing skills
	 * 3. update skills
	 */
	DO_SKILL_ACTION = '[User Profile] do skill actions',
}

export const loadUserProfile = createAction(
	UserProfileActionType.LOAD_USER_PROFILE
);
export const loadUserProfileSuccess = createAction(
	UserProfileActionType.LOAD_USER_PROFILE_SUCCESS,
	props<{ profile: UserProfileInterface }>()
);
export const loadUserProfileFailure = createAction(
	UserProfileActionType.LOAD_USER_PROFILE_FAILURE
);
export const loadUserSkills = createAction(
	UserProfileActionType.LOAD_PROFILE_SKILLS,
	props<{ userId?: number }>()
);
export const loadUserSkillsSuccess = createAction(
	UserProfileActionType.LOAD_PROFILE_SKILLS_SUCCESS,
	props<{
		skills: Array<ProfileSkillsInterface>;
		userSkills: Array<UserSavedSkillInterface>;
	}>()
);
export const loadUserSkillsFailure = createAction(
	UserProfileActionType.LOAD_PROFILE_SKILLS_FAILURE
);
export const loadAllSkills = createAction(
	UserProfileActionType.LOAD_PROFILE_SKILLS
);
export const loadAllSkillsWithFilter = createAction(
	UserProfileActionType.LOAD_PROFILE_SKILLS_WITH_FILTER,
	props<{ filter: number; userId?: number }>()
);
export const loadAllSkillsSuccess = createAction(
	UserProfileActionType.LOAD_PROFILE_SKILLS_SUCCESS,
	props<{ skills: Array<ProfileSkillsInterface> }>()
);
export const loadAllSkillsFailure = createAction(
	UserProfileActionType.LOAD_PROFILE_SKILLS_FAILURE
);

export const addSkill = createAction(
	UserProfileActionType.ADD_NEW_SKILL,
	props<{ skill: UserSavedSkillInterface }>()
);
export const removeSkill = createAction(
	UserProfileActionType.REMOVE_USER_SKILL,
	props<{ id: number }>()
);

export const updateSkill = createAction(
	UserProfileActionType.UPDATE_SKILL,
	props<{ id: number; skill: UserSavedSkillInterface }>()
);

export const updateProfilePicture = createAction(
	UserProfileActionType.UPDATE_PROFILE_PIC,
	props<{ newPath: string; s3Path: string }>()
);
export const updateBasicDetail = createAction(
	UserProfileActionType.UPDATE_BASIC_DETAIL,
	props<{ payload: UserProfileBasicDetailsInterface }>()
);
export const updateSkillsAndCred = createAction(
	UserProfileActionType.UPDATE_SKILL_AND_CRED,
	props<{ payload: UserSkillInterface }>()
);

export const deleteSkill = createAction(
	UserProfileActionType.DELETE_SKILL,
	props<{ ids: number[]; userId: number }>()
);

export const addCertToSkill = createAction(
	UserProfileActionType.ADD_CERT_TO_SKILL,
	props<{ skillId: number; path: string }>()
);
export const saveTempSkills = createAction(UserProfileActionType.SAVE_SKILL);

export const updateRemoveSkillArray = createAction(
	UserProfileActionType.ADD_TO_REMOVE_LIST,
	props<{ id?: number }>()
);

export const doSkillAction = createAction(
	UserProfileActionType.DO_SKILL_ACTION,
	props<{
		add: any;
		update: any;
		remove: any;
		skillTypeFilter: number;
		isManageUsers?: boolean;
		comingFromSkill?: boolean;
		userId?: number;
	}>()
);

export const resetUserSkill = createAction(
	UserProfileActionType.RESET_USER_SKILLS
);
