<div class="con">
	<div class="row">
		<div class="col-12">
			<div class="d-flex px-3 align-items-center">
				<div class="flex-grow-1"></div>
			</div>
		</div>
	</div>
	<div class="row flex-grow-1 g-0 conversations custom-scrollbar">
		<div class="col-12">
			<ng-container
				*ngIf="
					notifications && notifications.length > 0;
					else noNotifications
				"
			>
				<ng-container *ngFor="let thread of notifications">
					<div
						class="thread"
						[class.flag-viewed]="!thread.flagViewed"
					>
						<div
							class="cont d-flex align-items-center justify-content-between"
						>
							<div class="d-flex gap-3">
								<div class="d-flex flex-column">
									<h5>
										{{ thread.title }}
									</h5>
									<h6>{{ thread.contentDetail }}</h6>
								</div>
							</div>
							<div>
								<h6>{{ thread.createDate | date }}</h6>
							</div>
						</div>
					</div>
				</ng-container>
			</ng-container>
			<ng-template #noNotifications>
				<div class="no-notifications">
					{{ "NO_NOTIFICATIONS" | translate }}
				</div>
			</ng-template>
		</div>
	</div>
</div>
