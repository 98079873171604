<div class="cont">
	<mat-icon class="report">report</mat-icon>
	<div class="head">Profile Not Found</div>
	<div class="message">
		The profile with email {{ data ?? "" }} was not found in our system.
		Please contact adminitrator for further support.
	</div>
	<div class="close">
		<slr-btn01 mat-dialog-close>Close</slr-btn01>
	</div>
</div>
