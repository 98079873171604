import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil, take } from 'rxjs';
import { NotificationService } from 'src/app/core/services/notification.service';
import { TableColumn } from 'src/app/shared/components/tables/slr-table/slr-table.component';
import { CodeListInterface } from '../../../_models/manage-codes.interface';
import { SettingsService } from '../../../_services/settings.service';
import { LocalDataService } from 'src/app/core/services/local-data.service';

@Component({
	selector: 'app-manage-code-list',
	templateUrl: './manage-code-list.component.html',
	styleUrls: ['./manage-code-list.component.scss'],
})
export class ManageCodeListComponent implements OnInit, OnDestroy {
	public columns: TableColumn[] = [
		{
			name: 'EDUCATION_CODE_NAME',
			dataKey: 'displayName',
			isVisible: true,
			isSortable: true,
		},
		{
			name: 'DOCUMENT_SHARER',
			isVisible: true,
			dataKey: '_docSharer',
			clickAction: true,
			clickActionType: 'UPDATE',
			isSortable: true,
		},
	];
	public grandParentData: Array<CodeListInterface>;
	public parentData: Array<CodeListInterface>;
	public vendorData: Array<CodeListInterface>;
	public childData: Array<CodeListInterface>;
	public sharedData: Array<CodeListInterface>;
	private unsubscriber$ = new Subject<void>();
	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private settingsService: SettingsService,
		private localDataService: LocalDataService,
		private notificationService: NotificationService
	) {}

	ngOnInit(): void {
		this.getCodes();
		this.getCodeList();
	}

	public addNewCode(): void {
		this.router.navigate(['manage-codes/add-new']);
	}

	public getCodeList(): void {
		this.route.data.pipe(takeUntil(this.unsubscriber$)).subscribe({
			next: (res) => {
				this.grandParentData = res['codeList'][0];
				this.parentData = res['codeList'][1];
				this.vendorData = res['codeList'][2];
				this.childData = res['codeList'][3];
				this.sharedData = res['codeList'][4];

				this.sortCodes();
			},
		});
	}

	sortCodes() {
		this.grandParentData = this.localDataService.sortStrings(
			this.grandParentData,
			'clientName'
		);
		this.parentData = this.localDataService.sortStrings(
			this.parentData,
			'clientName'
		);
		this.vendorData = this.localDataService.sortStrings(
			this.vendorData,
			'clientName'
		);
		this.childData = this.localDataService.sortStrings(
			this.childData,
			'clientName'
		);
		this.sharedData = this.localDataService.sortStrings(
			this.sharedData,
			'clientName'
		);
	}

	public removeCode(
		type: number,
		event: { action: string; row: CodeListInterface }
	) {
		if (event.action === 'UPDATE') {
			this.settingsService
				.updateManageCodes(event.row.codeLookupId, !event.row.docSharer)
				.pipe(take(1))
				.subscribe({
					next: (res) => {
						this.notificationService.success(
							'Code Updated Successfully'
						);
					},
					error: (err) => {
						this.notificationService.error('Unable to update.');
					},
				});
		} else {
			this.settingsService
				.deleteManageCodes(event.row.codeLookupId, event.row.groupCode)
				.pipe(take(1))
				.subscribe({
					next: (res) => {
						this.notificationService.success(
							'Code Removed Successfully'
						);
						this.updateList(type, event.row);
					},
				});
		}
	}

	private updateList(type: number, toRemove: CodeListInterface): void {
		switch (type) {
			case 1:
				this.grandParentData = this.grandParentData.filter(
					(x) => x.codeLookupId !== toRemove.codeLookupId
				);
				break;
			case 2:
				this.parentData = this.parentData.filter(
					(x) => x.codeLookupId !== toRemove.codeLookupId
				);
				break;
			case 3:
				this.childData = this.childData.filter(
					(x) => x.codeLookupId !== toRemove.codeLookupId
				);
				break;
			case 4:
				this.vendorData = this.vendorData.filter(
					(x) => x.codeLookupId !== toRemove.codeLookupId
				);
				break;
			case 5:
				this.sharedData = this.sharedData.filter(
					(x) => x.codeLookupId !== toRemove.codeLookupId
				);
				break;
		}
	}

	private getCodes(): void {
		// this.settingsService.getAllCodes().subscribe({
		// 	next: (res) => {},
		// });
	}
	ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.unsubscribe();
	}
}
