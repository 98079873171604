import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-broadcast-message',
  templateUrl: './broadcast-message.component.html',
  styleUrls: ['./broadcast-message.component.scss']
})
export class BroadcastMessageComponent implements OnInit {

  constructor() { }
  @Input() content: any;

  ngOnInit(): void {
    console.log('content',this.content)

  }
  gotoregister(obj:any){
    window.open(obj.link);

  }

}
