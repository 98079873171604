import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import moment from 'moment';
import { GenericHelper } from 'src/app/core/helpers/generic-helper.class';

@Pipe({
	name: 'stringDateFormat',
})
export class StringDateFormatPipe extends DatePipe implements PipeTransform {
	override transform(value: any): any {
		if (!moment.isDate(value) && GenericHelper.getDateFromString(value))
			return super.transform(
				GenericHelper.getDateFromString(value),
				'yyyy-MM-dd'
			);
		return super.transform(value, 'yyyy-MM-dd');
	}
}
