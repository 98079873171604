<ng-select
	[items]="items"
	[bindLabel]="bindLabel"
	[bindValue]="bindValue"
	[formControl]="control"
	[class.custom-dropdown]="control?.value"
	[searchable]="searchable"
	[clearable]="clearable"
	[groupBy]="groupBy"
	[appendTo]="appendTo"
	(change)="updateSelectionChange()"
	[class]="customClass"
	[notFoundText]="'NO_ITEMS_FOUND' | translate"
></ng-select>
<label for="category" class="label" [class.mandatory]="isMandatory">
	{{ label | translate }}
</label>
