import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
	selector: 'app-custom-ng-select',
	templateUrl: './custom-ng-select.component.html',
	styleUrls: ['./custom-ng-select.component.scss'],
})
export class CustomNgSelectComponent {
	@Input() items: Array<any> = [];
	@Input() bindValue: string = '-';
	@Input() bindLabel: string = 'label';
	@Input() clearable: boolean = true;
	@Input() multiple: boolean = false;
	@Input() label: string = '';
	@Input() searchable: boolean = true;
	@Input() customClass: string = '';
	@Input() control: any = new FormControl('');
	@Input() isMandatory: boolean = false;
	@Input() groupBy: string = '';
	@Input() appendTo: string = '';
	@Output() selectChange: EventEmitter<any> = new EventEmitter();

	updateSelectionChange() {
		this.selectChange.next(this.control.value);
	}
}
