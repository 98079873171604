import {
	Component,
	OnDestroy,
	OnInit,
	ViewChild,
	ElementRef,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { PermissionHelper } from 'src/app/core/helpers/permission-helper.class';
import { LocalDataService } from 'src/app/core/services/local-data.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UserPermissionService } from 'src/app/modules/authentication/_services/user-permissions.service';
import { DropdownInterface } from 'src/app/shared/components/dropdowns/slr-drp01/dropdown.model';
import { SettingsService } from '../../_services/settings.service';
import { FileUploadService } from 'src/app/core/services/file-upload.service';
import { HttpEventType } from '@angular/common/http';
import { SharedState } from 'src/app/shared/_state/shared.reducer';
import { Store, select } from '@ngrx/store';
import { updateLocationSettings } from 'src/app/shared/_state/shared.actions';
import { BrandSettingsMapForAPI } from '../../_models/settins-map.enum';
import { GenericHelper } from 'src/app/core/helpers/generic-helper.class';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-main-settings',
	templateUrl: './main-settings.component.html',
	styleUrls: [
		'./main-settings.component.scss',
		'../settings/settings.component.scss',
	],
})
export class MainSettingsComponent implements OnInit, OnDestroy {
	@ViewChild('fileSelectInput') public fileSelect: ElementRef;
	public permissionHelper = new PermissionHelper();
	public settingsForm: FormGroup;
	public settings: any;
	public lmsSettings = [];
	public enterpriseSettings = [];
	public qrmSettings = [];
	public policySettings = [];
	public skillsSettings = [];
	public otherSettings = [];
	public emailSettings = [];

	public settingOptions = {
		policy_send_comments_to_reviewer: [
			{
				id: 1,
				name: 'Reviewers',
			},
			{
				id: 2,
				name: 'Approvers',
			},
			{
				id: 3,
				name: 'Both',
			},
		],
		show_dates_in_green_header: [
			{
				id: 1,
				name: 'Show Dates And Names',
				selected: false,
			},
			{
				id: 2,
				name: 'Hide Dates And Names',
				selected: false,
			},
			{
				id: 3,
				name: 'Show Only Dates',
				selected: false,
			},
		],
	};

	public showDetailsData: Array<DropdownInterface> = [
		{
			id: 1,
			name: 'Show Dates And Names',
		},
		{
			id: 2,
			name: 'Hide Dates And Names',
		},
		{
			id: 3,
			name: 'Show Only Dates',
		},
	];
	public policyCommnetsData: Array<DropdownInterface> = [
		{
			id: 1,
			name: 'Reviewers',
		},
		{
			id: 2,
			name: 'Approvers',
		},
		{
			id: 3,
			name: 'Both',
		},
	];
	public headerFontData: Array<DropdownInterface> = [
		{
			id: 1,
			name: 'Default',
		},
		{
			id: 2,
			name: 'Courier',
		},
		{
			id: 3,
			name: 'Halvetica',
		},
		{
			id: 4,
			name: 'Times New Roman',
		},
	];

	public mainSettingsTabPermissions = [
		this.permissionHelper.settingsModule.SETINGS_LMS,
		this.permissionHelper.settingsModule.SETTINGS_QRM,
		this.permissionHelper.settingsModule.SETTINGS_POLICY_PROFESSIONAL,
		this.permissionHelper.settingsModule.SETTINGS_OTHER,
		this.permissionHelper.settingsModule.SETTINGS_SKILLS,
		this.permissionHelper.settingsModule.SETTINGS_EMAIL,
	].reduce((a, b, permission) => {
		return {
			...a,
			[b]: this.checkTabSettings(b),
		};
	}, {});

	public fileUploading: boolean = false;
	public fileUploadProgress: number = 0;
	public uploadingFileFor: string;
	public defaultColors: any;

	unsubscriber$ = new Subject();

	constructor(
		private _fb: FormBuilder,
		private activatedRoute: ActivatedRoute,
		private localDataService: LocalDataService,
		private settingsService: SettingsService,
		private notificationService: NotificationService,
		private permissionService: UserPermissionService,
		private uploadService: FileUploadService,
		private sharedStore: Store<SharedState>,
		private translateService: TranslateService
	) {
		this.defaultColors = this.localDataService.defaultColors;
	}

	ngOnInit(): void {
		this.getSettingssData();
	}

	ngOnDestroy(): void {
		this.unsubscriber$.next('');
	}

	getSettingssData() {
		this.activatedRoute.data
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe((data) => {
				let settings = data['masterDatas'].data[0];
				settings.forEach((setting) => {
					if (!setting.order) {
						setting.order = 1000;
					}
				});
				this.settings = this.sortStrings(
					this.addTranslatedNameToSettings(settings),
					'order'
				);
				this.transformSettingsData();
				this.filterSettings();
				this.initForm();
			});
	}

	public sortStrings(arrayInput, sortKey): any[] {
		return arrayInput?.sort((first, second) =>
			first[sortKey] < second[sortKey] ? -1 : 1
		);
	}

	private addTranslatedNameToSettings(data: any): Array<any> {
		return this.localDataService.addTranslatedNameToObject(
			data,
			'name',
			'translatedName'
		);
	}

	transformSettingsData() {
		this.settings.forEach((setting) => {
			if (!setting.type) return;
			setting['changed'] = false;
			if (
				setting.type.includes('checkbox') ||
				setting.type.includes('checkbox_') ||
				setting.type.includes('check')
			) {
				this.updateCheckboxOptions(setting);
			}

			if (
				setting.type.includes('select') ||
				setting.type.includes('select_') ||
				setting.type.includes('multiple_')
			) {
				setting['useType'] = 'select';
			}

			if (setting.type.includes('date')) {
				setting['useType'] = 'date';
			}

			if (setting.type.includes('text')) {
				setting['useType'] = 'text';
			}
		});
	}

	updateCheckboxOptions(setting) {
		switch (setting.name) {
			case 'policy_send_comments_to_reviewer':
				setting['useType'] = 'radio';
				setting['options'] =
					this.settingOptions.policy_send_comments_to_reviewer;
				break;
			case 'show_dates_in_green_header':
				setting['useType'] = 'radio';
				setting['options'] =
					this.settingOptions.show_dates_in_green_header;

				let x = this.settingOptions.show_dates_in_green_header.forEach(
					(option) => {
						if (option.id == setting.value) {
							option.selected = true;
						}
					}
				);
				break;
			default:
				setting['useType'] = 'checkbox';
				setting.value = setting.value == 0 ? false : true;
				break;
		}
	}

	filterSettings() {
		this.lmsSettings = this.settings.filter(
			(setting) => setting?.categoryId === 1
		);

		this.enterpriseSettings = this.settings.filter(
			(setting) => setting?.categoryId === 2
		);

		this.qrmSettings = this.settings.filter(
			(setting) => setting?.categoryId === 3
		);

		this.policySettings = this.settings.filter(
			(setting) => setting?.categoryId === 4
		);

		this.otherSettings = this.settings.filter(
			(setting) => setting?.categoryId === 5
		);

		this.skillsSettings = this.settings.filter(
			(setting) => setting?.categoryId === 6
		);

		this.emailSettings = this.settings.filter(
			(setting) => setting?.categoryId === 7
		);
	}

	private initForm(): void {
		let formGroup = {};
		this.settings.forEach((setting) => {
			formGroup[setting.name] = new FormControl(setting.value);
		});
		this.settingsForm = new FormGroup(formGroup);
		this.settingsForm
			.get('client_education_year')
			.setValidators(
				GenericHelper.anniversaryDateValidator(
					this.settingsForm,
					'client_education_year'
				)
			);
		this.observeFormChanges();
	}

	observeFormChanges() {
		this.settingsForm.valueChanges
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe((value) => {});
	}

	// private initForm(): void {
	// 	this.settingsForm = this._fb.group({
	// 		HIDE_COURSE_WITH_DUE_DATES_PAST_A_CERTAIN_THRESHOLD:
	// 			new FormControl(),
	// 		HIDE_COURSE_THRESHOLD: new FormControl(),
	// 		DEFAULT_QUIZ_PASSING_SCORE: new FormControl(),
	// 		DEFAULT_TEST_ATTEMPTS: new FormControl(),
	// 		QRM_SHOW_DATE_FOR_ONE_TIME_SCHEDULE: new FormControl(),
	// 		QRM_SHOW_DATE_FOR_EVERY_WEEK_SCHEDULE: new FormControl(),
	// 		QRM_SHOW_DATE_FOR_EVERY_MONTH_SCHEDULE: new FormControl(),
	// 		QRM_SHOW_DATE_FOR_EVERY_2_MONTH_SCHEDULE: new FormControl(),
	// 		QRM_SHOW_DATE_FOR_EVERY_3_MONTH_SCHEDULE: new FormControl(),
	// 		QRM_SHOW_DATE_FOR_EVERY_4_MONTH_SCHEDULE: new FormControl(),
	// 		QRM_SHOW_DATE_FOR_EVERY_6_MONTH_SCHEDULE: new FormControl(),
	// 		QRM_SHOW_DATE_FOR_EVERY_YEAR_SCHEDULE: new FormControl(),
	// 		HEADER_LIGHT_FONT: new FormControl(),
	// 		OVERRIDE_EMAIL_TO_SEND_PENDING_APPROVAL_EMAIL_TO: new FormControl(),
	// 		CC_EMAIL: new FormControl(),
	// 		OVERRIDE_EMAIL: new FormControl(),
	// 	});
	// }

	slrCheckboxChange(event, setting) {
		setting.changed = !setting.changed;
		setting.value = event;
	}

	radioChange(event, setting) {
		console.log('Radio Event: ', event);
		this.settingsForm?.get(setting?.name).setValue(event?.id);
	}

	saveSettings(editHierarchy: boolean) {
		this.settings.forEach((setting) => {
			if (setting.useType != 'checkbox') {
				setting.value = this.settingsForm.get(setting.name).value;
			}
		});

		let editedSettings = [];
		let editedSettingsWithName = [];
		this.settings.forEach((setting) => {
			editedSettings.push({
				settingsId: setting.id,
				settingsMapId: setting.mapId,
				value: setting.value,
			});
			editedSettingsWithName.push({
				settingsId: setting.id,
				settingsMapId: setting.mapId,
				value: setting.value,
				name: setting.name,
			});
		});

		const brandingSettings = this.settings
			.filter((x) => x.brandSetting)
			.reduce((a, x) => {
				return {
					...a,
					[BrandSettingsMapForAPI[x.name]]: x.value,
				};
			}, {});

		let payload = {
			editHierarchy: editHierarchy,
			editing: true,
			locCode: this.localDataService.getSiteCode(),
			locationId: this.localDataService.getLocationId(),
			settingsLocEditList: editedSettings,
			userId: this.localDataService.getUserId(),
			brandingSettings,
		};

		this.settingsService
			.editSettings(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe({
				next: (value) => {
					if (value && value.code && value.code == 400) {
						this.notificationService.error(value.message);
						return;
					}
					this.notificationService.success(
						'Updated settings successfully!'
					);
					this.sharedStore.dispatch(
						updateLocationSettings({
							payload: editedSettingsWithName,
						})
					);
				},
				error: (err) => {
					this.notificationService.error(
						'Unable to update settings!'
					);
				},
			});
	}

	public checkTabSettings(id: number): boolean {
		return this.permissionService.checkUserPrivilege(id);
	}

	selectFile(event: string): void {
		this.uploadingFileFor = event;
		this.fileSelect.nativeElement.click();
	}

	uploadFile(event: any): void {
		const acceptedTypes = ['image/jpeg', 'image/jpg', 'image/png'];
		if (!acceptedTypes.some((x) => x == event.srcElement.files[0].type)) {
			this.notificationService.error(
				this.translateService.instant('LOGO_ALLOWED_FORMATS')
			);
			return;
		}
		this.fileUploading = true;
		this.uploadService
			.uploadFile(
				event.srcElement.files[0],
				event.srcElement.files[0].type.split('/').pop()
			)
			.subscribe({
				next: (res) => {
					if (res.type == HttpEventType.UploadProgress) {
						this.fileUploadProgress = Math.round(
							100 * (res.loaded / res.total)
						);
					}
					if (res.type === HttpEventType.Response) {
						this.settingsForm
							.get(this.uploadingFileFor)
							.setValue(res.body?.data[0]?.filePath);
						setTimeout(() => {
							this.fileSelect.nativeElement.value = '';
							this.uploadingFileFor = undefined;
							this.fileUploading = false;
							this.fileUploadProgress = 0;
						}, 500);
					}
				},
			});
	}

	removeFile(settingName: string): void {
		this.settingsForm.get(settingName).setValue(null);
	}

	previewFile(settingName: string): void {
		this.uploadService
			.getPresignedUrl(this.settingsForm.get(settingName).value)
			.subscribe({
				next: (res) => {
					window.open(res.s3PresingedURL, '_blank');
				},
			});
	}

	resetColor(settingName: string) {
		const settingControlName = BrandSettingsMapForAPI[settingName];
		this.settingsForm
			.get(settingName)
			.setValue(this.defaultColors[settingControlName]);
	}
}
