<mat-form-field class="auth" [formGroup]="fgName">
	<!-- <mat-label>{{ label }}</mat-label> -->
	<input
		type="number"
		(focusout)="setErrorMessage()"
		matInput
		[formControlName]="controlName"
		[placeholder]="placeholder"
	/>
	<mat-icon
		*ngIf="fgName.get(controlName).valid"
		class="color-green-1"
		matSuffix
		>done</mat-icon
	>
	<mat-error *ngIf="fgName.get(controlName).invalid">{{
		errorMessage
	}}</mat-error>
</mat-form-field>
