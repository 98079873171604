import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'getCourseStatusClass',
})
export class GetCourseStatusClassPipe implements PipeTransform {
	transform(dueDate: string, endOfYear: string, isCompleted: number): string {
		const today = new Date();
		const endOfYearDate = new Date(endOfYear);
		const bufferedDate = new Date();
		bufferedDate.setMonth(today.getMonth() + 3);
		if (dueDate) {
			if (endOfYearDate >= today) {
				if (new Date(dueDate) < new Date() && !isCompleted) {
					return 'passed-due-skill';
				} else if (
					new Date(dueDate) >= new Date() &&
					new Date(dueDate) < bufferedDate
				) {
					return 'coming-due-skill';
				} else {
					return '';
				}
			} else {
				if (new Date(dueDate) < new Date() && !isCompleted) {
					return 'passed-due-skill';
				} else {
					return '';
				}
			}
		}
		return '';
	}
}
