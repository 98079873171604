<div class="label-outlined form-group" [formGroup]="fgName">
	<ng-select
		class="custom-dropdown"
		[class.gray-bg]="classes"
		[class.custom-dropdown]="fgName?.get(controlName)?.value"
		[ngClass]="{
			'is-invalid':
				fgName?.get(controlName)?.errors &&
				fgName?.get(controlName)?.touched
		}"
		[items]="data"
		[bindLabel]="bindLabel"
		[bindValue]="bindValue"
		[placeholder]="placeholder | translate"
		[formControlName]="controlName"
		[searchable]="searchable"
		[style.width]="width"
		[disabled]="disabled"
		(change)="change($event)"
		[clearable]="clearable"
		[appendTo]="appendTo"
		[groupBy]="groupBy"
	></ng-select>
	<label *ngIf="label" [for]="label" class="label">
		{{ label | translate }}
	</label>
	<div
		class="errors"
		*ngIf="
			fgName?.get(controlName)?.errors &&
			fgName?.get(controlName)?.touched
		"
	>
		{{ errorMessage }}
	</div>
</div>
