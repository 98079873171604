<ng-container *ngIf="question.type === 'mcq'">
	<div
		class="option-container mb-2 py-3 mat-elevation-z5"
		[class]="
			optionSelected
				? optionCorrect
					? 'correct'
					: 'incorrect'
				: optionCorrect
				? 'correct'
				: ''
		"
		[class.mat-elevation-z5]="optionSelected || optionCorrect"
	>
		<div class="row">
			<div class="col-12">
				<div class="d-flex flex-row w-100">
					<div class="flex-grow-1">
						<div class="row">
							<div class="col-12">
								<mat-radio-button
									[checked]="optionSelected"
									[disabled]="true"
								>
									<div [innerHtml]="option.name"></div>
								</mat-radio-button>
							</div>
							<div class="col-12">
								<div
									*ngIf="
										optionCorrect &&
										option.data &&
										option.data.rationale &&
										option.data.rationale.length > 0
									"
									class="rationale ellipsis-text mat-elevation-z3"
								>
									<h6>Rationale</h6>
									<div
										[innerHtml]="option.data.rationale"
									></div>
								</div>
							</div>
						</div>
					</div>
					<div *ngIf="optionSelected">
						<span
							class="d-flex align-items-center justify-content-end w-100"
							[class]="
								optionCorrect ? 'color-green-0' : 'color-red-0'
							"
						>
							<mat-icon>{{
								optionCorrect ? "check" : "close"
							}}</mat-icon>
							{{
								optionCorrect
									? "Correct Answer"
									: "Wrong Answer"
							}}
						</span>
					</div>
				</div>
			</div>
			<!-- <div class="col-5">
				<slr-rbtn01
					[checked]="optionSelected"
					[label]="option.name"
					[image]="option.data.image?.src | async"
					[disabled]="true"
				></slr-rbtn01>
				<mat-radio-button [checked]="optionSelected" [disabled]="true">
					<div [innerHtml]="option.name"></div>
				</mat-radio-button>
			</div>
			<div class="col-5">
				<div
					*ngIf="
						optionCorrect &&
						option.data &&
						option.data.rationale &&
						option.data.rationale.length > 0
					"
					class="rationale"
				>
					<h6>Rationale</h6>
					<p>
						{{ option.data.rationale }}
					</p>
				</div>
			</div>
			<div class="col-2" *ngIf="optionSelected">
				<span
					class="d-flex align-items-center justify-content-end w-100"
					[class]="optionCorrect ? 'color-green-0' : 'color-red-0'"
				>
					<mat-icon>{{ optionCorrect ? "check" : "close" }}</mat-icon>
					{{ optionCorrect ? "Correct Answer" : "Wrong Answer" }}
				</span>
			</div> -->
		</div>
	</div>
</ng-container>
<ng-container *ngIf="question.type === 'multiple'">
	<div
		class="option-container"
		[class]="
			optionSelected
				? optionCorrect
					? 'correct'
					: 'incorrect'
				: optionCorrect
				? 'correct'
				: ''
		"
	>
		<div class="row">
			<div class="col-12">
				<div class="d-flex flex-row w-100">
					<div class="flex-grow-1">
						<div class="row">
							<div class="col-12">
								<mat-checkbox
									[checked]="optionSelected"
									[disabled]="true"
								>
									<div [innerHtml]="option.name"></div>
								</mat-checkbox>
							</div>
							<div class="col-12">
								<div
									*ngIf="
										optionCorrect &&
										option.data &&
										option.data.rationale &&
										option.data.rationale.length > 0
									"
									class="rationale ellipsis-text mat-elevation-z3"
								>
									<h6>Rationale</h6>
									<div
										[innerHtml]="option.data.rationale"
									></div>
								</div>
							</div>
						</div>
					</div>
					<div *ngIf="optionSelected">
						<span
							class="d-flex align-items-center justify-content-end w-100"
							[class]="
								optionCorrect ? 'color-green-0' : 'color-red-0'
							"
						>
							<mat-icon>{{
								optionCorrect ? "check" : "close"
							}}</mat-icon>
							{{
								optionCorrect
									? "Correct Answer"
									: "Wrong Answer"
							}}
						</span>
					</div>
				</div>
			</div>
			<!-- <div class="col-5">
				<mat-checkbox [checked]="optionSelected" [disabled]="true"
					>{{ option.name }}
					<ng-container *ngIf="option.data && option.data.image">
						<img [src]="option.data.image.src | async" alt="" />
					</ng-container>
				</mat-checkbox>
			</div>
			<div class="col-5">
				<div *ngIf="optionCorrect" class="rationale">
					<h6>Rationale</h6>
					<div [innerHtml]="option.data.rationale"></div>
				</div>
			</div>
			<div class="col-2" *ngIf="optionSelected">
				<span
					class="d-flex align-items-center justify-content-end w-100"
					[class]="optionCorrect ? 'color-green-0' : 'color-red-0'"
				>
					<mat-icon>{{ optionCorrect ? "check" : "close" }}</mat-icon>
					{{ optionCorrect ? "Correct Answer" : "Wrong Answer" }}
				</span>
			</div> -->
		</div>
	</div>
</ng-container>
<ng-container *ngIf="question.type === 'paragraph'">
	<slr-textarea
		[value]="paraghraphAnswer"
		[label]="'Your Answer'"
		[disabled]="true"
	></slr-textarea>

	<div class="rationale">
		<h6>Rationale</h6>
		<div [innerHtml]="option?.data?.rationale"></div>
		<!-- <p>
			{{ option.data.rationale }}
		</p> -->
	</div>
</ng-container>
