import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'durationInHour',
})
export class DurationInHourPipe implements PipeTransform {
	transform(duration: string): string {
		if (duration) {
			const hrs = duration.split(':');
			const minuteToHrs = Number(hrs[1]) / 60;
			const secToHrs = Number(hrs[2]) / 3600;
			return `${(+hrs[0] + minuteToHrs + secToHrs).toFixed(2)}`;
		}
		return duration;
	}
}
