import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { take } from 'rxjs';
import { GenericHelper } from 'src/app/core/helpers/generic-helper.class';
import { LocalDataService } from 'src/app/core/services/local-data.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import {
	BulkAssignSkillToUsersPayloadInterface,
	ProfileSkillsInterface,
	UserSavedSkillInterface,
} from 'src/app/modules/user-profile/_models/profile-skills.interfaces';
import { DropdownInterface } from 'src/app/shared/components/dropdowns/slr-drp01/dropdown.model';
import { UserSkillInterface } from '../../_models/user-skill.interface';
import { ManageUserService } from '../../_services/manage-user.service';

@Component({
	selector: 'app-add-user-skill',
	templateUrl: './add-user-skill.component.html',
	styleUrls: ['./add-user-skill.component.scss'],
})
export class AddUserSkillComponent implements OnInit {
	@Output() onCancel = new EventEmitter<void>();
	@Output() onSave = new EventEmitter<UserSkillInterface>();

	public skillForm: FormGroup;
	public skillData: Array<DropdownInterface>;
	public selectedSkill: ProfileSkillsInterface;
	public allSkills: Array<ProfileSkillsInterface>;
	public selectedUsers: any[];
	public competenceData = [
		{ id: 1, name: 'Somewhat Competent', data: { selected: false } },
		{ id: 2, name: 'Competent', data: { selected: false } },
		{ id: 3, name: 'Very Competent', data: { selected: false } },
	];

	constructor(
		private _fb: FormBuilder,
		private route: ActivatedRoute,
		private router: Router,
		private localData: LocalDataService,
		private manageuserService: ManageUserService,
		private notificationService: NotificationService
	) {
		this.skillForm = this._fb.group({
			skill: new FormControl(),
			competence: new FormControl(),
			obtainedDate: new FormControl(),
			renewalDate: new FormControl(),
			alertDate: new FormControl(),
		});
	}

	private initData(): void {
		if (this.localData.selectedUsersToAddSkill.value == null) {
			this.router.navigate(['/manage-users']);
			return;
		}
		this.selectedUsers = this.localData.selectedUsersToAddSkill.value;
		this.route.data.subscribe({
			next: (res) => {
				const allSkills: Array<ProfileSkillsInterface> =
					res['allSkills'];
				console.log(allSkills);
				this.skillData = allSkills.map((x) => {
					return {
						id: x.skillId,
						name: x.skillName,
						data: { skill: x },
					};
				});
			},
		});
	}

	private initForm(): void {
		let obtainedDate = moment(new Date());
		let renewalDate = moment(new Date());
		let alertDate = moment(new Date());
		renewalDate.add(
			this.selectedSkill.newRenewalFrequencyInYears == 0
				? this.selectedSkill.defaultRenewalFrequency
				: this.selectedSkill.newRenewalFrequencyInYears,
			'y'
		);
		alertDate = moment(renewalDate).subtract(
			this.selectedSkill.defaultAlertsThresholdInDays
				? this.selectedSkill.defaultAlertsThresholdInDays
				: 10,
			'd'
		);
		this.skillForm
			.get('competence')
			.setValue(this.competenceData.find((x) => x.id == 1).name);
		this.competenceData.map((x) => (x.data.selected = 1 == x.id));
		this.skillForm
			.get('obtainedDate')
			.setValue(
				GenericHelper.getDateFromString(
					obtainedDate.format('DD-MM-YYYY')
				)
			);
		this.skillForm
			.get('renewalDate')
			.setValue(
				GenericHelper.getDateFromString(
					renewalDate.format('DD-MM-YYYY')
				)
			);
		this.skillForm
			.get('alertDate')
			.setValue(
				GenericHelper.getDateFromString(alertDate.format('DD-MM-YYYY'))
			);
		if (!this.selectedSkill.datesOverridesAllowed) {
			this.skillForm.get('obtainedDate').disable();
			this.skillForm.get('renewalDate').disable();
		}
	}

	public selectSkill(event: DropdownInterface): void {
		this.selectedSkill = event ? event.data.skill : null;
		this.initForm();
	}

	public onCancelClick(): void {
		this.router.navigate(['/manage-users']);
	}

	public onSaveClick(): void {
		const payload: BulkAssignSkillToUsersPayloadInterface = {
			alertDate: moment(this.skillForm.get('alertDate').value).format(
				'DD-MM-YYYY'
			),
			certificatePath: null,
			courseId: this.selectedSkill.courseId,
			locationId: null,
			obtainedDate: moment(
				this.skillForm.get('obtainedDate').value
			).format('DD-MM-YYYY'),
			renewalDate: moment(this.skillForm.get('renewalDate').value).format(
				'DD-MM-YYYY'
			),
			skillCompetenceLevelId: this.getComId(),
			skillId: this.selectedSkill.skillId,
			skillType: this.selectedSkill.skillType,
			userIds: this.localData.selectedUsersToAddSkill.value.map(
				(x) => x.id
			),
		};
		this.manageuserService
			.bulkAssignSkillToUsers(payload)
			.pipe(take(1))
			.subscribe({
				next: (res) => {
					this.notificationService.success(
						'Skills added successfully'
					);
					this.router.navigate(['/manage-users']);
				},
			});
	}

	private getComId(): number {
		const value = this.skillForm.get('competence').value;
		if (!value) return null;
		return this.competenceData.find((x) => x.name == value).id;
	}

	ngOnInit(): void {
		this.initData();
	}
}
