import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { ReportTabs } from '../_models/reports-overview.model';
import { ExportReportsService } from '../_services/export-reports.service';
import { PermissionHelper } from 'src/app/core/helpers/permission-helper.class';
import { UserPermissionService } from '../../authentication/_services/user-permissions.service';

@Component({
	selector: 'app-reports-overview',
	templateUrl: './reports-overview.component.html',
	styleUrls: ['./reports-overview.component.scss'],
})
export class ReportsOverviewComponent implements OnInit {
	public reportTabs: Array<ReportTabs> = AppConstants.reportTypes;
	public selectedIndex = 0;
	public isChild: boolean = false;
	private permissionHelper = new PermissionHelper();

	constructor(
		private router: Router,
		private exportReportService: ExportReportsService,
		private userPermissionService: UserPermissionService
	) {}

	ngOnInit(): void {
		this.checkLocationType();
		this.redirectToRoute();
		this.exportReportService.getSurgeLogo();
	}
	private checkLocationType(): void {
		this.isChild = localStorage.getItem('currentLocationType') === 'child';
		this.isChild &&
			(this.reportTabs = this.reportTabs.filter(
				(tab) => !tab.checkLocation
			));
	}

	public openReport(url): void {
		if (url === 'other-reports') {
			return this.redirectToOtherReports();
		}
		this.router.navigate([url]);
	}
	private redirectToOtherReports(): void {
		if (
			this.userPermissionService.checkUserPrivilege(
				this.permissionHelper.SurveysModule.SURVEY_MANAGE_SURVEY
			)
		) {
			this.router.navigate(['reports/other/survey-summary']);
		} else {
			this.router.navigate(['reports/other/program-evaluation']);
		}
	}
	redirectToRoute() {
		this.selectedIndex = 0;
		for (const i in this.reportTabs) {
			if (
				this.router.url
					.split('/')[2]
					.includes(this.reportTabs[i].url.split('/')[1])
			) {
				this.selectedIndex = +i;
				break;
			}
		}
	}
}
