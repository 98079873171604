<span class="cursor-pointer" (click)="downloadCertificate()">
	<ng-content></ng-content>
	<span
		class="ms-auto medal-icon material-icons-outlined"
		matTooltip="{{ 'VIEW_CERTIFICATE' | translate }}"
		matTooltipPosition="above"
	>
		workspace_premium
	</span></span
>
