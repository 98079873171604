import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'slr-progress-bar',
	templateUrl: './progress-bar.component.html',
	styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent {
	@Input() public type: string = 'primary';
	@Input() public value: number;
	@Input() public showValue: boolean = true;
	@Input() public width: string = '100%';
}
