import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
} from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { LocalDataService } from 'src/app/core/services/local-data.service';

@Component({
	selector: 'app-filter-dropdown',
	templateUrl: './filter-dropdown.component.html',
	styleUrls: ['./filter-dropdown.component.scss'],
})
export class FilterDropdownComponent implements OnChanges {
	@Input() filter: any;
	@Input() groupBy: string;
	@Output() change = new EventEmitter();
	public filterForm: FormGroup;
	constructor(
		private formDirective: FormGroupDirective,
		private localDataService: LocalDataService
	) {}

	ngOnChanges(): void {
		switch (this.filter?.label) {
			case 'name':
				if (
					this.filter?.showLabel.toLowerCase() !== 'month' &&
					this.filter?.showLabel.toLowerCase() !== 'time_frame'
				) {
					this.sortCourseName();
				}
				break;
			case 'educationGroupName':
				this.sortEducationGroups();
				break;
			case 'skillName':
				this.sortSkills();
				break;
			case 'fullName':
				this.sortSupervisors();
				break;
			case 'surveyName':
				this.sortSurveys();
				break;
		}

		this.filterForm = this.formDirective.control;
	}

	sortEducationGroups() {
		let showAll = this.filter.data[0];
		this.filter.data = this.localDataService.sortStrings(
			this.filter.data.slice(1),
			'educationGroupName'
		);
		this.filter.data.unshift(showAll);
	}

	sortSkills() {
		let showAll = this.filter.data[0];
		this.filter.data = this.localDataService.sortStrings(
			this.filter.data.slice(1),
			'skillName'
		);
		this.filter.data.unshift(showAll);
	}

	sortSupervisors() {
		let showAll = this.filter.data[0];
		this.filter.data = this.localDataService.sortStrings(
			this.filter.data.slice(1),
			'fullName'
		);
		this.filter.data.unshift(showAll);
	}

	sortSurveys() {
		this.filter.data = this.localDataService.sortStrings(
			this.filter.data,
			'surveyName'
		);
	}
	sortCourseName() {
		let showAll = this.filter.data[0];
		this.filter.data = this.localDataService.sortStrings(
			this.filter.data.slice(1),
			'name'
		);
		this.filter.data.unshift(showAll);
	}

	selectChange(event) {
		this.change.emit(event);
	}
}
