import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-profile-not-found-popup',
	templateUrl: './profile-not-found-popup.component.html',
	styleUrls: ['./profile-not-found-popup.component.scss'],
})
export class ProfileNotFoundPopupComponent {
	constructor(@Inject(MAT_DIALOG_DATA) public data: string) {}
}
