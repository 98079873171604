<div
	class="chat-footer"
	[class]="{ 'reply-active': tempMessage?.value.isReply }"
>
	<div class="d-flex align-items-center gap-2 py-2 g-0 px-4">
		<div class="flex-grow-1" [formGroup]="messageForm">
			<textarea
				formControlName="message"
				#messageInput
				type="text"
				class="message-input"
				[placeholder]="'NEW_MESSAGE_PLACEHOLDER' | translate"
				(keyup.enter)="sendMessage($event)"
			></textarea>
		</div>

		<div class="footer-actions">
			<mat-icon [matMenuTriggerFor]="addMenu">add_circle</mat-icon>
			<mat-icon>emoji_emotions</mat-icon>
		</div>
		<mat-menu
			class="add-menu"
			#addMenu="matMenu"
			xPosition="before"
			yPosition="above"
		>
			<button mat-menu-item>
				<mat-icon>attach_file</mat-icon> Insert Document
			</button>
			<button mat-menu-item>
				<mat-icon>image</mat-icon> Add Photos(s)
			</button>
		</mat-menu>
		<slr-btn02 (onClick)="sendMessage($event)">{{
			"SEND" | translate
		}}</slr-btn02>
	</div>

	<ng-container *ngIf="tempMessage?.value.isReply">
		<div class="reply">
			<h6>Replying To</h6>
			<span>@{{ tempMessage.value.replyMessage.from.name }}</span>
			<p>
				{{ tempMessage.value.replyMessage.message }}
			</p>
			<mat-icon class="remove-reply">close</mat-icon>
		</div>
	</ng-container>
</div>
