import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'slr-btn03',
  templateUrl: './slr-btn03.component.html',
  styleUrls: ['./slr-btn03.component.scss']
})
export class SlrBtn03Component implements OnInit {

  @Output() public onClick: EventEmitter<void>
  constructor() { 
    this.onClick = new EventEmitter<void>()
  }

  ngOnInit(): void {
  }

  public clicked(): void {
    this.onClick.emit()
  }

}
