<div class="card">
	<div class="card-body p-0">
		<div class="header-block p-3 d-flex justify-content-center">
			{{ name | translate }}
		</div>
		<div class="p-3 mb-4">
			<div
				class="d-flex col-sm-12 justify-content-center align-items-center"
			>
				<h1>
					{{ value }}
				</h1>
			</div>
		</div>
	</div>
</div>
