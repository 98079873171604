<mat-form-field class="file-input" appearance="outline">
	<div>
		<mat-toolbar>
			<!-- Display files names -->
			<input matInput [(ngModel)]="fileAttr" readonly name="name" />
			<!-- Browse Button -->
			<button mat-flat-button color="primary">
				{{ "BROWSE" | translate }}
			</button>
		</mat-toolbar>
		<!-- Fetch selected filed on change -->
		<input
			[multiple]="multiple"
			type="file"
			#fileInput
			id="uploadFile"
			(change)="
				multiple ? uploadMultipleFileEvt($event) : uploadFileEvt($event)
			"
			name="uploadFile"
			[accept]="acceptTypes"
		/>
	</div>
</mat-form-field>
