export enum SettingsModulePrivilegesEnum {
	MODULE_NAME = 209,
	USER_ACCESS = 210,
	PRODUCT_ACCESS = 211,
	MANAGE_CODES = 212,
	SETINGS_LMS = 222,
	SETTINGS_ENTERPRISE = 223,
	SETTINGS_QRM = 224,
	SETTINGS_POLICY_PROFESSIONAL = 225,
	SETTINGS_OTHER = 226,
	SETTINGS_SKILLS = 227,
	SETTINGS_EMAIL = 228,

	USER_ACCESS_ENTERPRISE = 229,
	USER_ACCESS_LMS = 230,
	USER_ACCESS_POLICY_PROFESSIONAL = 231,
	USER_ACCESS_QRM = 232,
}
