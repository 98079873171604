import { TranslatePipe } from '@ngx-translate/core';
import { DropdownInterface } from 'src/app/shared/components/dropdowns/slr-drp01/dropdown.model';

export enum AppLanguages {
	ENGLISH = 'en',
	FRENCH = 'fr',
}

export interface AppLanguageIdMap {
	[id: number]: AppLanguages;
}

export class AppLanguageMapper {
	private idToCodeMap: AppLanguageIdMap = {
		1: AppLanguages.ENGLISH,
		2: AppLanguages.FRENCH,
		// Add more mappings as needed
	};

	private static translatePipe: TranslatePipe;
	constructor(translatePipe: TranslatePipe) {
		AppLanguageMapper.translatePipe = translatePipe;
	}

	// Method to get language code based on ID
	getLanguageCode(id: number): AppLanguages | undefined {
		return this.idToCodeMap[id];
	}

	// Method to get language ID based on code
	getLanguageId(code: AppLanguages): number | undefined {
		const id = Object.entries(this.idToCodeMap).find(
			([key, value]) => value === code
		)?.[0];
		return id ? parseInt(id, 10) : undefined;
	}

	// Method to get language name based on code
	private static getLanguageNameByCode(code: AppLanguages): string {
		switch (code) {
			case AppLanguages.ENGLISH:
				return this.translatePipe.transform('LANG_ENGLISH');
			case AppLanguages.FRENCH:
				return this.translatePipe.transform('LANG_FRENCH');
			default:
				return 'Unknown';
		}
	}

	// Method to get language name based on code
	getLanguageObjectAsDropdownInterface(): Array<DropdownInterface> {
		return Object.entries(this.idToCodeMap).reduce((a, b) => {
			return [
				...a,
				{
					id: parseInt(b[0], 10),
					name: AppLanguageMapper.getLanguageNameByCode(b[1]),
				},
			];
		}, []);
	}
}
