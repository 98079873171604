import { MyCoursesModulePrivilegesEnum } from '../models/_module_enums/my-courses-module-privileges.enum';
import { RosourceCenterModulePrivilegesEnum } from '../models/_module_enums/resource-center-module-privileges.enum';
import { SurveysModulePrivilegesEnum } from '../models/_module_enums/surveys-module-privileges.enum';
import { LiveEventModulePrivilegesEnum } from '../models/_module_enums/liveevents-module-privileges.enum';
import { SkillsModulePrivilegesEnum } from '../models/_module_enums/skills-module.privileges.enum';
import { QRMModulePrivilegesEnum } from '../models/_module_enums/qrm-module.privileges.enum';
import { DashboardModulePrivilegesEnum } from '../models/_module_enums/dashboard-module.privileges.enum';
import { ManageUsersModulePrivilegesEnum } from '../models/_module_enums/manage-users-module.privileges.enum';
import { ManageQiModulePrivilegesEnum } from '../models/_module_enums/manage-qi.privileges.enum';
import { SettingsModulePrivilegesEnum } from '../models/_module_enums/settings-module-privilege.enum';
import { ReportModulePrivilegesEnum } from '../models/_module_enums/reports-module.privilege.enum';
import { MyProfileModulePrivilegesEnum } from '../models/_module_enums/my-profile-module.privilege.enum';
import { BroadCastActionsEnum } from '../models/_module_enums/broadcast.module.enum';
export class PermissionHelper {
	public get dashboardModule(): typeof DashboardModulePrivilegesEnum {
		return DashboardModulePrivilegesEnum;
	}
	public get resourceModule(): typeof RosourceCenterModulePrivilegesEnum {
		return RosourceCenterModulePrivilegesEnum;
	}
	public get Broadcastmodule(): typeof BroadCastActionsEnum {
		return BroadCastActionsEnum;
	}
	public get myCourses(): typeof MyCoursesModulePrivilegesEnum {
		return MyCoursesModulePrivilegesEnum;
	}
	public get SurveysModule(): typeof SurveysModulePrivilegesEnum {
		return SurveysModulePrivilegesEnum;
	}
	public get SkillsModule(): typeof SkillsModulePrivilegesEnum {
		return SkillsModulePrivilegesEnum;
	}
	public get LiveeventModule(): typeof LiveEventModulePrivilegesEnum {
		return LiveEventModulePrivilegesEnum;
	}
	public get QrmModule(): typeof QRMModulePrivilegesEnum {
		return QRMModulePrivilegesEnum;
	}
	public get manageUsersModule(): typeof ManageUsersModulePrivilegesEnum {
		return ManageUsersModulePrivilegesEnum;
	}
	public get manageQiModule(): typeof ManageQiModulePrivilegesEnum {
		return ManageQiModulePrivilegesEnum;
	}
	public get settingsModule(): typeof SettingsModulePrivilegesEnum {
		return SettingsModulePrivilegesEnum;
	}
	public get reportsModule(): typeof ReportModulePrivilegesEnum {
		return ReportModulePrivilegesEnum;
	}
	public get myProfileModule(): typeof MyProfileModulePrivilegesEnum {
		return MyProfileModulePrivilegesEnum;
	}
}
