<form [formGroup]="searchForm">
	<div class="search-box" [class]="themeClass">
		<div class="input-group">
			<input
				type="text"
				class="form-control"
				placeholder="{{ placeHolderText | translate }}"
				formControlName="searchText"
				[(ngModel)]="searchText"
			/>
			<div
				class="input-group-append d-flex align-items-center justify-content-center"
			>
				<i
					*ngIf="_searchText.length === 0 || !showCancelIcon"
					class="material-icons-outlined"
				>
					{{ icon }}
				</i>
				<i
					*ngIf="showCancelIcon && _searchText.length > 0"
					[matTooltip]="'Reset Search'"
					(click)="clearInput()"
					(keyup)="clearInput()"
					class="material-icons-outlined cursor-pointer"
				>
					{{ cancelIcon }}
				</i>
			</div>
		</div>
	</div>
</form>
