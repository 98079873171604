<span
	(click)="openConfirmationModal()"
	class="customize cursor-pointer color-blue-2"
>
	{{ "CUSTOMIZE" | translate }}
</span>
<app-confirmation-modal
	[openModal]="openModal"
	(modalClosed)="emitSelectedValues(false)"
>
	<div class="col-12 row p-2 customize">
		<div class="col-6" *ngFor="let column of displayingList; index as i">
			<mat-checkbox
				[(ngModel)]="column.isShown"
				[class.ms-2]="i % 2 !== 0"
			>
				{{ column.columnName | translate }}
			</mat-checkbox>
		</div>
	</div>
	<div class="d-flex justify-content-center modal-footer">
		<button class="btn submit-btn" (click)="emitSelectedValues(true)">
			{{ "SUBMIT" | translate }}
		</button>
		<button class="btn cancel-btn ms-2" (click)="emitSelectedValues(false)">
			{{ "CANCEL" | translate }}
		</button>
	</div>
</app-confirmation-modal>
