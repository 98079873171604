<ng-container *ngIf="question.type === 'mcq'">
	<div
		class="option-container"
	>
		<div class="row">
			<div class="col-5">
				<slr-rbtn01
					[checked]="optionSelected"
					[label]="option.name"
					[disabled]="true"
				></slr-rbtn01>
			</div>
			<div class="col-5">
				<div class="rationale">
					{{option.data?.rationale}}
				</div>
			</div>
			<div class="col-2" *ngIf="optionSelected">
				<span
					class="d-flex align-items-center justify-content-end w-100"
					[class]="optionCorrect ? 'color-green-0' : 'color-red-0'"
				>
					<mat-icon>{{ optionCorrect ? "check" : "close" }}</mat-icon>
					{{ optionCorrect ? "Correct Answer" : "Wrong Answer" }}
				</span>
			</div>
		</div>
	</div>
</ng-container>
<ng-container *ngIf="question.type === 'multiple'">
	<div
		class="option-container"
	>
		<div class="row">
			<div class="col-5">
				<mat-checkbox [checked]="optionSelected" [disabled]="true">{{
					option.name
				}}</mat-checkbox>
			</div>
			<div class="col-5">
				<div class="rationale">
					<p>
						{{option.data?.rationale}}
					</p>
				</div>
			</div>
			<div class="col-2" *ngIf="optionSelected">
				<span
					class="d-flex align-items-center justify-content-end w-100"
					[class]="optionCorrect ? 'color-green-0' : 'color-red-0'"
				>
					<mat-icon>{{ optionCorrect ? "check" : "close" }}</mat-icon>
					{{ optionCorrect ? "Correct Answer" : "Wrong Answer" }}
				</span>
			</div>
		</div>
	</div>
</ng-container>
<ng-container *ngIf="question.type === 'paragraph'">
	<slr-textarea
		[value]="question.selectedAnswers.toString()"
		[label]="'Your Answer'"
		[disabled]="true"
	></slr-textarea>
</ng-container>
