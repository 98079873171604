<button
	[disabled]="disabled"
	class="btn btn01"
	[class]="themeClass"
	(click)="clicked()"
>
	<div class="d-flex gap-3 align-items-center">
		<mat-icon *ngIf="icon">{{ icon }}</mat-icon>
		<ng-content></ng-content>
	</div>
</button>
