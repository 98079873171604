export enum SiteCodeLevelMapping {
	GRAND_PARENT = 'g',
	PARENT = 'p',
	VENDOR_PARENT = 'vp',
	A = 'a',
	VENDOR = 'v',
	SHARED = 's',
	CHILD = 'c',
	AD = 'ad',
}
