import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-form-wizard',
  templateUrl: './form-wizard.component.html',
  styleUrls: ['./form-wizard.component.scss'],
})
export class FormWizardComponent {
  @Input() wizardSteps: Array<any> = [];
  @Input() activeStep = 3;
  @Output() stepChanged = new EventEmitter();

  changeActiveStep(i: number) {
    this.activeStep = i + 1;
    this.stepChanged.next(i + 1);
  }
}
