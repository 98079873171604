import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'slr-btn04',
  templateUrl: './slr-btn04.component.html',
  styleUrls: ['./slr-btn04.component.scss'],
})
export class SlrBtn04Component {
  @Input() public icon: string;
  @Output() public onClick: EventEmitter<void>;

  constructor() {
    this.onClick = new EventEmitter<void>();
  }

  public clicked(): void {
    this.onClick.emit();
  }
}
