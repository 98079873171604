<div class="padding-wrapper">
	<div class="d-flex-flex-column">
		<div class="d-flex">
			<div class="content-header">
				{{ "ADD_NEW_CODE" | translate }}
			</div>
		</div>

		<div class="row my-3 gap-2 flex-grow-1">
			<div
				class="code-container col py-4 mat-elevation-z6 rounded bg-white"
			>
				<div
					class="filter-data flex-column justify-content-start align-items-center"
				>
					<div class="col-12 client-level">
						{{ "GRAND_PARENT" | translate }}
					</div>
					<div class="col-12 search-box">
						<slr-input
							[placeholder]="'Search'"
							[fgName]="searchFormGroup"
							[controlName]="'grandParentSearch'"
						></slr-input>
					</div>
				</div>
				<div class="code-listing-container mt-4 ms-2">
					<div
						class="mb-4"
						*ngFor="let code of filteredGrandParentData"
					>
						<mat-checkbox
							[checked]="!code.newCode"
							(change)="setToggles($event, code)"
						>
							{{ code.clientName }}
						</mat-checkbox>
					</div>
				</div>
			</div>
			<div
				class="code-container col py-4 mat-elevation-z6 rounded bg-white"
			>
				<div
					class="filter-data flex-column justify-content-start align-items-center"
				>
					<div class="col-12 client-level">
						{{ "PARENT" | translate }}
					</div>
					<div class="col-12 search-box">
						<slr-input
							[placeholder]="'Search'"
							[fgName]="searchFormGroup"
							[controlName]="'parentSearch'"
						></slr-input>
					</div>
				</div>
				<div class="code-listing-container mt-4 ms-2">
					<div class="mb-4" *ngFor="let code of filteredParentData">
						<mat-checkbox
							[checked]="!code.newCode"
							(change)="setToggles($event, code)"
						>
							{{ code.clientName }}
						</mat-checkbox>
					</div>
				</div>
			</div>
			<div
				class="code-container col py-4 mat-elevation-z6 rounded bg-white"
			>
				<div
					class="filter-data flex-column justify-content-start align-items-center"
				>
					<div class="col-12 client-level">
						{{ "VENDOR" | translate }}
					</div>
					<div class="col-12 search-box">
						<slr-input
							[placeholder]="'Search'"
							[fgName]="searchFormGroup"
							[controlName]="'vendorSearch'"
						></slr-input>
					</div>
				</div>
				<div class="code-listing-container mt-4 ms-2">
					<div class="mb-4" *ngFor="let code of filteredVendorData">
						<mat-checkbox
							[checked]="!code.newCode"
							(change)="setToggles($event, code)"
						>
							{{ code.clientName }}
						</mat-checkbox>
					</div>
				</div>
			</div>
			<div
				class="code-container col py-4 mat-elevation-z6 rounded bg-white"
			>
				<div
					class="filter-data flex-column justify-content-start align-items-center"
				>
					<div class="col-12 client-level">
						{{ "SHARED" | translate }}
					</div>
					<div class="col-12 search-box">
						<slr-input
							[placeholder]="'Search'"
							[fgName]="searchFormGroup"
							[controlName]="'sharedSearch'"
						></slr-input>
					</div>
				</div>
				<div class="code-listing-container mt-4 ms-2">
					<div class="mb-4" *ngFor="let code of filteredSharedData">
						<mat-checkbox
							[checked]="!code.newCode"
							(change)="setToggles($event, code)"
						>
							{{ code.clientName }}
						</mat-checkbox>
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col">
				<slr-btn02 (onClick)="submit()" class="me-4">{{
					"SUBMIT" | translate
				}}</slr-btn02>
				<slr-btn01 (onClick)="routeBack()">{{
					"CANCEL" | translate
				}}</slr-btn01>
			</div>
		</div>
	</div>
</div>
