import { Component, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { BroadcastService } from 'src/app/core/services/broadcast.service';

@Component({
	selector: 'slr-loader',
	templateUrl: './slr-loader.component.html',
	styleUrls: ['./slr-loader.component.scss'],
})
export class SlrLoaderComponent implements OnDestroy {
	public show: boolean;
	private unsubscriber$ = new Subject<void>();
	constructor(private broadcastService: BroadcastService) {
		this.broadcastService.loaderState
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe((res) =>
				res.length ? this.showLoader() : this.hideLoader()
			);
	}
	private showLoader(): void {
		this.show = true;
	}
	private hideLoader(): void {
		this.show = false;
	}

	ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.unsubscribe();
	}
}
